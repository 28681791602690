import React from 'react';
import { Row, Col } from 'reactstrap';

const ContactInfo = (props) => {
    return (
        <Row>
            <Col md={{ size: 4, offset: 2 }}>
                <label>
                    <span className="bold-text">24/7 ACCESS On-Demand Concierge</span>
                </label>
                <p>1-833-242-2223 (TTY 711)</p>
                <label>
                    <span className="bold-text">Member Services</span>
                </label>
                <p>
                    English: 1 (866) 634-2247 (TTY 711)
                    <br />
                    Spanish: 1 (877) 399-2247
                    <br />
                    8am - 8pm, 7 days a week (excluding Thanksgiving and Christmas) Oct. 1 - March
                    31
                    <br />
                    8am - 8pm, Monday to Friday (excluding holidays)
                    <br />
                    April 1 - Sept. 30
                </p>
            </Col>
            <Col md={{ size: 4 }}>
                <label>
                    <span className="bold-text">Member Pharmacy Help</span>
                </label>
                <p>
                    Get 24/7 answers about your prescription drug benefits and copays
                    <br />1 (844) 227-7616 (TTY 711)
                </p>
                <label>
                    <span className="bold-text">Pharmacy Technical Help</span>
                </label>
                <p>
                    Resolve prescription claims and billing issues 24/7
                    <br />1 (844) 227-7615
                </p>
            </Col>
        </Row>
    );
};

export default ContactInfo;
