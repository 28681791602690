import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContext } from './ConnectForm';
import { noop, isEmpty } from 'lodash';

const FormHOC = ({ formDefaultValues, yupValidations = {}, children }) => {
    const methods = useForm({
        defaultValues: formDefaultValues,
        mode: 'onChange',
        resolver: isEmpty(yupValidations) ? undefined : yupResolver(yupValidations),
        shouldUnregister: false,
    });
    return (
        <FormContext {...methods}>
            <form onSubmit={noop}>{children}</form>
        </FormContext>
    );
};

export default FormHOC;
