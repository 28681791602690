export enum PatientPages {
    WELCOME,
    BASIC_INFO,
    MEDICAL_HISTORY,
    HEALTH_DETAILS,
    REVIEW,
    RECEIPT,
    DESIGNATE_REPRESENT,
    TREATMENT_CONSENT,
    AUTHORIZE_PHI,
}
export enum Race {
    NONE,
    AMERICAN_INDIAN_ALASKA,
    ASIAN,
    NATIVE_HW_PACIFIC,
    BLACK,
    WHITE,
    HISPANIC,
    OTHER,
    DECLINE_TO_ANSWER,
}
export enum ContactType {
    NONE,
    HOME_PHONE,
    MOBILE_PHONE,
}
export enum Languages {
    NONE,
    ENGLISH,
    SPANISH,
}
export enum MaritalStatus {
    NONE,
    SINGLE,
    MARRIED,
    DIVORCED,
    WIDOWED,
    OTHER,
}
export enum Relationship {
    NONE,
    SELF,
    SPOUSE,
    GUARDIAN,
    OTHER,
}
export enum Sex {
    NONE,
    MALE,
    FEMALE,
}
export enum YesNo {
    NONE,
    YES,
    NO,
    NA,
}
export enum TableInputActionsType {
    DELETE,
    EDIT,
}
