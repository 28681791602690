import React from 'react';
import styles from './Button.module.scss';

const Button = (props) => {
    const { type, text, onClick } = props;

    const mapTypeToStyle = (type) => {
        switch (type) {
            case 'primary':
                return styles.primary;
            default:
                return styles.primary;
        }
    };

    return (
        <button className={`${mapTypeToStyle(type)} ${styles.button}`} onClick={onClick}>
            {text}
        </button>
    );
};

export default Button;
