import React, { useCallback, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withPolicyData from '../../hoc/withPolicyData';
import withPlanData from '../../hoc/withPlanData';
import withDocumentData from '../../hoc/withDocumentData';
import SearchBar from '../molecules/SearchBar';
import { search, sendCustomEvent } from '../../common/utility';
import ResponsiveTable from '../organisms/ResponsiveTable';
import { DocumentLink } from '../molecules/DocumentLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'reactstrap';
import PageHeader from '../molecules/PageHeader';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { PDFViewerContext } from 'contexts/PdfViewerContext';
import { Input } from 'reactstrap';
import withFeatureFlags from '../../hoc/withFeatureFlags';
import { featureFlagsNames } from '../../common/constants';

const SERVICE_URL = 'api/members/documents';

const Benefits = (props) => {
    const [state, setState] = useState({});
    const { addAndOpenDocument } = useContext(PDFViewerContext);
    useEffect(() => {
        const subs = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewBenefits })
            .subscribe();
        return subs.unsubscribe();
    }, []);
    const handleDocumentClick = useCallback(
        (document) => {
            const customEventType = AUDITEVENTTYPE[document.download_custom_event_name];
            customEventType && sendCustomEvent({ eventType: customEventType });
            addAndOpenDocument({
                serviceUrl: SERVICE_URL,
                documentPath: document.id,
                displayName: document.filename,
            });
        },
        [addAndOpenDocument]
    );

    const updateSearch = (searchTerm) => {
        setState({ searchTerm });
    };

    const planYearOptions = [new Date().getFullYear(), new Date().getFullYear() + 1];

    return (
        <div>
            <PageHeader>
                Your plan -{' '}
                <strong>
                    {props.policies && props.policies.length && props.policies[0].policy_name} -
                    Benefit Highlights
                </strong>
            </PageHeader>
            <h3>Documents</h3>
            <ul className="file-list mb-5">
                {(props.documents || [])
                    .filter(
                        (document) =>
                            document && (!document.data || document.data.document_type !== 'eob')
                    )
                    .map((document, i) => (
                        <DocumentLink
                            key={i}
                            document={document}
                            onDocumentClick={handleDocumentClick}
                        />
                    ))}
            </ul>
            <SearchBar placeholder="Find keywords" change={updateSearch} />
            <Alert isOpen={props.error !== undefined} color="danger" aria-atomic="true">
                {(props.error || {}).message}
            </Alert>
            {props.featureFlags[featureFlagsNames.benefitYearDropdown] && (
                <Input
                    id={`benefit_years`}
                    name="benefit_years"
                    type="select"
                    defaultValue={new Date().getFullYear()}
                    onChange={(e) => props.changePlanYear(e.target.value)}
                >
                    {planYearOptions.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </Input>
            )}

            {props.plans === null || props.plans === undefined ? (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '275px' }}
                >
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        aria-label="Currently Loading Content"
                        size="3x"
                    />
                </div>
            ) : (
                <ResponsiveTable
                    data={search(props.plans || [], state.searchTerm)}
                    fields={[
                        {
                            header: 'Services Covered',
                            col: { lg: 3 },
                            format: 'header',
                            name: 'benefit_category_display_text',
                        },
                        {
                            header: 'What You Pay',
                            col: { lg: 3 },
                            format: 'preserve',
                            name: 'benefit_details_text',
                        },
                        {
                            header: 'More Info',
                            col: { lg: 6 },
                            format: 'preserve',
                            name: 'benefit_display_text',
                        },
                    ]}
                />
            )}
        </div>
    );
};

export default withDocumentData(withPolicyData(withPlanData(withFeatureFlags(Benefits))));

export { Benefits };

Benefits.propTypes = {
    plans: PropTypes.arrayOf(PropTypes.shape({})),
};
