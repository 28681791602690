import React, { useState, useEffect } from 'react';
import withClaimData from '../../hoc/withClaimData';
import SearchBar from '../molecules/SearchBar';
import { search, yyyyddmmTommddyyyy } from '../../common/utility';
import Claim from '../organisms/Claim';
import ResponsiveTable from '../organisms/ResponsiveTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import PageHeader from '../molecules/PageHeader';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';

const Claims = (props) => {
    const [searchTerm, updateSearchTerm] = useState(null);
    const [selectedClaim, setSelectedClaim] = useState();

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewClaims })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    return (
        <div>
            {selectedClaim ? (
                <>
                    <div className="ptr mt-5" onClick={() => setSelectedClaim()}>
                        <FontAwesomeIcon
                            icon={faLongArrowAltLeft}
                            className="mr-2"
                            aria-label="Go Back"
                        />
                        Back
                    </div>
                    <Claim {...selectedClaim} />
                </>
            ) : (
                <>
                    <PageHeader>Recent Claims</PageHeader>
                    <p>
                        Claims displayed below reflect services rendered the previous 12 months, up
                        to the 50 most recent claims. For more information about claims that are
                        older than 12 months, or claims not displayed here,{' '}
                        <Link to="/contact-us">contact us</Link>.{' '}
                    </p>
                    <SearchBar
                        placeholder="Search Claims by number, type, service provider"
                        change={updateSearchTerm}
                    />
                    <Alert isOpen={props.error !== undefined} color="danger" aria-atomic="true">
                        {(props.error || {}).message}
                    </Alert>
                    <ResponsiveTable
                        data={search(props.claims || [], searchTerm).map((claim) => ({
                            ...claim,
                            service_date: claim.claim_detail
                                ? `${yyyyddmmTommddyyyy(
                                      claim.claim_detail.service_from_date
                                  )} - ${yyyyddmmTommddyyyy(claim.claim_detail.service_to_date)}`
                                : '',
                        }))}
                        fields={[
                            {
                                header: 'Claim Number',
                                col: {
                                    md: 4,
                                    lg: 3,
                                },
                                format: 'header',
                                name: 'claim_number',
                            },
                            {
                                header: 'Service Provider',
                                col: {
                                    md: 3,
                                    lg: 4,
                                },
                                name: 'provider_name',
                            },
                            {
                                header: 'Service Date',
                                col: {
                                    md: 2,
                                },
                                name: 'service_date',
                            },
                            {
                                header: 'Claim Type',
                                col: {
                                    md: 1,
                                },
                                name: 'claim_type',
                            },
                            {
                                header: 'Your Responsibility',
                                col: {
                                    md: 2,
                                },
                                name: 'claim_detail.total_patient_responsibility_amount',
                                format: 'currency',
                            },
                        ]}
                        rowClicked={setSelectedClaim}
                    />
                </>
            )}
        </div>
    );
};

export { Claims };

export default withClaimData(Claims);
