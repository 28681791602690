import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { notifications$ } from '../../services/subjects';
import { timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Container } from 'react-bootstrap/cjs';

const Notifications = (props) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();
    const [color, setColor] = useState();

    notifications$
        .pipe(tap((_) => timer(3000).subscribe((_) => setOpen(false))))
        .subscribe((notification) => {
            setColor(notification.color || 'danger');
            setMessage(notification.message);
            setOpen(true);
        });

    return (
        <Container className="notifications">
            <Alert
                isOpen={open}
                color={color}
                fade={true}
                toggle={(_) => setOpen(false)}
                aria-atomic="true"
            >
                {message}
            </Alert>
        </Container>
    );
};

export default Notifications;
