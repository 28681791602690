import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import AppointmentSummaryNote from 'components/molecules/AppointmentSummaryNote';

const AppointmentSummary = ({ appointment }) => {
    return (
        <>
            <Row>
                <Col>
                    <div id="notes-container">
                        {appointment.visitSummaryNotes && appointment.visitSummaryNotes.length ? (
                            appointment.visitSummaryNotes.map((apptNote, idx) => (
                                <AppointmentSummaryNote
                                    author={
                                        apptNote.appointmentSummaryNoteAuthor == 0
                                            ? appointment.providerFullName
                                            : 'You'
                                    }
                                    summary={apptNote.summary}
                                    date={moment(apptNote.date).format('LLLL')}
                                    key={idx}
                                />
                            ))
                        ) : (
                            <p>No records to show</p>
                        )}
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <span className="bold-text">Appointment date:</span>{' '}
                    {moment(appointment.startTime).format('LLLL')}
                </Col>
            </Row>
            <Row>
                <Col>
                    <span className="bold-text">Provider:</span> {appointment.providerFullName}
                </Col>
            </Row>
        </>
    );
};

export default AppointmentSummary;

AppointmentSummary.propTypes = {
    appointment: PropTypes.shape({
        startTime: PropTypes.string.isRequired,
        providerFullName: PropTypes.string.isRequired,
        visitSummaryNotes: PropTypes.arrayOf(
            PropTypes.shape({
                appointmentSummaryNoteAuthor: PropTypes.number.isRequired,
                summary: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
};
