import React, { memo, DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import classNames from './Button.module.scss';

export type ButtonProps = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    variant: 'main' | 'secondary';
};

const Button = ({ variant, children, className, ...restOfProps }: ButtonProps) => {
    return (
        <button
            className={`${classNames.Button} ${classNames[variant]} ${className || ''}`}
            {...restOfProps}
        >
            {children}
        </button>
    );
};

Button.defaultProps = {
    variant: 'main',
};

const MemoButton = memo(Button);
export { MemoButton as Button };
