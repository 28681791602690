import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
let appInsights;

function getAppInsights() {
    if (appInsights) {
        return appInsights;
    }

    return {
        trackEvent: function (eventData) {
            console.log('Application Insights trackEvent: ' + JSON.stringify(eventData));
        },
    };
}

function initAppInsights(instrumentationKey, browserHistory) {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    });

    appInsights.loadAppInsights();
    return appInsights;
}

export { getAppInsights, initAppInsights, reactPlugin };
