import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormFieldInput from './FormFieldInput';
import { FormField } from './interfaces/Form';
import { Alert, AlertTitle, Avatar } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { HelpOutline } from '@mui/icons-material';

export interface GeneratedFormProps {
    formTemplateFields: FormField[] | null;
    readOnly: boolean;
}

const GeneratedForm: React.FC<GeneratedFormProps> = ({
    formTemplateFields,
    readOnly,
}: GeneratedFormProps) => {
    const {
        getValues,
        formState: { errors },
    } = useFormContext();

    const avatarStyles = {
        '&': {
            bgcolor: '#1976d2',
            height: 24,
            width: 24,
            margin: 2,
            padding: 2,
        },
    };

    return (
        <>
            {!isEmpty(formTemplateFields) ? (
                <div>
                    <ol className="d-flex flex-column gap-1">
                        {formTemplateFields?.map((field) => {
                            if (!field.options && !field.selections) {
                                return;
                            }
                            const props = {
                                field: field,
                                formResults: getValues(),
                                readOnly: readOnly,
                            };
                            return (
                                <li
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                    key={`${field.name}${field.listNumber}`}
                                >
                                    {field.listNumber && (
                                        <Avatar
                                            sx={[
                                                avatarStyles,
                                                get(errors, `${field.name}`, false) && {
                                                    '&': {
                                                        bgcolor: 'error.light',
                                                    },
                                                },
                                            ]}
                                        >
                                            {field.listNumber}
                                        </Avatar>
                                    )}
                                    <FormFieldInput {...props} />
                                </li>
                            );
                        })}
                    </ol>
                </div>
            ) : (
                <Alert severity="warning" icon={<HelpOutline />}>
                    <AlertTitle>Whoops!</AlertTitle>
                    There aren&apos;t any form fields to display!
                </Alert>
            )}
        </>
    );
};

export default GeneratedForm;
