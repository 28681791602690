import React, { useCallback } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { dateToStr, dateToEnglishStr, getTimeStr } from '../../common/utility';
import moment from 'moment';

import styles from './TimePicker.module.scss';
import { handleEnterKeyToClick } from '../../common/accessibilityHelpers';

export default class TimePicker extends React.Component {
    render() {
        return (
            <div>
                <p>
                    Dr {this.props.doctorName} is available at these times{' '}
                    <strong>
                        {dateToStr(this.props.date) === dateToStr(new Date()) ? 'Today,' : ''}{' '}
                        {dateToEnglishStr(this.props.date)}
                    </strong>
                </p>
                <ButtonGroup aria-label="Available time slots to select from">
                    <div style={{ display: 'block', width: '50%' }}>
                        <Button
                            variant="primary"
                            disabled
                            className={styles['time-slot-group-name']}
                        >
                            AM
                        </Button>
                        {this.props.availableTimes &&
                            this.props.availableTimes
                                .filter((time) => time.hour < 12)
                                .map((time, i) => (
                                    <TimeSlot
                                        key={i}
                                        date={this.props.date}
                                        time={time}
                                        selectedTime={this.props.selectedTime}
                                        setSelectedTime={this.props.setSelectedTime}
                                    />
                                ))}
                    </div>
                    <div style={{ display: 'block', width: '50%' }}>
                        <Button
                            variant="primary"
                            disabled
                            className={styles['time-slot-group-name']}
                        >
                            PM
                        </Button>
                        {this.props.availableTimes &&
                            this.props.availableTimes
                                .filter((time) => time.hour >= 12)
                                .map((time, i) => (
                                    <TimeSlot
                                        key={i}
                                        date={this.props.date}
                                        time={time}
                                        selectedTime={this.props.selectedTime}
                                        setSelectedTime={this.props.setSelectedTime}
                                    />
                                ))}
                    </div>
                </ButtonGroup>
                <p style={{ marginTop: '20px' }}>
                    <span className="bold-text">Need help finding an appointment?</span> Call 1
                    (855) AVA-VIRTUAL (855-282-8478) for help, 24 hours a day, seven days a week.{' '}
                </p>
            </div>
        );
    }
}

const TimeSlot = (props) => {
    const isDisabled = (time) => {
        const now = moment();
        var slotTime = new Date(props.date);
        slotTime.setHours(time.hour);
        slotTime.setMinutes(time.minute);
        return now.isAfter(moment(slotTime));
    };

    const onTimeSlotClick = useCallback(
        (time) => {
            props.setSelectedTime(time);
        },
        [props]
    );

    return (
        <Button
            variant="outline-primary"
            className={`${styles['time-slot']} ${
                props.selectedTime && getTimeStr(props.selectedTime) === getTimeStr(props.time)
                    ? styles['time-slot-selected']
                    : ''
            }`}
            disabled={isDisabled(props.time)}
            onClick={() => onTimeSlotClick(props.time)}
            onKeyUp={handleEnterKeyToClick}
        >
            {getTimeStr(props.time)}
        </Button>
    );
};
