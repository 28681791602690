import React from 'react';
import styles from './AppointmentSummaryNote.module.scss';
import PropTypes from 'prop-types';

const AppointmentSummaryNote = ({ author, summary, date }) => {
    return (
        <div className={styles['note']}>
            <p className="bold-text">{author}</p>
            <p>{summary}</p>
            <div className={styles['date-txt']}>{date}</div>
        </div>
    );
};

export default AppointmentSummaryNote;

AppointmentSummaryNote.propTypes = {
    author: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
};
