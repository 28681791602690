import React from 'react';
import BaseComponent from '../components/BaseComponent';
import { pcp$ } from '../services/subjects';

export default function withPcpData(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.state = {
                pcpError: '',
            };
        }

        componentDidMount() {
            this.subscribe(pcp$, {
                next: (pcp) => {
                    this.setState({ pcp });
                },
                error: (error) => {
                    this.setState({ pcpError: error.message });
                },
            });

            pcp$.connect();
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    };
}
