import { createTheme } from '@mui/material/styles';

export const messageCenterTheme = createTheme({
    components: {
        MuiTableCell: {
            variants: [
                {
                    props: { variant: 'head' },
                    style: {
                        color: 'white',
                    },
                },
            ],
        },
        MuiTablePagination: {
            styleOverrides: {
                displayedRows: {
                    marginBottom: 0,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.MuiTableRow-hover': {
                        cursor: 'pointer',
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#0376a8',
        },
        gray: {
            main: '#808080',
            dark: '#a9a9a9',
            contrastText: '#ffffff',
        },
    },
});
