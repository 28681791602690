import { camelCase, upperFirst } from 'lodash';

const formatKey = (val) => {
    return camelCase(
        val
            .replace(/ *\([^)]*\) */g, ' ')
            .replace(/[&/\\#+()$~%.'*?<>]/g, '')
            .replace('-', '')
            .split(' ')
            .map((word) => upperFirst(word))
    );
};

export { formatKey };
