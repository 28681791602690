import { useEffect, useState } from 'react';
import { DocumentHeader } from 'types/documents/DocumentHeader';
import { ExternalDocumentHeader } from 'types/documents/ExternalDocumentHeader';

export const usePlanDocumentsSearchFilter = (
    documents: DocumentHeader[],
    educationalResources: DocumentHeader[],
    additionalResources: Array<
        DocumentHeader | ExternalDocumentHeader
    >
) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentHeader[]>([]);
    const [filteredAdditionalResources, setFilteredAdditionalResources] = useState<
        typeof additionalResources
    >([]);
    const [filteredEducationalResources, setFilteredEducationalResources] = useState<
        DocumentHeader[]
    >([]);

    useEffect(() => {
        if (!searchTerm) {
            setFilteredDocuments(documents);
            setFilteredDocuments(educationalResources);
        }
        setFilteredDocuments(
            documents?.filter((doc) =>
                doc.filename?.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredEducationalResources(
            educationalResources?.filter((res) =>
                res.filename?.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredAdditionalResources(
            additionalResources?.filter((res) =>
                res.filename?.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, educationalResources, documents]);
    return {
        searchTerm,
        setSearchTerm,
        filteredDocuments,
        filteredEducationalResources,
        filteredAdditionalResources,
    };
};
