import { createContext, Dispatch, SetStateAction } from 'react';

// This is used only for mobile
export type NavMenuContextState = {
    navOpen: boolean;
    setNavOpen: Dispatch<SetStateAction<boolean>>;
};

export const NavMenuContext = createContext<NavMenuContextState>({
    navOpen: false,
    setNavOpen: () => {},
});

export const NavMenuProvider = NavMenuContext.Provider;
