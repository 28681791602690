import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from 'components/organisms/NavMenu';
import { Header } from 'components/organisms/Header';
import withMemberData from 'hoc/withMemberData';
import withInteractionData from 'hoc/withInteractionData';
import withFeatureFlags from 'hoc/withFeatureFlags';
class Layout extends Component {
    static displayName = Layout.name;
    render() {
        return (
            <div className="main-content">
                <Header {...this.props} />
                <NavMenu {...this.props} />
                <Container id="main-container" role="main" aria-label="main content area">
                    {this.props.children}
                </Container>
            </div>
        );
    }
}

export default withFeatureFlags(withInteractionData(withMemberData(Layout)));
