import React, { Component } from 'react';
import { unreadMessageCount$ } from '../services/subjects';

export default function withUnreadMessageCount(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {};
        }

        componentDidMount() {
            this.subscription = unreadMessageCount$.subscribe((unreadMessageCount) =>
                this.setState({ unreadMessageCount })
            );
        }

        componentWillUnmount() {
            this.subscription.unsubscribe();
        }

        render() {
            return (
                <WrappedComponent
                    unreadMessageCount={this.state.unreadMessageCount}
                    {...this.props}
                />
            );
        }
    };
}
