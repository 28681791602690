import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = (props) => {
    return (
        <div className="ahc-searchbar">
            <input
                type="text"
                placeholder={props.placeholder}
                onChange={(e) =>
                    typeof props.change === 'function' && props.change((e.target || {}).value)
                }
                aria-label={props.placeholder || 'Enter search terms'}
            />
            <FontAwesomeIcon icon={faSearch} aria-label="Search Content" color="#888" />
        </div>
    );
};

export default SearchBar;

SearchBar.propTypes = {
    change: PropTypes.func,
    placeholder: PropTypes.string,
    search: PropTypes.func,
};
