import { DEVICES } from 'components/Form/Patient/Constants';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import { Devices, NewPatientForm } from 'components/Form/Patient/Models';

const HealthInformationReview = ({ state }: { state: NewPatientForm }) => {
    const defaultDevices: Devices = {
        cane: false,
        walker: false,
        biPap: false,
        electronicScooter: false,
        wheelchair: false,
        cPap: false,
    };
    const devices: Devices = get<NewPatientForm, string, Devices>(state, `devices`, defaultDevices);
    const usedDevices = Object.keys(devices).filter(
        (val) => devices[val as keyof Devices] === true
    );
    const deviceLabels = DEVICES.map(({ label, key }) => {
        return usedDevices.includes(key) ? label : null;
    }).filter((val) => !isEmpty(val));

    const { surgicalHistory, prescriptions, physiciansInfo } = state;
    return (
        <Row className="px-4">
            <Col md={6} className="border-left px-2">
                <p className="h4">Surgical History</p>
                {surgicalHistory.map(({ date, surgery }, idx) => (
                    <div key={`member_${idx}`}>
                        <p className="h5"></p>
                        <strong>{surgery}</strong>
                        <p>Date: {moment(date).format('MM/DD/YYYY')}</p>
                    </div>
                ))}
                <hr />
                <p className="h4">Prescriptions</p>
                {prescriptions.map(({ name, dose, frequency, orderingProvider }, idx) => (
                    <div key={`member_${idx}`}>
                        <p className="h5"></p>
                        <strong>{name}</strong>
                        <p>
                            Dose: {dose}
                            <br />
                            Frequency: {frequency}
                            <br />
                            Ordering Provider: {orderingProvider}
                        </p>
                    </div>
                ))}
            </Col>
            <Col md={6} className="border-left px-2">
                <p className="h4">Physician Info</p>
                {physiciansInfo.map(({ specialty, physician }, idx) => (
                    <div key={`member_${idx}`}>
                        <p className="h5"></p>
                        <strong>{physician}</strong>
                        <p>Specialty: {specialty}</p>
                    </div>
                ))}
                <hr />
                <p className="h4">Used Devices</p>
                <ul>
                    {deviceLabels.map((label, idx) => {
                        return (
                            <li key={`condition_${idx}`}>
                                <strong>{label}</strong>
                            </li>
                        );
                    })}
                </ul>
                <hr />
            </Col>
        </Row>
    );
};
export default HealthInformationReview;
