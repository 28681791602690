import { formatToPhone } from 'common/utility';
import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

const EmergencyInformationFields = ({ control, state }) => {
    return (
        <>
            <Row>
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.emergencyContact.firstName}
                        name="emergencyContact.firstName"
                        question="First Name"
                        showError={false}
                    />
                </Col>
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.emergencyContact.lastName}
                        name="emergencyContact.lastName"
                        question="Last Name"
                        showError={false}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.emergencyContact.middleInitial}
                        name="emergencyContact.middleInitial"
                        question="MI"
                        showError={false}
                    />
                </Col>
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.emergencyContact.phoneNumber}
                        name="emergencyContact.phoneNumber"
                        question="Phone #"
                        showError={false}
                        onKeyUp={formatToPhone}
                    />
                </Col>
                {/* <Col md={4}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.emergencyContact.relations}
                        name="emergencyContact.relations"
                        question="Relations"
                        showError={false}
                    />
                </Col> */}
            </Row>
            <Row>
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.emergencyContact.address}
                        name="emergencyContact.address"
                        question="Address"
                        showError={false}
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.emergencyContact.city}
                        name="emergencyContact.city"
                        question="City"
                        showError={false}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.emergencyContact.state}
                        name="emergencyContact.state"
                        question="State"
                        showError={false}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.emergencyContact.zipCode}
                        name="emergencyContact.zipCode"
                        question="Zip"
                        showError={false}
                    />
                </Col>
            </Row>
        </>
    );
};
export default EmergencyInformationFields;
