import React, { useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import CheckboxWithController from 'components/Form/Patient/Input/Checkbox';
import { DEVICES } from 'components/Form/Patient/Constants';
const DeviceUseInformation = ({ control, state }) => {
    const TableHeaders = () => (
        <thead className="text-center">
            <tr>
                <th className="align-middle">Device</th>
                <th className="align-middle">Yes/No</th>
            </tr>
        </thead>
    );

    const TableContent = useCallback(() => {
        return (
            <tbody className="text-center">
                {DEVICES.map(({ label, key }, idx) => (
                    <tr key={`device_${idx}`}>
                        <td>{label}</td>
                        <td>
                            <div>
                                <CheckboxWithController
                                    control={control}
                                    defaultValue={get(state, `devices[${key}]`, false)}
                                    name={`devices[${key}]`}
                                    showError={false}
                                />
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    }, [state]);
    return (
        <Table striped bordered responsive>
            <TableHeaders />
            <TableContent />
        </Table>
    );
};
export default DeviceUseInformation;
