import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';
import { pick } from 'lodash';
import FormHOC from '../../Form/FormHOC';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import { DateInputWithController } from 'components/Form/Patient/Input/DateInput';

const FormContent = ({ state, prevPageAction, onSubmit }) => {
    const { control, handleSubmit, errors, setValue, register } = useFormContext();
    return (
        <>
            <Row>
                <Col>
                    <div className="h2 py-3">
                        <strong>
                            <u>Consent for Treatment</u>
                        </strong>
                    </div>
                    <p>
                        I,{' '}
                        <strong className="px-2 h5">
                            <u>
                                {state?.firstName} {state?.lastName}
                            </u>
                        </strong>
                        , am voluntarily seeking healthcare and hereby consent (Patient’s name) to
                        medical treatment, procedures, laboratory tests and other health care
                        services. I understand that I have the right to refuse specific treatments
                        or procedures. However, by signing below, I agree in general, to permit
                        laboratory and diagnostic tests, routine medical treatment (for example,
                        medications, injections, drawing blood for tests, counseling, screening
                        tests, health education and other diagnostic procedures), emergency
                        procedures as necessary, and hospital services performed at the request of
                        the attending physician or other physicians assisting in my care.
                    </p>
                    <p>
                        The consent given shall be valid and binding and the physician(s) can rely
                        on this authorization and accept any consent given by the patient until such
                        time as physician receives written notice that the authorization is revoked.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.patientPrintName}
                        name="patientPrintName"
                        question="Patient Name"
                    />
                </Col>
                <Col md={6}>
                    <DateInputWithController
                        control={control}
                        defaultValue={state?.dateOfBirth}
                        name="dateOfBirth"
                        question="Date Of Birth"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.patientSignName}
                        name="patientSignName"
                        question="Signature of Patient or Legal Representative"
                    />
                </Col>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.relationshipToPatient}
                        name="relationshipToPatient"
                        question="Relationship"
                    />
                </Col>
                <Col md={6}>
                    <DateInputWithController
                        control={control}
                        defaultValue={state?.signDate}
                        name="signDate"
                        question="Date"
                    />
                </Col>
            </Row>
            <hr />

            <div className="d-flex justify-content-center mt-3">
                <div className="d-flex justify-content-around w-75">
                    <Button onClick={prevPageAction} size="lg">
                        Go Back
                    </Button>

                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Save and Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

const TreatmentConsent = ({ state, onSubmit, props, ...rest }) => {
    return (
        <FormHOC>
            <FormContent onSubmit={onSubmit} state={state} {...rest} />
        </FormHOC>
    );
};

export default TreatmentConsent;
