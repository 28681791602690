import React, { Component } from 'react';
import { plans$ } from '../services/subjects';
import { switchMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import planService from '../services/planService';

export default function withPlanData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = { planYear: null };
        }

        fetchPlanByYear = (planYear) => {
            this.setState({ plans: null });
            planService
                .get$(planYear)
                .pipe(
                    map((response) => response.response.results),
                    tap((plans) => plans$.next(plans))
                )
                .subscribe();
        };

        componentDidMount() {
            const fetchPlansFromServer$ = planService.get$().pipe(
                map((response) => response.response.results),
                tap((plans) => plans$.next(plans))
            );

            plans$
                .pipe(switchMap((plans) => (plans === null ? fetchPlansFromServer$ : of(plans))))
                .subscribe(
                    (plans) => this.setState({ plans }),
                    (error) =>
                        this.setState({
                            error: { message: 'Error retrieving plan data' },
                            plans: [],
                        })
                );
        }

        render() {
            return (
                <WrappedComponent
                    {...this.state}
                    {...this.props}
                    changePlanYear={this.fetchPlanByYear}
                />
            );
        }
    };
}
