import React from 'react';
import { map, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import BaseComponent from 'components/BaseComponent';
import memberService from 'services/memberService';
import { languages$ } from 'services/subjects';

export default function withLanguages(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            const fetchLanguages$ = memberService.getLanguages$.pipe(
                map((response) => response.response),
                tap((languages) => languages$.next(languages))
            );

            languages$
                .pipe(
                    switchMap((languages) => (languages === null ? fetchLanguages$ : of(languages)))
                )
                .subscribe(
                    (languages) => this.setState({ languages }),
                    (error) =>
                        this.setState({
                            error: { message: 'Error retrieving languages' },
                            languages: [],
                        })
                );
        }

        componentWillUnmount() {
            super.componentWillUnmount();
        }

        render() {
            return <WrappedComponent {...this.props} languages={this.state.languages} />;
        }
    };
}
