import React, { ElementType } from 'react';
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from 'ApplicationInsights';

const withAppInsightsErrorBoundary = <TWrappedComponentProps,>(WrappedComponent: ElementType, ErrorBoundaryComponent: ElementType) => {
    return (props: TWrappedComponentProps) => {
        return (
            <AppInsightsErrorBoundary
                onError={() => {
                    return <ErrorBoundaryComponent /> || <span>---</span>;
                }}
                appInsights={reactPlugin}
            >
                <WrappedComponent {...props} />
            </AppInsightsErrorBoundary>
        );
    };
}

export { withAppInsightsErrorBoundary };
