import { useState, useEffect } from 'react';
import { config$ } from '../services/subjects';

function useConfig() {
    const [config, setConfig] = useState(null);

    useEffect(() => {
        const subscription = config$.subscribe((config) => setConfig(config));
        return () => subscription.unsubscribe();
    });

    return config;
}

export default useConfig;
