import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { pick, omitBy } from 'lodash';
import FormHOC from '../../Form/FormHOC';
import BasicInformationReview from './Review/BasicInformationReview';
import MedicalHistoryReview from './Review/MedicalHistoryReview';
import HealthInformationReview from './Review/HealthInformationReview';

const FormContent = ({ state, prevPageAction, onSubmit }) => {
    const { handleSubmit } = useFormContext();

    return (
        <>
            <h2 className="pb-4">Final Review</h2>

            <div className="h3">Basic Information</div>
            <hr />
            <BasicInformationReview state={state} />

            <div className="h3">Medical History</div>
            <hr />
            <MedicalHistoryReview state={state} />
            <div className="h3">Health Information</div>
            <hr />
            <HealthInformationReview state={state} />
            {/* <h3>Form State:</h3>
            <pre style={{ textAlign: 'left' }}>
                {JSON.stringify(
                    omitBy(state, (val, key) => key === 'validators'),
                    null,
                    2
                )}
            </pre> */}
            <div className="d-flex justify-content-center mt-3">
                <div className="d-flex justify-content-around w-75">
                    <Button onClick={prevPageAction} size="lg">
                        Go Back
                    </Button>

                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

const FinalReview = ({ state, onSubmit, props, ...rest }) => {
    const valuePaths = ['reason', 'type'];
    return (
        <FormHOC formDefaultValues={pick(state, valuePaths)}>
            <FormContent onSubmit={onSubmit} state={state} {...rest} />
        </FormHOC>
    );
};

export default FinalReview;
