import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { pick, get } from 'lodash';
import CheckboxWithController from 'components/Form/Patient/Input/Checkbox';
import { CONDITIONS, FAMILY } from 'components/Form/Patient/Constants';
const FamilyHistoryInformation = ({ control, state }) => {
    const TableHeaders = () => (
        <thead className="text-center">
            <tr>
                {FAMILY.map(({ label }, idx) => (
                    <th key={`header_${idx}`} className="align-middle text-wrap">
                        {label}
                    </th>
                ))}
            </tr>
        </thead>
    );
    const TableContent = useCallback(() => {
        return (
            <tbody className="text-center">
                {CONDITIONS.map(({ label, key: conditionKey }, idx) => (
                    <tr key={`condition_${idx}`}>
                        <td className="align-middle">{label} </td>
                        {FAMILY.map(({ key: familyKey }, idx) => {
                            const formattedKey = familyKey;
                            const formattedLabel = conditionKey;
                            return idx === 0 ? null : (
                                <td key={`input_${formattedLabel}_${idx}`}>
                                    <CheckboxWithController
                                        control={control}
                                        defaultValue={get(
                                            state,
                                            `${formattedLabel}[${formattedKey}]`,
                                            false
                                        )}
                                        name={`${formattedLabel}[${formattedKey}]`}
                                        showError={false}
                                    />
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        );
    }, [state]);
    return (
        <Table striped bordered responsive>
            <TableHeaders />
            <TableContent />
        </Table>
    );
};
export default FamilyHistoryInformation;
