export const plansWithPremiums = [
    'H3815-009-2020',
    'H3815-018-2020',
    'H3815-025-2020',
    'H3815-019-2020',
    'H3815-020-2020',
    'H3815-021-2020',
    'H3815-022-2020',
    'H3815-023-2020',
    'H3815-024-2020',
    'H4961-001-2020',
    'H4961-002-2020',
    'H4961-003-2020',
    'H4961-004-2020',
    'H3815-009-2021',
    'H3815-018-2021',
    'H3815-019-2021',
    'H3815-020-2021',
    'H3815-021-2021',
    'H3815-022-2021',
    'H3815-023-2021',
    'H3815-024-2021',
    'H3815-025-2021',
    'H3815-030-2021',
    'H4961-001-2021',
    'H4961-002-2021',
    'H4961-003-2021',
    'H4961-004-2021',
    'H5296-002-2021',
    'H9686-002-2021',
];

export const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const featureFlagsNames = {
    memberPostVisitSummary: 'member-post-visit-summary',
    HEALTH_RISK_ASSESSMENT: 'member-health-risk-assessment',
    disablePcpChange: 'disable-pcp-change',
    crmInteractionMessageCenter: 'crm-interaction-message-center',
    memberSafeRideSSO: 'member-safe-ride-sso',
    mailOrderOtcLink: 'mail-order-otc-link',
    benefitYearDropdown: 'benefit-year-dropdown',
    avaNextUiVersion: 'ava-member-ux-next-version',
};
