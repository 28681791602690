import { ajax } from 'rxjs/ajax';
import { map, switchMap } from 'rxjs/operators';
import { HealthyReward } from 'types/healthyRewards/HealthyReward';
import { jsonHeaders$ } from './headers';

export type SelftReportColorectalRewardRequest = {
    year: string;
    month: string;
    type: string;
}

export const healthyRewardService = {
    get$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/healthyrewards`,
                headers,
            }).pipe<HealthyReward[]>(
                map(response => response.response as HealthyReward[])
            )
        )
    ),
    requestFOBTKit$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'POST',
                url: `api/healthyrewards/requestFOBTKit`,
                headers,
            })
        )
    ),
    selftReportColorectalReward$: (request: SelftReportColorectalRewardRequest) => jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'POST',
                url: `api/healthyrewards/SelftReportColorectalReward`,
                headers,
                body: request,
            })
        )
    ),
};

