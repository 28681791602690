import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import styles from './InputQuestion.module.scss';

class InputQuestion extends PureComponent {
    static displayName = InputQuestion.name;
    static propTypes = {
        question: PropTypes.string,
        option: PropTypes.shape({
            label: PropTypes.string,
        }),
        onChange: PropTypes.func,
        onKeyUp: PropTypes.func,
        onKeyDown: PropTypes.func,
        maxLength: PropTypes.number,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        showErrorMessage: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    render() {
        return (
            <Row>
                <Col>
                    <div>
                        <label
                            id={`label-inputquestion-${this.props.option.label}`}
                            className={styles['label-bold']}
                            htmlFor={this.props.option.label}
                        >
                            {this.props.question}
                        </label>
                    </div>
                    <div>
                        <input
                            data-testid={this.props.option.label}
                            className={styles.input}
                            type="input"
                            name={this.props.option.label}
                            onChange={(e) => this.props.onChange(e.target.value)}
                            id={this.props.option.label}
                            value={this.props.value}
                            onKeyDown={this.props.onKeyDown}
                            onKeyUp={this.props.onKeyUp}
                            maxLength={this.props.maxlength}
                            aria-labelledby={`label-inputquestion-${this.props.option.label}`}
                            disabled={this.props.disabled}
                            ref={this.props.inputRef}
                        />
                        {this.props.showErrorMessage && (
                            <span className={styles['error-message']}>
                                Please check for proper formatting.
                            </span>
                        )}
                    </div>
                </Col>
            </Row>
        );
    }
}

export default InputQuestion;
