import { CircularProgress } from '@mui/material';
import Loading from 'components/atoms/Loading';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from 'interfaces/FeatureFlags';
import React, { useContext } from 'react';

const FeatureFlagsContext = React.createContext<{
    featureFlags: FeatureFlags;
    areFeatureFlagLoading: boolean;
}>({
    featureFlags: {},
    areFeatureFlagLoading: true,
});

interface FeatureFlagsProps {
    children: React.ReactNode;
}

const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);

const WithFeatureFlags = ({ children }: FeatureFlagsProps) => {
    const { featureFlags, isFeatureFlagLoading } = useFeatureFlags();

    return (
        <>
            {!isFeatureFlagLoading ? (
                <FeatureFlagsContext.Provider
                    value={{
                        featureFlags,
                        areFeatureFlagLoading: isFeatureFlagLoading,
                    }}
                >
                    {children}
                </FeatureFlagsContext.Provider>
            ) : (
                <Loading />
            )}
        </>
    );
};

export { WithFeatureFlags as default, useFeatureFlagsContext };
