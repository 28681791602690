import React, { useEffect, useState } from 'react';
import { FormTemplate } from './interfaces/FormTemplate';
import {
    generateInitialState,
    generateValidators,
    populateValues,
    generatePages,
} from './utils/generateHelpers';
import FormHOC from './Form/FormHOC';
import segmentFields from './utils/segmentFields';
import { noop } from 'lodash';
import NewWizard from './Form/NewWizard/NewWizard';
import { Segment } from './interfaces/Segment';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { Alert, AlertTitle } from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { FormField } from './interfaces/Form';

export interface FormGeneratorProps {
    formTemplate: FormTemplate | null;
    values?: { [key: string]: Object } | null;
    onSubmit?: (data: object) => any;
    readOnly?: boolean;
}

interface FormWrapperProps {
    segmentedFields: Segment[];
    onSubmit: (data: object) => any;
    readOnly: boolean;
}

const FormWrapper = ({ segmentedFields, onSubmit, readOnly }: FormWrapperProps) => {
    const {
        handleSubmit,
        formState: { errors },
        trigger,
    } = useFormContext();

    const onSubmitWrapper: SubmitHandler<any> = (data, e) => {
        onSubmit(data);
    };

    const handleValidation = async (pageIndex: number) => {
        const currentPageNames: string[] = segmentedFields[pageIndex].fields.map(
            (field: FormField) => field.name || ''
        );
        return await trigger(currentPageNames);
    };

    return (
        <NewWizard
            pages={generatePages(segmentedFields, readOnly)}
            onSubmit={handleSubmit(onSubmitWrapper, (errors) =>
                console.error('Missing fields: ', errors)
            )}
            readOnly={readOnly}
            errors={errors}
            handleValidation={handleValidation}
        />
    );
};

const FormGenerator = ({ formTemplate, values, onSubmit, readOnly }: FormGeneratorProps) => {
    const [segmentedFields, setSegmentedFields] = useState<Segment[] | null>(null);

    useEffect(() => {
        let result: Segment[] | null = null;
        if (formTemplate) {
            result = segmentFields(formTemplate.fields, formTemplate.categories);
        }
        setSegmentedFields(result);
    }, [formTemplate]);

    return (
        <div>
            {segmentedFields ? (
                <FormHOC
                    formDefaultValues={generateInitialState(
                        values ? populateValues(formTemplate?.fields, values) : formTemplate?.fields
                    )}
                    yupValidations={generateValidators(formTemplate)}
                >
                    <FormWrapper
                        segmentedFields={segmentedFields}
                        onSubmit={onSubmit ?? noop}
                        readOnly={readOnly ?? false}
                    />
                </FormHOC>
            ) : (
                <Alert severity="warning" icon={<HelpOutline />}>
                    <AlertTitle>Whoops!</AlertTitle>
                    It looks like we couldn&apos;t get a form template!
                </Alert>
            )}
        </div>
    );
};

export default FormGenerator;
