import React from 'react';

/* mode: add, remove */
const handleHighlight = (children, mode) => () => {
    document.querySelectorAll('.nav-item.dropdown').forEach((navElement) => {
        if ((navElement.innerText || '').toLowerCase() === children.toLowerCase()) {
            navElement.classList[mode]('highlight');
        }
    });
};

const NavHoverLocator = ({ children }) => {
    return (
        <span
            onMouseEnter={handleHighlight(children, 'add')}
            onMouseLeave={handleHighlight(children, 'remove')}
            tabIndex="0"
            onFocus={handleHighlight(children, 'add')}
            onBlur={handleHighlight(children, 'remove')}
        >
            {children}
        </span>
    );
};

export default NavHoverLocator;
