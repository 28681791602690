import { Subject } from 'rxjs';

const openPrompt = new Subject();
const closePrompt = new Subject();

const promptService = {
    open: (prompt) => openPrompt.next(prompt),
    openPrompt$: openPrompt.asObservable(),
    close: () => closePrompt.next(),
    closePrompt$: closePrompt.asObservable(),
};

export default promptService;
