import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';
import { pick } from 'lodash';
import FormHOC from '../../Form/FormHOC';
import ReceiptHipaaNoticeVerbiage from './Verbiage/ReceiptHipaaNotice';
import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import { YES_NO_NA } from 'components/Form/Patient/Constants';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import { DateInputWithController } from 'components/Form/Patient/Input/DateInput';

const FormContent = ({ state, prevPageAction, onSubmit }) => {
    const { control, handleSubmit, errors, setValue, register } = useFormContext();
    return (
        <>
            <ReceiptHipaaNoticeVerbiage />
            <Row>
                <Col>
                    <RadiosWithController
                        control={control}
                        defaultValue={state?.willReceiveReminders}
                        name="willReceiveReminders"
                        question="I agree to receive appointment and treatment reminders via text and voicemail"
                        options={YES_NO_NA}
                        colSize={12}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.patientPrintName}
                        name="patientPrintName"
                        question="Patient Name"
                    />
                </Col>
                <Col md={6}>
                    <DateInputWithController
                        control={control}
                        defaultValue={state?.signDate}
                        name="signDate"
                        question="Date"
                    />
                </Col>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.patientSignName}
                        name="patientSignName"
                        question="Patient or Responsible Party Signature"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.relationshipToPatient}
                        name="relationshipToPatient"
                        question="Relationship To Patient"
                    />
                </Col>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.noSignReason}
                        name="noSignReason"
                        question="Reason Patient Cannot Sign (if applicable)"
                    />
                </Col>
            </Row>
            <hr />

            <div className="d-flex justify-content-center mt-3">
                <div className="d-flex justify-content-around w-75">
                    <Button onClick={prevPageAction} size="lg">
                        Go Back
                    </Button>

                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Save and Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

const ReceiptNotice = ({ state, onSubmit, props, ...rest }) => {
    return (
        <FormHOC>
            <FormContent onSubmit={onSubmit} state={state} {...rest} />
        </FormHOC>
    );
};

export default ReceiptNotice;
