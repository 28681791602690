import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './ProgressBar.module.scss';
import { PatientPages } from '../Enums';

export interface ProgressBarProps {
    steps: Step[];
    handleStepChange: (page: PatientPages) => void;
    currentStep: PatientPages;
}
export interface Step {
    title: string;
    page: PatientPages;
}

const ProgressBar = ({ steps, handleStepChange, currentStep }: ProgressBarProps) => {
    return (
        <div className={styles['sticky-wrapper']}>
            <Row>
                <Col>
                    <div className={styles['steps-container']}>
                        {steps.map(({ title, page }, i) => (
                            <div
                                key={i}
                                data-testid={`step_${i}`}
                                className={`${
                                    currentStep === page
                                        ? styles['step-selected']
                                        : currentStep > page
                                        ? styles['step-completed']
                                        : ''
                                } ${styles.step}`}
                            >
                                <div
                                    className={styles['step-number']}
                                    onClick={() => {
                                        currentStep > page && handleStepChange(page);
                                    }}
                                >
                                    {i + 1}
                                </div>
                                <div className={styles['step-title']}>{title}</div>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default ProgressBar;
