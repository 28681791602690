import React, { Component } from 'react';
import { medications$ } from '../services/subjects';
import { map, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import medicationService from '../services/medicationService';

export default function withMedicationData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            const fetchMedicationsFromServer$ = medicationService.get$.pipe(
                map((response) => response.response),
                tap((medications) => medications$.next(medications))
            );

            medications$
                .pipe(
                    switchMap((medications) =>
                        medications === null ? fetchMedicationsFromServer$ : of(medications)
                    )
                )
                .subscribe(
                    (medications) => this.setState({ medications }),
                    (error) =>
                        this.setState({
                            error: { message: 'Error retrieving medication data' },
                            medications: [],
                        })
                );
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    };
}
