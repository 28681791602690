import React, { memo, useCallback } from 'react';
import { ReactComponent as Spinner } from 'icons/v2/spinner.svg';
import classNames from './Loader.module.scss';
import { Stack } from '@mui/material';

export type LoaderProps = {
    size: 'lg' | 'md' | 'sm';
    container?: boolean;
};

const Loader = ({ size, container }: LoaderProps) => {
    const renderSpinner = useCallback(
        () => (
            <Spinner
                className={`${classNames.Loader} ${classNames[size]}`}
            />
        ),
        [size]
    );

    return container ? (
        <Stack width="100%" height="50vh" direction="row" justifyContent="center" alignItems="center">
            {renderSpinner()}
        </Stack>
    ) : (
        renderSpinner()
    );
};

Loader.defaultProps = {
    size: 'md',
}

const MemoLoader = memo(Loader);
export {MemoLoader as Loader};
