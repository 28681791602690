import { ArrowBack, ErrorOutline } from '@mui/icons-material';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import './ErrorMessage.scss';

export interface ErrorMessageProps {
    message?: string;
}

const ErrorMessage = ({ message }: ErrorMessageProps) => {
    return (
        <Alert severity="error" icon={false} className="error-message-alert">
            <AlertTitle className="error-message-alert-title">
                <ErrorOutline fontSize="large" htmlColor="#ef5350" />
                <p className="error-message-title" data-testid="errorMessage">
                    {message || 'Error'}
                </p>
            </AlertTitle>
            <p className="error-message-details">Please contact the system administrator.</p>
            <Link to="/" className="error-message-return" data-testid="linkHome">
                <ArrowBack /> Return to Home Page
            </Link>
        </Alert>
    );
};

export default ErrorMessage;
