import React from 'react';
import Calendar from 'react-calendar';
import { dateToStr } from '../../common/utility';
import moment from 'moment';
import withForwardedRef from '../../hoc/withForwardedRef';

const inViewYear = (date, compareToDate) => {
    return (
        moment(date, 'MM/DD/YYYY').format('M') === moment(compareToDate).format('M') &&
        moment(date, 'MM/DD/YYYY').format('YYYY') === moment(compareToDate).format('YYYY')
    );
};

const inViewDecade = (date, compareToDate) => {
    return moment(date, 'MM/DD/YYYY').format('YYYY') === moment(compareToDate).format('YYYY');
};

const inViewCentury = (date, compareToDate) => {
    const availableYear = parseInt(moment(date, 'MM/DD/YYYY').format('YYYY'));
    const startCompareYear = parseInt(moment(compareToDate).format('YYYY'));
    const endCompareYear = startCompareYear + 9;
    return startCompareYear <= availableYear && availableYear <= endCompareYear;
};

class DatePicker extends React.Component {
    onChange = (date) => {
        this.props.setSelectedDate(date);
    };

    tileDisabled = ({ activeStartDate, date, view }) => {
        let currentDateStr = dateToStr(date);
        if (view === 'month') {
            return (
                date.getDay() === 0 ||
                date.getDay() === 6 ||
                !this.props.availableDates.includes(currentDateStr)
            );
        }
        if (view === 'year') {
            return !this.props.availableDates.some((d) => inViewYear(d, date));
        }
        if (view === 'decade') {
            return !this.props.availableDates.some((d) => inViewDecade(d, date));
        }
        if (view === 'century') {
            return !this.props.availableDates.some((d) => inViewCentury(d, date));
        }
    };

    onActiveStartDateChange = (startDate) => {
        this.props.onActiveStartDateChange(startDate);
    };

    render() {
        return (
            <div ref={this.props.forwardedRef}>
                <Calendar
                    calendarType="US"
                    minDate={new Date()}
                    onChange={this.onChange}
                    value={this.props.date}
                    showNeighboringMonth={false}
                    tileDisabled={this.tileDisabled}
                    onActiveStartDateChange={this.onActiveStartDateChange}
                />
                <h6
                    className={
                        dateToStr(this.props.date) === dateToStr(new Date())
                            ? 'visible-element'
                            : 'hidden-element'
                    }
                    style={{ float: 'right', marginRight: 15, color: '#006edc' }}
                >
                    TODAY
                </h6>
            </div>
        );
    }
}

export default withForwardedRef(DatePicker);
