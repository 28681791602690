import React from 'react';
import appointmentService from '../services/appointmentService';
import appointmentRequestService from '../services/appointmentRequestService';
import BaseComponent from '../components/BaseComponent';
import {
    appointmentStatuses$,
    appointmentRequestStatuses$,
    appointmentReasons$,
    appointmentRequestReasons$,
    appointmentTypes$,
    preferredTimes$,
} from '../services/subjects';

export default function withAppointmentData(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.state = {};
        }

        fetch = () => {
            const fetchAppointmentsFromServer = appointmentService.getAll$;

            this.subscriptions.push(
                fetchAppointmentsFromServer.subscribe((appointments) =>
                    this.setState({ appointments })
                )
            );

            this.subscriptions.push(
                appointmentRequestService.getAll$.subscribe((requests) =>
                    this.setState({ requests })
                )
            );

            this.subscriptions.push(
                appointmentService.getConfig$.subscribe((config) => {
                    const apptConfig = {
                        AllowMemberToJoinAppointmentBefore: parseInt(
                            config.allowMemberToJoinAppointmentBefore
                        ),
                        VirtualRoomHostUrl: config.virtualRoomHostUrl,
                    };
                    this.setState({ config: apptConfig });
                })
            );

            this.subscribe(appointmentReasons$, {
                next: (reasons) => this.setState({ reasons: reasons }),
            });

            this.subscribe(appointmentRequestReasons$, {
                next: (requestReasons) => this.setState({ requestReasons: requestReasons }),
            });

            this.subscribe(appointmentTypes$, {
                next: (visitTypes) => this.setState({ visitTypes }),
            });

            this.subscribe(appointmentStatuses$, {
                next: (appointmentStatusMap) => this.setState({ appointmentStatusMap }),
            });
            this.subscribe(appointmentRequestStatuses$, {
                next: (appointmentRequestStatusMap) =>
                    this.setState({ appointmentRequestStatusMap }),
            });

            this.subscribe(preferredTimes$, {
                next: (preferredTimes) => this.setState({ preferredTimes }),
            });
        };

        componentDidMount() {
            this.fetch();
            appointmentReasons$.connect();
            appointmentRequestReasons$.connect();
            appointmentTypes$.connect();
            appointmentStatuses$.connect();
            appointmentRequestStatuses$.connect();
            preferredTimes$.connect();
        }

        render() {
            return (
                <WrappedComponent
                    visitTypes={this.state.visitTypes}
                    preferredTimes={this.state.preferredTimes}
                    reasons={this.state.reasons}
                    requestReasons={this.state.requestReasons}
                    appointmentStatusMap={this.state.appointmentStatusMap}
                    appointmentRequestStatusMap={this.state.appointmentRequestStatusMap}
                    appointments={this.state.appointments}
                    appointmentRequests={this.state.requests}
                    saveAttachedFiles={appointmentService.saveAttachedFiles$}
                    downloadFileById={appointmentService.downloadFileById$}
                    createAppointment={appointmentService.create$}
                    cancelAppointment={appointmentService.cancel$}
                    cancelAppointmentRequest={appointmentRequestService.cancel$}
                    refreshAppointments={this.fetch}
                    config={this.state.config}
                    {...this.props}
                />
            );
        }
    };
}
