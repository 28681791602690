import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import html2pdf from 'html2pdf.js';
import withPolicyData from 'hoc/withPolicyData';
import PageHeader from 'components/molecules/PageHeader';
import { handleEnterKeyToClick } from 'common/accessibilityHelpers';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { Policy } from 'types/Policy';
import { Stack, useTheme } from '@mui/material';
import withMemberCardData from 'hoc/withMemberCardData';
import { Container } from 'components/v2/atoms/Container/Container';
import { Loader } from 'components/v2/atoms/Loader/Loader';
import { Alert } from 'components/v2/molecules/Alert/Alert';
import { ReactComponent as DownloadIcon } from 'icons/v2/download.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { sendCustomEvent } from 'common/utility';

const SCALE_CARD_IMAGE_BY = 2;
const CARD_IMAGE_QUALITY = 0.98;

export type IdCardProps = {
    idCards: string[];
    frontIdImgSrc?: string;
    backIdImgSrc?: string;
    policies: Policy[];
    error?: Error;
    docIds: string[];
};

const IdCard = ({ frontIdImgSrc, error, docIds, backIdImgSrc, policies }: IdCardProps) => {
    const [downloading, setDownloading] = useState(false);
    const theme = useTheme();
    const lessThanBreakpointMd = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewIdCard })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    const html2pdfOptions = {
        margin: 1,
        filename: `Alighment Health Plan ${
            policies && policies.length && policies[0].policy_name
        } ID Card.pdf`,
        image: { type: 'jpeg', quality: CARD_IMAGE_QUALITY },
        html2canvas: { scale: SCALE_CARD_IMAGE_BY },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    const renderDownload = () => (
        <span
            className="body-link"
            onClick={() => {
                sendCustomEvent({ eventType: AUDITEVENTTYPE.MemberPortalDownloadCard });
                var element = document.getElementById('id-card-display');
                if (!downloading && element) {
                    setDownloading(true);
                    html2pdf()
                        .set(html2pdfOptions)
                        .from(element)
                        .save()
                        .then(() => setDownloading(false));
                }
            }}
            onKeyUp={handleEnterKeyToClick}
            tabIndex={0}
            aria-label="Click to download ID Card"
        >
            <DownloadIcon />
            &nbsp;&nbsp;<strong className="body-link">Download my ID card</strong>
            &nbsp;&nbsp;{downloading && <Loader size="sm" />}
        </span>
    );

    return (
        <Container>
            <Stack direction="row">
                <PageHeader>
                    <strong className="page-title">MY ID CARD</strong>
                </PageHeader>
            </Stack>
            {!lessThanBreakpointMd && (
                <Stack direction="row" justifyContent="flex-end" marginBottom={5}>
                    {frontIdImgSrc && renderDownload()}
                </Stack>
            )}
            {error && (
                <Alert title="An Error Has Ocurred" variant="error">
                    {(error || {}).message}
                </Alert>
            )}
            {!error ? (
                <Stack
                    direction={{
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                    }}
                    gap={4}
                    marginBottom={{xs: 5, md: 20}}
                    id="id-card-display"
                >
                    <Stack flex={{ xs: 0, md: 1 }} width={{ xs: '100%', md: 'auto' }}>
                        {frontIdImgSrc ? (
                            <img
                                id={docIds && docIds[0]}
                                className="card-shadow"
                                src={frontIdImgSrc}
                                width="100%"
                                alt="Access Card front side"
                            />
                        ) : (
                            <Stack height="30vw" justifyContent="center" alignItems="center">
                                <Loader size="lg" />
                            </Stack>
                        )}
                    </Stack>
                    <Stack flex={{ xs: 0, md: 1 }} width={{ xs: '100%', md: 'auto' }}>
                        {backIdImgSrc ? (
                            <img
                                id={docIds && docIds[1]}
                                className="card-shadow"
                                src={backIdImgSrc}
                                width="100%"
                                alt="Access Card back side"
                            />
                        ) : (
                            <Stack height="30vw" justifyContent="center" alignItems="center">
                                <Loader size="lg" />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            ) : null}
             {lessThanBreakpointMd && (
                <Stack direction="row" justifyContent="flex-end" marginBottom={{xs: 8, md: 5}}>
                    {frontIdImgSrc && renderDownload()}
                </Stack>
            )}
            <Stack rowGap={2}>
                <Stack rowGap={1}>
                    <article>
                        <strong>WHAT IF THE INFORMATION ON MY CARD IS WRONG?</strong>
                    </article>
                    <p>
                        If any information on your member ID card is wrong, call your 24/7 ACCESS
                        On-Demand Concierge at your earliest convenience at{' '}
                        <strong>1-833-242-2223</strong> (TTY 711). We will be happy to fix any
                        errors and send you a new one.
                    </p>
                </Stack>

                <Stack rowGap={1}>
                    <article>
                        <strong>WHAT IF I LOSE MY MEMBER ID CARD?</strong>
                    </article>

                    <p>
                        If you lose your ID card and need to show it for care, you can show a
                        printed copy instead. To request a new ID card, or if you are using a screen
                        reader and need assistance, please call your 24/7 ACCESS On-Demand Concierge
                        at <strong>1-833-242-2223</strong> (TTY 711)
                    </p>
                </Stack>
            </Stack>
        </Container>
    );
};

const IdCardWithData = memo(withPolicyData(withMemberCardData(IdCard)));
export { IdCardWithData as IdCard };
