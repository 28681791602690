import { ajax } from 'rxjs/ajax';
import { map, tap, switchMap, take } from 'rxjs/operators';
import { jsonHeaders$, authHeaders$ } from './headers';
import { downloadFile } from '../common/utility';

const appointmentService = {
    getAll$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/appointments`,
                headers,
            }).pipe(map((response) => response.response))
        )
    ),
    saveAttachedFiles$: ({ attachedFiles, appointmentId }) =>
        authHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/appointments/${appointmentId}/saveattachedfiles`,
                    headers,
                    body: attachedFiles,
                }).pipe(map((response) => response.response))
            )
        ),
    getAttachedFiles$: (appointmentId) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/appointments/${appointmentId}/attachedfiles`,
                    headers,
                }).pipe(map((response) => response.response))
            )
        ),
    downloadFileById$: ({ appointmentId, fileId, contentType }) =>
        authHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/appointments/${appointmentId}/attachedfile/${fileId}`,
                    headers,
                    responseType: 'arraybuffer',
                })
                    .pipe(map((response) => response.response))
                    .pipe(tap((response) => downloadFile(response, contentType)))
            )
        ),
    create$: (appointmentData) =>
        jsonHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/appointments`,
                    headers,
                    body: appointmentData,
                }).pipe(map((response) => response.response.appointmentId))
            )
        ),
    cancel$: (appointmentId) =>
        jsonHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/appointments/${appointmentId}/cancel`,
                    headers,
                }).pipe(map((response) => response.response))
            )
        ),
    getTranslatorPreferredLanguage$: () =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/appointments/translatorpreferredlanguage`,
                    headers,
                }).pipe(map((response) => response.response))
            )
        ),
    getConfig$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: 'api/config/b2c',
                headers,
            }).pipe(map((response) => response.response))
        )
    ),
};

export default appointmentService;
