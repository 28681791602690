import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { get } from 'lodash';
import { DateInputWithController } from 'components/Form/Patient/Input/DateInput';

const InsuranceInformationFields = ({ control, state }) => {
    const InsuranceFields = useCallback(
        ({ name }) => (
            <>
                <Row>
                    <Col md={5}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={get(state, `${name}.policyHolder`)}
                            name={`${name}.policyHolder`}
                            question="Name of Policy Holder"
                            showError={false}
                        />
                    </Col>
                    <Col md={5}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={get(state, `${name}.policyContractNum`)}
                            name={`${name}.policyContractNum`}
                            question="Policy Contract #"
                            showError={false}
                        />
                    </Col>
                    <Col md={2}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={get(state, `${name}.groupNum`)}
                            name={`${name}.groupNum`}
                            question="Group #"
                            showError={false}
                        />
                    </Col>
                    <Col md={5}>
                        <DateInputWithController
                            control={control}
                            defaultValue={get(state, `${name}.dateOfBirth`)}
                            name={`${name}.dateOfBirth`}
                            question="Date Of Birth"
                            showError={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={get(state, `${name}.address`)}
                            name={`${name}.address`}
                            question="Address"
                            showError={false}
                        />
                    </Col>
                    <Col md={3}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={get(state, `${name}.city`)}
                            name={`${name}.city`}
                            question="City"
                            showError={false}
                        />
                    </Col>
                    <Col md={2}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={get(state, `${name}.state`)}
                            name={`${name}.state`}
                            question="State"
                            showError={false}
                        />
                    </Col>
                    <Col md={2}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={get(state, `${name}.zipCode`)}
                            name={`${name}.zipCode`}
                            question="Zip"
                            showError={false}
                        />
                    </Col>
                </Row>
            </>
        ),
        [state]
    );
    return (
        <>
            <div className="h3 pt-2">Primary Insurance</div>
            <InsuranceFields name="primary" />
            <div className="h3 pt-5">Secondary Insurance</div>
            <InsuranceFields name="secondary" />
        </>
    );
};
export default InsuranceInformationFields;
