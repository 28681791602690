import React from 'react';
import Biography from '../molecules/Biography';
import Appointment from '../organisms/Appointment';
import Composer from '../organisms/Composer';
import withPcpData from '../../hoc/withPcpData';
import withIdentityData from '../../hoc/withIdentityData';
import withMemberData from '../../hoc/withMemberData';
import withAppointmentData from '../../hoc/withAppointmentData';
import withPolicyData from '../../hoc/withPolicyData';
import ResponsiveTable from '../organisms/ResponsiveTable';
import BaseComponent from '../BaseComponent';
import { LinkedPost } from '../molecules/Post';
import SearchBar from '../molecules/SearchBar';
import PageHeader from '../molecules/PageHeader';
import { beforeRedirect$, createdAppointment$, createdRequest$ } from 'services/subjects';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { handleEnterKeyToClick } from '../../common/accessibilityHelpers';
import { Container, Col, Row } from 'reactstrap';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleNotch, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { hasSearchTerm, getTimeZone } from '../../common/utility';
import { isNil } from 'lodash';
import moment from 'moment';
import styles from './VirtualCare.module.scss';
import { PlanTypes } from '../../hoc/Enums';
import AppointmentSummary from 'components/organisms/AppointmentSummary';
import Feature from '../../hoc/Feature';
import withFeatureFlags from '../../hoc/withFeatureFlags';
import { featureFlagsNames } from '../../common/constants';
import Message from 'components/organisms/Message';
import withMemberPortalConfigData from 'hoc/withMemberPortalConfigData';

const IS_SEND_MESSAGE_VISIBLE_KEY = 'isSendMessageVisible';

/**
 * Virtual care content component
 * @component VirtualCare
 * **/
class VirtualCare extends BaseComponent {
    static displayName = VirtualCare.name;

    constructor(props) {
        super(props);
        const isSendMessageVisible = sessionStorage.getItem(IS_SEND_MESSAGE_VISIBLE_KEY) ?? false;
        if (isSendMessageVisible) {
            sessionStorage.removeItem(IS_SEND_MESSAGE_VISIBLE_KEY);
        }
        this.state = {
            showSuccessAlert: false,
            showRequestSuccessAlert: false,
            isBiographyVisible: false,
            isSendMessageVisible,
            isFormVisible: false,
            futureAppointments: null,
            pastAppointments: null,
            pastAppointmentRequests: null,
            filteredPastAppt: null,
            filteredPastApptRequests: null,
            createdAppointment: null,
            createdRequest: null,
            showSuccessAppointmentCancelationAlert: false,
            cancelationAlertType: null,
            showAppointmentSummary: false,
        };
        this.beforeRedirectSubscription = null;
    }

    /**
     * function to get future appointments subset from appointments collection
     *  @param {{startTime: Date}[]} appointments - appointment object
     * @returns {object[]} - subset of appointments
     */
    getFutureAppointments(
        appointments,
        appointmentRequests,
        visitTypeMap,
        reasons,
        statusMap,
        apptRequestStatusMap
    ) {
        const now = moment();

        //get future appointments and not started, started and confirmed appointments, should map VirtualAppointmentStatus.cs
        const futureAppointments = appointments
            .filter((appointment) => {
                const appointmentDup = { ...appointment };
                return (
                    now.isBefore(moment(appointmentDup.endTime)) &&
                    (statusMap[appointmentDup.status] === 'Not Started' ||
                        statusMap[appointmentDup.status] === 'Started' ||
                        statusMap[appointmentDup.status] === 'Confirmed' ||
                        statusMap[appointmentDup.status] === 'Checked In')
                );
            })
            .sort((appointment1, appointment2) =>
                !appointment1.startTime || !appointment2.startTime
                    ? 0
                    : new Date(appointment1.startTime).getTime() -
                      new Date(appointment2.startTime).getTime()
            )
            .map((appointment) => {
                appointment.type = visitTypeMap[appointment.type];
                appointment.reason = reasons.find(
                    (reason) => reason.enumValue === appointment.reason
                )?.description;
                appointment.status = statusMap[appointment.status];
                return appointment;
            });

        // weird thing happening where the appt request status is sometimes coming in as
        // as string instead of enum number even through we are receiving from
        // props/db. doing type check on status so things render properly
        const apptRequestStatusWatchlist = ['Pending', 'Scheduled', 'In Progress'];
        const futureAppointmentRequests = appointmentRequests
            .filter((appt) => {
                const existingAppointment = appt.appointmentId
                    ? appointments.find((a) => a.id === appt.appointmentId)
                    : null;
                if (
                    !existingAppointment &&
                    apptRequestStatusWatchlist.includes(
                        typeof appt.status === 'string'
                            ? appt.status
                            : apptRequestStatusMap[appt.status]
                    )
                ) {
                    return appt;
                }
            })
            .map((request) => {
                request.type = visitTypeMap[request.visitType];
                request.reason = request.requestReasonDescription;
                request.status =
                    typeof request.status === 'string'
                        ? request.status
                        : apptRequestStatusMap[request.status];
                request.isRequest = true;
                return request;
            });

        return futureAppointments.concat(futureAppointmentRequests);
    }

    /**
     * function to get past appointments subset from appointments collection
     *  @param {{startTime: Date}[]} appointments - appointment object
     * @returns {object[]} - subset of appointments
     */
    getPastAppointments(
        appointments,
        appointmentRequests,
        visitTypeMap,
        reasons,
        statusMap,
        apptRequestStatusMap
    ) {
        const now = moment();

        //get past appointments and canceled (by member and provider) appointments, should map VirtualAppointmentStatus.cs
        const pastAppointments = appointments
            .filter((appointment) => {
                return (
                    now.isAfter(moment(appointment.endTime)) ||
                    statusMap[appointment.status] === 'No show' ||
                    statusMap[appointment.status] === 'Completed' ||
                    statusMap[appointment.status] === 'Rescheduled' ||
                    statusMap[appointment.status] === 'Claim Created' ||
                    statusMap[appointment.status] === 'Checked Out'
                );
            })
            .map((appointment) => {
                appointment.type = visitTypeMap[appointment.type];
                appointment.reason = reasons.find(
                    (reason) => reason.id === appointment.reason
                )?.description;
                appointment.status = statusMap[appointment.status];
                appointment.timeBlock = `${moment(appointment.startTime).format(
                    'MM/DD/YYYY h:mm A'
                )} - ${moment(appointment.endTime).format('h:mm A')}`;
                return appointment;
            });

        return pastAppointments;
    }

    getPastAppointmentRequests(
        appointments,
        appointmentRequests,
        visitTypeMap,
        apptRequestStatusMap
    ) {
        // weird thing happening where the appt request status is sometimes coming in as
        // as string instead of enum number even through we are receiving from
        // props/db. doing type check on status so things render properly
        const apptRequestStatusWatchlist = ['Completed', 'Canceled'];
        const pastAppointmentRequests = appointmentRequests
            .filter((appt) => {
                const existingAppointment = appt.appointmentId
                    ? appointments.find((a) => a.id === appt.appointmentId)
                    : null;
                if (
                    !existingAppointment &&
                    apptRequestStatusWatchlist.includes(
                        typeof appt.status === 'string'
                            ? appt.status
                            : apptRequestStatusMap[appt.status]
                    )
                ) {
                    return appt;
                }
            })
            .map((request) => {
                request.type = visitTypeMap[request.visitType];
                request.reason = request.requestReasonDescription;
                request.status =
                    typeof request.status === 'string'
                        ? request.status
                        : apptRequestStatusMap[request.status];
                request.isRequest = true;
                return request;
            });

        return pastAppointmentRequests;
    }

    componentDidMount = () => {
        this.subscriptions.push(
            createdAppointment$.subscribe({
                next: (appt) => {
                    this.setState({ createdAppointment: appt });
                    if (appt !== null) {
                        this.setState({ showSuccessAlert: true });
                    }
                },
            }),
            createdRequest$.subscribe({
                next: (request) => {
                    this.setState({ createdRequest: request });
                    if (request !== null) {
                        this.setState({ showRequestSuccessAlert: true });
                    }
                },
            }),
            trackService
                .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewVirtualCare })
                .subscribe()
        );

        this.setPastAndFutureAppts();
    };

    componentWillUnmount = () => {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.beforeRedirectSubscription && this.beforeRedirectSubscription.unsubscribe();
    };

    componentDidUpdate(prevProps) {
        const {
            appointments: prevAppointments,
            appointmentRequests: prevAppointmentRequests,
            appointmentStatusMap: prevAppointmentStatusMap,
            reasons: prevReasons,
            visitTypes: prevVisitTypes,
        } = prevProps;
        const { appointments, appointmentRequests, appointmentStatusMap, reasons, visitTypes } =
            this.props;

        if (
            prevAppointments !== appointments ||
            prevAppointmentRequests !== appointmentRequests ||
            reasons !== prevReasons ||
            appointmentStatusMap !== prevAppointmentStatusMap ||
            visitTypes !== prevVisitTypes
        ) {
            this.setPastAndFutureAppts();
        }
    }

    setPastAndFutureAppts = () => {
        const {
            appointments,
            appointmentRequests,
            appointmentStatusMap,
            appointmentRequestStatusMap,
            reasons,
            visitTypes,
        } = this.props;

        if (appointments && appointmentRequests && appointmentStatusMap && reasons && visitTypes) {
            this.setState({
                futureAppointments: this.getFutureAppointments(
                    appointments,
                    appointmentRequests,
                    visitTypes,
                    reasons,
                    appointmentStatusMap,
                    appointmentRequestStatusMap
                ),
                pastAppointments: this.getPastAppointments(
                    appointments,
                    appointmentRequests,
                    visitTypes,
                    reasons,
                    appointmentStatusMap,
                    appointmentRequestStatusMap
                ),
                pastAppointmentRequests: this.getPastAppointmentRequests(
                    appointments,
                    appointmentRequests,
                    visitTypes,
                    appointmentRequestStatusMap
                ),
            });
        }
    };

    /**
     * event handler and search filter function for searching past appointments table
     */
    handleSearchChange = (term, isApptRequest) => {
        if (term === '') {
            isApptRequest
                ? this.setState({ filteredPastApptRequests: null })
                : this.setState({ filteredPastAppt: null });
        } else {
            const newDataSet = this.searchArray(term, isApptRequest);
            isApptRequest
                ? this.setState({ filteredPastApptRequests: newDataSet })
                : this.setState({ filteredPastAppt: newDataSet });
        }
    };

    searchArray = (searchTerm, isApptRequest) => {
        const searchArray = isApptRequest
            ? this.state.pastAppointmentRequests
            : this.state.pastAppointments;
        const keyword = searchTerm.toLowerCase();
        let newArray = [];
        if (isApptRequest) {
            for (let i = 0; i < searchArray.length; i++) {
                const currAppt = searchArray[i];
                if (
                    hasSearchTerm(keyword, currAppt.reason, 'string') ||
                    hasSearchTerm(keyword, currAppt.type, 'string')
                ) {
                    newArray.push(currAppt);
                }
            }
        } else {
            for (let i = 0; i < searchArray.length; i++) {
                const currAppt = searchArray[i];
                if (
                    hasSearchTerm(keyword, currAppt.reason, 'string') ||
                    hasSearchTerm(keyword, currAppt.type, 'string') ||
                    hasSearchTerm(keyword, currAppt.provider.providerFullName, 'string') ||
                    hasSearchTerm(keyword, currAppt.startTime, 'date') ||
                    hasSearchTerm(keyword, currAppt.endTime, 'date')
                ) {
                    newArray.push(currAppt);
                }
            }
        }
        return newArray;
    };

    /**
     * event handler to show biography once biography link is clicked
     * @param {Event} event  - react event
     */
    handleBiographyLinkClick = (event) => {
        event && event.preventDefault();
        this.setState({ isBiographyVisible: true });
    };

    /**
     * event handler to hide biography once biography close button is clicked
     */
    handleBiographyClose = () => {
        this.setState({ isBiographyVisible: false });
    };

    /**
     * event handler to hide the form once cancel is pressed on form
     */
    handleFormCancel = () => {
        this.setState({ isFormVisible: false });
    };

    /**
     * event handler to show the form once book an appointment is clicked
     */
    handleAppointmentClick = () => {
        this.setState({ isFormVisible: true });
    };

    handleSendMessageClick = () => {
        this.beforeRedirectSubscription && this.beforeRedirectSubscription.unsubscribe();
        this.beforeRedirectSubscription = beforeRedirect$.subscribe(async () => {
            sessionStorage.setItem(
                IS_SEND_MESSAGE_VISIBLE_KEY,
                JSON.stringify(this.state.isSendMessageVisible)
            );
        });

        this.setState({ isSendMessageVisible: true });
    };

    handleSendMessageBackOrCancel = () => {
        this.beforeRedirectSubscription && this.beforeRedirectSubscription.unsubscribe();
        this.setState({ isSendMessageVisible: false });
    };

    handleAlertClose = () => {
        createdAppointment$.next(null);
        this.setState({
            showSuccessAlert: false,
        });
    };

    handleRequestAlertClose = () => {
        createdRequest$.next(null);
        this.setState({
            showRequestSuccessAlert: false,
        });
    };

    handleAppointmentCancelationCompleted = (value, type) => {
        this.setState({
            showSuccessAppointmentCancelationAlert: value,
            cancelationAlertType: type,
        });
    };

    handleGoToAppointment = (appointment) => {
        if (
            this.props.config.AllowMemberToJoinAppointmentBefore &&
            moment()
                .add(this.props.config.AllowMemberToJoinAppointmentBefore, 'minutes')
                .isBefore(moment(appointment.startTime))
        ) {
            return;
        }
        if (!this.props.config.VirtualRoomHostUrl) {
            return;
        }

        window.open(
            `${this.props.config.VirtualRoomHostUrl}/${appointment.id}/${appointment.memberConnectionId}`
        );
    };

    hideSendMessage = () => {
        this.beforeRedirectSubscription && this.beforeRedirectSubscription.unsubscribe();
        this.setState({ isSendMessageVisible: false });
    };

    showAppointmentSummary(appointment) {
        this.setState({ showAppointmentSummary: true, appointmentSummary: appointment });
    }

    hideAppointmentSummary() {
        this.setState({ showAppointmentSummary: false, appointmentSummary: null });
    }

    getPastAppointmentsGridData() {
        const { pastAppointments, filteredPastAppt } = this.state;

        return (filteredPastAppt || pastAppointments || []).map((appt) => {
            if (
                appt.visitSummaryNotes &&
                appt.visitSummaryNotes.length &&
                this.isSummaryNotesEnabled()
            ) {
                return {
                    ...appt,
                    summaryNotesIcon: (
                        <FontAwesomeIcon
                            icon={faFileAlt}
                            aria-label="Appointment notes"
                            size="2x"
                            className={styles['summary-notes-icon']}
                        />
                    ),
                };
            } else {
                return appt;
            }
        });
    }

    getPastAppointmentsGridFields() {
        var fields = [
            {
                header: 'Date & Time',
                col: { lg: this.isSummaryNotesEnabled() ? 3 : 4 },
                name: 'timeBlock',
            },
            {
                header: 'Reason',
                col: { lg: 2 },
                name: 'reason',
            },
            {
                header: 'Provider',
                col: { lg: 2 },
                name: 'provider.providerFullName',
            },
            {
                header: 'Visit Type',
                col: { lg: 2 },
                name: 'type',
            },
            {
                header: 'Status',
                col: { lg: 2 },
                name: 'status',
            },
        ];

        if (this.isSummaryNotesEnabled()) {
            var notesField = {
                header: 'Notes',
                col: { lg: 1 },
                name: 'summaryNotesIcon',
                onClick: (fieldName, appointment) => this.showAppointmentSummary(appointment),
                sortable: false,
            };

            fields.push(notesField);
        }

        return fields;
    }

    getPastAppointmentRequestsGridFields() {
        var fields = [
            {
                header: 'Reason',
                col: { lg: 4 },
                name: 'reason',
            },
            {
                header: 'Visit Type',
                col: { lg: 4 },
                name: 'type',
            },
            {
                header: 'Status',
                col: { lg: 4 },
                name: 'status',
            },
        ];

        return fields;
    }

    isSummaryNotesEnabled() {
        return this.props.featureFlags[featureFlagsNames.memberPostVisitSummary] ?? false;
    }

    render() {
        const { isBiographyVisible, isSendMessageVisible, futureAppointments, appointmentSummary } =
            this.state;

        const pastAppointmentsGridData = this.getPastAppointmentsGridData();
        const pastAppointmentsGridFields = this.getPastAppointmentsGridFields();
        const pastAppointmentRequestsGridData =
            this.state.filteredPastApptRequests || this.state.pastAppointmentRequests || [];
        const pastAppointmentRequestsGridFields = this.getPastAppointmentRequestsGridFields();

        const { pcp, identity, reasons, visitTypes, planType, hasVirtualCare } = this.props;

        if (!identity) {
            return null;
        }

        if (isSendMessageVisible) {
            if (this.props.featureFlags) {
                const flag = this.props.featureFlags[featureFlagsNames.crmInteractionMessageCenter];
                if (flag) {
                    return (
                        <Message
                            interaction={null}
                            memberId={this.props.identity.memberId}
                            memberPortalConfig={this.props.memberPortalConfig}
                            onBackClick={this.handleSendMessageBackOrCancel}
                            onMessageSuccess={this.hideSendMessage}
                        />
                    );
                }
            }
            return (
                <Composer
                    sent={this.hideSendMessage}
                    cancelled={this.hideSendMessage}
                    onBack={this.handleSendMessageBackOrCancel}
                    onCancel={this.handleSendMessageBackOrCancel}
                />
            );
        }

        return this.props.appointments === undefined ? (
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: '275px' }}
            >
                <FontAwesomeIcon
                    icon={faCircleNotch}
                    aria-label="Currently Loading Content"
                    size="3x"
                />
            </div>
        ) : (
            <section id="virtual-care" className={styles['virtual-care']}>
                {pcp && (
                    <Biography
                        pcp={pcp}
                        onBookAppointmentClick={this.handleAppointmentClick}
                        visible={isBiographyVisible}
                        onClose={this.handleBiographyClose}
                    />
                )}
                <Container>
                    <Row>
                        <Col>
                            <PageHeader className={styles.title} data-testid="getVirtualCareTitle">
                                {planType == PlanTypes.HMO
                                    ? 'Your Doctor Is In'
                                    : 'Get Virtual Care'}
                            </PageHeader>
                            {planType == PlanTypes.HMO && (
                                <div
                                    className={styles.section}
                                    data-testid="getVirtualCareDescription"
                                >
                                    <p className={styles.p}>
                                        Book an appointment with your doctor now.
                                    </p>
                                </div>
                            )}
                            <div className={styles.section}>
                                <Row className={styles['virtual-care-cards']}>
                                    <Col md="4" sm="12" className={styles['virtual-care-card']}>
                                        {planType === PlanTypes.HMO ? (
                                            <LinkedPost
                                                icon="doctor-v2"
                                                header="See Your PCP"
                                                subHeader={`Book an Appointment with ${
                                                    this.props.pcp ? this.props.pcp.name : ''
                                                }`}
                                                to="/appointment-form"
                                            />
                                        ) : (
                                            <LinkedPost
                                                icon="doctor-v2"
                                                header="Schedule an appointment"
                                                subHeader="Connect with a Clinician"
                                                to="/appointment-request"
                                            />
                                        )}
                                    </Col>
                                    <Col md="4" sm="12" className={styles['virtual-care-card']}>
                                        <LinkedPost
                                            icon="doctor-v2"
                                            header="Get 24/7 Care"
                                            subHeader="Request the Next Available Appointment"
                                            to="/urgent-care-appointment-form"
                                        />
                                    </Col>
                                    <Col md="4" sm="12" className={styles['virtual-care-card']}>
                                        <LinkedPost
                                            icon="bell"
                                            header="Talk to Your Virtual Care Team"
                                            subHeader={`Contact an Agent Now`}
                                            onClick={
                                                visitTypes && reasons && this.handleSendMessageClick
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <h2 style={{ fontWeight: 'bold' }}>Your Virtual Appointments</h2>
                            <section>
                                <p className="mt-4">
                                    You can join your virtual appointment 15 minutes before the
                                    scheduled time. If you would like to reschedule, please cancel
                                    the appointment and schedule a new one.
                                </p>
                                <h3 className={`${styles.labeled}`}>Upcoming Appointments</h3>
                                <Row data-testid="rowFutureAppointments">
                                    {isNil(futureAppointments) ? (
                                        <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{ height: '275px', width: '100%' }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                aria-label="Currently Loading Content"
                                                size="3x"
                                            />
                                        </div>
                                    ) : (
                                        futureAppointments.map((appointment) => (
                                            <Col
                                                lg="4"
                                                key={appointment.id}
                                                className={styles['appt-cards']}
                                            >
                                                <Appointment
                                                    onGoToAppointment={
                                                        !appointment.isRequest &&
                                                        this.handleGoToAppointment
                                                    }
                                                    key={appointment.id}
                                                    appointment={appointment}
                                                    downloadFileById={this.props.downloadFileById}
                                                    cancelAppointment={
                                                        appointment.startTime !== undefined
                                                            ? this.props.cancelAppointment
                                                            : this.props.cancelAppointmentRequest
                                                    }
                                                    refreshAppointments={
                                                        this.props.refreshAppointments
                                                    }
                                                    onAppointmentCancellationCompleted={() =>
                                                        this.handleAppointmentCancelationCompleted(
                                                            true,
                                                            appointment.startTime !== undefined
                                                                ? 'Appointment'
                                                                : 'Request'
                                                        )
                                                    }
                                                    preferredTimes={this.props.preferredTimes}
                                                />
                                            </Col>
                                        ))
                                    )}
                                </Row>
                            </section>
                            <section className="mt-5">
                                <h3 className={`${styles.labeled}`}>Past Appointments</h3>
                                <Tabs>
                                    <Tab eventKey="appointments" title="Appointments">
                                        <SearchBar
                                            placeholder="Search Past Appointments"
                                            change={this.handleSearchChange}
                                        />
                                        <div data-testid="rowPastAppointments">
                                            {isNil(pastAppointmentsGridData) ? (
                                                <div
                                                    className="d-flex align-items-center justify-content-center"
                                                    style={{ height: '275px' }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCircleNotch}
                                                        aria-label="Currently Loading Content"
                                                        size="3x"
                                                    />
                                                </div>
                                            ) : (
                                                <ResponsiveTable
                                                    data={pastAppointmentsGridData}
                                                    fields={pastAppointmentsGridFields}
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    {planType !== PlanTypes.HMO && (
                                        <Tab eventKey="requests" title="Appointment Requests">
                                            <SearchBar
                                                placeholder="Search Past Appointment Requests"
                                                change={(value) => {
                                                    this.handleSearchChange(value, true);
                                                }}
                                            />
                                            <div>
                                                {isNil(this.state.pastAppointmentRequests) ? (
                                                    <div
                                                        className="d-flex align-items-center justify-content-center"
                                                        style={{ height: '275px' }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faCircleNotch}
                                                            aria-label="Currently Loading Content"
                                                            size="3x"
                                                        />
                                                    </div>
                                                ) : (
                                                    <ResponsiveTable
                                                        data={pastAppointmentRequestsGridData}
                                                        fields={pastAppointmentRequestsGridFields}
                                                    />
                                                )}
                                            </div>
                                        </Tab>
                                    )}
                                </Tabs>
                            </section>
                        </Col>
                    </Row>
                </Container>
                <Modal centered show={this.state.showSuccessAlert} onHide={this.handleAlertClose}>
                    <div className={styles['success-alert']}>
                        <div className={[styles['calendar-icon-container']]}>
                            <FontAwesomeIcon
                                className={styles['calendar-icon']}
                                aria-label="Success"
                                size="3x"
                                icon={faCheckCircle}
                            />
                        </div>
                        <div className="text-center">
                            <h4>Your appointment with {(pcp || {}).name} is booked!</h4>
                            <br />
                            <h4>
                                {moment(this.state.createdAppointment?.startTime).format(
                                    'dddd, MMMM D, YYYY'
                                )}
                            </h4>
                            <h4>
                                {moment(this.state.createdAppointment?.startTime).format('h:mm A')}{' '}
                                {getTimeZone()}
                            </h4>
                            <br />
                            <p>
                                <strong>Next Steps:</strong> Your appointment confirmation and
                                instructions have been sent to you.
                            </p>
                            <p>
                                <strong>Appointment Reminder:</strong> We will send you a reminder
                                15 minutes before your appointment.
                            </p>
                        </div>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <button
                                        className={styles['modal-btn']}
                                        onClick={this.handleAlertClose}
                                        onKeyUp={handleEnterKeyToClick}
                                        tabIndex="0"
                                    >
                                        OK
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    centered
                    show={this.state.showRequestSuccessAlert}
                    onHide={this.handleRequestAlertClose}
                >
                    <div className={styles['success-alert']}>
                        <div className={[styles['calendar-icon-container']]}>
                            <FontAwesomeIcon
                                className={styles['calendar-icon']}
                                style={{ left: '0%', display: 'inline-block' }}
                                aria-label="Success"
                                size="3x"
                                icon={faCheckCircle}
                            />
                            <h3 style={{ display: 'inline-block' }}>Request Submitted</h3>
                        </div>
                        <div className="text-center">
                            <p>
                                Your visit request has been submitted, a text and email will be sent
                                for your records after your appointment is confirmed.
                            </p>
                            <p>
                                You will be contacted at the phone number provided as soon as
                                possible to confirm the date and time of your appointment.
                            </p>
                        </div>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <button
                                        className={styles['modal-btn']}
                                        onClick={this.handleRequestAlertClose}
                                        onKeyUp={handleEnterKeyToClick}
                                        tabIndex="0"
                                    >
                                        OK
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    centered
                    show={this.state.showSuccessAppointmentCancelationAlert}
                    onHide={() => this.handleAppointmentCancelationCompleted(false)}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className={styles['cancel-appointment-success-alert']}>
                            <div className="text-center">
                                <h4>{this.state.cancelationAlertType} has been cancelled</h4>
                            </div>
                            <Row>
                                <Col>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            className={styles['modal-btn']}
                                            onClick={() =>
                                                this.handleAppointmentCancelationCompleted(false)
                                            }
                                            tabIndex="0"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
                <Feature name={featureFlagsNames.memberPostVisitSummary}>
                    {appointmentSummary && (
                        <Modal
                            centered
                            show={this.state.showAppointmentSummary}
                            onHide={() => this.hideAppointmentSummary()}
                        >
                            <Modal.Header closeButton>Appointment summary</Modal.Header>
                            <Modal.Body>
                                <AppointmentSummary appointment={appointmentSummary} />
                            </Modal.Body>
                        </Modal>
                    )}
                </Feature>
            </section>
        );
    }
}

export default withPolicyData(
    withMemberData(
        withAppointmentData(
            withPcpData(withIdentityData(withFeatureFlags(withMemberPortalConfigData(VirtualCare))))
        )
    )
);
