import { map, switchMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { jsonHeaders$ } from './headers';
import { getSearchParamsString } from 'common/utility';

const formTemplateService = {
    getMostRecentFormTemplate$: (templateName: string) =>
        jsonHeaders$
            .pipe(
                switchMap((headers) =>
                    ajax({
                        method: 'GET',
                        url: `api/FormTemplate/GetMostRecentFormTemplate?${getSearchParamsString({
                            templateName,
                        })}`,
                        headers,
                    })
                )
            )
            .pipe(map((ajaxResponse) => ajaxResponse.response)),
};

export default formTemplateService;
