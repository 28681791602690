import { ajax } from 'rxjs/ajax';
import { switchMap, map, tap, take } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { combineLatest } from 'rxjs';
import {
    openPdfResponseInNewTab,
    arrayBufferToBase64,
    downloadImage,
    downloadImages,
} from '../common/utility';
import trackService from './trackService';

const getById$ = (id) =>
    jsonHeaders$.pipe(
        take(1),
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/members/documents/${id}`,
                headers,
                responseType: 'arraybuffer',
            })
        ),
        map((response) => response.response)
    );

const documentService = {
    get$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/members/documents`,
                headers,
            })
        )
    ),
    getTopDocuments$: () => jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/members/documents/GetTopDocuments`,
                headers,
            })
        )
    ),
    getEducationalResources$: () => jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/members/documents/GetEducationalDocuments`,
                headers,
            })
        )
    ),
    getAdditionalResources$: () => jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/members/documents/GetAdditionalResources`,
                headers,
            })
        ),
        map((response) => (response.response || []).concat({
            href: 'https://alignment.access.mcg.com/index',
            target: '_alignment_mcg',
            customEvent: 'MemberPortalCGTLink',
            filename: 'Alignment Health Medicare Guidelines'
        }))
    ),
    getPdfById$: (id) => getById$(id).pipe(tap(openPdfResponseInNewTab)),
    downloadAndCombineImages$: (ids) =>
        combineLatest(ids.map((id) => getById$(id))).pipe(
            tap((buffers) => downloadImages(buffers))
        ),
    downloadImageById$: (id) => getById$(id).pipe(tap(downloadImage)),
    getImageById$: (id) =>
        getById$(id).pipe(
            map(arrayBufferToBase64),
            map((imageStr) => 'data:image/jpeg;base64,' + imageStr)
        ),
};

export default documentService;
