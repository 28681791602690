import React, { useEffect, useState } from 'react';
import { faSort, faSortUp, faSortDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { SortDirection } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { dateToStr, parseMarkdownHyperlink } from 'common/utility';
import SearchBar from 'components/molecules/SearchBar';
import { getHasUnread } from 'components/pages/MessageCenter';
import { Interaction } from 'interfaces/Interaction';
import { MemberPortalConfig } from 'interfaces/MemberPortalConfig';

interface iconObjInterface {
    icon: IconDefinition;
    ariaLabel: string;
    sortDirection: SortDirection;
}
const iconMap: { [key: number]: iconObjInterface } = {
    0: { icon: faSort, ariaLabel: 'Click to sort in ascending order', sortDirection: false },
    1: { icon: faSortUp, ariaLabel: 'Click to sort in descending order', sortDirection: 'asc' },
    2: { icon: faSortDown, ariaLabel: 'Click to remove sort', sortDirection: 'desc' },
};

interface InboxProps {
    handleNewMessage: () => void;
    interactions: Interaction[];
    memberId: string;
    memberPortalConfig: MemberPortalConfig | null;
    onInteractionSelect: (interaction: Interaction) => void;
}

const Inbox = ({
    handleNewMessage,
    interactions,
    memberId,
    memberPortalConfig,
    onInteractionSelect,
}: InboxProps) => {
    const [filteredInteractions, setFilteredInteractions] = useState<Interaction[]>(interactions);
    const [currPage, setCurrPage] = useState<number>(1);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [sort, setSort] = useState<{
        field: string;
        direction: number;
    }>({
        field: 'date',
        direction: 2,
    });

    useEffect(() => {
        updateInteractions();
    }, [interactions, selectedCategory, searchTerm, sort]);

    const getLatestMessage = (interaction: Interaction) => {
        if (Array.isArray(interaction.memberPortalMessageHistory)) {
            return interaction.memberPortalMessageHistory[0];
        }
        return null;
    };

    const updateInteractions = () => {
        let tempInteractions = [...interactions];
        if (selectedCategory) {
            tempInteractions = tempInteractions.filter(
                (i) => i.category && i.category.toLowerCase() === selectedCategory.toLowerCase()
            );
        }
        if (searchTerm) {
            tempInteractions = tempInteractions.filter((i) => {
                const latestMessage = getLatestMessage(i);
                if (
                    latestMessage?.message &&
                    latestMessage.message.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    return i;
                }
            });
        }
        if (sort.direction > 0 && sort.field) {
            tempInteractions.sort((a, b) => {
                let valToSortA: any;
                let valToSortB: any;
                if (sort.field === 'category') {
                    valToSortA = a.category?.toLowerCase() ?? null;
                    valToSortB = b.category?.toLowerCase() ?? null;
                } else {
                    const latestMessageA = getLatestMessage(a);
                    const latestMessageB = getLatestMessage(b);
                    valToSortA = latestMessageA
                        ? latestMessageA[sort.field as keyof typeof latestMessageA]
                        : null;
                    valToSortB = latestMessageB
                        ? latestMessageB[sort.field as keyof typeof latestMessageB]
                        : null;
                }
                if (sort.field === 'date') {
                    valToSortA = valToSortA ? new Date(valToSortA) : null;
                    valToSortB = valToSortB ? new Date(valToSortB) : null;
                } else {
                    valToSortA = valToSortA?.toLowerCase().replace(/\W/g, '') ?? '';
                    valToSortB = valToSortB?.toLowerCase().replace(/\W/g, '') ?? '';
                }

                let sortVal;
                if (valToSortA < valToSortB) {
                    sortVal = -1;
                } else if (valToSortB < valToSortA) {
                    sortVal = 1;
                } else {
                    sortVal = 0;
                }

                if (sort.direction === 1) {
                    return sortVal;
                } else {
                    return sortVal * -1;
                }
            });
        }

        setFilteredInteractions(tempInteractions);
    };

    const getInteractionsForPage = () => {
        const startIndex = (currPage - 1) * 5;
        const endIndex = startIndex + 5;

        return filteredInteractions.slice(startIndex, endIndex);
    };

    const changeSort = (field: string) => {
        let direction = sort.field === field ? sort.direction : 0;
        direction++;
        if (direction === 3) {
            direction = 0;
        }
        setSort({ field, direction });
    };

    const getHeaderCell = (field: string, label: string) => {
        let iconObj = iconMap[0];
        if (sort.field === field) {
            iconObj = iconMap[sort.direction as keyof typeof iconMap];
        }

        return (
            <TableCell
                onClick={() => {
                    changeSort(field);
                }}
                sortDirection={iconObj.sortDirection}
                sx={{ cursor: 'pointer' }}
            >
                {label} <FontAwesomeIcon aria-label={iconObj.ariaLabel} icon={iconObj.icon} />
            </TableCell>
        );
    };

    const getParsedMessagePreview = (message: string) => {
        const parsed = parseMarkdownHyperlink(message, true);
        if (parsed.length > 50) {
            return `${parsed.substring(0, 50)}...`;
        }
        return parsed;
    };

    const totalPageCount = Math.ceil(filteredInteractions.length / 5);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button onClick={handleNewMessage} variant="contained">
                        New Message
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ display: { md: 'flex' }, justifyContent: { md: 'flex-end' } }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <span className="mr-2" style={{ whiteSpace: 'nowrap' }}>
                            Filter by:
                        </span>
                        <Select
                            displayEmpty
                            onChange={(args) => {
                                setSelectedCategory(args.target.value ? args.target.value : null);
                            }}
                            size="small"
                            sx={{ minWidth: '300px' }}
                            value={selectedCategory ?? ''}
                        >
                            <MenuItem value="">All Categories</MenuItem>
                            {memberPortalConfig?.messageCenterCategories?.map((category, i) => (
                                <MenuItem key={`inbox-category-${i}`} value={category}>
                                    {category}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </Grid>
            </Grid>
            <div className="mt-2">
                <p className="mb-0" style={{ fontWeight: 'bold' }}>
                    Search Messages
                </p>
                <SearchBar
                    change={(term) => {
                        setSearchTerm(term);
                    }}
                    placeholder="Search by keyword"
                />
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {getHeaderCell('authorName', 'Sender')}
                            {getHeaderCell('category', 'Category')}
                            {getHeaderCell('message', 'Message')}
                            {getHeaderCell('date', 'Date')}
                            <TableCell sx={{ width: '20px' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {interactions.length < 1 ? (
                            <TableRow>
                                <TableCell colSpan={5}>There are no messages</TableCell>
                            </TableRow>
                        ) : (
                            getInteractionsForPage().map((interaction, i) => {
                                if (interaction.memberPortalMessageHistory?.length ?? 0 > 1) {
                                    const latestMessage = interaction.memberPortalMessageHistory[0];
                                    const unreadStyle = getHasUnread(interaction, memberId)
                                        ? { fontWeight: 'bold' }
                                        : undefined;

                                    return (
                                        <TableRow
                                            key={`interaction-${i}`}
                                            hover={true}
                                            onClick={() => {
                                                onInteractionSelect(interaction);
                                            }}
                                        >
                                            <TableCell sx={unreadStyle}>
                                                {latestMessage.authorName}
                                            </TableCell>
                                            <TableCell sx={unreadStyle}>
                                                {interaction.category}
                                            </TableCell>
                                            <TableCell sx={unreadStyle}>
                                                {latestMessage.message &&
                                                    getParsedMessagePreview(latestMessage.message)}
                                            </TableCell>
                                            <TableCell sx={unreadStyle}>
                                                {dateToStr(new Date(latestMessage.date))}
                                            </TableCell>
                                            <TableCell sx={{ width: '20px' }}>
                                                {Array.isArray(latestMessage.attachments) &&
                                                    latestMessage.attachments.length > 0 && (
                                                        <AttachmentIcon />
                                                    )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="mb-2">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Pagination
                            color="primary"
                            count={totalPageCount}
                            onChange={(args, newPageNumber) => {
                                setCurrPage(newPageNumber);
                            }}
                            page={currPage}
                            showFirstButton
                            showLastButton
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{ display: { md: 'flex' }, justifyContent: { md: 'flex-end' } }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p className="mb-0">
                                {currPage} of {totalPageCount} pages ({filteredInteractions.length}{' '}
                                items)
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Inbox;
