import React, { useCallback } from 'react';
import { DropdownItem } from 'reactstrap';
import { handleEnterKey, handleEnterKeyToClick } from '../../common/accessibilityHelpers';

const DropdownItemWithAria = ({ children, ...rest }) => {
    const handleNestedEnterKey = useCallback(
        (e) => {
            handleEnterKeyToClick(e);

            if (
                children.type === 'a' ||
                children.type === 'button' ||
                typeof children.type === 'function'
            ) {
                handleEnterKey(() => {
                    const self = e.target;
                    const child = self.firstElementChild;
                    if (child && child.click && typeof child.click === 'function') {
                        child.click();
                    }
                })(e);
            }
        },
        [children]
    );
    return (
        <DropdownItem {...rest} onKeyUp={handleNestedEnterKey}>
            {children}
        </DropdownItem>
    );
};

export default DropdownItemWithAria;
