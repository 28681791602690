import {
    CONTACT_METHODS,
    MARITAL_STATUSES,
    SEXES,
    YES_NO_NA,
} from 'components/Form/Patient/Constants';
import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { has } from 'lodash';
import { get } from 'react-hook-form';
import { DateInputWithController } from 'components/Form/Patient/Input/DateInput';
import { formatToPhone } from 'common/utility';
import SelectWithController from 'components/Form/Patient/Input/Select';

const PersonalInformationFields = ({ control, state, errors }) => {
    const RelationshipStatusField = useCallback(
        () => (
            <Row>
                <Col>
                    <RadiosWithController
                        defaultValue={state?.maritalStatus}
                        question="Patient"
                        name="maritalStatus"
                        options={MARITAL_STATUSES}
                        colSize={12}
                        isHeading
                    />
                </Col>
            </Row>
        ),
        [state]
    );
    return (
        <Container>
            <Row className="mt-4">
                <Col>
                    <SelectWithController
                        defaultValue={state?.maritalStatus}
                        question="Patient"
                        name="maritalStatus"
                        options={MARITAL_STATUSES}
                        colSize={12}
                    />
                    <RadiosWithController
                        defaultValue={state?.isPreviousPatient}
                        question="Have you been a patient of Access Primary Care Physicians, Inc. in the past?"
                        name="isPreviousPatient"
                        options={YES_NO_NA}
                        colSize={6}
                    />
                </Col>
                <Col>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.primaryCarePhysician}
                        name="primaryCarePhysician"
                        question="Primary Care Physician"
                        error={get(errors, 'primaryCarePhysician')}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.firstName}
                        name="firstName"
                        question="First Name"
                        error={get(errors, 'firstName')}
                    />
                </Col>
                <Col>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.lastName}
                        name="lastName"
                        question="Last Name"
                        error={get(errors, 'lastName')}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.middleInitial}
                        name="middleInitial"
                        question="MI"
                        error={get(errors, 'middleInitial')}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={5}>
                    <DateInputWithController
                        control={control}
                        defaultValue={state?.dateOfBirth}
                        name="dateOfBirth"
                        question="Date of Birth"
                        error={get(errors, 'dateOfBirth')}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.age}
                        name="age"
                        question="Age"
                        error={get(errors, 'age')}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.address}
                        name="address"
                        question="Address"
                        error={get(errors, 'address')}
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.city}
                        name="city"
                        question="City"
                        error={get(errors, 'city')}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.state}
                        name="state"
                        question="State"
                        error={get(errors, 'state')}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.zipCode}
                        name="zipCode"
                        question="Zip"
                        error={get(errors, 'zipCode')}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={4}>
                    <RadiosWithController
                        defaultValue={state?.sex}
                        question="Sex"
                        name="sex"
                        options={SEXES}
                        colSize={6}
                    />
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                    <RadiosWithController
                        defaultValue={state?.isStudent}
                        question="Are you a student?"
                        name="isStudent"
                        options={YES_NO_NA}
                        colSize={6}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.phoneHome}
                        name="phoneHome"
                        question="Phone (Home)"
                        error={get(errors, 'phoneHome')}
                        onKeyUp={formatToPhone}
                    />
                </Col>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.phoneMobile}
                        name="phoneMobile"
                        question="Phone (Mobile)"
                        error={get(errors, 'phoneMobile')}
                        onKeyUp={formatToPhone}
                    />
                </Col>
                <Col md={6} className="mt-2">
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.employer.name}
                        name="employer.name"
                        question="Name of Employer"
                        error={get(errors, 'employer.name')}
                    />
                </Col>
                <Col md={6} className="mt-2">
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.employer.phoneNumber}
                        name="employer.phoneNumber"
                        question="Employer's Phone #"
                        error={get(errors, 'employer.phoneNumber')}
                        onKeyUp={formatToPhone}
                    />
                </Col>
            </Row>
            <div className="h4 pt-5">Employer&apos;s Address</div>
            <Row>
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.employer.address}
                        name="employer.address"
                        question="Address"
                        error={get(errors, 'employer.address')}
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.employer.city}
                        name="employer.city"
                        question="City"
                        error={get(errors, 'employer.city')}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.employer.state}
                        name="employer.state"
                        question="State"
                        error={get(errors, 'employer.state')}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.employer.zipCode}
                        name="employer.zipCode"
                        question="Zip"
                        error={get(errors, 'employer.zipCode')}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={5}>
                    <RadiosWithController
                        defaultValue={state?.contactPreference}
                        question="Preferred Method of Contact?"
                        name="contactPreference"
                        options={CONTACT_METHODS}
                        colSize={6}
                    />
                </Col>
                <Col md={{ span: 5, offset: 2 }}>
                    <RadiosWithController
                        defaultValue={state?.canSendReminders}
                        question="May we send appointment and treatment reminders via text and voicemail?"
                        name="canSendReminders"
                        options={YES_NO_NA}
                        colSize={6}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.email}
                        name="email"
                        question="Email"
                        error={get(errors, 'email')}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.spouseName}
                        name="spouseName"
                        question="Spouse's Name"
                        error={get(errors, 'spouseName')}
                    />
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                    <DateInputWithController
                        control={control}
                        defaultValue={state?.spouseDateOfBirth}
                        name="spouseDateOfBirth"
                        question="Date Of Birth"
                        error={get(errors, 'spouseDateOfBirth')}
                    />
                </Col>
            </Row>
        </Container>
    );
};
export default PersonalInformationFields;
