import React, { InputHTMLAttributes, memo, DetailedHTMLProps } from 'react';
import { ReactComponent as SearchIcon } from 'icons/v2/search.svg';
import classNames from './Searchbar.module.scss';
import { Button } from '../Button/Button';

const DEFAULT_PLACEHOLDER = 'Enter Keyword';

type InputProps = DetailedHTMLProps<
InputHTMLAttributes<HTMLInputElement>,
HTMLInputElement
>;

export type SearchbarProps = InputProps & {
    onSearch: () => void;
};

const Searchbar = ({ className, onSearch, ...restOfProps }: SearchbarProps) => {
    return (
        <article aria-label="Searchbar" role="search" className={classNames.Searchbar}>
            <article className={classNames['search-container']}>
                <input
                    className={`${classNames.input} ${className || ''}`}
                    type="text"
                    placeholder={DEFAULT_PLACEHOLDER}
                    {...restOfProps}
                />
                <SearchIcon className={classNames['search-icon']} aria-hidden />
            </article>
            <Button variant="main" aria-label="Submit search" className={classNames.button} onClick={onSearch}>
                Search
            </Button>
        </article>
    );
};

const MemoSearchbar = memo(Searchbar);
export { MemoSearchbar as Searchbar };
