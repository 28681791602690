import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';
import { get } from 'lodash';
import FormHOC from '../../Form/FormHOC';
import CheckboxWithController from 'components/Form/Patient/Input/Checkbox';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import { DateInputWithController } from 'components/Form/Patient/Input/DateInput';
import { TextAreaFieldWithController } from 'components/Form/Patient/Input/TextAreaInput';
import { TableInputForm } from 'components/Form/Patient/Input/TableForm';

const FormContent = ({ state, prevPageAction, onSubmit }) => {
    const { control, handleSubmit, errors, setValue, register } = useFormContext();
    return (
        <>
            <Row>
                <Col>
                    <div className="h2 py-3">
                        <strong>
                            <u>
                                Authorization for the Release of Protected Health Information (PHI)
                            </u>
                        </strong>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.patientFullName}
                        name="patientFullName"
                        showError={false}
                        question="Patient Name (Last, First, Middle)"
                    />
                </Col>
                <Col md={6}>
                    <DateInputWithController
                        control={control}
                        defaultValue={state?.dateOfBirth}
                        name="dateOfBirth"
                        showError={false}
                        question="Date of Birth"
                    />
                </Col>
                <Col md={7}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.address}
                        name="address"
                        showError={false}
                        question="Address"
                    />
                </Col>
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.city}
                        name="city"
                        showError={false}
                        question="City"
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.state}
                        name="state"
                        showError={false}
                        question="State"
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.zipCode}
                        name="zipCode"
                        showError={false}
                        question="Zip Code"
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.ssn}
                        name="ssn"
                        showError={false}
                        question="SSN"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="h3 font-weight-bold py-3">
                        I hereby authorize the following entity to release the Protected Health
                        Information (PHI) below to: Access Primary Care Physicians, Inc.
                    </div>
                </Col>
                <Col md={7}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.phiEntity}
                        name="phiEntity"
                        showError={false}
                        question="Entity Possessing the PHI"
                    />
                </Col>

                <Col md={7}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.address}
                        name="address"
                        showError={false}
                        question="Address"
                    />
                </Col>
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.city}
                        name="city"
                        showError={false}
                        question="City"
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.state}
                        name="state"
                        showError={false}
                        question="State"
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.zipCode}
                        name="zipCode"
                        showError={false}
                        question="Zip Code"
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.ssn}
                        name="ssn"
                        showError={false}
                        question="SSN"
                    />
                </Col>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.phoneNum}
                        name="phoneNum"
                        showError={false}
                        question="Phone Number(s)"
                    />
                </Col>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.fax}
                        name="fax"
                        showError={false}
                        question="Fax"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="h3 py-3">
                        <strong>
                            <u>
                                If this authorization has not been revoked, it will terminate one
                                year from the date of my signature unless a different expiration
                                date or expiration event is stated.
                            </u>
                        </strong>
                    </div>
                </Col>
                <Col md={12}>
                    <TableInputForm
                        control={control}
                        state={state}
                        setValue={setValue}
                        register={register}
                        name="authorizePHI"
                        title="PHI and Dates of PHI Authorized for Use of Disclosure"
                        initialInputs={{ description: '', startDate: '', endDate: '' }}
                        headers={['Description', 'Start Date of PHI ', 'End Date of PHI ']}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <p className="h4 font-weight-bold">
                        **The following information will be released unless you indicate DO NOT
                        RELEASE by checking the appropriate box
                    </p>
                </Col>
                <Col md={6}>
                    <CheckboxWithController
                        control={control}
                        defaultValue={get(state, `testCheck3`, false)}
                        name={`testCheck3`}
                        question="AIDS/HIV OR STD treatment"
                    />
                </Col>
                <Col md={6}>
                    <CheckboxWithController
                        control={control}
                        defaultValue={get(state, `testCheck4`, false)}
                        name={`testCheck4`}
                        question="Psychiatric/Mental Care"
                    />
                </Col>
                <Col md={6}>
                    <CheckboxWithController
                        control={control}
                        defaultValue={get(state, `testCheck5`, false)}
                        name={`testCheck5`}
                        question="Alcohol/Drug/Substance Abuse"
                    />
                </Col>
                <Col md={6}>
                    <CheckboxWithController
                        control={control}
                        defaultValue={get(state, `testCheck6`, false)}
                        name={`testCheck6`}
                        question="Genetic Screening"
                    />
                </Col>
                <Col md={12}>
                    <TextAreaFieldWithController
                        control={control}
                        defaultValue={state?.otherNoRelease}
                        name="otherNoRelease"
                        showError={false}
                        question="Other, please specify"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="h4 py-3">
                        <strong>
                            <u>I understand that:</u>
                        </strong>
                    </div>
                    <ul>
                        <li>
                            I may refuse to sign this authorization and it is strictly voluntary.
                        </li>
                        <li>
                            My treatment, payment, enrollment of eligibility of benefits may not be
                            conditioned on signing this authorization.
                        </li>
                        <li>
                            I may revoke this authorization at any time in writing to the provider
                            authorized to release the PHI, but if I do, it will not have any effect
                            on any actions taken prior to receiving the revocation.
                        </li>
                        <li>
                            If the requestor or receiver is not a health plan or health care
                            provider, the released information may no longer be protected by Federal
                            Privacy Regulations and may be disclosed.
                        </li>
                        <li>I have the right to receive a COPY of this form after I sign it.</li>
                        <li>
                            I will receive a photocopy only of my medical record and that the
                            original will remain with Primary Care Plus
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.patientSignName}
                        name="patientSignName"
                        showError={false}
                        question="Signature of Patient or Patient's Representative (if applicable)"
                    />
                </Col>
                <Col md={4}>
                    <DateInputWithController
                        control={control}
                        defaultValue={state?.signDate}
                        name="signDate"
                        showError={false}
                        question="Date"
                    />
                </Col>
                <Col md={8}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.patientRelationship}
                        name="patientRelationship"
                        showError={false}
                        question="Personal Representative’s Relationship to Patient and Description of Authority to Act"
                    />
                </Col>
            </Row>
            <hr />

            <div className="d-flex justify-content-center mt-3">
                <div className="d-flex justify-content-around w-75">
                    <Button onClick={prevPageAction} size="lg">
                        Go Back
                    </Button>

                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Save and Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

const AuthorizeReleasePHI = ({ state, onSubmit, props, ...rest }) => {
    return (
        <FormHOC>
            <FormContent onSubmit={onSubmit} state={state} {...rest} />
        </FormHOC>
    );
};

export default AuthorizeReleasePHI;
