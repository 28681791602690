import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Composer from './Composer';
import { getComparerFn, sendCustomEvent } from '../../common/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faReply } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import messageService from '../../services/legacyMessageService';
import { Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { handleEnterKeyToClick } from '../../common/accessibilityHelpers';
import promptService from '../../services/promptService';
import loadOverlayService from '../../services/loadOverlayService';
import { notifications$ } from '../../services/subjects';
import { AUDITEVENTTYPE } from 'services/trackService';

const Message = (props) => {
    const backClicked$ = new Subject();
    let isNewMessage =
        Object.keys(props.message).length === 0 && props.message.constructor === Object;

    const [composing, setComposing] = useState(isNewMessage);

    let isParentMessage = !props.message.parent;

    useEffect(() => {
        if (isParentMessage && props.message.id && !props.message.is_read) {
            of(true)
                .pipe(delay(2000), takeUntil(backClicked$))
                .subscribe((_) => {
                    messageService
                        .updateMessage$(props.message.id, true, props.message)
                        .subscribe();
                });
        }
    }, [isParentMessage, props.message.id, props.message.is_read, backClicked$, props.message]);

    function handleDeletionConfirmed() {
        loadOverlayService.show('Deleting Message');
        promptService.close();
        messageService.deleteMessage$(props.message.id).subscribe(
            (_) => {
                sendCustomEvent({ eventType: AUDITEVENTTYPE.MemberPortalMessageDeleted });
                notifications$.next({
                    message: 'This message is deleted',
                    color: 'success',
                });
                props.loadMessages();
                backClicked$.next();
                loadOverlayService.hide('Deleting Message');
                props.back();
            },
            () => {
                notifications$.next({
                    message:
                        'An error has ocurred trying to delete the message please contact support if problem persists!',
                    color: 'danger',
                });
                props.back();
            }
        );
    }

    function handleDeletionCanceled() {
        promptService.close();
        props.back();
    }

    return (
        <div className="message mb-4">
            {!isNewMessage && isParentMessage && (
                <div className="action-bar dark green">
                    <span
                        onClick={() => {
                            backClicked$.next();
                            props.back();
                        }}
                        onKeyUp={handleEnterKeyToClick}
                        tabIndex="0"
                    >
                        <FontAwesomeIcon
                            icon={faLongArrowAltLeft}
                            className="mr-1"
                            aria-label="Go Back"
                        />
                        Back
                    </span>
                    <span
                        className="reply"
                        onClick={() => {
                            setComposing(!composing);
                        }}
                        onKeyUp={handleEnterKeyToClick}
                        tabIndex="0"
                    >
                        <FontAwesomeIcon icon={faReply} aria-label="Reply to Message" />
                        Reply
                    </span>
                    <span
                        onClick={() =>
                            promptService.open({
                                title: 'Are you sure you want to delete this message?',
                                onActionConfirmed: handleDeletionConfirmed,
                                onActionDeclined: handleDeletionCanceled,
                            })
                        }
                        onKeyUp={handleEnterKeyToClick}
                        tabIndex="0"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} aria-label="Delete Message" />
                        Delete
                    </span>
                </div>
            )}
            <div className={`${isParentMessage ? 'border' : 'border-top'}`}>
                {(composing || props.message.handleReply) && (
                    <Composer
                        parentMessageId={props.message && props.message.id}
                        sent={() => {
                            props.loadMessages();
                            props.back();
                        }}
                        cancelled={isNewMessage ? props.back : () => setComposing(false)}
                        category={props.message.category}
                        subject={props.message.subject}
                        memberPortalConfig={props.memberPortalConfig}
                    />
                )}
                {isParentMessage && (
                    <div style={{ marginLeft: '30px', marginTop: '30px' }}>
                        <h5>
                            {(props.message.replies || []).length ? 'Re: ' : ''}
                            {props.message.subject}
                        </h5>
                    </div>
                )}
                {props.message.id && (
                    <div className="message-content">
                        <div>
                            <label>From:</label>
                            <span className="bold-text">{props.message.author_name}</span>
                        </div>
                        <div>
                            <label>Date:</label>
                            {new Date(props.message.created_at).toLocaleString([], {
                                month: '2-digit',
                                day: '2-digit',
                                year: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            })}
                        </div>
                        <div>
                            <label>Category:</label>
                            {props.message.category}
                        </div>
                        <p>{props.message.content}</p>
                    </div>
                )}
                {(props.message.replies || [])
                    .sort(getComparerFn('created_at', -1))
                    .map((reply) => (
                        <Message key={reply.id} message={reply} />
                    ))}
            </div>
        </div>
    );
};

Message.propTypes = {
    back: PropTypes.func,
    message: PropTypes.shape({
        author_name: PropTypes.string,
        author_user_id: PropTypes.string,
        content: PropTypes.string,
        category: PropTypes.string,
        created_at: PropTypes.instanceOf(Date),
        updated_at: PropTypes.instanceOf(Date),
        id: PropTypes.string,
        reference_id: PropTypes.string,
        is_read: PropTypes.bool,
        is_staff: PropTypes.bool,
        parent: PropTypes.string,
        tags: PropTypes.array,
        status: PropTypes.string,
        subject: PropTypes.string,
        replies: PropTypes.array,
        handleReply: PropTypes.bool,
    }).isRequired,
};

export default memo(Message);
