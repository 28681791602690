import React from 'react';
import PropTypes from 'prop-types';
import Linked from '../../hoc/Linked';
import Icon from '../atoms/Icon';

import styles from './Post.module.scss';
import { isEmpty } from 'lodash';

const Post = (props) => {
    return (
        <div className={`${styles.post} text-center card align-items-center padding`}>
            <Icon icon={props.icon} size="5x" className="text-center" aria-label={props.icon} />
            {!isEmpty(props.header) && (
                <h2 className={`${styles.title} rem-1-125`}>{props.header}</h2>
            )}
            {!isEmpty(props.subHeader) && <label>{props.subHeader}</label>}
        </div>
    );
};

Post.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

const LinkedPost = Linked(Post);

export { Post, LinkedPost };
