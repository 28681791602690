import { ajax } from 'rxjs/ajax';
import { switchMap, take } from 'rxjs/operators';
import { authHeaders$, jsonHeaders$ } from './headers';
import { AttachedFile } from 'interfaces/AttachedFile';

export const messageService = {
    getInteractions$: () =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/messages`,
                    headers,
                })
            )
        ),
    createInteraction$: (
        recipient: string,
        category: string,
        subject: string | null,
        message: string,
        attachments: AttachedFile[]
    ) =>
        jsonHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/messages/create`,
                    headers,
                    body: { recipient, category, subject, message, attachments },
                })
            )
        ),
    addMessage$: (interactionId: string, message: string, attachments: AttachedFile[]) =>
        jsonHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/messages/addMessage`,
                    headers,
                    body: { interactionId, message, attachments },
                })
            )
        ),
    markMessagesRead$: (interactionId: string) =>
        jsonHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/messages/markMessagesRead`,
                    headers,
                    body: { interactionId },
                })
            )
        ),
    uploadFilesForInteraction$: (files: any) =>
        authHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: 'api/messages/uploadFilesForInteraction',
                    headers,
                    body: files,
                })
            )
        ),
    getInteractionFileContent$: (fileId: string) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/messages/getInteractionFileContent/${fileId}`,
                    headers,
                })
            )
        ),
};
