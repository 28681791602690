import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import styles from './TextAreaQuestion.module.scss';

class TextAreaQuestion extends PureComponent {
    static displayName = TextAreaQuestion.name;

    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        question: PropTypes.string,
        option: PropTypes.shape({
            label: PropTypes.string,
        }),
        value: PropTypes.string,
        id: PropTypes.string,
    };

    render() {
        return (
            <Row>
                <Col>
                    <label
                        id={`label-textarea-question-${this.props.option.label}`}
                        className={styles['question-title']}
                        data-testid="textareaTitle"
                    >
                        {this.props.question}
                    </label>
                    <textarea
                        id={this.props.option.label}
                        className={styles.textarea}
                        onChange={(e) => {
                            this.props.onChange(e.target.value);
                        }}
                        onBlur={this.props.onBlur}
                        value={this.props.value}
                        name={this.props.id}
                        aria-labelledby={`label-textarea-question-${this.props.option.label}`}
                    />
                </Col>
            </Row>
        );
    }
}

export default TextAreaQuestion;
