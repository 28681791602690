import React from 'react';
import Icon from 'components/atoms/Icon';
import {
    getName,
    getIsPcp,
    getSpecialtiesAsString,
    getClosestDistance,
    getIsAcceptingAtAnyLocation,
} from 'components/pages/PcpChange/util';
import { Providers } from 'interfaces/Provider';
import './ProviderCard.scss';

interface ProviderCardProps {
    provider: Providers;
}

const ProviderCard: React.FC<ProviderCardProps> = ({ provider }: ProviderCardProps) => {
    const getAllLocationsCount = () => {
        const count = provider.ahRecords.reduce((prev, curr) => prev + curr.addresses.length, 0);
        return count;
    };

    const getPanelOverrideStatuses = (provider: Providers) => {
        const statuses = new Set([
            provider.ahRecords
                .map((ahRecord) => ahRecord.addresses.map((address) => address.panelOverrideStatus))
                .flat(1),
        ]);
        return Array.from(statuses).join('/');
    };

    return (
        <div className="provider-card-container">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p style={{ fontWeight: 'bold', fontSize: '.9rem' }} className="mr-2">
                    {getName(provider.nppes)}
                </p>
                {provider.ratings.qualityRating === 5 && <Icon icon="gold-badge" />}
                {provider.ratings.qualityRating === 4 && <Icon icon="silver-badge" />}
            </div>
            <div>
                <p className="mb-1">
                    <span style={{ fontWeight: 'bold' }}>NPI: </span>
                    {provider.npi}
                </p>
                <p className="mb-1">
                    <span style={{ fontWeight: 'bold' }}>Specialty/PCP: </span>
                    {getIsPcp(provider.ahRecords[0])
                        ? 'Primary Care Practitioner'
                        : getSpecialtiesAsString(provider.ahRecords[0])}
                </p>
                <p className="mb-1">
                    <span style={{ fontWeight: 'bold' }}>Gender: </span>
                    {provider.nppes.gender}
                </p>
                <p className="mb-1">
                    <span style={{ fontWeight: 'bold' }}>Language: </span>
                    {provider.primaryLanguage}
                    {provider.otherLanguages.map((language) => {
                        return `, ${language}`;
                    })}
                </p>
                <p className="mb-1">
                    <span style={{ fontWeight: 'bold' }}>
                        {getIsAcceptingAtAnyLocation(provider)
                            ? 'Accepting New (All) Patients'
                            : 'Accepting New Patients: '}
                    </span>
                    {!getIsAcceptingAtAnyLocation(provider) && `No`}
                </p>
            </div>
            <div className="card-footer">
                <div className="card-footer-icon mx-1">
                    <div>
                        <Icon icon={'plus'} className={`svg-inline--fa fa-w-16`} />
                        <span className="ml-1" style={{ fontWeight: 'bold' }}>
                            {provider.ahRecords.length}
                        </span>
                    </div>
                    <p className="mb-0">Groups</p>
                </div>
                <div className="card-footer-icon mx-1">
                    <div>
                        <Icon icon={'hospital'} className={`svg-inline--fa fa-w-16`} />
                        <span className="ml-1" style={{ fontWeight: 'bold' }}>
                            {getAllLocationsCount()}
                        </span>
                    </div>
                    <p className="mb-0">Locations</p>
                </div>
                <div className="card-footer-icon mx-1">
                    <Icon icon={'pin'} className={`svg-inline--fa fa-w-16`} />
                    <p className="mb-0">
                        {getClosestDistance(provider.ahRecords[0])?.toString().substring(0, 4)}{' '}
                        miles
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ProviderCard;
