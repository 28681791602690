import React from 'react';
import PrescriptionsImag from '../../../assets/prescriptions.png';
import { Banner } from 'components/v2/organisms/Banner/Banner';
interface PrescriptionsProps {}

const Prescriptions = ({}: PrescriptionsProps) => {
    return (
                <div>
                <Banner image={PrescriptionsImag} title="PRESCRIPTIONS" description="Welcome to your prescription overview, a dedicated space to understand and manage your medications effectively. We understand that prescriptions play a crucial role in your well-being, and our goal is to provide you with clarity, convenience, and peace of mind." color="#fdbd61" />
                </div>
    );
};

export default Prescriptions;

