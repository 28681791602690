
import React from 'react';
import MyHealthImg from '../../../assets/My-Health.png';
import { Banner } from 'components/v2/organisms/Banner/Banner';
interface HealthSummaryProps {}

export const HealthSummary = ({}: HealthSummaryProps) => {
    return (
                <div>
                <Banner image={MyHealthImg} title="MY HEALTH" description="Welcome to your personalized health overview, a window into your well-being that empowers you with insights, resources, and a roadmap to optimal health. Here’s a glimpse of what awaits you." color="#A4CC69" />
                </div>
    );
};


