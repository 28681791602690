import React, { useState, useEffect, createContext } from 'react';
import {
    MemberPortalConfig,
    MemberPortalConfigContextType,
    MemberPortalConfigInitialValue,
} from 'interfaces/MemberPortalConfig';
import configurationService from 'services/configurationService';
import { memberPortalConfig$ } from 'services/subjects';
import { Subscription, merge, of } from 'rxjs';
import { memberNotificationsService } from 'services/memberNotificationsService';
import { MemberNotification } from 'types/notifications';

export const MemberPortalConfigContext = createContext<MemberPortalConfigContextType>(
    MemberPortalConfigInitialValue
);
interface Props {
    children: React.ReactNode;
    member: any;
}
export const MemberPortalConfigProvider = ({ children, member }: Props) => {
    const [memberPortalConfig, setMemberPortalConfig] = useState<MemberPortalConfig>(
        MemberPortalConfigInitialValue.memberPortalConfig
    );
    const [memberNotifications, setMemberNotifications] = useState<MemberNotification[]>([]);
    let getMemberPortalConfigSubscription: Subscription | null = null;
    let memberPortalConfigSubject: Subscription | null = null;

    const getMemberPortalConfig = () => {
        getMemberPortalConfigSubscription = configurationService
            .getMemberPortalConfig$()
            .subscribe({
                next: (response) => {
                    memberPortalConfig$.next(response?.response ?? null);
                },
            });
        memberPortalConfigSubject = memberPortalConfig$.subscribe({
            next: (value) => {
                setMemberPortalConfig(value || MemberPortalConfigInitialValue.memberPortalConfig);
            },
        });
    };

    const getMemberNotifications = () => {
        memberNotificationsService.get$(member.member_number).pipe().subscribe({
            next: (response) => {
                setMemberNotifications(response)
            },
            error: (error: Error) => {
                // TODO implement toast
            },
        });
    }
    const refreshMemberNotifications =  () => {


        const notificationsToMarkAsRead = memberNotifications.slice(0, 3);
        const markDropdownNotificationsAsRead = notificationsToMarkAsRead.map((notification) =>
            of(
                memberNotificationsService
                    .markNotificationAsRead$(notification.id)
                    .pipe().subscribe()
            )
        );

        merge(markDropdownNotificationsAsRead).subscribe(()=> getMemberNotifications());
      
    };

 
    useEffect(() => {
        getMemberPortalConfig();
        return () => {
            getMemberPortalConfigSubscription?.unsubscribe();
            memberPortalConfigSubject?.unsubscribe();
        };
    }, []);

    useEffect(() => {
      if(!member) return;
      getMemberNotifications();
    }, [member]);

    return (
        <MemberPortalConfigContext.Provider
            value={{
                memberPortalConfig,
                memberNotifications,
                refreshMemberNotifications
            }}
        >
            {children}
        </MemberPortalConfigContext.Provider>
    );
};
