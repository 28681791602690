import React from 'react';
import { Row, Col } from 'reactstrap';
import { BiChevronLeft, BiChevronRight, BiFirstPage, BiLastPage } from 'react-icons/bi';
import { handleEnterKey } from '../../common/accessibilityHelpers';

const EdgePager = ({ component: Component, onClick, disabled, ...rest }) => (
    <Component
        onClick={onClick}
        className={`page-movement ${disabled ? 'disabled-icon' : ''}`}
        {...rest}
        tabIndex={disabled ? '-1' : '0'}
        onKeyUp={handleEnterKey(onClick)}
    />
);

export default class TablePagination extends React.Component {
    constructor(props) {
        super(props);

        this.pageNumbers = this.getPageNumbers(1);
    }

    getLastPageNumber = (tableSize) => {
        return Math.ceil(tableSize / this.props.pageSettings.pageSize);
    };

    getPageNumbers = (startPageNumber) => {
        const { pageCount } = this.props.pageSettings;

        if (this.pageNumbers && this.pageNumbers.includes(startPageNumber)) {
            return this.pageNumbers;
        }

        if (startPageNumber === this.getLastPageNumber(this.props.tableSize)) {
            startPageNumber = Math.max(1, startPageNumber - pageCount + 1);
        }

        var maxPageNum = Math.min(
            this.getLastPageNumber(this.props.tableSize),
            startPageNumber + pageCount - 1
        );

        return [...Array(maxPageNum).keys()].slice(startPageNumber - 1).map((index) => index + 1);
    };

    onClickPageNumber = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > this.getLastPageNumber(this.props.tableSize)) {
            return;
        }

        this.props.setPageNumber(pageNumber);
    };

    render() {
        return (
            <Row>
                <Col>
                    <EdgePager
                        component={BiFirstPage}
                        disabled={this.props.pageNumber === 1}
                        onClick={() => this.onClickPageNumber(1)}
                    />
                    <EdgePager
                        component={BiChevronLeft}
                        disabled={this.props.pageNumber === 1}
                        onClick={() => this.onClickPageNumber(this.props.pageNumber - 1)}
                    />
                    {this.getPageNumbers(this.props.pageNumber).map((number) => (
                        <span
                            className={`page-num ${
                                this.props.pageNumber === number ? 'selected-page' : ''
                            }`}
                            key={number}
                            onClick={() => this.onClickPageNumber(number)}
                            onKeyUp={handleEnterKey(() => this.onClickPageNumber(number))}
                            tabIndex="0"
                        >
                            {number}
                        </span>
                    ))}
                    <EdgePager
                        component={BiChevronRight}
                        disabled={
                            this.getLastPageNumber(this.props.tableSize) === 0 ||
                            this.props.pageNumber === this.getLastPageNumber(this.props.tableSize)
                        }
                        onClick={() => this.onClickPageNumber(this.props.pageNumber + 1)}
                    />
                    <EdgePager
                        component={BiLastPage}
                        disabled={
                            this.getLastPageNumber(this.props.tableSize) === 0 ||
                            this.props.pageNumber === this.getLastPageNumber(this.props.tableSize)
                        }
                        onClick={() =>
                            this.onClickPageNumber(this.getLastPageNumber(this.props.tableSize))
                        }
                    />
                </Col>
                <Col>
                    <span className="page-info">
                        {this.getLastPageNumber(this.props.tableSize) === 0
                            ? 0
                            : this.props.pageNumber}{' '}
                        of {this.getLastPageNumber(this.props.tableSize)} pages (
                        {this.props.tableSize} items)
                    </span>
                </Col>
            </Row>
        );
    }
}
