import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Row, Col } from 'react-bootstrap';
import FormHOC from '../../Form/FormHOC';
import FamilyHistoryInformation from './MedicalForms/FamilyHistoryInformation';
import PersonalHealthInformation from './MedicalForms/PersonalHealthInformation';
import { TableInputForm } from 'components/Form/Patient/Input/TableForm';
import PreventativeHealthInformation from './MedicalForms/PreventativeHealthInformation';
import VaccineInformation from './MedicalForms/VaccineInformation';
import OBGYNInformation from './MedicalForms/OBGYNInformation';
import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import { TextAreaFieldWithController } from 'components/Form/Patient/Input/TextAreaInput';
import { PatientPages, YesNo } from 'components/Form/Patient/Enums';
import { validators } from 'components/Form/Patient/Validations';
import { YES_NO_NA } from 'components/Form/Patient/Constants';

const AllergiesInformation = ({ control, state, setValue, register }) => {
    return (
        <TableInputForm
            control={control}
            state={state}
            setValue={setValue}
            register={register}
            name="allergies"
            title="Allergies to Medications or Environmental"
            initialInputs={{ cause: '', reaction: '' }}
            headers={['Medication or Other (Environmental)', 'Reaction']}
        />
    );
};
const DeceasedFamilyInformation = ({ control, state, setValue, register }) => {
    return (
        <TableInputForm
            control={control}
            state={state}
            setValue={setValue}
            register={register}
            name="deceasedFamily"
            title="Deceased Family Member"
            initialInputs={{ relationship: '', cause: '', age: '' }}
            headers={['Relationship', 'Cause of Death', 'Age at Death']}
        />
    );
};
const AccidentsTraumaInformation = ({ control, state }) => {
    const haveMetalPlatesWithinBody = useWatch({
        control,
        name: 'haveMetalPlatesWithinBody',
        defaultValue: state?.haveMetalPlatesWithinBody || YesNo.NA,
    });
    return (
        <>
            <Row>
                <Col>
                    <RadiosWithController
                        defaultValue={state?.hadSevereAccident}
                        question="Have you ever had a severe accident?"
                        name="hadSevereAccident"
                        options={YES_NO_NA}
                        colSize={12}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <RadiosWithController
                        defaultValue={state?.haveMetalPlatesWithinBody}
                        question="Do you have any metal pins/plates in your body?"
                        name="haveMetalPlatesWithinBody"
                        options={YES_NO_NA}
                        colSize={12}
                    />
                </Col>
            </Row>
            {haveMetalPlatesWithinBody === YesNo.YES && (
                <Row>
                    <Col>
                        <TextAreaFieldWithController
                            control={control}
                            defaultValue={state?.metalPlatesDescription}
                            name="metalPlatesDescription"
                            type="textarea"
                            question="If yes, please describe"
                            showError={false}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

const FormContent = ({ onSubmit, prevPageAction, state }) => {
    const { control, handleSubmit, errors, setValue, register } = useFormContext();

    return (
        <>
            <h2 className="pb-4">Allergies to Medications or Environmental</h2>
            <AllergiesInformation state={state} setValue={setValue} register={register} />
            <hr />

            <h2 className="pb-4">Family History</h2>
            <FamilyHistoryInformation state={state} control={control} />

            <div className="h4 py-3">
                If your mother, father, brothers, or sisters are deceased, please list their age at
                the time of their death and the cause:
            </div>
            <DeceasedFamilyInformation state={state} setValue={setValue} register={register} />
            <hr />

            <h2 className="pb-4">Your Health History</h2>
            <PersonalHealthInformation state={state} control={control} />
            <hr />

            <h2 className="pb-4">Preventative Health History</h2>
            <PreventativeHealthInformation state={state} setValue={setValue} register={register} />
            <Row>
                <Col md={6}>
                    <div className="h4 py-3">Vaccine History</div>
                    <VaccineInformation state={state} setValue={setValue} register={register} />
                </Col>
                <Col md={6}>
                    <div className="h4 py-3">OB/GYN History</div>
                    <OBGYNInformation state={state} control={control} />
                </Col>
            </Row>

            <hr />

            <h2 className="pb-4">Accidents - Trauma</h2>
            <AccidentsTraumaInformation state={state} control={control} />
            <hr />

            <div className="d-flex justify-content-center mt-3">
                <div className="d-flex justify-content-around w-75">
                    <Button onClick={prevPageAction} size="lg">
                        Go Back
                    </Button>

                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Save and Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

const MedicalHistoryForm = ({ state, onSubmit, props, ...rest }) => {
    return (
        <FormHOC yupValidations={validators[PatientPages.MEDICAL_HISTORY]}>
            <FormContent onSubmit={onSubmit} state={state} {...rest} />
        </FormHOC>
    );
};

export default MedicalHistoryForm;
