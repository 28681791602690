import React, { forwardRef, useCallback, useState, ChangeEvent } from 'react';
import { Controller, Control, ErrorOption } from 'react-hook-form';
import { Row, Col, Form } from 'react-bootstrap';

const SelectWithController = ({
    control,
    name,
    defaultValue,
    label,
    options,
    disabled,
    required,
}) => {
    const handleChange = useCallback((e) => {
        const newValue = e.target.value;

        setSelectVal(newValue);
    }, []);
    const [selectVal, setSelectVal] = useState(defaultValue || '');
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty, error },
                formState
            ) => (
                <Form.Group className="pt-3">
                    <Form.Label
                        className="h5 font-weight-bold"
                        id={`label-question-select-${name}`}
                    >
                        {label}
                        <span className="text-danger">{required ? ' *' : ' '}</span>
                    </Form.Label>
                    <Form.Control
                        as="select"
                        value={selectVal}
                        id={name}
                        aria-labelledby={`label-question-select-${name}`}
                        disabled={disabled}
                        onChange={(e) => {
                            handleChange(e);
                            onChange(e.target.value);
                        }}
                    >
                        <option value="" disabled>
                            Select...
                        </option>
                        {options.map(({ text, value }, idx) => (
                            <option key={`option_${idx}`} value={value}>
                                {text}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            )}
        />
    );
};

export default SelectWithController;
