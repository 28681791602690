import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TestTube } from '../../icons/test-tube.svg';
import { ReactComponent as Calendar } from '../../icons/calendar.svg';
import { ReactComponent as CreditCard } from '../../icons/credit-card.svg';
import { ReactComponent as CreditCards } from '../../icons/credit-cards.svg';
import { ReactComponent as Doctor } from '../../icons/doctor.svg';
import { ReactComponent as Virus } from '../../icons/virus.svg';
import { ReactComponent as GoldBadge } from '../../icons/gold-badge.svg';
import { ReactComponent as Hospital } from '../../icons/hospital.svg';
import { ReactComponent as Microscope } from '../../icons/microscope.svg';
import { ReactComponent as Medication } from '../../icons/medication.svg';
import { ReactComponent as IconPin } from '../../icons/pin.svg';
import { ReactComponent as IconPlus } from '../../icons/plus.svg';
import { ReactComponent as ReceptionBell } from '../../icons/reception-bell.svg';
import { ReactComponent as SilverBadge } from '../../icons/silver-badge.svg';

import { ReactComponent as DoctorV2 } from '../../icons/v2/Doctor.svg';
import { ReactComponent as Bell } from '../../icons/v2/Bell.svg';
import { ReactComponent as Stethoscope } from '../../icons/v2/Stethoscope.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = (props) => {
    const { className = '' } = props;
    switch (props.icon) {
        case 'calendar':
            return (
                <Calendar
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'credit-card':
            return (
                <CreditCard
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'credit-cards':
            return (
                <CreditCards
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'doctor':
            return (
                <Doctor
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                    onClick={props.onClick}
                />
            );
        case 'gold-badge':
            return <GoldBadge className={props.className} />;
        case 'hospital':
            return (
                <Hospital
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'microscope':
            return (
                <Microscope
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'medication':
            return (
                <Medication
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'pin':
            return <IconPin className={props.className} />;
        case 'plus':
            return <IconPlus className={props.className} />;
        case 'reception-bell':
            return (
                <ReceptionBell
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'silver-badge':
            return <SilverBadge className={props.className} />;
        case 'test-tube':
            return (
                <TestTube
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'virus':
            return (
                <Virus
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'doctor-v2':
            return (
                <DoctorV2
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'stethoscope':
            return (
                <Stethoscope
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        case 'bell':
            return (
                <Bell
                    className={`svg-inline--fa fa-w-16 ${
                        props.size ? `fa-${props.size}` : ''
                    } ${className}`}
                />
            );
        default:
            return <FontAwesomeIcon {...props} />;
    }
};

export default Icon;

Icon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
};
