import React from 'react';
import SearchBar from '../molecules/SearchBar';
import ResponsiveTable from '../organisms/ResponsiveTable';
import PageHeader from '../molecules/PageHeader';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const TableInfoPage = (props) => {
    return (
        <div>
            <PageHeader>{props.pageHeader}</PageHeader>
            {props.information}
            <SearchBar placeholder={props.searchBarPlaceholder} change={props.onSearchChange} />
            <Alert isOpen={props.error !== undefined} color="danger">
                {(props.error || {}).message}
            </Alert>
            {!props.data ? (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '275px' }}
                >
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        aria-label="Currently Loading Content"
                        size="3x"
                    />
                </div>
            ) : (
                <ResponsiveTable
                    data={props.data}
                    fields={props.fields}
                    rowClicked={props.rowClicked}
                />
            )}
        </div>
    );
};

export default TableInfoPage;
