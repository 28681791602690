import React, { JSXElementConstructor, useState, useEffect } from 'react';
import documentService from '../services/documentService';
import { map, first, filter } from 'rxjs/operators';
import { DocumentHeader } from 'types/documents/DocumentHeader';

export type WithEducationalResourcesDataState = {
    loadingEducationalResources: boolean;
    educationalResourcesError: string;
    educationalResources: DocumentHeader[];
}

export default function withEducationalResourcesData(WrappedComponent: JSXElementConstructor<WithEducationalResourcesDataState & unknown>) {
    return (props: any) => {
        const [loadingEducationalResources, setLoadingEducationalResources] = useState(false);
        const [educationalResources, setEducationalResources] = useState<DocumentHeader[]>([]);
        const [educationalResourcesError, setEducationalResourcesError] = useState('');
        useEffect(() => {
            setLoadingEducationalResources(true);
            documentService.getEducationalResources$()
                .pipe(
                    filter((response) => response && response.response),
                    map((response) => response.response),
                    first()
                )
                .subscribe({
                    next:(response) => {
                        setLoadingEducationalResources(false);
                        setEducationalResources(response);
                    },
                    error: (err: Error) => {
                        setLoadingEducationalResources(false);
                        setEducationalResourcesError(err.message);
                    }
                });
        }, []);

        return (
            <WrappedComponent
                educationalResources={educationalResources}
                loadingEducationalResources={loadingEducationalResources}
                educationalResourcesError={educationalResourcesError}
                {...props}
            />
        );
    };
}
