import React, { useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import CheckboxWithController from 'components/Form/Patient/Input/Checkbox';
import { ILLNESSES } from 'components/Form/Patient/Constants';
const PersonalHealthInformation = ({ control, state }) => {
    const columnCount = 4;
    const TableHeaders = () => (
        <thead className="text-center">
            <tr>
                <th colSpan={columnCount} className="align-middle">
                    (Check if you have had any of the following)
                </th>
            </tr>
        </thead>
    );

    const TableContent = useCallback(() => {
        const TableRows = [];
        for (let index = 0; index < ILLNESSES.length; index += columnCount) {
            const indices = Array.from(
                Array(columnCount).fill(index),
                (val, idx) => val + idx
            ).filter((idx) => idx < ILLNESSES.length);

            TableRows.push(
                <tr key={`disease_${index}`}>
                    {indices.map((idx) => {
                        const { label, key } = ILLNESSES[idx];
                        return (
                            <td key={key}>
                                <div className="d-flex justified-content-center">
                                    <CheckboxWithController
                                        control={control}
                                        defaultValue={get(state, `healthHistory[${key}]`, false)}
                                        name={`healthHistory[${key}]`}
                                        showError={false}
                                    />{' '}
                                    {label}
                                </div>
                            </td>
                        );
                    })}
                </tr>
            );
        }
        return <tbody className="text-center">{TableRows}</tbody>;
    }, [state]);
    return (
        <Table striped bordered responsive>
            <TableHeaders />
            <TableContent />
        </Table>
    );
};
export default PersonalHealthInformation;
