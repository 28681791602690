import { NewPatientForm } from 'components/Form/Patient/Models';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const BasicInformationReview = ({ state: formState }: { state: NewPatientForm }) => {
    const {
        firstName,
        lastName,
        middleInitial,
        dateOfBirth,
        address,
        city,
        state,
        zipCode,
        phoneHome,
        phoneMobile,
        email,
        employer,
        pharmacy,
        primary,
        secondary,
        responsibleParty,
        emergencyContact,
    } = formState;
    return (
        <Row className="px-4">
            <Col md={6} className="border-left px-2">
                <p>
                    Full Name:{' '}
                    <strong>
                        {firstName} {middleInitial} {lastName}
                    </strong>
                </p>
                <p>
                    Date of Birth: <strong>{moment(dateOfBirth).format('MM/DD/YYYY')}</strong>
                </p>
                <p>
                    Address:{' '}
                    <strong>
                        {address} | {city}, {state} {zipCode}
                    </strong>
                </p>
                <p>
                    Home Phone: <strong>{phoneHome}</strong>
                </p>
                <p>
                    Mobile Phone: <strong>{phoneMobile}</strong>
                </p>
                <p>
                    Email: <strong>{email}</strong>
                </p>
                <hr />
                <p className="h5">Primary Insurance</p>
                <p>
                    Policy Holder: <strong>{primary?.policyHolder}</strong>
                </p>
                <p>
                    Group Number: <strong>{primary?.groupNum}</strong>
                </p>
                <p>
                    Policy Contract Number: <strong>{primary?.policyContractNum}</strong>
                </p>
                <p>
                    Address:{' '}
                    <strong>
                        {primary?.address} | {primary?.city}, {primary?.state} {primary?.zipCode}
                    </strong>
                </p>
                <p className="h5">Secondary Insurance</p>
                <p>
                    Policy Holder: <strong>{secondary?.policyHolder}</strong>
                </p>
                <p>
                    Group Number: <strong>{secondary?.groupNum}</strong>
                </p>
                <p>
                    Policy Contract Number: <strong>{secondary?.policyContractNum}</strong>
                </p>
                <p>
                    Address:{' '}
                    <strong>
                        {secondary?.address} | {secondary?.city}, {secondary?.state}{' '}
                        {secondary?.zipCode}
                    </strong>
                </p>
            </Col>
            <Col className="border-left px-2">
                <p className="h5">Pharmacy</p>
                <p>
                    Name: <strong>{pharmacy?.name}</strong>
                </p>
                <p>
                    Phone: <strong>{pharmacy?.phoneNumber}</strong>
                </p>
                <p>
                    Address:{' '}
                    <strong>
                        {pharmacy?.address} | {pharmacy?.city}, {pharmacy?.state}{' '}
                        {pharmacy?.zipCode}
                    </strong>
                </p>
                <hr />
                <p className="h5">Employer</p>
                <p>
                    Name: <strong>{employer?.name}</strong>
                </p>
                <p>
                    Phone: <strong>{employer?.phoneNumber}</strong>
                </p>
                <p>
                    Address:{' '}
                    <strong>
                        {employer?.address} | {employer?.city}, {employer?.state}{' '}
                        {employer?.zipCode}
                    </strong>
                </p>
                <hr />
                <p className="h5">Responsible Party</p>
                <p>
                    Full Name:{' '}
                    <strong>
                        {responsibleParty?.firstName} {responsibleParty?.middleInitial}{' '}
                        {responsibleParty?.lastName}
                    </strong>
                </p>
                <p>
                    Phone: <strong>{responsibleParty?.phoneNumber}</strong>
                </p>
                <p>
                    Address:{' '}
                    <strong>
                        {responsibleParty?.address} | {responsibleParty?.city},{' '}
                        {responsibleParty?.state} {responsibleParty?.zipCode}
                    </strong>
                </p>
                <hr />
                <p className="h5">Emergency Contact</p>
                <p>
                    Full Name:{' '}
                    <strong>
                        {emergencyContact?.firstName} {emergencyContact?.middleInitial}{' '}
                        {emergencyContact?.lastName}
                    </strong>
                </p>
                <p>
                    Phone: <strong>{emergencyContact?.phoneNumber}</strong>
                </p>
                <p>
                    Address:{' '}
                    <strong>
                        {emergencyContact?.address} | {emergencyContact?.city},{' '}
                        {emergencyContact?.state} {emergencyContact?.zipCode}
                    </strong>
                </p>
            </Col>
        </Row>
    );
};
export default BasicInformationReview;
