import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';

const accountService = {
    update$: (
        body = { first_name, last_name, member_id, password, birthdate, email, user_channel_info }
    ) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'PUT',
                    url: `api/account`,
                    headers,
                    body,
                })
            )
        ),
    create$: (
        body = { first_name, last_name, member_id, password, birthdate, email, user_channel_info }
    ) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/account`,
                    headers,
                    body,
                })
            )
        ),
    forgotEmail$: (body = { first_name, last_name, member_id, birthdate }) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/account/forgot/email`,
                    headers,
                    body,
                })
            )
        ),
    validate$: (body = { first_name, last_name, member_id, birthdate }) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/account/setup/validatemember`,
                    headers,
                    body,
                })
            )
        ),
    requestToken$: (body = { email }) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/account/password/token-request`,
                    headers,
                    body,
                })
            )
        ),
    validateToken$: (body = { token }) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/account/password/token-validation`,
                    headers,
                    body,
                })
            )
        ),
    resetPassword$: (body = { token, email, object_id, password }) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'PUT',
                    url: `api/account/password`,
                    headers,
                    body,
                })
            )
        ),
    registerLogin$: () =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/memberaccount/registerLogin`,
                    headers,
                })
            )
        ),
};

export default accountService;
