import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ResponsiveTable from '../organisms/ResponsiveTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { currencyFormatter, yyyyddmmTommddyyyy } from '../../common/utility';
import PageHeader from '../molecules/PageHeader';
import withClaimServiceLinesData from 'hoc/withClaimServiceLinesData';

const Claim = (props) => {
    const format = currencyFormatter.format;
    
    return (
        <div>
            <PageHeader>{props.claim_number} - Claim Details</PageHeader>
            <Row className="mb-4">
                <Col md="6">
                    <Row className="mb-2">
                        <Col xs="4">
                            <span className="bold-text">Claim Type:</span>
                        </Col>
                        <Col xs="8">{props.claim_type}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs="4">
                            <span className="bold-text">Service Provider:</span>
                        </Col>
                        <Col xs="8">{props.provider_name}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs="4">
                            <span className="bold-text">Service Date:</span>
                        </Col>
                        <Col xs="8">{props.service_date}</Col>
                    </Row>
                </Col>
                <Col md="6">
                    <Row className="mb-2">
                        <Col xs="6">
                            <span className="bold-text">Amount the provider billed:</span>
                        </Col>
                        <Col xs="6">{format(props.claim_detail.total_billed_amount)}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs="6">
                            <span className="bold-text">
                                Total Cost (amount the plan approved):
                            </span>
                        </Col>
                        <Col xs="6">{format(props.claim_detail.total_allowed_amount)}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs="6">
                            <span className="bold-text">{"Plan's Share:"}</span>
                        </Col>
                        <Col xs="6">{format(props.claim_detail.total_paid_amount)}</Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs="6">
                            <span className="bold-text">
                                Your Share (your provider may bill you):
                            </span>
                        </Col>
                        <Col xs="6">
                            {format(props.claim_detail.total_patient_responsibility_amount)}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {props.loadingClaimServiceLines ? (
                    <div className="d-flex my-5">
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            aria-label="Currently Loading Content"
                            size="2x"
                        />
                    </div>
                ) : <ResponsiveTable
                data={props.claimServiceLines.map((line) => ({
                    ...line,
                    service_from_date: yyyyddmmTommddyyyy(line.service_from_date),
                    service_to_date: yyyyddmmTommddyyyy(line.service_to_date),
                }))}
                fields={[
                    {
                        header: 'Service Code',
                        col: { md: 2 },
                        name: 'procedure[0].procedure_code',
                    },
                    {
                        header: 'Service Description',
                        col: {
                            md: 3,
                        },
                        name: 'procedure[0].procedure_description',
                    },
                    {
                        header: 'Start Date',
                        col: {
                            md: 1,
                        },
                        name: 'service_from_date',
                    },
                    {
                        header: 'End Date',
                        col: {
                            md: 1,
                        },
                        name: 'service_to_date',
                    },
                    {
                        header: 'Amount the provider billed',
                        col: {
                            md: 1,
                        },
                        name: 'billed_amount',
                        format: 'currency',
                    },
                    {
                        header: 'Total Cost (amount the plan approved)',
                        col: {
                            md: 1,
                        },
                        name: 'allowed_amount',
                        format: 'currency',
                    },
                    {
                        header: `Plan's share`,
                        col: {
                            md: 1,
                        },
                        name: 'paid_amount',
                        format: 'currency',
                    },
                    {
                        header: 'Your share (your provider may bill you)',
                        col: {
                            md: 2,
                        },
                        name: 'patient_responsibility_amount',
                        format: 'currency',
                    },
                ]}
            />}
        </div>
    );
};

export default withClaimServiceLinesData(Claim);

Claim.propTypes = {
    claim_adjustment_version: PropTypes.string,
    claim_detail: PropTypes.shape({
        service_from_date: PropTypes.string,
        service_to_date: PropTypes.string,
        total_allowed_amount: PropTypes.number,
        total_provider_responsibility_amount: PropTypes.number,
        total_amount_not_covered: PropTypes.number,
        total_paid_amount: PropTypes.number,
        principal_procedure: PropTypes.shape({
            procedure_description: PropTypes.string,
            procedure_code: PropTypes.string,
        }),
        total_deductible_amount: PropTypes.number,
        total_billed_amount: PropTypes.number,
        statement_from_date: PropTypes.string,
        statement_to_date: PropTypes.string,
        total_coinsurance_amount: PropTypes.number,
        total_copay_amount: PropTypes.number,
        total_patient_responsibility_amount: PropTypes.number,
    }).isRequired,
    claimServiceLines: PropTypes.arrayOf(
        PropTypes.shape({
            place_of_service_description: PropTypes.string,
            coinsurance_amount: PropTypes.number,
            amount_not_covered: PropTypes.number,
            paid_amount: PropTypes.number,
            billed_amount: PropTypes.number,
            allowed_amount: PropTypes.number,
            notes: PropTypes.string,
            service_to_date: PropTypes.string,
            service_units: PropTypes.string,
            benefit_category_code_description: PropTypes.string,
            copay_amount: PropTypes.number,
            claimline_number: PropTypes.number,
            type_of_service_description: PropTypes.string,
            patient_responsibility_amount: PropTypes.number,
            place_of_service_code: PropTypes.string,
            data: PropTypes.string,
            procedure: PropTypes.arrayOf(PropTypes.shape(
                {
                    procedure_description: PropTypes.string,
                    procedure_code: PropTypes.string,
                },
            )),
            status: PropTypes.string,
            deductible_amount: PropTypes.number,
            service_from_date: PropTypes.string,
        })
    ),
    loadingClaimServiceLines: PropTypes.bool.isRequired,
    claimServiceLinesError: PropTypes.string.isRequired,
    claim_number: PropTypes.string.isRequired,
    claim_status: PropTypes.string,
    claim_subtype: PropTypes.string,
    claim_type: PropTypes.string,
    created_at: PropTypes.string,
    data: PropTypes.string,
    diagnoses: PropTypes.arrayOf(
        PropTypes.shape({
            diagnosis_description: PropTypes.string,
            diagnosis_code: PropTypes.string,
        })
    ),
    effective_date: PropTypes.string,
    eob_document_id: PropTypes.string,
    liability_level: PropTypes.string,
    notes: PropTypes.string,
    npi_number: PropTypes.string,
    office_zip: PropTypes.string,
    patient_id: PropTypes.string,
    patient_name: PropTypes.string,
    patient_relationship_code: PropTypes.string,
    payee_information: PropTypes.shape({
        payee_name: PropTypes.string,
        check_number: PropTypes.string,
        check_date: PropTypes.string,
        check_amount: PropTypes.string,
        payee_type: PropTypes.string,
    }),
    principal_diagnosis: PropTypes.shape({
        diagnosis_description: PropTypes.string,
        diagnosis_code: PropTypes.string,
    }),
    process_date: PropTypes.string,
    provider_liability_level_name: PropTypes.string,
    provider_name: PropTypes.string,
    provider_number: PropTypes.string,
    provider_specialty_name: PropTypes.string,
    receive_date: PropTypes.string,
    subscriber_id: PropTypes.string,
    termination_date: PropTypes.string,
    updated_at: PropTypes.string,
};
