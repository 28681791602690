import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ReceiptHipaaNoticeVerbiage = () => (
    <>
        <h1>
            <strong>
                <u>Responsibility for Payment/Receipt of HIPAA Notice/Patient Communication</u>
            </strong>
        </h1>
        <Row>
            <Col className="h5">
                <p>
                    I understand that I am fully responsible for all fees due to Access Primary Care
                    Physicians, Inc. or any associated medical provider (collectively referred to as
                    the “Clinic”) as a result of services I have received and that all fees are due
                    and payable at the time of service unless Clinic agrees to accept assignment of
                    my Medicare, Medicaid or other insurance benefits.
                </p>
                <div>
                    <u>If I have insurance coverage other than Medicare:</u>
                </div>
                <p>
                    I understand that assigning benefits to the Clinic and the filing of an
                    insurance claim on my behalf is a courtesy to me and this is not absolving me of
                    my responsibility to pay for services if the insurance company fails to pay for
                    these services or if deductibles and/or co-pays are due. I understand that my
                    insurance policy may not cover the full cost of services or may consider it an
                    uncovered service or medically unnecessary, or I may not have coverage benefits
                    for these services. I therefore agree to be responsible for those charges
                    incurred, as well as for my co-pay and/or any deductible that has not been met.
                </p>
                <p>
                    I further understand that any verification of my insurance benefits by the
                    Clinic is not a guarantee of payment by my insurance company. If my insurance
                    company does not pay for the services I have received, or fails to pay within 60
                    days of service, I understand that the Clinic will bill me for these services
                    and I agree to pay any amounts due within 10 days of receipt of a bill for these
                    services. In addition, if a claim is filed on my behalf as an unassigned claim,
                    then I will also be responsible for the difference between the amount paid by my
                    insurance company and the actual charge for that service.
                </p>
                <div>
                    <u>If I am covered under Medicare or a Medicare Advantage health plan:</u>
                </div>
                <p>
                    I understand that I will be responsible for my co-pay and/or any deductible that
                    has not been met either through my Medicare coverage or any supplemental policy
                    that I may also have. In addition, if a claim is filed on my behalf as an
                    unassigned claim, then I will also be responsible for the difference between the
                    amount paid by Medicare and the actual charge for thatservice.
                </p>
                <p>
                    I further understand that I will be notified in advance by an Advanced
                    Beneficiary Notice of Noncoverage if Medicare likely will not pay for items or
                    services. I will then have the right to make an informed choice whether or not
                    to receive the items or services. If I choose to receive the items or services,
                    I am aware that I will be responsible for paying for such items or services
                </p>
                <p>
                    I request that payment of authorized Medicare, Medicaid or other insurance
                    benefits be made on my behalf to the Clinic for any services furnished to me
                    subject to any regulations pertaining to their assignment of benefits. I
                    authorize any holder of my medical information to release to the Centers for
                    Medicare &amp; Medicaid Services, Social Security Administration and its agents,
                    intermediaries or carriers, or to any other third-party sources or insurance
                    companies and its agents any information or documentation needed to determine
                    these benefits or the benefits payable for related services. A copy of this
                    authorization may be used in place of an original and this authorization shall
                    remain in force until revoked by me in writing.
                </p>
                <p>
                    I certify that the insurance information given by me is current and accurate to
                    the best of my knowledge and I understand and agree to abide by the terms
                    outlined above.
                </p>
                <p>
                    <strong>
                        I further acknowledge that I have received a copy of the Clinic’s Notice of
                        Privacy Practices.
                    </strong>
                </p>
            </Col>
        </Row>
    </>
);
export default ReceiptHipaaNoticeVerbiage;
