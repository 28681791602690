import React from 'react';
import './pageHeader.css';

const PageHeader = ({ children, ...rest }) => (
    <h1 className="page-header" {...rest}>
        {children}
    </h1>
);

export default PageHeader;
