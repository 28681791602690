import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import FormHOC from '../../Form/FormHOC';
import WelcomeVerbiage from './Verbiage/Welcome';

const FormContent = ({ onSubmit }) => {
    const { handleSubmit } = useFormContext();
    return (
        <>
            <WelcomeVerbiage />
            <hr />

            <div className="d-flex justify-content-center mt-3">
                <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                    Start Access Form
                </Button>
            </div>
        </>
    );
};

const Welcome = ({ state, onSubmit, props, ...rest }) => {
    return (
        <FormHOC>
            <FormContent onSubmit={onSubmit} {...rest} />
        </FormHOC>
    );
};

export default Welcome;
