import React, { Component } from 'react';
import { jsonHeaders$ } from '../services/subjects';
import { mergeMap, map, take } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { interval, merge, Subject } from 'rxjs';

const getMessages$ = jsonHeaders$.pipe(
    mergeMap((headers) =>
        ajax({
            method: 'GET',
            url: `api/legacymessages?page_size=5&page_number=1`,
            headers,
        })
    ),
    map((response) => response.response)
);

const poll$ = interval(30000);

const fetchMessages$ = new Subject();

// only take for 15 minutes
const _listenForMessages$ = merge(poll$, fetchMessages$).pipe(
    take(5),
    mergeMap((args) => getMessages$)
);

function withMessageData(WrappedComponent, listenForMessages$ = _listenForMessages$) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {};
        }

        loadMessages = () => fetchMessages$.next();

        componentDidMount() {
            this.subscription = listenForMessages$.subscribe((messages) =>
                this.setState({ messages })
            );
            this.loadMessages();
        }

        componentWillUnmount() {
            this.subscription.unsubscribe();
        }

        render() {
            return (
                <WrappedComponent
                    messages={this.state.messages}
                    {...this.props}
                    loadMessages={this.loadMessages.bind(this)}
                />
            );
        }
    };
}

export { withMessageData as default };
