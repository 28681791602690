import React, { Component } from 'react';
import { claims$ } from '../services/subjects';
import { map, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import claimService from '../services/claimService';

export default function withClaimData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            const fetchClaimsFromServer$ = claimService.get$.pipe(
                map((response) => response.response.results),
                tap((claims) => claims$.next(claims))
            );

            claims$
                .pipe(
                    switchMap((claims) => (claims === null ? fetchClaimsFromServer$ : of(claims)))
                )
                .subscribe(
                    (claims) => this.setState({ claims }),
                    (error) =>
                        this.setState({
                            error: { message: 'Error retrieving claim data' },
                            claims: [],
                        })
                );
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    };
}
