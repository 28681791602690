import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';

const providerService = {
    searchProviders$: (parameters) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({ method: 'POST', url: `api/pcp/searchProviders`, headers, body: parameters })
            )
        ),
    getIpas$: jsonHeaders$.pipe(
        switchMap((headers) => ajax({ method: 'GET', url: `api/pcp/getIpas`, headers }))
    ),
};

export default providerService;
