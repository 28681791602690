import React from 'react';
import { Button } from '@mui/material';
import { isEmpty } from 'lodash';

export interface NavigationButtonsProps {
    pages: any[];
    currentPageIndex: number;
    changePage: (index: number) => any;
    onSubmit: (data: object) => any;
    readOnly: boolean;
    validatePage?: (nextAction: (...args: any[]) => any) => any;
    errors: object;
}

const NavigationButtons = ({
    pages,
    currentPageIndex,
    changePage,
    readOnly,
    validatePage,
    onSubmit,
    errors,
}: NavigationButtonsProps) => {
    return (
        <>
            {!isEmpty(pages) && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '1em',
                        padding: '1em',
                    }}
                >
                    {currentPageIndex > 0 && (
                        <Button variant="outlined" onClick={() => changePage(currentPageIndex - 1)}>
                            Back
                        </Button>
                    )}
                    {currentPageIndex < pages.length - 1 && (
                        <Button
                            variant="outlined"
                            color={isEmpty(errors) ? 'primary' : 'error'}
                            onClick={
                                readOnly || !validatePage
                                    ? () => changePage(currentPageIndex + 1)
                                    : () => validatePage(() => changePage(currentPageIndex + 1))
                            }
                        >
                            Next
                        </Button>
                    )}
                    {!readOnly && currentPageIndex >= pages.length - 1 && (
                        <Button
                            variant="outlined"
                            onClick={validatePage ? () => validatePage(onSubmit) : onSubmit}
                        >
                            Submit
                        </Button>
                    )}
                </div>
            )}
        </>
    );
};

export default NavigationButtons;
