const isNode = (node) => {
    return typeof node == 'object' && 'nodeType' in node && node.nodeType === 1;
};

const ariaFixMsalIframe = () => {
    const iFrameList = document.getElementsByTagName('iframe');
    if (iFrameList.length > 0) {
        const iFrame = iFrameList.item(0);
        iFrame.setAttribute('title', 'msal iframe');
        iFrame.setAttribute('aria-hidden', 'true');
    }
};

const formatIdString = (value) => {
    if (typeof value !== 'string') return value;
    // eslint-disable-next-line
    return value.replace(/([\s()#\[\]])/g, '');
};

export { isNode, ariaFixMsalIframe, formatIdString };
