import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { combineLatest } from 'rxjs';
import { memberId$ } from './subjects';
import { PcpChangeForm } from 'interfaces/SelfService';

export const selfServeService = {
    getWorkflowReasonsForServiceFlow$: (workflowTypeString: string) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/selfserve/getWorkflowReasonsForServiceFlow/${workflowTypeString}`,
                    headers,
                })
            )
        ),
    processPcpChange$: (formData: PcpChangeForm, workflowReason: string | null) =>
        combineLatest(jsonHeaders$, memberId$).pipe(
            switchMap(([headers, memberId]) =>
                ajax({
                    method: 'POST',
                    url: `api/selfserve/pcpChange/${workflowReason}`,
                    headers,
                    body: { formData: formData, memberId: memberId },
                })
            )
        ),
};
