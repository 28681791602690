import React, {
    useEffect,
    useState,
    useRef,
    MouseEvent as ReactMouseEvent,
    useLayoutEffect,
    useContext,
} from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowUpDecoratorIcon } from 'icons/v2/arrow-up-decorator.svg';
import classNames from './NavMenu.module.scss';
import { NavItem, navItems } from './navItems';
import { Stack } from '@mui/material';
import { ReactComponent as CancelXIcon } from 'icons/v2/cancel-x.svg';
import { NavMenuContext } from 'contexts/v2/NavMenuContext';
import { ReactComponent as ArrowDownIcon } from 'icons/v2/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'icons/v2/arrow-up.svg';
import { ReactComponent as ArrowRightIcon } from 'icons/v2/arrow-right.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ARROW_UP_ID = 'arrow-bg';

export const NavMenu = () => {
    const [subMenuOpen, setSubMenuOpen] = useState('');
    const { navOpen, setNavOpen } = useContext(NavMenuContext);
    const currentNavItemRef = useRef<HTMLElement | null>(null);
    const theme = useTheme();
    const matchesBreakpointMd = useMediaQuery(theme.breakpoints.down('md'));

    const handleNavItemClick = (event: ReactMouseEvent<HTMLDivElement>, title: string) => {
        currentNavItemRef.current = event.currentTarget.parentElement;
        if (title === subMenuOpen) {
            setSubMenuOpen('');
        } else {
            setSubMenuOpen(title);
        }
    };

    const renderNavMainMenu = (navItemTitle: string, navItem: NavItem) => {
        const Icon = navItem.icon;
        const childrenAndOpen = navItem.children && subMenuOpen === navItemTitle;
        return (
            <Stack
                direction="row"
                className={classNames['nav-item']}
                onClick={(e) => handleNavItemClick(e, navItemTitle)}
            >
                <Stack direction="row" alignItems="center">
                    <span
                        className={classNames['bg-icon']}
                        style={{ backgroundColor: navItem.bgIconColor }}
                    >
                        <Icon className={classNames.icon} />
                    </span>{' '}
                    {matchesBreakpointMd && (!navItem.children?.length || childrenAndOpen)  ? (
                        <Link
                            to={navItem.to}
                            className={classNames.link}
                            onClick={handleMenuItemClick}
                        >
                            {navItemTitle}
                        </Link>
                    ) : (
                        <span className={classNames.link}>{navItemTitle}</span>
                    )}
                </Stack>
                {childrenAndOpen ? (
                    <ArrowUpIcon className={classNames.arrow} />
                ) : (
                    <ArrowDownIcon className={classNames.arrow} />
                )}
            </Stack>
        );
    };

    const handleMenuItemClick = (e: ReactMouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        setSubMenuOpen('');
        setNavOpen(false);
    };

    const handleClickAway = (event: MouseEvent) => {
        if (
            currentNavItemRef?.current &&
            !currentNavItemRef?.current.contains(event.target as Node)
        ) {
            setSubMenuOpen('');
        }
    };

    useLayoutEffect(() => {
        if (!currentNavItemRef.current || !subMenuOpen || matchesBreakpointMd) {
            return;
        }

        const menuElementRect = currentNavItemRef.current?.getBoundingClientRect();
        const menuElementStartsAtX = menuElementRect.left;
        const menuElementEndsAtX = menuElementRect.right;

        const subMenuElement = currentNavItemRef.current?.querySelector(
            `.${classNames['sub-menu']}`
        ) as HTMLElement;
        const arrowUpPathElement = currentNavItemRef.current?.querySelector(
            `#${ARROW_UP_ID}`
        ) as SVGPathElement;
        const subMenuElementWidth = subMenuElement.offsetWidth;

        if (menuElementStartsAtX + subMenuElementWidth <= window.innerWidth) {
            // Position the sub-menu to the right of the parent element
            subMenuElement.style.left = '0';
            subMenuElement.style.right = 'auto';
            subMenuElement.style.transform = 'translateX(0%)';
            arrowUpPathElement.style.fill = '#edf3ff';
        } else if (menuElementEndsAtX - subMenuElementWidth >= 0) {
            subMenuElement.style.left = 'auto';
            subMenuElement.style.right = '0';
            subMenuElement.style.transform = 'translateX(0%)';
            arrowUpPathElement.style.fill = 'white';
        } else {
            subMenuElement.style.left = '50%';
            subMenuElement.style.right = 'auto';
            subMenuElement.style.transform = 'translateX(-50%)';
        }
    }, [subMenuOpen]);

    useEffect(() => {
        if (!subMenuOpen) {
            return;
        }
        document.addEventListener('click', handleClickAway);
        return () => {
            document.removeEventListener('click', handleClickAway);
        };
    }, [subMenuOpen]);
    return (
        <nav
            aria-label="Main navigation menu"
            role="navigation"
            className={`${classNames.Nav} ${navOpen && matchesBreakpointMd ? classNames.open : ''}`}
        >
            <article
                className={classNames['close-menu-icon-container']}
                onClick={() => {
                    setNavOpen(false);
                }}
            >
                <CancelXIcon className={`${classNames['close-menu-icon']}`} />
            </article>
            {Object.entries(navItems).map(([navItemTitle, navItem]) => {
                return (
                    <article key={navItemTitle} className={classNames['menu']}>
                        {!!navItem && !navItem.children?.length ? (
                            <Link to={navItem.to} onClick={handleMenuItemClick}>
                                {renderNavMainMenu(navItemTitle, navItem)}
                            </Link>
                        ) : (
                            <article>
                                {renderNavMainMenu(navItemTitle, navItem)}
                                {subMenuOpen === navItemTitle && (
                                    <article>
                                        <ArrowUpDecoratorIcon
                                            className={classNames['arrow-up-decorator']}
                                        />
                                        <article
                                            className={classNames['sub-menu']}
                                            role="navigation"
                                        >
                                            <article className={classNames['sub-menu-left']}>
                                                <h3 className={`mb-3 ${classNames.title}`}>
                                                    <Link
                                                        to={navItem.to}
                                                        className={classNames['title-link']}
                                                        onClick={handleMenuItemClick}
                                                    >
                                                        {navItem.subMenuTitle}
                                                        <ArrowRightIcon
                                                            className={classNames['arrow-right']}
                                                        />
                                                    </Link>
                                                </h3>
                                                <p>{navItem.description}</p>
                                            </article>
                                            <article className={classNames['sub-menu-right']}>
                                                {navItem.children?.map((subItem) => (
                                                    <article
                                                        className={classNames['sub-menu-item']}
                                                        key={subItem.title}
                                                    >
                                                        <Link
                                                            to={subItem.to}
                                                            onClick={handleMenuItemClick}
                                                            className={'body-link'}
                                                        >
                                                            {subItem.title}
                                                        </Link>
                                                    </article>
                                                ))}
                                            </article>
                                        </article>
                                    </article>
                                )}
                            </article>
                        )}
                        <article className={classNames.hr} />
                    </article>
                );
            })}
        </nav>
    );
};
