import React, { Component } from 'react';
import { idCardImg$ } from '../services/subjects';
import { map, tap, switchMap, filter } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import memberCardService from '../services/memberCardService';
import documentService from '../services/documentService';
import { enrolledOnly } from './enrolledOnly';

export default function withMemberCardData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            const fetchIdCardImgFromServer$ = memberCardService.get$.pipe(
                filter((response) => response.response),
                map((response) => response.response.results),
                switchMap((idCards) =>
                    combineLatest(
                        of([idCards[0].front_doc_id, idCards[0].back_doc_id]),
                        documentService.getImageById$(idCards[0].front_doc_id),
                        documentService.getImageById$(idCards[0].back_doc_id)
                    )
                ),
                map(([docIds, front, back]) => ({ docIds, front, back })),
                tap((idCardImg) => idCardImg$.next(idCardImg))
            );

            enrolledOnly(idCardImg$)
                .pipe(
                    switchMap((idCardImg) =>
                        idCardImg === null ? fetchIdCardImgFromServer$ : of(idCardImg)
                    ),
                    filter((idCardImg) => idCardImg)
                )
                .subscribe(
                    (idCardImg) =>
                        this.setState({
                            docIds: idCardImg.docIds,
                            frontIdImgSrc: idCardImg.front,
                            backIdImgSrc: idCardImg.back,
                        }),
                    (error) =>
                        this.setState({ error: { message: 'Error retrieving member card data' } })
                );
        }

        render() {
            return (
                <WrappedComponent
                    docIds={this.state.docIds}
                    frontIdImgSrc={this.state.frontIdImgSrc}
                    backIdImgSrc={this.state.backIdImgSrc}
                    error={this.state.error}
                    {...this.props}
                />
            );
        }
    };
}
