import React from 'react';
import { map, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import BaseComponent from 'components/BaseComponent';
import memberService from 'services/memberService';
import { memberPbpAssignmentHistory$ } from 'services/subjects';

export default function withMemberPbpAssignmentHistory(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            const fetchMemberPbpAssignmentHistory$ = memberService.getPbpAssignmentHistory$.pipe(
                map((response) => response.response),
                tap((pbpAssignmentHistory) =>
                    memberPbpAssignmentHistory$.next(pbpAssignmentHistory)
                )
            );

            memberPbpAssignmentHistory$
                .pipe(
                    switchMap((pbpAssignmentHistory) =>
                        pbpAssignmentHistory === null
                            ? fetchMemberPbpAssignmentHistory$
                            : of(pbpAssignmentHistory)
                    )
                )
                .subscribe(
                    (pbpAssignmentHistory) => this.setState({ pbpAssignmentHistory }),
                    (error) =>
                        this.setState({
                            error: { message: 'Error retrieving member PBP assignment history' },
                            pbpAssignmentHistory: [],
                        })
                );
        }

        componentWillUnmount() {
            super.componentWillUnmount();
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    pbpAssignmentHistory={this.state.pbpAssignmentHistory}
                />
            );
        }
    };
}
