import React, { useEffect } from 'react';
import withClaimData from '../../hoc/withClaimData';
import withBenefitPeriodData from '../../hoc/withBenefitData';
import UsageBar from '../molecules/UsageBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { yyyyddmmTommddyyyy } from '../../common/utility';
import PageHeader from '../molecules/PageHeader';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';

const OutOfPocket = (props) => {
    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewOutOfPocket })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    return (
        <div>
            <PageHeader>Maximum Out-of-Pocket Expenses</PageHeader>
            {props.benefits && props.benefits.length ? (
                <div>
                    <p className="rem-1-125">
                        Benefit Period: {yyyyddmmTommddyyyy(props.benefits[0].effective_date)} -{' '}
                        {yyyyddmmTommddyyyy(props.benefits[0].benefit_period)}
                    </p>
                    <p>
                        Medicare Advantage plans have a maximum out-of-pocket (MOOP) limit on what
                        you pay for covered health care in a calendar year, aside from any monthly
                        premium.{' '}
                    </p>
                </div>
            ) : null}
            {props.benefits ? (
                props.benefits.map((benefit, i) => (
                    <div className="section-margin" key={i}>
                        <h2 className="rem-1-575">{benefit.title}</h2>
                        <p>{benefit.description}</p>
                        <UsageBar
                            total={benefit.total}
                            used={
                                benefit.accumulators.filter(
                                    (accum) => accum.type === 'amount_used'
                                )[0].value
                            }
                        />
                    </div>
                ))
            ) : (
                <div className="text-center">
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        aria-label="Currently Loading Content"
                        size="3x"
                    />
                </div>
            )}
        </div>
    );
};

export default withBenefitPeriodData(withClaimData(OutOfPocket));
