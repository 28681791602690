import { createTheme } from '@mui/material/styles';

export const messageTheme = createTheme({
    palette: {
        primary: {
            main: '#0376a8',
        },
        gray: {
            main: 'gray',
            dark: 'dark gray',
            contrastText: 'white',
        },
    },
});
