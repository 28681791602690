import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect, useState, ReactNode, MouseEvent } from 'react';
import { Button, Table, Modal, Form, Dropdown } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import { DateInput } from './DateInput';
import { TableInputActionsType } from '../Enums';

interface ToggleProps {
    children: ReactNode;
    onClick: (event: MouseEvent) => void;
}
export interface TableInputFormProps {
    name: string;
    title: string;
    headers: string[] | undefined;
    initialInputs: object;
    state: object | undefined;
    setValue: (path: string, value: any) => void;
    register: (path: string) => void;
}
interface Records {
    data: object[];
}

const CustomToggle = React.forwardRef<HTMLSpanElement, ToggleProps>(
    ({ children, onClick }, ref) => (
        <span ref={ref}>
            {children}
            <FontAwesomeIcon
                data-testid="moreOptions"
                icon={faEllipsisV}
                aria-label="More Options"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            />
        </span>
    )
);
const TableInputForm = ({
    name,
    headers = [],
    initialInputs,
    title,
    state,
    setValue,
    register,
}: TableInputFormProps) => {
    const [records, setRecords] = useState<Records>({ data: [] });
    const [stagingInputs, setStagingInputs] = useState<object>({ ...initialInputs });
    const [modalVisible, setModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState({ status: false, index: 0 });
    useEffect(() => {
        register(name);
        const stateData = get(state, name, []);
        if (!isEmpty(stateData)) {
            setRecords({ data: [...stateData] });
        }
        setValue(name, [...stateData]);
    }, []);
    const saveAdditions = () => {
        let newRecords = [...records.data, stagingInputs];
        if (isEditing.status) {
            newRecords = [
                ...records.data.slice(0, isEditing.index),
                stagingInputs,
                ...records.data.slice(isEditing.index + 1),
            ];
            setIsEditing({ status: false, index: 0 });
        }
        setValue(name, newRecords);
        setRecords({
            data: newRecords,
        });
        setStagingInputs({ ...initialInputs });
    };
    const updateRecords = (action: TableInputActionsType, position: number) => {
        switch (action) {
            case TableInputActionsType.DELETE: {
                const newData = [
                    ...records.data.slice(0, position),
                    ...records.data.slice(position + 1),
                ];
                setValue(name, newData);
                setRecords({
                    data: newData,
                });
                return;
            }
            case TableInputActionsType.EDIT:
                setIsEditing({ status: true, index: position });
                setModalVisible(true);
                setStagingInputs(records.data[position]);
                return;
            default:
                return;
        }
    };
    const TableHeaders = () => (
        <thead className="">
            <tr>
                {headers.map((label, idx) => (
                    <th key={`header_${idx}`} className="align-middle text-wrap">
                        {label}
                    </th>
                ))}
                <th className="align-middle text-wrap text-right">
                    <Button
                        onClick={() => {
                            setModalVisible(true);
                        }}
                    >
                        + Add
                    </Button>
                </th>
            </tr>
        </thead>
    );
    const TableContent = useCallback(() => {
        return (
            <tbody className="">
                {records.data.map((record, idx) => (
                    <tr key={`row_${idx}`}>
                        {Object.keys(initialInputs).map((key, idx) => (
                            <td key={`data_${idx}`}>{get(record, key)}</td>
                        ))}

                        <td className="text-right pr-5 text-secondary">
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu title="">
                                    <Dropdown.Item
                                        onClick={() =>
                                            updateRecords(TableInputActionsType.EDIT, idx)
                                        }
                                    >
                                        Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="text-danger"
                                        onClick={() =>
                                            updateRecords(TableInputActionsType.DELETE, idx)
                                        }
                                    >
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    }, [records]);
    return (
        <>
            <Table striped>
                <TableHeaders />
                <TableContent />
            </Table>
            <Modal
                show={modalVisible}
                backdrop="static"
                onHide={() => {
                    setModalVisible(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {Object.keys(initialInputs).map((key, idx) => {
                            const correspondingLabel = headers[idx];
                            if (correspondingLabel.includes('Date')) {
                                return (
                                    <DateInput
                                        name={key}
                                        key={`input_${idx}`}
                                        question={correspondingLabel}
                                        onChange={(val) => {
                                            setStagingInputs((state) => ({
                                                ...state,
                                                [key]: val,
                                            }));
                                        }}
                                        value={get(stagingInputs, key, '')}
                                    />
                                );
                            }
                            return (
                                <Form.Group key={`form_input_${idx}`}>
                                    <Form.Label htmlFor={`form_input_${idx}`}>
                                        {correspondingLabel}
                                    </Form.Label>
                                    <Form.Control
                                        id={`form_input_${idx}`}
                                        as="input"
                                        type="text"
                                        value={get(stagingInputs, key, '')}
                                        onChange={({ target }) =>
                                            setStagingInputs((state) => ({
                                                ...state,
                                                [key]: target.value,
                                            }))
                                        }
                                    />
                                </Form.Group>
                            );
                        })}

                        <hr />
                        <div className="text-right">
                            <Button
                                variant="secondary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsEditing({ status: false, index: 0 });
                                    setModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveAdditions();
                                    setModalVisible(false);
                                }}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export { TableInputForm, CustomToggle };
