import formResourcesImg from 'assets/Forms and Resources.png';
import { Banner } from 'components/v2/organisms/Banner/Banner';
import React, { useEffect, useContext, useCallback, useState, ChangeEvent, memo } from 'react';
import withDocumentData, { WithDocumentDataState } from 'hoc/withDocumentData';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { PDFViewerContext } from 'contexts/PdfViewerContext';
import withEducationalResourcesData, {
    WithEducationalResourcesDataState,
} from 'hoc/withEducationalResourcesData';
import { Searchbar } from 'components/v2/atoms/Searchbar/Searchbar';
import { DocumentHeader } from 'types/documents/DocumentHeader';
import { Container } from 'components/v2/atoms/Container/Container';
import { Stack } from '@mui/material';
import { Tile } from 'components/v2/atoms/Tile/Tile';
import { ReactComponent as PdfIcon } from 'icons/v2/pdf.svg';
import { Loader } from 'components/v2/atoms/Loader/Loader';
import classNames from './FormAndResources.module.scss';
import { withAppInsightsErrorBoundary } from 'hoc/withAppInsightsErrorBoundary';
import { useAdditionalResourcesData } from 'hooks/useAdditionalResources';
import { sendCustomEvent } from 'common/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const SERVICE_URL = 'api/members/documents';
const EOB = 'eob';

export type FormAndResourcesProps = WithDocumentDataState & WithEducationalResourcesDataState;

const FormAndResources = ({
    educationalResources,
    documents,
    loadingDocuments,
    loadingEducationalResources,
}: FormAndResourcesProps) => {
    const [searchKeyword, setSearchKeyword] = useState('');
    const { addAndOpenDocument } = useContext(PDFViewerContext);
    const { additionalResources = [], loadingAdditionalResources } = useAdditionalResourcesData();
    const [planDocumentsSearchMatches, setPlanDocumentsSearchMatches] = useState<DocumentHeader[]>(
        documents || []
    );
    const [educationalResourcesSearchMatches, setEducationalResourcesSearchMatches] = useState<
        DocumentHeader[]
    >(educationalResources || []);
    const [additionalResourcesSearchMatches, setAdditionalResourcesMatches] = useState<
        typeof additionalResources
    >(educationalResources || []);

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewPlanDocuments })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    const matchingSearchfilterExpression = (documentHeader: DocumentHeader | { filename: string}) =>
        documentHeader.filename?.toLowerCase().includes(searchKeyword.toLowerCase().trim());

    const handleDocumentClick = useCallback(
        (document: DocumentHeader) => {
            const field = document.download_custom_event_name;
            const customEventType = AUDITEVENTTYPE[field as keyof typeof AUDITEVENTTYPE];
            customEventType && sendCustomEvent({ eventType: customEventType });
            addAndOpenDocument({
                serviceUrl: SERVICE_URL,
                documentPath: document.id,
                displayName: document.filename || '',
            });
        },
        [addAndOpenDocument]
    );

    const handleChangeSearchText = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(target.value);
    }, []);

    const filterSearchResults = () => {
        setPlanDocumentsSearchMatches(
            searchKeyword ? documents?.filter(matchingSearchfilterExpression) : documents
        );
        setEducationalResourcesSearchMatches(
            searchKeyword
                ? educationalResources?.filter(matchingSearchfilterExpression)
                : educationalResources
        );
        setAdditionalResourcesMatches(
            searchKeyword
                ? additionalResources?.filter(matchingSearchfilterExpression) || []
                : additionalResources || []
        );
    };

    const handleClickExternalLink = (resource: { customEvent?: keyof typeof AUDITEVENTTYPE }) => {
        const cutomEvent = resource.customEvent && AUDITEVENTTYPE[resource.customEvent];
        if (cutomEvent) {
            sendCustomEvent({ eventType: cutomEvent });
        }
    };

    const handleSearch = filterSearchResults;

    useEffect(() => {
        filterSearchResults();
    }, [educationalResources, documents, additionalResources]);
    return (
        <main aria-label="Form And Resources Page">
            <Banner
                width={'45%'}
                image={formResourcesImg}
                title="FORMS & RESOURCES"
                description="Welcome to your plan documents overview, a central hub where the wealth of information about your healthcare plan comes together. Our goal is to provide you with easy access to essential documents, resources, and details that empower you to make informed decisions about your health."
                color="#68C8F7"
            />
            <br />
            <br />
            <Container className={classNames.FormAndResources}>
                <Stack spacing={4}>
                    <Searchbar
                        placeholder="Enter Keyword"
                        name="search"
                        onChange={handleChangeSearchText}
                        value={searchKeyword}
                        onSearch={handleSearch}
                    />
                    <Tile title="PLAN AND BENEFIT RESOURCES" arial-label="Plan resources tile">
                        {loadingDocuments ? (
                            <Stack justifyContent="center" alignItems="center" padding={4}>
                                <Loader />
                            </Stack>
                        ) : (
                            <ul className={classNames.ul}>
                                {planDocumentsSearchMatches?.length ? (
                                    planDocumentsSearchMatches
                                        .filter(
                                            (planDocument) =>
                                                planDocument.data.document_type !== EOB
                                        )
                                        .map((planDocument) => (
                                            <li
                                                className={`${classNames['li-doc']} body-link`}
                                                key={planDocument.id}
                                                onClick={handleDocumentClick.bind({}, planDocument)}
                                            >
                                                <PdfIcon className={classNames['pdf-icon']} />
                                                {planDocument.filename}
                                            </li>
                                        ))
                                ) : (
                                    <li className={classNames['li']}>No Results</li>
                                )}
                            </ul>
                        )}
                    </Tile>
                    <Tile title="EDUCATIONAL RESOURCES" arial-label="Educational resources tile">
                        {loadingEducationalResources ? (
                            <Stack justifyContent="center" alignItems="center" padding={4}>
                                <Loader />
                            </Stack>
                        ) : (
                            <ul className={classNames.ul}>
                                {educationalResourcesSearchMatches?.length ? (
                                    educationalResourcesSearchMatches.map((educationalResource) => (
                                        <li
                                            className={`${classNames['li-doc']} body-link`}
                                            key={educationalResource.id}
                                            onClick={handleDocumentClick.bind(
                                                {},
                                                educationalResource
                                            )}
                                        >
                                            <PdfIcon className={classNames['pdf-icon']} />
                                            {educationalResource.filename}
                                        </li>
                                    ))
                                ) : (
                                    <li className={classNames['li']}>No Results</li>
                                )}
                            </ul>
                        )}
                    </Tile>
                    <Tile title="ADDITIONAL RESOURCES" arial-label="Additional resources tile">
                        {loadingAdditionalResources ? (
                            <Stack justifyContent="center" alignItems="center" padding={4}>
                                <Loader />
                            </Stack>
                        ) : (
                            <ul className={classNames.ul}>
                                <li className={`${classNames['li-doc']} body-link`}></li>
                                {additionalResourcesSearchMatches?.length ? (
                                    additionalResourcesSearchMatches.map((additionalResource) =>
                                        'href' in additionalResource ? (
                                            <a
                                                key={additionalResource.filename}
                                                href={additionalResource.href}
                                                target={additionalResource.target}
                                                onClick={handleClickExternalLink.bind(
                                                    {},
                                                    additionalResource
                                                )}
                                            >
                                                <FontAwesomeIcon
                                                    className={classNames.icon}
                                                    icon={faExternalLinkAlt}
                                                    aria-label="External Link"
                                                />
                                                {additionalResource.filename}
                                            </a>
                                        ) : (
                                            <li
                                                className={`${classNames['li-doc']} body-link`}
                                                key={additionalResource.id}
                                                onClick={handleDocumentClick.bind(
                                                    {},
                                                    additionalResource
                                                )}
                                            >
                                                <PdfIcon className={classNames['pdf-icon']} />
                                                {additionalResource.filename}
                                            </li>
                                        )
                                    )
                                ) : (
                                    <li className={classNames['li']}>No Results</li>
                                )}
                            </ul>
                        )}
                    </Tile>
                </Stack>
            </Container>
        </main>
    );
};

const FormAndResourcesWithData = withEducationalResourcesData(withDocumentData(FormAndResources));
const FormAndResourcesWithErrorBoundary = withAppInsightsErrorBoundary(
    FormAndResourcesWithData,
    () => (
        <Container>
            <header>
                <h1 className="page-title">FORM & RESOURCES</h1>
                <p>An error has ocurred</p>
            </header>
        </Container>
    )
);

const MemoPlanFormAndResourcesWithErrorBoundary = memo(FormAndResourcesWithErrorBoundary);
export { MemoPlanFormAndResourcesWithErrorBoundary as FormAndResources };
