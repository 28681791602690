import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';

const availabilityService = {
    get$: (pcpId, reason, body) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    url: `api/pcp/${pcpId}/availability/${reason}`,
                    method: 'POST',
                    headers,
                    body: body,
                })
            )
        ),
};

export default availabilityService;
