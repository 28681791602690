import React, { useEffect, useState, useCallback } from 'react';
import { Route, Switch } from 'react-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    far,
    faFolderOpen,
    faFilePdf,
    faEnvelope,
    faCreditCard,
    faTrashAlt,
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
    faSearch,
    faWallet,
    faStethoscope,
    faPrescriptionBottleAlt,
    faConciergeBell,
    faUserCircle,
    faChevronDown,
    faPlusCircle,
    faLongArrowAltLeft,
    faReply,
    faDownload,
    faCircleNotch,
    faArrowsAltV,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faSort,
    faSortUp,
    faSortDown,
} from '@fortawesome/free-solid-svg-icons';

import './custom.scss';

import Layout from './components/templates/Layout';
import HomeV1 from './components/pages/Home';
import { Home as HomeV2 } from './components/v2/pages/Home/Home';
import { CssTypography as CssTypographyV2 } from './components/v2/styles/CssTypography';
import BenefitsV1 from './components/pages/Benefits';
import LegacyMessageCenter from './components/pages/LegacyMessageCenter';
import MessageCenter from './components/pages/MessageCenter';
import IdCardV1 from './components/pages/IdCard';
import { IdCard as IdCardV2 } from 'components/v2/pages/IdCard/IdCard';
import PlanDocumentsV1 from './components/pages/PlanDocuments';
import OutOfPocket from './components/pages/OutOfPocket';
import Claims from './components/pages/Claims';
import HealthSummaryV1 from './components/pages/HealthSummary';
import Medications from './components/pages/Medications';
import Labs from './components/pages/Labs';
import AccountInformation from './components/pages/AccountInformation';
import Preferences from './components/pages/Preferences';
import ExplanationOfBenefits from './components/pages/ExplanationOfBenefits.tsx';
import ContactUs from './components/pages/ContactUs';
import FAQs from './components/pages/FAQs';
import Footer from './components/molecules/Footer';
import Notifications from './components/molecules/Notifications';
import ScrollToTop from './components/molecules/ScrollToTop';
import VirtualCareScreenV1 from './components/pages/VirtualCare';
import ScheduleAppointmentForm from './components/pages/ScheduleAppointmentForm';
import UrgentCareAppointmentForm from './components/pages/UrgentCareAppointmentForm';
import RequestOptionForm from './components/pages/RequestOptionForm';
import HealthRiskAssessmentForm from 'components/pages/HealthRiskAssessmentForm';
import NotFound from 'components/pages/NotFound';
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import loadOverlayService from './services/loadOverlayService';
import SSOHandler from './components/SSOHandler';
import Prompt from './components/molecules/Prompt';
import SessionExpirationModal from './components/molecules/SessionExpirationModal';
import PcpChange from 'components/pages/PcpChange/PcpChange';
import styles from './App.module.scss';

import { useMsal, useAccount } from '@azure/msal-react';
import { accessToken$, identity$, refreshToken$, tokenExpiration$ } from './services/subjects';
import { useConfig } from './hooks';
import AccessNewPatientForm from 'components/pages/AccessNewPatientForm';
import { featureFlagsNames } from 'common/constants';
import useFeatureFlags from 'hooks/useFeatureFlags';
import WithFeatureFlags from 'contexts/WithFeatureFlags';
import Loading from 'components/atoms/Loading';
import Prescriptions from 'components/v2/pages/Prescriptions';
import { withUiVersion } from 'hoc/withUiVersion';
import { Benefits as BenefitsV2 } from 'components/v2/pages/Benefits';
import { GetCare as VirtualCareScreenV2 } from 'components/v2/pages/GetCare';
import { HealthSummary as HealthSummaryV2 } from 'components/v2/pages/HealthSummary';
import { FormAndResources as FormAndResourcesV2 } from 'components/v2/pages/FormAndResources/FormAndResources';
import { Payments } from 'components/v2/pages/Payments';
import { HealthyRewards as HealthyRewardsV2 } from 'components/v2/pages/HealthyRewards/HealthyRewards';
import { Notifications as NotificationsPage } from 'components/v2/pages/Notifications/Notifications';

const Home = withUiVersion(HomeV1, HomeV2);
const CssTypography = withUiVersion(null, CssTypographyV2);
const HealthyRewards = withUiVersion(NotFound, HealthyRewardsV2);
const Benefits = withUiVersion(BenefitsV1, BenefitsV2);
const VirtualCareScreen = withUiVersion(VirtualCareScreenV1, VirtualCareScreenV2);
const HealthSummary = withUiVersion(HealthSummaryV1, HealthSummaryV2);
const FormAndResources = withUiVersion(PlanDocumentsV1, FormAndResourcesV2);
const IdCard = withUiVersion(IdCardV1, IdCardV2);

library.add(
    far,
    faFolderOpen,
    faFilePdf,
    faSearch,
    faEnvelope,
    faWallet,
    faStethoscope,
    faCreditCard,
    faPrescriptionBottleAlt,
    faConciergeBell,
    faUserCircle,
    faChevronDown,
    faPlusCircle,
    faLongArrowAltLeft,
    faArrowsAltV,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faReply,
    faTrashAlt,
    faDownload,
    faCircleNotch,
    faCheckCircle,
    faTimesCircle,
    faSort,
    faSortUp,
    faSortDown
);

const App = () => {
    const [loadMessage, setLoadMessage] = useState('');
    const [showLoadOverlay, setShowLoadOverlay] = useState(false);
    const { instance: msalInstance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const config = useConfig();
    const scopes = config?.scopes;
    const { featureFlags, isFeatureFlagLoading } = useFeatureFlags();
    LoadingOverlay.propTypes = undefined;

    const refreshToken = () => {
        if (!account) {
            return;
        }

        msalInstance
            .acquireTokenSilent({ account, scopes })
            .then((response) => {
                accessToken$.next(response.accessToken);
                const secondsToExpire = response.idTokenClaims.exp - Date.now() / 1000;
                tokenExpiration$.next(parseInt(secondsToExpire));
            })
            .catch((ex) => {
                // Unable to acquire the token silently. Manual intervention is required
                msalInstance.loginRedirect({ redirectUri: window.location.origin });
            });
    };

    useEffect(() => {
        var subscriptions = [];

        subscriptions.push(
            loadOverlayService.show$.subscribe((message) => {
                setLoadMessage(message);
                setShowLoadOverlay(true);
            })
        );

        subscriptions.push(
            loadOverlayService.hide$.subscribe(() => {
                setLoadMessage('');
                setShowLoadOverlay(false);
            })
        );

        return () => {
            subscriptions.map((subscription) => subscription.unsubscribe());
        };
    }, []);

    const publishAuthData = useCallback(() => {
        if (!account) {
            return;
        }

        var subscriptions = [];

        refreshToken();

        subscriptions.push(
            refreshToken$.subscribe({
                next: refreshToken,
            })
        );

        var userIdentity = {
            memberId: account.idTokenClaims.extension_MEMID,
            name: account.idTokenClaims.name,
            firstName: account.idTokenClaims.given_name,
            lastName:
                account.idTokenClaims.family_name ||
                (account.idTokenClaims.name.indexOf('_') > -1 &&
                    account.idTokenClaims.name.split('_')[1]),
            emails: account.idTokenClaims.emails,
        };

        identity$.next(userIdentity);

        return () => {
            subscriptions.map((subscription) => subscription.unsubscribe());
        };
    }, [account, scopes, msalInstance]);

    useEffect(() => {
        if (config) {
            publishAuthData();
        }
    }, [config, publishAuthData]);

    if (document.location.pathname.startsWith('/sso')) {
        return <Route exact path="/sso" component={SSOHandler} />;
    }

    return (
        <ScrollToTop>
            <LoadingOverlay
                active={showLoadOverlay}
                spinner
                className={styles.overlay}
                text={loadMessage}
            >
                <main className={styles['loading-overlay']}>
                    <SessionExpirationModal />
                    <Notifications />
                    <Prompt />
                    <WithFeatureFlags>
                        <CssTypography />
                        <Layout>
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/virtual-care" component={VirtualCareScreen} />
                                <Route
                                    exact
                                    path="/patient-form"
                                    component={AccessNewPatientForm}
                                />
                                <Route
                                    exact
                                    path="/appointment-form"
                                    component={ScheduleAppointmentForm}
                                />
                                <Route
                                    exact
                                    path="/urgent-care-appointment-form"
                                    render={() => <UrgentCareAppointmentForm />}
                                />
                                <Route
                                    exact
                                    path="/appointment-request"
                                    component={RequestOptionForm}
                                />
                                <Route
                                    exact
                                    path="/account-information"
                                    component={AccountInformation}
                                />
                                <Route exact path="/benefits" component={Benefits} />
                                <Route exact path="/claims" component={Claims} />
                                <Route
                                    exact
                                    path="/communication-preferences"
                                    component={Preferences}
                                />
                                <Route exact path="/health-summary" component={HealthSummary} />
                                <Route exact path="/medications" component={Medications} />
                                <Route exact path="/labs" component={Labs} />
                                <Route exact path="/id-card" component={IdCard} />
                                <Route
                                    exact
                                    path="/message-center"
                                    component={() => {
                                        if (isFeatureFlagLoading) {
                                            return <Loading />;
                                        } else {
                                            return featureFlags[
                                                featureFlagsNames.crmInteractionMessageCenter
                                            ] ? (
                                                <MessageCenter />
                                            ) : (
                                                <LegacyMessageCenter />
                                            );
                                        }
                                    }}
                                />
                                <Route exact path="/out-of-pocket" component={OutOfPocket} />
                                <Route exact path="/plan-documents" component={FormAndResources} />
                                <Route
                                    exact
                                    path="/explanation-of-benefits"
                                    component={ExplanationOfBenefits}
                                />
                                <Route exact path="/contact-us" component={ContactUs} />
                                <Route exact path="/faq" component={FAQs} />
                                <Route
                                    exact
                                    path="/health-risk-assessment"
                                    component={() =>
                                        isFeatureFlagLoading ? (
                                            <Loading />
                                        ) : featureFlags[
                                              featureFlagsNames.HEALTH_RISK_ASSESSMENT
                                          ] ? (
                                            <HealthRiskAssessmentForm />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                {featureFlags &&
                                    !featureFlags[featureFlagsNames.disablePcpChange] && (
                                        <Route exact path="/pcp-change" component={PcpChange} />
                                    )}
                                {featureFlags &&
                                    featureFlags[featureFlagsNames.avaNextUiVersion] && [
                                        <Route
                                            key="prescriptions"
                                            exact
                                            path="/prescriptions"
                                            component={Prescriptions}
                                        />,
                                        <Route
                                            exact
                                            path="/payments"
                                            key="payments"
                                            component={Payments}
                                        />,
                                        <Route
                                            exact
                                            path="/notifications"
                                            key="notifications"
                                            component={NotificationsPage}
                                        />,
                                    ]}
                                <Route exact path="/healthy-rewards" component={HealthyRewards} />
                                <Route component={NotFound} />
                            </Switch>
                        </Layout>
                    </WithFeatureFlags>
                    <Footer />
                </main>
            </LoadingOverlay>
        </ScrollToTop>
    );
};

export default App;
