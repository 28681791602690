import React from 'react';

const VerbiageListItem = ({ heading, description }) => (
    <li className="py-3">
        <strong>{heading} - </strong>
        <span>{description}</span>
    </li>
);
const WelcomeVerbiage = () => (
    <>
        <h1>Welcome</h1>
        <div className="h5">
            <p>
                Thank you for putting your trust in Access Primary Care Physicians, Inc. for your
                healthcare needs. We would like to take this opportunity to welcome you to our
                practice and look forward to providing you with personalized, comprehensive health
                care.
            </p>
            <p>
                Having the most current information is essential in meeting your healthcare needs.
                We would appreciate your assistance in updating your medical record by completing
                the forms listed below:
            </p>
            <ul>
                <VerbiageListItem
                    heading="Patient Information Form"
                    description={`provides your physician with thorough knowledge of your current 
                            health issues, an accurate medication list, and a family medical history. Also includes your 
                            current contact information (phone number, email, text) so we can reach you regarding your 
                            healthcare.`}
                />
                <VerbiageListItem
                    heading="Responsibility for Payment and Receipt of HIPAA Notice Form"
                    description={`allows us to bill your insurance 
                            company for services provided to you and acknowledges you have received the Notice of Privacy 
                            Practices.`}
                />
                <VerbiageListItem
                    heading="Consent for Treatment Form"
                    description={`gives our medical staff permission to provide basic evaluation 
                            and treatment of your medical conditions.`}
                />
                <VerbiageListItem
                    heading="Designation of Personal Representative Form"
                    description={`grants a family member or friend permission to 
                            discuss medical or billing information on your behalf. Written permission is needed for us to 
                            discuss any aspect of your care with anyone else.`}
                />
                <VerbiageListItem
                    heading="Authorization for Release of Protected Health Information"
                    description={`allows us to obtain your medical 
                            records from other healthcare providers.`}
                />
            </ul>
            <p>
                An important part of each visit with your doctor is reviewing all medications you
                are currently taking from ALL providers - both primary care and specialists. Please
                bring all medications with you to every visit
            </p>
            <p>
                Access Primary Care Physicians, Inc. is a patient centered medical group which is a
                reflection of our commitment to providing the highest quality care for our patients.
                We provide proactive care to promote wellness and prevent illness and will be
                communicating with you by telephone, or text message (with your permission). Please
                be sure to provide current contact information on the Patient Information Form.
            </p>
            <p>
                As a patient of Access Primary Care Physicians, Inc., we are committed to helping
                you be well and enjoy life to the fullest. Should you have any questions or
                comments, please do not hesitate to contact us.
            </p>
        </div>
    </>
);
export default WelcomeVerbiage;
