import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InboxMessage from '../molecules/InboxMessage';
import SearchBar from '../molecules/SearchBar';
import { search, getComparerFn, sendCustomEvent } from '../../common/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { isNil } from 'lodash';
import './LegacyInbox.scss';
import { handleEnterKeyToClick } from '../../common/accessibilityHelpers';
import TablePagination from '../molecules/TablePagination';
import { AUDITEVENTTYPE } from 'services/trackService';

const Inbox = (props) => {
    const pageSettings = props.pageSettings || { pageSize: 10, pageCount: 3 };

    const [messages, updateMessages] = useState(null);
    const [selectedMessages, updateSelectedMessages] = useState([]);

    const [searchTerm, updateSearchTerm] = useState(null);

    const [filterCategory, updateFilterCategory] = useState(null);

    const [sortBy, updateSortBy] = useState('updated_at');

    //sort directions 0: none 1: asc -1:desc
    const [sortDirection, updateSortDirection] = useState(-1);

    const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);

    const [pageNumber, updatePageNumber] = useState(1);

    const categoryDropdownToggle = () => setCategoryDropdownOpen(!categoryDropdownOpen);

    useEffect(() => {
        if (!isNil(props.messages)) {
            const tempMessages = props.messages.map((obj) => {
                var msg = Object.assign({}, obj);
                msg.checked = false;
                return msg;
            });
            updateMessages(tempMessages);
        }
    }, [props.messages]);

    let allMessages = search(
        messages
            ? [...messages]
                  .sort(getComparerFn(sortBy, sortDirection))
                  .filter(
                      (message) =>
                          filterCategory === null ||
                          filterCategory === '' ||
                          (typeof filterCategory.toLowerCase === 'function' &&
                              message.category &&
                              typeof message.category.toLowerCase === 'function' &&
                              message.category.toLowerCase() === filterCategory.toLowerCase())
                  )
            : messages,
        searchTerm
    );

    const toggleSort = (field) => {
        if (sortBy === field) {
            updateSortDirection(((sortDirection + 2) % 3) - 1);
        } else {
            updateSortBy(field);
            updateSortDirection(1);
        }
    };

    const setPageNumber = (pageNumber) => {
        updatePageNumber(pageNumber);
    };

    const getDataInPage = (sortedData) => {
        const { pageSize } = pageSettings;
        var startIndex = pageSize * (pageNumber - 1);

        return sortedData && startIndex < sortedData.length
            ? sortedData.slice(startIndex, Math.min(startIndex + pageSize, sortedData.length))
            : [];
    };

    const header = (field, display) => {
        let sortIconProperties = {};
        if (sortBy !== field || sortDirection === 0) {
            sortIconProperties = {
                icon: faSort,
                ariaLabel: `Click to sort ${display}`,
            };
        } else if (sortDirection === 1) {
            sortIconProperties = {
                icon: faSortUp,
                ariaLabel: `Click to sort ${display} in ascending order`,
            };
        } else {
            sortIconProperties = {
                icon: faSortDown,
                ariaLabel: `Click to sort ${display} in descending order`,
            };
        }
        return (
            <th
                className="ptr"
                onClick={() => toggleSort(field)}
                onKeyUp={handleEnterKeyToClick}
                tabIndex="0"
                aria-label={sortIconProperties.ariaLabel}
            >
                {display}{' '}
                <FontAwesomeIcon
                    icon={sortIconProperties.icon}
                    aria-label={sortIconProperties.ariaLabel}
                />
            </th>
        );
    };

    const selectMessage = (id, checked) => {
        const tempMessages = [...messages];
        const index = tempMessages.findIndex((msg) => msg.id === id);
        tempMessages[index].checked = checked;
        updateMessages(tempMessages);
    };

    return (
        <div className="inbox">
            <SearchBar change={updateSearchTerm} placeholder="Search messages" />
            <div className="inbox-action-bar">
                <div className="action-btns">
                    <Button color="primary" onClick={() => props.open({})}>
                        New Message
                    </Button>
                    <div className="inbox-dropdown">
                        <span>
                            <strong>Filter by:</strong>
                        </span>
                        <Dropdown
                            className="d-inline"
                            isOpen={categoryDropdownOpen}
                            toggle={categoryDropdownToggle}
                        >
                            <DropdownToggle className="bg-white" color="black" caret>
                                {filterCategory || 'All Categories'}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={(_) => updateFilterCategory(null)}
                                    onKeyUp={handleEnterKeyToClick}
                                >
                                    All Categories
                                </DropdownItem>
                                {props.memberPortalConfig?.messageCenterCategories?.map(
                                    (category, i) => (
                                        <DropdownItem
                                            key={i}
                                            onClick={(_) => updateFilterCategory(category)}
                                            onKeyUp={handleEnterKeyToClick}
                                        >
                                            {category}
                                        </DropdownItem>
                                    )
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr className="d-none d-md-table-row table-headers">
                        {header('author_name', 'Sender')}
                        {header('category', 'Category')}
                        {header('message', 'Message')}
                        {header('updated_at', 'Date')}
                    </tr>
                </thead>
                <tbody>
                    {!allMessages ? (
                        <tr>
                            <td colSpan={5} className="text-center">
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    size="3x"
                                    aria-label="There are no messages"
                                />
                            </td>
                        </tr>
                    ) : (
                        (search(allMessages, searchTerm) &&
                            search(allMessages, searchTerm).length !== 0 &&
                            getDataInPage(search(allMessages, searchTerm)).map((message) => (
                                <InboxMessage
                                    key={message.id}
                                    {...message}
                                    selected={(messageId) => {
                                        updateSelectedMessages(
                                            selectedMessages.concat([messageId])
                                        );
                                        selectMessage(messageId, true);
                                    }}
                                    deselected={(messageId) => {
                                        updateSelectedMessages(
                                            selectedMessages.filter(
                                                (existingId) => existingId !== messageId
                                            )
                                        );
                                        selectMessage(messageId, false);
                                    }}
                                    clicked={(m) => {
                                        sendCustomEvent({
                                            eventType: AUDITEVENTTYPE.MemberPortalGetSingleMessages,
                                        });
                                        props.open(m);
                                    }}
                                />
                            ))) || (
                            <tr>
                                <td colSpan={5}>No recent messages</td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
            <div
                className={`${
                    search(allMessages, searchTerm) ? 'pagination-container' : 'invisible-box'
                }`}
            >
                <TablePagination
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    tableSize={
                        search(allMessages, searchTerm) ? search(allMessages, searchTerm).length : 0
                    }
                    pageSettings={pageSettings}
                />
            </div>
        </div>
    );
};

Inbox.propTypes = {
    open: PropTypes.func.isRequired,
    messages: PropTypes.array,
};

export default Inbox;
