import React, { useEffect } from 'react';
import { Container, Col, Row } from 'reactstrap';
import PageHeader from '../molecules/PageHeader';
import { LinkedPost } from '../molecules/Post';
import withPolicyData from '../../hoc/withPolicyData';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';

const HealthSummary = (props) => {
    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewHealthSummary })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    const posts = [
        {
            icon: 'doctor',
            header: `Get Virtual Care`,
            subHeader: `Talk to your doctor by phone or video`,
            to: '/virtual-care',
        },
        {
            icon: 'microscope',
            header: 'View Quest Results',
            subHeader: 'Make an appointment or see lab results',
            href: 'https://myquest.questdiagnostics.com/web/home',
            target: '_blank',
        },
        {
            icon: 'test-tube',
            header: `View LabCorp Results`,
            subHeader: `Make an appointment or see lab results`,
            href: 'https://patient.labcorp.com',
            target: '_blank',
        },
    ];

    return (
        <div>
            <Container>
                <PageHeader>
                    <span className="bold-text">My Health Summary</span>
                </PageHeader>
                <p>
                    You can share this overview of your health with your doctor. This information is
                    also handy when you go to a hospital or other treatment center. Just click on
                    the topic title below to see more information or complete your health
                    assessment.
                </p>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}>
                    {posts.map((post, i) => (
                        <Col key={i} md="4">
                            <LinkedPost {...post} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default withPolicyData(HealthSummary);
