import React, { Component } from 'react';
import { benefits$ } from '../services/subjects';
import { map, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import benefitService from '../services/benefitService';
import { enrolledOnly } from './enrolledOnly';

export default function withBenefitPeriodData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            const fetchBenefitsFromServer = benefitService.getByPeriod$('1').pipe(
                map((response) => response.response.results),
                tap((benefits) => benefits$.next(benefits))
            );

            enrolledOnly(benefits$)
                .pipe(
                    switchMap((benefits) =>
                        benefits === null ? fetchBenefitsFromServer : of(benefits)
                    )
                )
                .subscribe((benefits) => this.setState({ benefits }));
        }

        render() {
            return <WrappedComponent benefits={this.state.benefits} {...this.props} />;
        }
    };
}
