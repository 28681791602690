import React from 'react';
import classNames from "./DropdownNotification.module.scss"
import { MemberNotification } from 'types/notifications';
import { ReactComponent as PrescrtionNotification } from 'icons/v2/my-prescriptions.svg';
import { ReactComponent as MyHealthNotification } from 'icons/v2/my-health.svg';
import { ReactComponent as AppointmentNotification } from 'icons/v2/get-care.svg';
import moment from 'moment';

interface Props {
    notification: MemberNotification;
}

const getNotificationIcon = (type: any, size = 20): React.ReactNode => {

    type NotificationIconType = {
        [key: number]: JSX.Element
    }

    const NotificationIcon:NotificationIconType = {
        0: <AppointmentNotification className={classNames['appointment-icon']} style={{width: size, height: size }}/>,
        1: <PrescrtionNotification className={classNames['prescription-icon']} style={{width: size, height: size}}/>,
        2: <MyHealthNotification className={classNames['my-health-icon']} style={{width: size, height: size }}/>,
    };

    return NotificationIcon[type];
};
 
const getNotificationDateFormat = (date:string) => {
return moment(date).format('MMMM D YYYY, h:mm a')
}

export const DropdownNotification = ({ notification }: Props) => {
    return (
        <div className={classNames['notification-container']}>
            <div>{getNotificationIcon(notification.type, 35)} </div>
            <div className={classNames['notification-container-description']}>
                <span className={classNames['notification-title']}> {notification.title} </span>
                <span className={classNames['notification-description']}>
                    {' '}
                    {notification.description}{' '}
                </span>
                <span className={classNames['notification-date']}> {getNotificationDateFormat(notification.date)} </span>
            </div>
        </div>
    );
};
