import React, { useEffect, useState } from 'react';
import { map, first } from 'rxjs/operators';
import claimService from '../services/claimService';

export default function withClaimServiceLinesData(WrappedComponent) {
    return function(props) {

        const [claimServiceLines, setClaimServiceLines] = useState([]);
        const [claimServiceLinesError, setClaimServiceLinesError] = useState("");
        const [loadingClaimServiceLines, setLoadingClaimServiceLines] = useState(false);
        useEffect(() => {
            if(!props.claim_number) return;
            setLoadingClaimServiceLines(true);
            const claimServiceLinesObservable$ = claimService.getClaimServiceLines$(props.claim_number).pipe(
                map((response) => response.response.results),
            );

            claimServiceLinesObservable$
                .pipe(first())
                .subscribe(
                    setClaimServiceLines,
                    setClaimServiceLinesError,
                    () => setLoadingClaimServiceLines(false), 
                );
        }, [props.claim_number])

        return <WrappedComponent {...props} {...{claimServiceLines, claimServiceLinesError, loadingClaimServiceLines}} />;
    };
}
