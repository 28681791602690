import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import TableContent from '../molecules/TableContent';
import TablePagination from '../molecules/TablePagination';
import { getComparerFn } from '../../common/utility';

class ResponsiveTable extends React.Component {
    constructor(props) {
        super(props);

        this.pageSettings = props.pageSettings || { pageSize: 10, pageCount: 3 };
        this.state = {
            pageNumber: 1,
            sortBy: null,
            sortDirection: 0,
        };
    }

    getDataInPage = (pageNumber, data, sortBy, sortDirection) => {
        const { pageSize } = this.pageSettings;
        var startIndex = pageSize * (pageNumber - 1);
        const sortedData = sortBy ? [...data].sort(getComparerFn(sortBy, sortDirection)) : data;

        return sortedData && startIndex < sortedData.length
            ? sortedData.slice(startIndex, Math.min(startIndex + pageSize, sortedData.length))
            : [];
    };

    setPageNumber = (pageNumber) => {
        this.setState({
            pageNumber,
        });
    };

    toggleSort = (fieldName) => {
        if (this.state.sortBy === fieldName) {
            this.setState({
                sortDirection: ((this.state.sortDirection + 2) % 3) - 1,
            });
        } else {
            this.setState({
                sortBy: fieldName,
                sortDirection: 1,
            });
        }
    };

    render() {
        return (
            <Container className="table">
                <TableContent
                    data={this.getDataInPage(
                        this.state.pageNumber,
                        this.props.data,
                        this.state.sortBy,
                        this.state.sortDirection
                    )}
                    fields={this.props.fields}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    toggleSort={this.toggleSort}
                    rowClicked={this.props.rowClicked}
                />
                <TablePagination
                    pageNumber={this.state.pageNumber}
                    setPageNumber={this.setPageNumber}
                    tableSize={this.props.data.length}
                    pageSettings={this.pageSettings}
                />
            </Container>
        );
    }
}

export default ResponsiveTable;

ResponsiveTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string,
            col: PropTypes.shape({
                xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
                sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
                md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
                lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
                xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
            }),
            name: PropTypes.string.isRequired,
            format: PropTypes.oneOf(['header', 'bold', 'currency', 'date', 'list']),
        })
    ),
    rowClicked: PropTypes.func,
};
