import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { handleEnterKeyToClick } from '../../common/accessibilityHelpers';

export const DocumentLink = (props) => {
    return props.document ? (
        <li>
            <button
                className="button-link"
                onClick={props.onDocumentClick && props.onDocumentClick.bind({}, props.document)}
                aria-label="Click to download document"
                onKeyUp={handleEnterKeyToClick}
                tabIndex="0"
            >
                <FontAwesomeIcon
                    icon={faFilePdf}
                    aria-label="Document File"
                />
                {props.document.filename}
            </button>
        </li>
    ) : null;
};
