import { ajax } from 'rxjs/ajax';
import { map, take, switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';

const appointmentRequestService = {
    getAll$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/appointmentrequests`,
                headers,
            }).pipe(map((response) => response.response))
        )
    ),
    createRequest$: (requestData) =>
        jsonHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/appointmentrequests`,
                    headers,
                    body: requestData,
                }).pipe(map((response) => response.response.appointmentId))
            )
        ),
    cancel$: (requestId) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/appointmentrequests/${requestId}/cancel`,
                    headers,
                }).pipe(map((response) => response.response))
            )
        ),
};

export default appointmentRequestService;
