import { healthyRewardService } from 'services/healthyRewardService';
import { useObservableQuery } from './useObservableQuery';

export const useHealthyRewardsData = () => {
    const { data: healthyRewards, loading: loadingHealthyRewards, errorMessage: healthyRewardsErrorMessage, reload: reloadHealthyRewards } = useObservableQuery({
        dependencies: [],
        query: () => healthyRewardService.get$,
        initialValue: [],
        enabled: true,
    });
    
    return {
        healthyRewards,
        loadingHealthyRewards,
        reloadHealthyRewards,
        healthyRewardsErrorMessage,
    };
};
