import { Col, Container, Row } from 'reactstrap';
import React, { useState, useRef, useEffect, MouseEvent as ReactMouseEvent, useContext } from 'react';
import { FaEnvelope, FaCircleUser } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import withUnreadMessageCount from 'hoc/withUnreadMessageCount';
import { useMsal } from '@azure/msal-react';
import MenuIcon from '@mui/icons-material/Menu';
import withIdentityData from 'hoc/withIdentityData';
import classNames from './Headerv2.module.scss';
import withMemberData from 'hoc/withMemberData';
import HeaderNotificationsDropdown from './HeaderNotificationsDropdown';
import { NavMenuContext } from 'contexts/v2/NavMenuContext';
import { AUDITEVENTTYPE } from 'services/trackService';
import { sendCustomEvent } from 'common/utility';

const HEADER_TEXT_ACTIVE_COLOR = '#375CBE';
const HEADER_TEXT_INACTIVE_COLOR = 'white';

const Header = ({ identity, member, unreadMessageCount, ...rest }: any) => {
    const { instance: msalInstance } = useMsal();
    const [isMyInfoOpen, setIsMyInfoOpen] = useState(false);
    const dropdownElementRef = useRef<HTMLDivElement>(null);
    const {setNavOpen} = useContext(NavMenuContext);
    const toCapitalCase = (str: string) =>
        str
            .split(' ')
            .map((word) =>
                word.substring(0, 1).toUpperCase().concat(word.substring(1).toLowerCase())
            )
            .join(' ');
    const getMemberInitials = () =>
        member && isMyInfoOpen
            ? toCapitalCase(`${member?.first_name} ${member?.last_name}`)
            : `${member?.first_name.substring(0, 1).toUpperCase()}.${member?.last_name
                  .substring(0, 1)
                  .toUpperCase()}.`;

    const handleClickOnMyInfo = () =>
        setIsMyInfoOpen((currentIsMyInfoOpen) => !currentIsMyInfoOpen);

    const handleClickAway = (event: MouseEvent) => {
        if (
            dropdownElementRef?.current &&
            !dropdownElementRef?.current.contains(event.target as Node)
        ) {
            setIsMyInfoOpen(false);
        }
    };

    const handleLogOut = (e: ReactMouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        sendCustomEvent({eventType: AUDITEVENTTYPE.MemberPortalLogOut});
        msalInstance.logoutRedirect();
    };

    useEffect(() => {
        if (isMyInfoOpen) {
            document.addEventListener('click', handleClickAway);
        }
        return () => {
            document.removeEventListener('click', handleClickAway);
        };
    }, [isMyInfoOpen]);

    const handleOpen = () => {
        setNavOpen(true);
    };

    return (
        <Container fluid> 
        <Row className={`d-flex ${classNames.header}`}>
            <MenuIcon className={classNames['menu-icon']} onClick={handleOpen} />
            <Col className="d-flex flex-column justify-content-center">
                <Link to="/">
                    <img src="img/logo-horizontal-v2.png" height={40} alt="Logo" />
                </Link>
            </Col>
            <Col className="d-flex flex-column justify-content-center align-items-left">
                <div>
                    <strong className={classNames.bold}>Member Id:&nbsp;</strong>
                    <span>{identity?.memberId}</span>
                </div>
                <div>
                    <strong className={classNames.bold}>Plan:&nbsp;</strong>
                    <span>{member?.pbp_name}</span>
                </div>
            </Col>
            <Col>
                <Row className={`d-flex justify-content-end ${classNames['notifications-container']}`} >
                  
                       <HeaderNotificationsDropdown/>
               
                    <Link to="/message-center">
                        <div
                            className={`d-flex flex-column justify-content-center align-items-center ${classNames['header-item']}`}
                        >
                            <div>
                                <FaEnvelope color="white" size={40} />
                                {!!unreadMessageCount && (
                                    <span className={classNames['unread-messages-count-container']}>
                                        {unreadMessageCount}
                                    </span>
                                )}
                            </div>
                            <span>Messages</span>
                        </div>
                    </Link>
                    <div
                        aria-label="button"
                        className={`d-flex flex-column justify-content-center align-items-center ${
                            classNames['header-item']
                        } ${classNames.clickable} ${isMyInfoOpen ? classNames.open : ''}`}
                        onClick={handleClickOnMyInfo}
                    >
                        <div>
                            <FaCircleUser
                                color={
                                    isMyInfoOpen
                                        ? HEADER_TEXT_ACTIVE_COLOR
                                        : HEADER_TEXT_INACTIVE_COLOR
                                }
                                size={40}
                            />
                        </div>
                        <span>{getMemberInitials()}</span>
                        {isMyInfoOpen && (
                            <div className={classNames['dropdown']} ref={dropdownElementRef}>
                                <ul className={classNames['dropdown-container']}>
                                    <Link to="/account-information">
                                        <li className={classNames['dropdown-item']}>
                                            <strong className={classNames.bold}>
                                                My Information
                                            </strong>
                                        </li>
                                    </Link>
                                    <Link to="/id-card">
                                        <li className={classNames['dropdown-item']}>
                                            <strong className={classNames.bold}>My ID Card</strong>
                                        </li>
                                    </Link>
                                    <Link to="/communication-preferences">
                                        <li className={classNames['dropdown-item']}>
                                            <strong className={classNames.bold}>
                                                Communication Preferences
                                            </strong>
                                        </li>
                                    </Link>
                                    <a href="" onClick={handleLogOut}>
                                        <li className={classNames['dropdown-item']}>
                                            <strong className={classNames.bold}>
                                                Log Out
                                            </strong>
                                        </li>
                                    </a>
                                </ul>
                            </div>
                        )}
                    </div>
                </Row>
            </Col>
        </Row>
        </Container>
    );
};

const HeaderWithData = withUnreadMessageCount(withMemberData(withIdentityData(Header)));

export { HeaderWithData as Header };
