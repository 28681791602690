import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Subscription } from 'rxjs';
import ProviderMedicalGroupsDialog from 'components/molecules/ProviderSearch/ProviderMedicalGroupsDialog';
import ProviderSearchResults from 'components/molecules/ProviderSearch/ProviderSearchResults';
import { MedicalGroup } from 'components/pages/PcpChange/PcpChange';
import withLanguages from 'hoc/withLanguages';
import { Providers } from 'interfaces/Provider';
import { ProviderSearchParameters } from 'interfaces/SelfService';
import providerService from 'services/providerService';

interface ProviderSearchProps {
    effectiveDate: string;
    languages: any;
    ipasForPbp: { id: string; desc: string }[];
    onMedicalGroupSelect: (medicalGroup: MedicalGroup | null) => void;
    onProviderSelect: (provider: Providers) => void;
    onSearchValueChange: (property: string, value: any) => void;
    pbpOptions: { text: string; value: string }[];
    providers: Providers[] | null;
    searchValues: ProviderSearchParameters;
    selectedProvider: Providers | null;
    setProviders: (providers: Providers[] | null) => void;
    setSearchValues: (searchValues: ProviderSearchParameters) => void;
    zipCodesForPbp: { text: string; value: string }[];
}

const ProviderSearch = ({
    effectiveDate,
    languages,
    ipasForPbp,
    onMedicalGroupSelect,
    onProviderSelect,
    onSearchValueChange,
    pbpOptions,
    providers,
    searchValues,
    selectedProvider,
    setProviders,
    setSearchValues,
    zipCodesForPbp,
}: ProviderSearchProps) => {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isOpenMedicalGroupsDialog, setIsOpenMedicalGroupsDialog] = useState<boolean>(false);
    const [queueSearch, setQueueSearch] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    useEffect(() => {
        if (queueSearch) {
            searchProviders();
            setQueueSearch(false);
        }
    }, [searchValues.pageNumber, queueSearch]);

    const searchProviders = () => {
        setLoading(true);

        const searchProvidersSub = providerService
            .searchProviders$({
                ...searchValues,
                providerCategory: 'Primary Care Provider (PCP)',
                sortby: `qualityrating,${searchValues.sortby}`,
                pageIndex: searchValues.pageNumber,
            })
            .subscribe({
                next: (response) => {
                    if (response && response.response) {
                        const tempSearchValues = { ...searchValues };
                        tempSearchValues.totalNpiCount = response.response.totalNPICount;
                        if (response.response.totalNPICount != searchValues.totalNpiCount) {
                            tempSearchValues.pageNumber = 1;
                        }
                        setSearchValues(tempSearchValues);
                        setProviders(response.response.providers);
                    }
                    setLoading(false);
                },
                error: () => {
                    setLoading(false);
                },
            });

        setSubscriptions([...subscriptions, searchProvidersSub]);
    };

    return (
        <div style={{ position: 'relative' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        select
                        id="pbp"
                        label="PBP"
                        disabled={true}
                        onChange={(args: any) => {
                            onSearchValueChange('pbpId', args.target.value);
                        }}
                        value={searchValues.pbpId ?? ''}
                    >
                        {pbpOptions.map((pbpItem) => (
                            <MenuItem key={pbpItem.value} value={pbpItem.value}>
                                {pbpItem.text}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        id="searchTerm"
                        label="PCP Name/NPI"
                        onChange={(args: any) => {
                            onSearchValueChange('searchTerm', args.target.value);
                        }}
                        value={searchValues.searchterm ?? ''}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        select
                        id="zipCode"
                        label="Zip Code"
                        onChange={(args: any) => {
                            onSearchValueChange('fromAddress', args.target.value);
                        }}
                        value={searchValues.fromAddress ?? ''}
                    >
                        {zipCodesForPbp.map((zip) => (
                            <MenuItem key={`zip-code-MenuItem-${zip.value}`} value={zip.value}>
                                {zip.text}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        select
                        id="gender"
                        label="Gender"
                        onChange={(args: any) => {
                            onSearchValueChange(
                                'gender',
                                args.target.value ? args.target.value : null
                            );
                        }}
                        value={searchValues.gender ?? ''}
                    >
                        <MenuItem value="">Any</MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        select
                        id="language"
                        label="Language"
                        onChange={(args: any) => {
                            onSearchValueChange('language', args.target.value);
                        }}
                        value={searchValues.language ?? ''}
                    >
                        <MenuItem value="">Any</MenuItem>
                        {languages?.map(
                            (language: { languageID: string; languageDescription: string }) => (
                                <MenuItem
                                    key={`language-MenuItem-${language.languageID}`}
                                    value={language.languageDescription}
                                >
                                    {language.languageDescription}
                                </MenuItem>
                            )
                        )}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        select
                        id="medicalGroup"
                        label="Medical Group"
                        onChange={(args: any) => {
                            onSearchValueChange('ipa', args.target.value);
                        }}
                        value={searchValues.ipa ?? ''}
                    >
                        <MenuItem value="">Any</MenuItem>
                        {ipasForPbp.map((medicalGroup: any, i: number) => (
                            <MenuItem
                                key={`medical-group-MenuItem-${medicalGroup.id}-${i}`}
                                value={medicalGroup.id}
                            >
                                {medicalGroup.desc} ({medicalGroup.id})
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        select
                        id="withinMiles"
                        label="Distance (miles)"
                        onChange={(args: any) => {
                            onSearchValueChange('withinmiles', args.target.value);
                        }}
                        value={searchValues.withinmiles ?? ''}
                    >
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                        fullWidth
                        select
                        id="acceptingNewPatients"
                        label="Accepting New Patients"
                        onChange={(args: any) => {
                            onSearchValueChange(
                                'acceptingNewPatients',
                                args.target.value ? args.target.value : null
                            );
                        }}
                        value={searchValues.acceptingNewPatients ?? ''}
                    >
                        <MenuItem value="">Any</MenuItem>
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            <div className="my-2">
                <Button color="primary" variant="contained" onClick={searchProviders}>
                    Search PCPs
                </Button>
            </div>

            {providers && (
                <div>
                    <ProviderSearchResults
                        effectiveDate={effectiveDate}
                        onProviderSelect={(provider) => {
                            onProviderSelect(provider);
                            setIsOpenMedicalGroupsDialog(true);
                        }}
                        onSearchValueChange={onSearchValueChange}
                        pageNumber={searchValues.pageNumber}
                        providers={providers}
                        searchValues={searchValues}
                        setQueueSearch={setQueueSearch}
                        totalRecordsCount={searchValues.totalNpiCount ?? 0}
                    />
                </div>
            )}

            <ProviderMedicalGroupsDialog
                effectiveDate={effectiveDate}
                isDialogOpen={isOpenMedicalGroupsDialog}
                onDialogClose={() => {
                    setIsOpenMedicalGroupsDialog(false);
                }}
                onMedicalGroupSelect={(medicalGroup: MedicalGroup | null) => {
                    onMedicalGroupSelect(medicalGroup);
                }}
                provider={selectedProvider}
            />

            {loading && (
                <CircularProgress
                    style={{
                        position: 'absolute',
                        top: 'calc(50% - 20px)',
                        left: 'calc(50% - 20px)',
                        height: '40px',
                        width: '40px',
                    }}
                />
            )}
        </div>
    );
};

export default withLanguages(ProviderSearch);
