enum FieldType {
    // currently unused in favor of radio
    Checkbox = 'Checkbox',
    DropDownList = 'Dropdown',
    MultiSelectCheckbox = 'MultiSelectCheckbox',
    Radio = 'Radio',
    TextEditable = 'TextBox',
}

export default FieldType;
