import React, { useState } from 'react';
import { Stepper, Step, StepButton, Alert, AlertTitle } from '@mui/material';
import NavigationButtons from './NavigationButtons';
import { Page } from '../../interfaces/Page';
import { isEmpty } from 'lodash';
import { HelpOutline } from '@mui/icons-material';

export interface NewWizardProps {
    pages: Page[] | null;
    onSubmit: (data: any) => any;
    onPageChange?: (...args: any[]) => any;
    readOnly: boolean;
    errors: {};
    handleValidation: (pageIndex: number) => boolean | Promise<boolean>;
}

export interface CurrentPageProps {
    pages: Page[];
    currentPageIndex: number;
    changePage: (index: number) => any;
    onSubmit: (data: object) => any;
    readOnly: boolean;
    errors: {};
    handleValidation: (pageIndex: number) => boolean | Promise<boolean>;
}

const CurrentPage = ({
    pages,
    currentPageIndex,
    changePage,
    onSubmit,
    readOnly,
    errors,
    handleValidation,
}: CurrentPageProps) => {
    const validatePage = async (nextAction: (...args: any[]) => any) => {
        const isValidPage = await handleValidation(currentPageIndex);
        if (isValidPage) {
            await nextAction();
        }
    };

    const navigationProps = {
        pages: pages,
        currentPageIndex: currentPageIndex,
        changePage: changePage,
        onSubmit: onSubmit,
        readOnly: readOnly,
        validatePage: validatePage,
        errors: errors,
    };

    return (
        <div>
            {!isEmpty(pages) ? (
                <div data-testid="CurrentPage">
                    {pages[currentPageIndex].content}
                    <NavigationButtons {...navigationProps}></NavigationButtons>
                </div>
            ) : (
                <Alert severity="warning" icon={<HelpOutline />}>
                    <AlertTitle>Whoops!</AlertTitle>
                    It looks like there isn&apos;t a page to show!
                </Alert>
            )}
        </div>
    );
};

const NewWizard = ({
    pages,
    onSubmit,
    onPageChange,
    readOnly,
    errors,
    handleValidation,
}: NewWizardProps) => {
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

    const changePage = (newPageIndex: number) => {
        if (onPageChange) {
            onPageChange();
        }
        setCurrentPageIndex(newPageIndex);
    };

    const steps = pages?.map((page, index) => (
        <Step key={`${page.label}.${index}`}>
            <StepButton onClick={() => changePage(index)}>{page.label}</StepButton>
        </Step>
    ));

    return (
        <div style={{ padding: '2em' }}>
            {pages && pages.length > 0 ? (
                <div data-testid="NewWizard">
                    <Stepper
                        activeStep={currentPageIndex}
                        alternativeLabel={true}
                        nonLinear={readOnly}
                        sx={{ padding: 2 }}
                    >
                        {steps}
                    </Stepper>
                    <CurrentPage
                        pages={pages}
                        currentPageIndex={currentPageIndex}
                        changePage={changePage}
                        onSubmit={onSubmit}
                        readOnly={readOnly}
                        errors={errors}
                        handleValidation={handleValidation}
                    />
                </div>
            ) : (
                <Alert severity="warning" icon={<HelpOutline />}>
                    <AlertTitle>Whoops!</AlertTitle>
                    It looks like there aren&apos;t any pages to show!
                </Alert>
            )}
        </div>
    );
};

export { NewWizard as default, CurrentPage };
