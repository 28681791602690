import { ajax } from 'rxjs/ajax';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { messages$, notifications$ } from './subjects';

const messageIdAjax = (method, messageId, body) =>
    jsonHeaders$.pipe(
        take(1),
        switchMap((headers) =>
            ajax({
                method,
                url: `api/legacymessages/${messageId}`,
                headers,
                body,
            })
        )
    );

const messageService = {
    get$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/legacymessages?page_size=50&page_number=1`,
                headers,
            })
        )
    ),
    getUnreadCount$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/legacymessages/unread_count`,
                headers,
            })
        )
    ),
    getMessage$: (messageId) => messageIdAjax('GET', messageId),
    updateMessage$: (messageId, isRead, message) =>
        messageIdAjax('PUT', messageId, { ...message, is_read: isRead }).pipe(
            tap((_) => {
                messages$.pipe(take(1)).subscribe({
                    next: (messages) => {
                        var updated = messages.map((message) => {
                            if (message.id === messageId) {
                                message.is_read = isRead;
                            }
                            return message;
                        });
                        messages$.next(updated);
                    },
                });
            })
        ),
    deleteMessage$: (messageId) => messageIdAjax('DELETE', messageId),
    createMessage$: (message) =>
        jsonHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/legacymessages`,
                    headers,
                    body: message,
                })
            ),
            tap((_) => notifications$.next({ color: 'success', message: 'Message sent!' })),
            tap((_) =>
                messageService.get$
                    .pipe(
                        map((response) => response.response),
                        tap((messages) => messages$.next(messages))
                    )
                    .subscribe()
            )
        ),
};

export default messageService;
