import React, { useState } from 'react';
import { getIsEffective, getName } from 'components/pages/PcpChange/util';
import { Providers, AHRecord } from 'interfaces/Provider';
import MedicalGroupCard from './MedicalGroupCard';
import SearchBar from '../SearchBar';
import { MedicalGroup } from 'components/pages/PcpChange/PcpChange';
import './ProviderMedicalGroups.scss';

interface ProviderMedicalGroupsProps {
    effectiveDate: string;
    onSelectMedicalGroup: (medicalGroup: MedicalGroup) => void;
    provider: Providers | null;
    selectedMedicalGroup: MedicalGroup | null;
}

const ProviderMedicalGroups: React.FC<ProviderMedicalGroupsProps> = ({
    effectiveDate,
    onSelectMedicalGroup,
    provider,
    selectedMedicalGroup,
}: ProviderMedicalGroupsProps) => {
    const [searchTerm, setSearchTerm] = useState<string | null>(null);

    const search = (medicalGroups: AHRecord[], term: string | null) => {
        if (!term) {
            return medicalGroups;
        }
        const loweredTerm = term.toLowerCase();
        return medicalGroups.filter((group) => {
            if (group.ipa.desc.toLowerCase().includes(loweredTerm)) {
                return group;
            }
            if (
                group.addresses.some(
                    (address) =>
                        address.address1.toLowerCase().includes(loweredTerm) ||
                        address.address2.toLowerCase().includes(loweredTerm) ||
                        address.city.toLowerCase().includes(loweredTerm) ||
                        address.state.toLowerCase().includes(loweredTerm) ||
                        address.zip.toLowerCase().includes(loweredTerm) ||
                        address.county.toLowerCase().includes(loweredTerm)
                )
            ) {
                return group;
            }
        });
    };

    if (!provider) {
        return null;
    }

    return (
        <div className="crm-provider-medical-groups-container">
            <p className="mb-1">You are choosing a medical group for: </p>
            <p style={{ fontWeight: 'bold', fontSize: '.9rem' }}>{getName(provider.nppes)}</p>
            <SearchBar placeholder="Search for address or a group name" change={setSearchTerm} />
            <p>Number of Medical Groups: {provider.ahRecords.length}</p>
            <div>
                {search(provider.ahRecords, searchTerm).map((record, i) => (
                    <div
                        className={`mx-2 my-4 p-2 ${
                            getIsEffective(effectiveDate, record.effectiveDate, record.termDate)
                                ? ''
                                : 'disabled'
                        }`}
                        key={`medical_group_${record.ipa.id}_${i}`}
                        style={{ border: '1px solid #e0e0e0' }}
                    >
                        <MedicalGroupCard
                            ahRecord={record}
                            onSelectMedicalGroup={onSelectMedicalGroup}
                            selectedMedicalGroup={selectedMedicalGroup}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProviderMedicalGroups;
