import React, { useEffect, useRef, useState } from 'react';
import Wizard from '../Form/Wizard';
import {
    initialFormState,
    reducer,
    pageMetaData,
    patientSteps,
} from '../Form/Patient/WizardConfig';
import withMemberData from 'hoc/withMemberData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import patientAccessService from 'services/patientAccessService';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { isEmpty, merge, head } from 'lodash';
import { PatientPages, Sex } from 'components/Form/Patient/Enums';
import moment from 'moment';
import withFeatureFlags from 'hoc/withFeatureFlags';

const FEATURE_NAME = 'member-new-patient-form';
const Loading = () => (
    <div className="d-flex align-items-center justify-content-center" style={{ height: '375px' }}>
        <FontAwesomeIcon icon={faCircleNotch} aria-label="Currently Loading Content" size="3x" />
    </div>
);
const hydrateFormData = (
    store,
    initialState,
    { birth_date, addresses, email, first_name, last_name, middle_name, home_phone, gender }
) => {
    const { city_name, state, street_name_1, zip_code } = head(addresses);
    const memberData = {
        firstName: first_name,
        lastName: last_name,
        middleInitial: middle_name,
        dateOfBirth: birth_date,
        age: moment().diff(moment(birth_date), 'y'),
        address: street_name_1,
        city: city_name,
        state,
        zipCode: zip_code,
        phoneHome: home_phone,
        email,
        sex: gender === 'M' ? Sex.MALE : Sex.FEMALE,
    };
    return { ...merge({}, { ...initialState }, { ...memberData }, { ...store }) };
};
const AccessNewPatientForm = ({ member, featureFlags }) => {
    const [initialState, setInitialState] = useState({});
    const topAnchor = useRef(null);

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewPatientForm })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (member) {
            patientAccessService.get$().subscribe({
                next: ({ patientData }) => {
                    setInitialState(hydrateFormData(patientData, initialFormState, member));
                },
                error: () => {
                    setInitialState(initialFormState);
                },
            });
        }
    }, [member]);

    if (!featureFlags[FEATURE_NAME]) {
        return (
            <div>
                <p>Feature not available at the current time</p>
            </div>
        );
    }

    if (!member || isEmpty(initialState)) {
        return <Loading />;
    }

    return (
        <div style={{ margin: '5%' }} ref={topAnchor}>
            <img src="img/access-physician-inc-logo.png" height={100} alt="Access Logo" />
            <hr />
            <Wizard
                initialFormState={initialState}
                reducer={reducer}
                pageMetaData={pageMetaData}
                pagesMap={PatientPages}
                anchor={topAnchor}
                steps={patientSteps}
            />
        </div>
    );
};

export default withFeatureFlags(withMemberData(AccessNewPatientForm));
