import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';
import { pick } from 'lodash';
import { TableInputForm } from 'components/Form/Patient/Input/TableForm';
import FormHOC from '../../Form/FormHOC';
import PhysicianInformation from './MedicalForms/PhysicianInformation';
import { TextAreaFieldWithController } from 'components/Form/Patient/Input/TextAreaInput';
import HealthHabitsInformation from './MedicalForms/HealthHabitsInformation';
import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import { YES_NO_NA } from 'components/Form/Patient/Constants';

const SurgicalHistoryInformation = ({ control, state, setValue, register }) => {
    return (
        <>
            <TableInputForm
                control={control}
                state={state}
                setValue={setValue}
                register={register}
                name="surgicalHistory"
                title="Past Surgical History"
                initialInputs={{ date: '', surgery: '' }}
                headers={['Date', 'Surgery']}
            />
            <TextAreaFieldWithController
                control={control}
                defaultValue={state?.additionalMedicalInformation}
                name="additionalMedicalInformation"
                type="textarea"
                question="Please List Any Additional Medical Information:"
                showError={false}
            />
        </>
    );
};
const PrescriptionMedicationInformation = ({ control, state, setValue, register }) => {
    return (
        <TableInputForm
            control={control}
            state={state}
            setValue={setValue}
            register={register}
            name="prescriptions"
            title="Prescription Medications, Vitamins, and Health Supplements"
            initialInputs={{ name: '', dose: '', frequency: '', orderingProvider: '' }}
            headers={['Name', 'Dose', 'Frequency', 'Ordering Provider']}
        />
    );
};
const FollowUpQuestions = ({ state }) => (
    <Row>
        <Col md={12}>
            <RadiosWithController
                defaultValue={state?.hasAdvanceDirectiveLivingWill}
                question="Do you have an advance directive/living will?"
                name="hasAdvanceDirectiveLivingWill"
                options={YES_NO_NA}
                colSize={6}
            />
        </Col>
        <Col md={12}>
            <RadiosWithController
                defaultValue={state?.wantsCopyOfWill}
                question="If yes, please supply the office with a copy for your chart. If no, would you like one?"
                name="wantsCopyOfWill"
                options={YES_NO_NA}
                colSize={6}
            />
        </Col>
    </Row>
);
const FormContent = ({ state, prevPageAction, onSubmit }) => {
    const { control, handleSubmit, errors, setValue, register } = useFormContext();

    return (
        <>
            <h2 className="pb-4">Past Surgical History</h2>
            <SurgicalHistoryInformation state={state} setValue={setValue} register={register} />
            <hr />

            <h2 className="pb-4">Health Habits History</h2>
            <HealthHabitsInformation control={control} state={state} />
            <hr />

            <h2 className="pb-4">
                List All Prescription Medications, Vitamins, and Health Supplements
            </h2>
            <PrescriptionMedicationInformation
                state={state}
                setValue={setValue}
                register={register}
            />
            <hr />

            <h2 className="pb-4">Physician List</h2>
            <div className="h5">
                (Please list any other physicians currently assisting in your care)
            </div>
            <PhysicianInformation state={state} setValue={setValue} register={register} />
            <FollowUpQuestions state={state} />
            <hr />

            <div className="d-flex justify-content-center mt-3">
                <div className="d-flex justify-content-around w-75">
                    <Button onClick={prevPageAction} size="lg">
                        Go Back
                    </Button>

                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Save and Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

const HealthInformationForm = ({ state, onSubmit, props, ...rest }) => {
    const valuePaths = ['reason', 'type'];
    return (
        <FormHOC formDefaultValues={pick(state, valuePaths)}>
            <FormContent onSubmit={onSubmit} state={state} {...rest} />
        </FormHOC>
    );
};

export default HealthInformationForm;
