import React, { useState, useEffect, useRef } from 'react';
import Wizard from '../Form/Wizard';
import {
    initialFormState,
    reducer,
    PAGES,
    pageMetaData,
} from '../Form/Appointment/appointmentWizardConfig';
import withPcpData from '../../hoc/withPcpData';
import withAppointmentData from '../../hoc/withAppointmentData';
import withMemberData from 'hoc/withMemberData';
import withIdentityData from 'hoc/withIdentityData';
import appointmentService from '../../services/appointmentService';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const ScheduleAppointmentForm = (props) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [initFormState, setInitFormState] = useState(null);
    const titleRef = useRef();
    useEffect(() => {
        getTranslatorPreferredLanguage();

        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewAppointmentForm })
            .subscribe();

        setSubscriptions([...subscriptions, trackPageVisitSub]);

        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    const getTranslatorPreferredLanguage = () => {
        const getLanguageSub = appointmentService.getTranslatorPreferredLanguage$().subscribe({
            next: (response) => {
                const tempFormState = { ...initialFormState };
                tempFormState.translatorPreferredLanguage =
                    response.language === null ? '' : response.language;
                setInitFormState(tempFormState);
            },
            error: () => {
                setInitFormState(initialFormState);
            },
        });
        setSubscriptions([...subscriptions, getLanguageSub]);
    };

    return (
        <div style={{ margin: '5%' }}>
            {props.reasons &&
            props.visitTypes &&
            props.pcp &&
            props.member &&
            props.identity &&
            initFormState !== null ? (
                <>
                    <h1 ref={titleRef}>Schedule a Virtual Appointment with {props.pcp?.name}</h1>
                    <Wizard
                        initialFormState={initFormState}
                        reducer={reducer}
                        pageMetaData={pageMetaData}
                        pagesMap={PAGES}
                        props={props}
                        anchor={titleRef}
                    />
                </>
            ) : (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '375px' }}
                >
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        aria-label="Currently Loading Content"
                        size="3x"
                    />
                </div>
            )}
        </div>
    );
};

export default withPcpData(
    withAppointmentData(withMemberData(withIdentityData(ScheduleAppointmentForm)))
);
