import React, { useState, useEffect } from 'react';
import TableInfoPage from '../templates/TableInfoPage';
import withMedicationData from '../../hoc/withMedicationData';
import { search } from '../../common/utility';
import { isNil } from 'lodash';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { Button } from 'react-bootstrap';
import messageService from 'services/legacyMessageService';
import withMemberData from 'hoc/withMemberData';
import loadOverlayService from 'services/loadOverlayService';
import withFeatureFlags from 'hoc/withFeatureFlags';

const Medications = (props) => {
    const [searchTerm, updateSearchTerm] = useState(null);
    const medications = props.medications?.map((medication) => {
        if (medication.isActive === '1') {
            let message = {
                author_name: `${props.member.first_name} ${props.member.last_name}`,
                author_user_id: `${props.member.email}`,
                content: `Requesting refill for: ${medication.drugName}`,
                category: 'Prescription Refill',
                is_staff: false,
                subject: 'Prescription Refill',
            };
            const askRefill = () => {
                loadOverlayService.show('Requesting Refill...');

                messageService.createMessage$(message).subscribe({
                    next: () => {
                        loadOverlayService.hide();
                    },
                    error: () => {
                        loadOverlayService.hide();
                    },
                });
            };

            return {
                ...medication,
                askRefill: <Button onClick={askRefill}>Ask Refill</Button>,
            };
        }
        return { ...medication };
    });

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewMedications })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    const onSearchChange = (term) => {
        updateSearchTerm(term);
    };

    return (
        <>
            {props.featureFlags['member-medication-refill'] ? (
                <TableInfoPage
                    pageHeader={'Medications'}
                    searchBarPlaceholder={'Search Medications by Name, Pharmacy, Prescriber'}
                    onSearchChange={onSearchChange}
                    data={!isNil(medications) && search(medications || [], searchTerm)}
                    fields={[
                        {
                            name: 'drugName',
                            header: 'Name',
                            col: { lg: 2 },
                            format: 'header',
                        },
                        {
                            name: 'quantity',
                            header: 'Quantity',
                            col: { lg: 2 },
                        },
                        {
                            name: 'frequency',
                            header: 'Supply',
                            col: { lg: 1 },
                        },
                        {
                            name: 'pharmacyName',
                            header: 'Pharmacy',
                            col: { lg: 2 },
                        },
                        {
                            name: 'prescriberName',
                            header: 'Prescriber',
                            col: { lg: 2 },
                        },
                        {
                            name: 'dateofService',
                            header: 'Filled',
                            col: { lg: 2 },
                            format: 'date',
                        },
                        {
                            name: 'askRefill',
                            header: 'Active',
                            col: { lg: 1 },
                        },
                    ]}
                />
            ) : null}
        </>
    );
};

export default withFeatureFlags(withMemberData(withMedicationData(Medications)));
