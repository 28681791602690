import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';
import PropTypes from 'prop-types';
const TextField = ({
    label,
    name,
    question,
    value,
    onKeyDown,
    onKeyUp,
    disabled,
    errorMessage,
    onChange,
    maxLength,
    normalize,
    required,
}) => {
    return (
        <Form.Group>
            <Form.Label
                className="h5 font-weight-bold"
                id={`label-inputquestion-${question}`}
                htmlFor={name}
            >
                {question}
                <span className="text-danger">{required ? ' *' : ' '}</span>
            </Form.Label>
            <Form.Control
                data-testid={name}
                // type="input"
                name={name}
                onChange={(e) => {
                    const val = normalize ? normalize(e.target.value) : e.target.value;
                    onChange(val);
                }}
                id={name}
                value={value || ''}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                maxLength={maxLength}
                aria-labelledby={`label-inputquestion-${question}`}
                disabled={disabled}
            />
            <Form.Text className="h5 text-danger">{errorMessage}</Form.Text>
        </Form.Group>
    );
};
const TextFieldWithController = ({
    control,
    name,
    defaultValue = '',
    label,
    type,
    error,
    onKeyUp,
    normalize,
    disabled,
    required,
    ...rest
}) => (
    <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty, error },
            formState
        ) => (
            <div className="pt-3">
                <TextField
                    name={name}
                    value={value}
                    type={type}
                    onChange={onChange}
                    normalize={normalize}
                    question={label}
                    option={{ label: name }}
                    onKeyUp={onKeyUp}
                    disabled={disabled}
                    errorMessage={get(error, 'message', '')}
                    required={required}
                    {...rest}
                />
            </div>
        )}
    />
);
TextFieldWithController.propTypes = {
    control: PropTypes.any,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    onKeyUp: PropTypes.func,
    normalize: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};
TextFieldWithController.defaultProps = {
    normalize: (val) => val,
};
export default TextFieldWithController;
