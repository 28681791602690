import React from 'react';
import { SUBMISSION_STATUS } from 'enums/status';
import './stylesheets/HealthRiskAssessmentConfirmation.scss';
import ErrorMessage from '../../molecules/ErrorMessage';
import SuccessConfirmation from './SuccessConfirmation';
import Loading from 'components/atoms/Loading';
import { SubmissionResponse } from 'interfaces/HealthRiskAssessment';

export interface HealthRiskAssessmentConfirmationProps {
    submissionResponse: SubmissionResponse;
}

const HealthRiskAssessmentConfirmation = ({
    submissionResponse,
}: HealthRiskAssessmentConfirmationProps) => {
    return (
        <div className="hra-confirmation">
            {submissionResponse.status !== SUBMISSION_STATUS.PENDING ? (
                submissionResponse.status === SUBMISSION_STATUS.SUCCESS ? (
                    <SuccessConfirmation submissionResponse={submissionResponse} />
                ) : (
                    <ErrorMessage message="There was an error and the application could not be processed." />
                )
            ) : (
                <Loading message="Submitting Health Risk Assessment..." />
            )}
        </div>
    );
};

export default HealthRiskAssessmentConfirmation;
