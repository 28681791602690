import { AHRecord, AHRecordAddress, Nppes, Providers } from 'interfaces/Provider';

export const getName = (providerInfo: Nppes | null) => {
    if (providerInfo) {
        let name = providerInfo.firstName;
        if (providerInfo.middleName && providerInfo.middleName.length > 0) {
            name = name + ' ' + providerInfo.middleName;
        }
        name = name + ' ' + providerInfo.lastName;
        if (providerInfo.suffix && providerInfo.suffix.length > 0) {
            name = name + ', ' + providerInfo.suffix;
        }

        if (name.trim().length < 1) {
            name = providerInfo.organizationName;
        }

        return name;
    }
    return '';
};

export const getIsPcp = (medicalGroup: AHRecord) => {
    return medicalGroup.providerCategory?.includes('Primary Care Provider (PCP)');
};

export const getSpecialtiesAsString = (medicalGroup: AHRecord) => {
    let specialtiesString = '';
    medicalGroup.practisingSpecialities?.forEach((specialty) => {
        if (specialtiesString.length < 1) {
            specialtiesString = specialty.desc;
        } else {
            specialtiesString = specialtiesString + '; ' + specialty.desc;
        }
    });
    return specialtiesString;
};

export const getClosestDistance = (medicalGroup: AHRecord) => {
    if (!medicalGroup.addresses) {
        return;
    }
    return Math.min(...medicalGroup.addresses.map((address) => address.distanceFromGivenAddress));
};

export const getIsAcceptingNewPatientsAtClosest = (medicalGroup: AHRecord) => {
    const closestDistance = getClosestDistance(medicalGroup);
    const closestAddress = medicalGroup.addresses?.find(
        (address) => address.distanceFromGivenAddress === closestDistance
    );
    if (closestAddress) {
        return getIsAcceptingNewPatients(closestAddress);
    }
    return false;
};

export const getIsAcceptingNewPatients = (address: AHRecordAddress) => {
    return address.isAcceptingNewPatients.toLowerCase() === 'yes';
};

export const getClosestAddress = (medicalGroup: AHRecord) => {
    const closestDistance = getClosestDistance(medicalGroup);
    return medicalGroup.addresses?.find(
        (address: AHRecordAddress) => address.distanceFromGivenAddress === closestDistance
    );
};

export const getClosestAddressAsString = (medicalGroup: AHRecord) => {
    const closestAddress = getClosestAddress(medicalGroup);
    if (!closestAddress) {
        return;
    }
    return getAddressAsString(closestAddress);
};

export const getAddressAsString = (address: AHRecordAddress) => {
    let addressString = address.address1;
    if (address.address2 && address.address2.length > 0) {
        addressString = addressString + ', ' + address.address2;
    }
    return addressString + ', ' + address.city + ', ' + address.state + ' ' + address.zip;
};

export const getIsAcceptingAtAnyLocation = (provider: Providers) => {
    return provider.ahRecords.some((record) => {
        return record.addresses.some((address) => {
            return address.isAcceptingNewPatients.toLowerCase() === 'yes';
        });
    });
};

export const getIsEffective = (
    date: Date | string,
    effectiveDate: Date | string,
    termDate: Date | string
) => {
    return (
        new Date(date) >= new Date(effectiveDate) &&
        (!termDate ||
            (typeof termDate === 'string' && termDate.length < 1) ||
            new Date(date) < new Date(termDate))
    );
};

export const getIsProviderEffective = (date: Date | string, provider: Providers) => {
    return provider.ahRecords.some((ah) => getIsEffective(date, ah.effectiveDate, ah.termDate));
};

export const formatAsPhone = (phoneNumber: string) => {
    return `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(
        6,
        10
    )}`;
};
