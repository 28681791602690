import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, FormGroup, Input } from 'reactstrap';
import { Editor, EditorState, RichUtils } from 'draft-js';
import messageService from '../../services/legacyMessageService';
import withMemberData from '../../hoc/withMemberData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import withPcpData from '../../hoc/withPcpData';
import withPolicyData from '../../hoc/withPolicyData';
import { PlanTypes } from '../../hoc/Enums';
import { notifications$ } from 'services/subjects';
import { sendCustomEvent } from 'common/utility';
import { AUDITEVENTTYPE } from 'services/trackService';

const CHARACTERLIMIT = 1200;

const Composer = (props) => {
    const [sending, setSending] = useState(false);
    const [sendFailed, setSendFailed] = useState(false);
    const [category, setCategory] = useState(props.category);
    const [recipient, setRecipient] = useState(props.recipient);
    const [subject, setSubject] = useState(props.subject);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    let characterCount = editorState.getCurrentContent().getPlainText().length;

    let recipients = [
        {
            id: '',
            name: 'Virtual Care/Concierge Team',
        },
    ];
    if (props.pcp) {
        recipients = [
            ...recipients,
            {
                id: props.pcp.npi,
                name: 'PCP Office',
            },
        ];
    }

    return (
        <div className="message-content">
            {!props.parentMessageId && (
                <Row>
                    {props.planType === PlanTypes.HMO ? (
                        <Col md="3">
                            <FormGroup>
                                <Input
                                    type="select"
                                    invalid={recipient === undefined || recipient === 'none'}
                                    onChange={(e) => setRecipient(e.target.value)}
                                    data-testid="recipient-list"
                                >
                                    <option value="none"> -- To --</option>
                                    {recipients.map((recipient, i) => (
                                        <option key={i} value={recipient.id}>
                                            {recipient.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    ) : null}
                    <Col md="3">
                        <FormGroup>
                            <Input
                                type="select"
                                invalid={!category}
                                disabled={
                                    props.planType === PlanTypes.HMO &&
                                    recipient === undefined
                                }
                                onChange={(e) => setCategory(e.target.value)}
                                aria-label="Select Category"
                                data-testid="category-list"
                            >
                                <option value={undefined}> --Select Category--</option>
                                {(props.planType === PlanTypes.HMO
                                    ? recipient === ''
                                        ? props.memberPortalConfig?.messageCenterCategories ?? []
                                        : props.memberPortalConfig?.messageCenterPcpCategories ?? []
                                    : props.memberPortalConfig?.messageCenterCategories ?? []
                                ).map((category, i) => (
                                    <option key={i}>{category}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Input
                                type="text"
                                invalid={!subject}
                                placeholder="Enter Subject Here"
                                onChange={(e) => setSubject(e.target.value)}
                                aria-label="Enter Subject Here"
                                data-testid="subject-input"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            )}

            <Editor
                spellCheck
                editorState={editorState}
                onChange={setEditorState}
                handleKeyCommand={(command, editorState) => {
                    const newState = RichUtils.handleKeyCommand(editorState, command);
                    if (newState) {
                        setEditorState(newState);
                        return 'handled';
                    }
                    return 'not-handled';
                }}
                ariaLabel="Type message here"
                data-testid="message-input"
            />
            <div className="d-flex justify-content-end">
                <p style={{ color: characterCount > CHARACTERLIMIT ? 'red' : 'inherit' }}>
                    {CHARACTERLIMIT - characterCount} characters remaining
                </p>
            </div>
            <div className="text-center">
                <Button onClick={props.cancelled} className="mr-3">
                    Cancel
                </Button>
                <Button
                    disabled={sending || characterCount > CHARACTERLIMIT}
                    color="primary"
                    onClick={() => {
                        let messageContent = editorState.getCurrentContent().getPlainText();
                        if (!messageContent) {
                            notifications$.next({
                                message: 'Message body is required!',
                                color: 'danger',
                            });
                            return;
                        }
                        let message = {
                            parent: props.parentMessageId,
                            author_name: `${props.member.first_name} ${props.member.last_name}`,
                            author_user_id: `${props.member.email}`,
                            content: messageContent,
                            category,
                            is_staff: true,
                            subject,
                            created_at: new Date(),
                            updated_at: new Date(),
                            npi: recipient,
                        };
                        setSending(true);
                        messageService.createMessage$(message).subscribe(
                            (_) => typeof props.sent === 'function' && props.sent(),
                            (err) => {
                                sendCustomEvent({
                                    eventType: AUDITEVENTTYPE.MemberPortalMessageFailedToSend,
                                });
                                setSending(false);
                                setSendFailed(true);
                                console.log(err);
                            }
                        );
                    }}
                    data-testid="send-button"
                >
                    Send
                </Button>
                {sending ? (
                    <FontAwesomeIcon
                        className="button-icon"
                        icon={faCircleNotch}
                        aria-label="Currently Loading Content"
                        data-testid="send-loading-icon"
                    />
                ) : sendFailed ? (
                    <FontAwesomeIcon
                        className="button-icon"
                        icon={faTimesCircle}
                        aria-label="Failed to Send"
                        data-testid="send-failed-icon"
                    />
                ) : (
                    <FontAwesomeIcon
                        className="button-icon fade-out"
                        icon={faCheckCircle}
                        aria-label="Success"
                    />
                )}
            </div>
        </div>
    );
};

Composer.propTypes = {
    sent: PropTypes.func,
    cancelled: PropTypes.func,
    parentMessageId: PropTypes.string,
    subject: PropTypes.string,
};

export { Composer };

export default withMemberData(withPcpData(withPolicyData(Composer)));
