import { ajax } from 'rxjs/ajax';
import { switchMap, map } from 'rxjs/operators';
import { authHeaders$ } from './headers';

function getPartnerLink(partnerName, relayUrl) {
    return `/api/sso/initiatesinglesignonpartner?partnername=${partnerName}${
        relayUrl ? '&relayUrl=' + relayUrl : ''
    }`;
}

function getSSORoute(partnerName, relayUrl) {
    return `/sso?partnerName=${partnerName}&relayUrl=${relayUrl}`;
}

export const teledocLink = getSSORoute('teladoc.com', 'https://member.teladoc.com');
export const accessCardLink = getSSORoute('https://www.otcnetwork.com/Member/', '');
export const payspanLink = getSSORoute(
    'payspan.com',
    'https://ua-alignment.payspanh.com/memberships'
);
export const getPrimeLink = (landingPage) =>
    getSSORoute(
        'https://login.myprime.com/sps/loginmyprime/saml20',
        `https://login.myprime.com/MyRx/SSOServlet?landingPage=${landingPage}`
    );

export const PrimeLandingPages = {
    FINDADRUG: 'FindMedicines',
    FINDAPHARMACY: 'FindAPharmacy',
    PRESCRIPTIONHISTORY: 'PrescriptionHistory',
    COMMUNICATIONPREFERENCES: 'CommunicationPreferences',
};

export const getSafeRideLink = (landingPage) =>
    getSSORoute(
        'https://app.saferidehealth.com/alignment-member/metadata',
        `https://app.saferidehealth.com/`
    );

export const getOptumLink = () =>
    getSSORoute('uhg.prod.lww', 'https://www.liveandworkwell.com/services/pfsso');

export const getNationsLink = () => getSSORoute('NBSSO2021OTC', '');

export const SafeRideLandingPages = {
    SCHEDULETRANSPORTATION: 'transportation',
};

const ssoService = {
    fetchSSOForm$: (partnerName, relayUrl) =>
        authHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: getPartnerLink(partnerName, relayUrl),
                    headers,
                    responseType: 'text',
                }).pipe(map((response) => response.response))
            )
        ),
};

export default ssoService;
