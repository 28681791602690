import React, { ChangeEvent, forwardRef, useCallback, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { Form } from 'react-bootstrap';

export interface CheckboxFieldWithControllerProps {
    control: Control<any>;
    question: string | undefined;
    name: string;
    defaultValue: boolean | undefined;
    disabled?: boolean;
}

const CheckboxWithController = forwardRef<HTMLInputElement, CheckboxFieldWithControllerProps>(
    ({ control, name, defaultValue, question, disabled }, ref) => {
        const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.checked;

            setCheckboxVal(newValue);
        }, []);
        const [checkboxVal, setCheckboxVal] = useState<boolean>(defaultValue || false);
        return (
            <Controller
                control={control}
                name={name}
                defaultValue={checkboxVal}
                render={({ onChange, onBlur, value }) => (
                    <div className="d-flex">
                        <Form.Check
                            ref={ref}
                            type="checkbox"
                            checked={checkboxVal}
                            name={name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(e);
                                onChange(e.target.checked);
                            }}
                            id={name}
                            aria-labelledby={`label-question-checkbox-${name}`}
                            disabled={disabled}
                        />
                        <label
                            id={`label-question-checkbox-${name}`}
                            className="h5"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setCheckboxVal((checked) => !checked)}
                        >
                            {question}
                        </label>
                    </div>
                )}
            />
        );
    }
);
export default CheckboxWithController;
