import React from 'react';
import { Controller } from 'react-hook-form';
import { Row, Col, Form } from 'react-bootstrap';
import { get } from 'lodash';

const RadioQuestion = ({
    name,
    question,
    options,
    value,
    onChange,
    colSize,
    errors,
    disabled,
    required,
}) => {
    return (
        <div className="pt-3">
            <p className="h5 font-weight-bold">
                {question}
                <span className="text-danger">{required ? ' *' : ' '}</span>
            </p>
            <Row role="radiogroup" aria-labelledby={`label-radiogroup`}>
                {options.map((option, i) => (
                    <Col key={`radio-option-${i}`} lg={colSize}>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                data-testid={option.text}
                                type="radio"
                                size="lg"
                                checked={value == option.value}
                                name={`${name}-${option.text}`}
                                onChange={(_) => onChange(option.value)}
                                id={`${name}-${option.text}`}
                                value={option.value}
                                aria-label={`${name}-${option.text}`}
                                disabled={disabled}
                            />
                            <label
                                key={`radio-option-${i}`}
                                htmlFor={`${name}-${option.text}`}
                                tabIndex={0}
                                onKeyUp={() => onChange(option.value)}
                                className="mb-0 px-1 py-2"
                            >
                                <span className="h5">{option.text}</span>
                            </label>
                        </div>
                    </Col>
                ))}
            </Row>
            <p className="h5 text-danger">{get(errors, `[${name}].message`)}</p>
        </div>
    );
};

const RadiosWithController = ({
    control,
    errors,
    name,
    defaultValue,
    question,
    options,
    colSize,
    disabled,
    required,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty, error },
                formState
            ) => (
                <RadioQuestion
                    name={name}
                    onChange={onChange}
                    value={value}
                    question={question}
                    options={options}
                    colSize={colSize}
                    errors={errors}
                    disabled={disabled}
                    required={required}
                />
            )}
        />
    );
};
export default RadiosWithController;
