import React, { useState, memo } from 'react';
import Stack from '@mui/material/Stack';
import { Button } from 'components/v2/atoms/Button/Button';
import { SectionTitle } from 'components/v2/atoms/SectionTitle/SectionTitle';
import { Alert } from 'components/v2/molecules/Alert/Alert';
import { Loader } from 'components/v2/atoms/Loader/Loader';
import { take } from 'rxjs/operators';
import { healthyRewardService } from 'services/healthyRewardService';

export type ColorectalCancerScreeningHaveNotCompletedProps = {
    fobtKitAlreadyRequested: boolean;
};
export const ColorectalCancerScreeningHaveNotCompleted = memo(
    ({
        fobtKitAlreadyRequested,
    }: ColorectalCancerScreeningHaveNotCompletedProps) => {
        const [fobtKitRequestedAlertOpen, setFobtKitRequestedAlertOpen] = useState(false);
        const [fobtKitLoading, setFobtKitLoading] = useState(false);
        const [requestedFOBTKit, setRequestedFOBTKit] = useState(false);

        const handleClickRequestFOBTKit = () => {
            setFobtKitLoading(true);
            healthyRewardService.requestFOBTKit$.pipe(take(1)).subscribe({
                next: () => {
                    setRequestedFOBTKit(true);
                    setFobtKitRequestedAlertOpen(true);
                    setFobtKitLoading(false);
                },
                error: (error: Error) => {
                    // TODO implement toast
                    console.error(error?.message);
                    setFobtKitLoading(false);
                },
            });
        };

        return (
            <section>
                <SectionTitle>HAVE NOT COMPLETED</SectionTitle>
                {requestedFOBTKit && fobtKitRequestedAlertOpen && (
                    <Stack paddingY={4} spacing={2}>
                        <Alert
                            title="FOBT KIT REQUESTED"
                            variant="success"
                            onClose={setFobtKitRequestedAlertOpen.bind({}, false)}
                        >
                            You have successfully requested your FOBT Kit
                        </Alert>
                    </Stack>
                )}
                <Stack paddingY={4}>
                    {fobtKitAlreadyRequested || requestedFOBTKit ? (
                        <p>
                            You have already requested your FOBT Kit. Please allow 5-10 business
                            days for your it to be delivered. Once you have completed your FOBT test
                            and followed instructions to return the kit, please use the section to
                            enter the details of your test to receive your reward points.
                        </p>
                    ) : (
                        <p>
                            If you have not had your Colorectal Cancer Screening and would like to
                            receive an FOBT kit, click Request Kit below. Please allow 5-10 business
                            days for the kit to mail.
                        </p>
                    )}
                </Stack>
                <br />
                {!fobtKitAlreadyRequested && !requestedFOBTKit && (
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        padding={3}
                    >
                        {fobtKitLoading ? (
                            <Loader size="md" />
                        ) : (
                            <Button type="button" onClick={handleClickRequestFOBTKit}>
                                Request FOBT Kit
                            </Button>
                        )}
                    </Stack>
                )}
            </section>
        );
    }
);
