import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { ThemeProvider } from '@mui/material/styles';
import PageHeader from 'components/molecules/PageHeader';
import Inbox from 'components/organisms/Inbox';
import Message from 'components/organisms/Message';
import withFeatureFlags from 'hoc/withFeatureFlags';
import withInteractionData from 'hoc/withInteractionData';
import withMemberPortalConfigData from 'hoc/withMemberPortalConfigData';
import { Interaction } from 'interfaces/Interaction';
import { MemberPortalConfig } from 'interfaces/MemberPortalConfig';
import { memberId$ } from 'services/subjects';
import { messageCenterTheme } from './MessageCenterTheme';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { sendCustomEvent } from 'common/utility';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { usePersistedStateAfterARedirection } from 'hooks/usePersistedStateAfterARedirection';

export const getHasUnread = (interaction: Interaction, memberId: string) => {
    if (!interaction || !memberId) {
        return false;
    }

    let hasUnread = false;
    if (interaction.memberPortalMessageHistory?.length ?? 0 > 0) {
        hasUnread =
            interaction.memberPortalMessageHistory.filter(
                (message) => message.authorId != memberId && !message.isRead
            ).length > 0
                ? true
                : false;
    }
    return hasUnread;
};

interface MessageCenterProps {
    interactions: Interaction[] | null;
    memberPortalConfig: MemberPortalConfig | null;
    refreshInteractions: () => void;
}

const MessageCenter = ({
    interactions,
    memberPortalConfig,
    refreshInteractions,
}: MessageCenterProps) => {
    const [memberId, setMemberId] = useState<string | null>(null);
    const [showMessage, setShowMessage] = usePersistedStateAfterARedirection<boolean>('showMessage', false);
    const [currentInteraction, setCurrentInteraction] = usePersistedStateAfterARedirection<Interaction | null>('interaction', null);
    const [alert, setAlert] = useState<{
        severity: 'error' | 'info' | 'warning' | 'success';
        message: string;
    } | null>(null);
    
    useEffect(() => {
        const memberIdSub = memberId$.subscribe({
            next: (memberId) => {
                setMemberId(memberId);
            },
        });

        return () => {
            memberIdSub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalMessageCenter })
            .subscribe();
        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    if (!interactions || !memberId) {
        return (
            <div style={{ display: 'relative' }}>
                <CircularProgress
                    style={{
                        position: 'absolute',
                        top: 'calc(50% - 20px)',
                        left: 'calc(50% - 20px)',
                        height: '40px',
                        width: '40px',
                    }}
                />
            </div>
        );
    }

    const handleCaseClosedError = () => {
        refreshInteractions();
        setCurrentInteraction({
            ...currentInteraction,
            isCaseClosed: true,
        } as Interaction);
    };

    return (
        <div>
            <ThemeProvider theme={messageCenterTheme}>
                <PageHeader>My Message Center</PageHeader>
                {showMessage ? (
                    <Message
                        interaction={currentInteraction}
                        memberId={memberId}
                        memberPortalConfig={memberPortalConfig}
                        onBackClick={() => {
                            setShowMessage(false);
                            setCurrentInteraction(null);
                        }}
                        onMessageSuccess={() => {
                            refreshInteractions();
                            setShowMessage(false);
                            setCurrentInteraction(null);
                            setAlert({ severity: 'success', message: 'Message succesfully sent.' });
                        }}
                        onCaseClosedError={handleCaseClosedError}
                        refreshInteractions={refreshInteractions}
                        onMessageError={() => {
                            sendCustomEvent({ eventType: AUDITEVENTTYPE.MemberPortalMessageFailedToSend });
                        }}
                    
                    />
                ) : (
                    <div>
                        <div>
                            <p>
                                Thank you for reaching out to your ACCESS On-Demand Concierge team.
                            </p>
                            <p>
                                Please send us your message and we will respond to you within 72
                                hours. If you need immediate assistance, however, please give us a
                                call at 1-833-AHC-ACCESS (1-833-242-2223), TTY 711, 24 hours a day,
                                seven days a week.
                            </p>
                        </div>
                        <Inbox
                            handleNewMessage={() => {
                                setCurrentInteraction(null);
                                setShowMessage(true);
                            }}
                            interactions={interactions ?? []}
                            memberId={memberId}
                            memberPortalConfig={memberPortalConfig}
                            onInteractionSelect={(interaction) => {
                                sendCustomEvent({ eventType: AUDITEVENTTYPE.MemberPortalGetSingleMessages });
                                setCurrentInteraction(interaction);
                                setShowMessage(true);
                            }}
                        />
                    </div>
                )}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={6000}
                    open={alert ? true : false}
                    onClose={() => {
                        setAlert(null);
                    }}
                >
                    <Alert
                        onClose={() => {
                            setAlert(null);
                        }}
                        severity={alert?.severity}
                    >
                        {alert?.message}
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        </div>
    );
};

export default withFeatureFlags(withInteractionData(withMemberPortalConfigData(MessageCenter)));
