import React from 'react';
import PropTypes from 'prop-types';
import PreferenceOptionRadio from './PreferenceOptionRadio';

const PreferenceOptionRadios = (props) =>
    props.options.map((option) => (
        <PreferenceOptionRadio
            key={`${option.campaignName}_${option.communicationType}`}
            option={option}
            {...props}
        />
    ));

export default PreferenceOptionRadios;

PreferenceOptionRadios.propTypes = {
    setChanged: PropTypes.func,
    options: PropTypes.array,
};
