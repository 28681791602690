import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';

const configurationService = {
    getFeatureFlags$: () =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: 'api/config/feature-flags',
                    headers,
                })
            )
        ),
    getMemberPortalConfig$: () =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({ method: 'GET', url: 'api/config/memberPortalConfig', headers })
            )
        ),
};

export default configurationService;
