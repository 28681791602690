import { ajax } from 'rxjs/ajax';
import { map, switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { getSearchParamsString } from 'common/utility';
import { HealthRiskAssessment } from 'interfaces/HealthRiskAssessment';

const healthRiskAssessmentService = {
    submitHealthRiskAssessment$: (HraData: HealthRiskAssessment) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/HealthRiskAssessment`,
                    headers,
                    body: HraData,
                }).pipe(map((response) => response))
            )
        ),
    getHealthRiskAssessmentPdf$: (applicationId: string, storageBlobId: string) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/HealthRiskAssessment/download?${getSearchParamsString({
                        applicationId,
                        storageBlobId,
                    })}`,
                    headers,
                    responseType: 'arraybuffer',
                }).pipe(map((response) => response.response))
            )
        ),
};

export { healthRiskAssessmentService };
