import { useFeatureFlagsContext } from 'contexts/WithFeatureFlags';
import React, { ComponentType } from 'react';
 
const NEXT_UI_FEATURE_FLAG = 'ava-member-ux-next-version';
 
export const withUiVersion =
<TPrevComponentProps extends {}, TNextComponentProps extends {}>(
        PrevComponent?: ComponentType<TPrevComponentProps> | null,
        NextComponent?: ComponentType<TNextComponentProps> | null,
    ) =>
    (props: TNextComponentProps & TPrevComponentProps) => {
        const { featureFlags, areFeatureFlagLoading } = useFeatureFlagsContext();
        const nextUiFeatureFlag = featureFlags[NEXT_UI_FEATURE_FLAG];
        if(areFeatureFlagLoading) {
            return null;
        }
        return nextUiFeatureFlag ? (
            NextComponent ? <NextComponent {...props} /> : null
        ) : (
            PrevComponent ? <PrevComponent {...props} /> : null
        );
    };