import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './CheckboxQuestion.module.scss';
import { handleEnterKey } from '../../../common/accessibilityHelpers';

class CheckboxQuestion extends PureComponent {
    static displayName = CheckboxQuestion.name;

    static propTypes = {
        question: PropTypes.shape({
            info: PropTypes.object,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        option: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        forwardedRef: PropTypes.object,
    };

    render() {
        const { question, option, forwardedRef, disabled } = this.props;
        return (
            <>
                <input
                    data-testid={question.id}
                    className={styles.checkbox}
                    type="checkbox"
                    checked={this.props.value === option.value}
                    name={option.label}
                    onChange={(_) => this.props.onChange(option.value)}
                    id={option.label}
                    aria-labelledby={`label-question-checkbox-${option.label}`}
                    disabled={disabled}
                />
                <p
                    data-testid="question-info"
                    className={styles.label}
                    id={`label-question-checkbox-${question.id}`}
                    tabIndex="0"
                    onKeyUp={handleEnterKey((_) => this.props.onChange(option.value))}
                    ref={forwardedRef}
                >
                    {question.info}
                </p>
            </>
        );
    }
}

export default CheckboxQuestion;
