import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ProviderMedicalGroups from './ProviderMedicalGroups';
import { AHRecord, AHRecordAddress, Providers } from 'interfaces/Provider';
import { MedicalGroup } from 'components/pages/PcpChange/PcpChange';

interface ProviderMedicalGroupsDialogProps {
    effectiveDate: string;
    isDialogOpen: boolean;
    onDialogClose: () => void;
    onMedicalGroupSelect: (medicalGroup: MedicalGroup | null) => void;
    provider: Providers | null;
}

const ProviderMedicalGroupsDialog = ({
    effectiveDate,
    isDialogOpen,
    onDialogClose,
    onMedicalGroupSelect,
    provider,
}: ProviderMedicalGroupsDialogProps) => {
    const [selectedMedicalGroup, setSelectedMedicalGroup] = useState<MedicalGroup | null>(null);

    return (
        <Dialog fullWidth open={isDialogOpen} maxWidth="md">
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <p className="mb-0">Select a Medical Group</p>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            onDialogClose();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <ProviderMedicalGroups
                    effectiveDate={effectiveDate}
                    onSelectMedicalGroup={(medicalGroup: MedicalGroup) => {
                        setSelectedMedicalGroup(medicalGroup);
                    }}
                    provider={provider}
                    selectedMedicalGroup={selectedMedicalGroup}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        onMedicalGroupSelect(selectedMedicalGroup);
                    }}
                >
                    Select
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProviderMedicalGroupsDialog;
