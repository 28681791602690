import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';

const claimService = {
    get$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/enrollment/claims?pageSize=50`,
                headers,
            })
        )
    ),
    getClaimServiceLines$: (claimNumber) => jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/enrollment/claims/claimservicelines?claimNumber=${claimNumber}`,
                headers,
            })
        )
    ),
    getById$: (id) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/enrollment/claims/${id}`,
                    headers,
                })
            )
        ),
};

export default claimService;
