import { ContactType, Languages, MaritalStatus, Race, Relationship, Sex, YesNo } from './Enums';

const ILLNESSES = [
    { label: 'Abnormal Heart Rhythm', key: 'abnormalHeartRhythm' },
    { label: 'Chronic Pain', key: 'chronicPain' },
    { label: 'Heartburn/GERD', key: 'heartburnGERD' },
    { label: 'Obesity', key: 'obesity' },
    { label: 'Allergies (any)', key: 'allergies' },
    { label: 'Chronic Kidney Disease', key: 'chronicKidneyDisease' },
    { label: 'Heart Murmur', key: 'heartMurmur' },
    { label: 'Osteoporosis', key: 'osteoporosis' },
    { label: 'Anemia', key: 'anemia' },
    { label: 'Depression', key: 'depression' },
    { label: 'Hepatitis', key: 'hepatitis' },
    { label: 'Peripheral Vascular Disease', key: 'peripheralVascularDisease' },
    { label: 'Anxiety/Stress', key: 'anxietyStress' },
    { label: 'Diabetes', key: 'diabetes' },
    { label: 'High Blood Pressure', key: 'highBloodPressure' },
    { label: 'Seizures/Epilepsy', key: 'seizuresEpilepsy' },
    { label: 'Asthma', key: 'asthma' },
    { label: 'Emphysema/COPD', key: 'emphysemaCOPD' },
    { label: 'High Cholesterol', key: 'highCholesterol' },
    { label: 'Sleep Apnea', key: 'sleepApnea' },
    { label: 'Arthritis', key: 'arthritis' },
    { label: 'Gallbladder Disease', key: 'gallbladderDisease' },
    { label: 'HIV/AIDS', key: 'hivAIDS' },
    { label: 'Stomach Ulcers', key: 'stomachUlcers' },
    { label: 'Atrial Fibrillation', key: 'atrialFibrillation' },
    { label: 'Gout', key: 'gout' },
    { label: 'Irritable Bowel Syndrome', key: 'irritableBowelSyndrome' },
    { label: 'Stroke', key: 'stroke' },
    { label: 'Colitis or Crohn’s Disease', key: 'colitisOrChronsDisease' },
    { label: 'Headaches/Migraines', key: 'headachesMigraines' },
    { label: 'Kidney Failure', key: 'kidneyFailure' },
    { label: 'Thyroid Disease', key: 'thyroidDisease' },
    { label: 'Cancer', key: 'cancer' },
    { label: 'Heart Attack/Failure', key: 'heartAttackFailure' },
    { label: 'Kidney Stones', key: 'kidneyStones' },
];
const CONDITIONS = [
    { label: 'Cancer', key: 'cancer' },
    { label: 'Diabetes', key: 'diabetes' },
    { label: 'Heart Attack', key: 'heartAttack' },
    { label: 'High Blood Pressure', key: 'highBloodPressure' },
    { label: 'High Cholesterol', key: 'highCholesterol' },
    { label: 'Stroke', key: 'stroke' },
    { label: 'Other', key: 'other' },
];
const FAMILY = [
    { label: 'Condition', key: 'condition' },
    { label: 'Mother', key: 'mother' },
    { label: 'Father', key: 'father' },
    { label: 'Maternal Grandparents', key: 'maternalGrandparents' },
    { label: 'Paternal Grandparents', key: 'paternalGrandparents' },
    { label: 'Brother 1', key: 'brother1' },
    { label: 'Brother 2', key: 'brother2' },
    { label: 'Sister 1', key: 'sister1' },
    { label: 'Sister 2', key: 'sister2' },
    { label: 'Additional Sibling(s)', key: 'additionalSiblings' },
];
const HEALTH_TESTS = [
    { label: 'Colonoscopy', key: 'colonoscopy' },
    { label: 'Cholesterol Screening', key: 'cholesterolScreening' },
    { label: 'Cardiac Stress Test', key: 'cardiacStressTest' },
    { label: 'Bone Density', key: 'boneDensity' },
    { label: 'Mammogram', key: 'mammogram' },
    { label: 'Breast Exam', key: 'breastExam' },
];
const VACCINE_TYPES = [
    { label: 'Tetanus (Td)', key: 'tetanus' },
    { label: 'Pneumonia', key: 'pneumonia' },
    { label: 'Hepatitis B', key: 'hepatitisB' },
    { label: 'Influenza (Flu)', key: 'influenza' },
    { label: 'Shingles', key: 'shingles' },
    { label: 'Other', key: 'other' },
];
const DEVICES = [
    { label: 'Cane', key: 'cane' },
    { label: 'Walker', key: 'walker' },
    { label: 'Bi-pap (sleep apnea)', key: 'biPap' },
    { label: 'Electronic Scooter', key: 'electronicScooter' },
    { label: 'Wheelchair', key: 'wheelchair' },
    { label: 'C-pap (sleep apnea)', key: 'cPap' },
];
const SPECIALITIES = [
    { label: 'Allergy/Immunology', key: 'allergyImmunology' },
    { label: 'Hematology', key: 'hematology' },
    { label: 'Pain Management', key: 'painManagement' },
    { label: 'Cardiology', key: 'cardiology' },
    { label: 'Nephrology', key: 'nephrology' },
    { label: 'Podiatry', key: 'podiatry' },
    { label: 'Chiropractor', key: 'chiropractor' },
    { label: 'Neurology', key: 'neurology' },
    { label: 'Psychiatry/Mental Health', key: 'psychiatryMentalHealth' },
    { label: 'Dental', key: 'dental' },
    { label: 'OB/GYN', key: 'obGYN' },
    { label: 'Pulmonary Medicine', key: 'pulmonaryMedicine' },
    { label: 'Dermatology', key: 'dermatology' },
    { label: 'Oncology', key: 'oncology' },
    { label: 'Rheumatology', key: 'rheumatology' },
    { label: 'Endocrinology', key: 'endocrinology' },
    { label: 'Ophthalmologist', key: 'ophthalmologist' },
    { label: 'Sleep Medicine', key: 'sleepMedicine' },
    { label: 'Gastroenterology', key: 'gastroenterology' },
    { label: 'Optometrist', key: 'optometrist' },
    { label: 'Urology', key: 'urology' },
    { label: 'General Surgery', key: 'generalSurgery' },
    { label: 'Orthopedics', key: 'orthopedics' },
    { label: 'Other Specialty', key: 'otherSpecialty' },
];

const RACES = [
    { label: 'American Indian or Alaska Native', value: Race.AMERICAN_INDIAN_ALASKA },
    { label: 'Asian', value: Race.ASIAN },
    { label: 'Native Hawaiian or Other Pacific', value: Race.NATIVE_HW_PACIFIC },
    { label: 'Black', value: Race.BLACK },
    { label: 'White', value: Race.WHITE },
    { label: 'Hispanic', value: Race.HISPANIC },
    { label: 'Other', value: Race.OTHER },
    { label: 'Decline to Answer', value: Race.DECLINE_TO_ANSWER },
];
const LANGUAGES = [
    { label: 'English', value: Languages.ENGLISH },
    { label: 'Spanish', value: Languages.SPANISH },
];
const MARITAL_STATUSES = [
    { label: 'Single', value: MaritalStatus.SINGLE },
    { label: 'Married', value: MaritalStatus.MARRIED },
    { label: 'Divorced', value: MaritalStatus.DIVORCED },
    { label: 'Widowed', value: MaritalStatus.WIDOWED },
    { label: 'Other', value: MaritalStatus.OTHER },
];
const RELATIONSHIPS = [
    { label: 'Self', value: Relationship.SELF },
    { label: 'Spouse', value: Relationship.SPOUSE },
    { label: 'Guardian', value: Relationship.GUARDIAN },
    { label: 'Other', value: Relationship.OTHER },
];
const ETHNICITIES = [
    { label: 'Hispanic', value: YesNo.YES },
    { label: 'Non-Hispanic', value: YesNo.NO },
];
const SEXES = [
    { label: 'Male', value: Sex.MALE },
    { label: 'Female', value: Sex.FEMALE },
];
const YES_NO_NA = [
    { label: 'Yes', value: YesNo.YES },
    { label: 'No', value: YesNo.NO },
];
const CONTACT_METHODS = [
    { label: 'Home Phone', value: ContactType.HOME_PHONE },
    { label: 'Mobile Phone', value: ContactType.MOBILE_PHONE },
];

export {
    ILLNESSES,
    CONDITIONS,
    FAMILY,
    HEALTH_TESTS,
    VACCINE_TYPES,
    DEVICES,
    SPECIALITIES,
    RACES,
    LANGUAGES,
    MARITAL_STATUSES,
    RELATIONSHIPS,
    ETHNICITIES,
    SEXES,
    YES_NO_NA,
    CONTACT_METHODS,
};
