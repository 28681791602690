import React from 'react';
import paymentsImg from '../../../assets/Payments.png';
import { Banner } from 'components/v2/organisms/Banner/Banner';

interface PaymentsProps {}

export const Payments = ({}: PaymentsProps) => {
    return (
        <div>
            <Banner
            width={"40%"}
                image={paymentsImg}
                title="PAYMENTS"
                description="Welcome to your payment overview, where we demystify the financial aspect of your care journey. We understand that navigating healthcare costs can be complex, and our goal is to provide you with clarity and confidence in managing your payments."
                color="#68C8F7"
            />
        </div>
    );
};
