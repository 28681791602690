import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { faVideo, faPhone } from '@fortawesome/free-solid-svg-icons';
import { pick } from 'lodash';
import FormHOC from '../../Form/FormHOC';
import RadioQuestion from '../../molecules/input/RadioQuestion';

const FormContent = ({ onSubmit, state, props, ...rest }) => {
    const { handleSubmit, control } = useFormContext();
    const [allowUserToContinue, setAllowUserToContinue] = useState(false);
    const [reasonField, setReasonField] = useState(state.reason);
    const [typeField, setTypeField] = useState(state.type);

    useEffect(() => {
        if (reasonField !== '' && typeField !== '') {
            setAllowUserToContinue(true);
        }
    }, [state.reason, state.type, reasonField, typeField]);

    const handleChange = (value, field) => {
        if (field === 'reason') {
            setReasonField(value);
        }
        if (field === 'type') {
            setTypeField(value);
        }
    };

    const reasons = state.isAppointmentForm ? props.reasons : props.requestReasons;

    return (
        <>
            <div className="mt-5 mb-5">
                <Controller
                    control={control}
                    name="reason"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                        <RadioQuestion
                            onChange={(e) => {
                                onChange(e);
                                handleChange(e, 'reason');
                            }}
                            onBlur={onBlur}
                            value={value}
                            question="What is the reason for your visit?"
                            options={reasons.map((reason) => ({
                                label: reason.description,
                                value: reason,
                            }))}
                        />
                    )}
                />
            </div>
            <div className="mt-5 mb-5">
                <Controller
                    control={control}
                    name="type"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                        <RadioQuestion
                            onChange={(e) => {
                                onChange(e);
                                handleChange(e, 'type');
                            }}
                            onBlur={onBlur}
                            value={value}
                            question="How would you like to speak to a doctor?"
                            options={[
                                {
                                    label: 'Video Visit',
                                    value: 0,
                                    icon: faVideo,
                                },
                                {
                                    label: 'Phone Visit',
                                    value: 1,
                                    icon: faPhone,
                                },
                            ]}
                        />
                    )}
                />
            </div>
            <div className="text-center mt-5">
                <Button
                    onClick={() => {
                        rest.history.push({
                            pathname: '/virtual-care',
                        });
                    }}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="primary"
                    size="lg"
                    disabled={!allowUserToContinue}
                >
                    Next
                </Button>
            </div>
        </>
    );
};

const AppointmentFirstStep = ({ state, onSubmit, props, ...rest }) => {
    const valuePaths = ['reason', 'type'];
    return (
        <FormHOC formDefaultValues={pick(state, valuePaths)}>
            <FormContent onSubmit={onSubmit} state={state} props={props} {...rest} />
        </FormHOC>
    );
};

export default withRouter(AppointmentFirstStep);
