import React, { useState, useEffect, useContext, useCallback } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import {
    PdfViewerComponent,
    Toolbar,
    Magnification,
    Navigation,
    Print,
    TextSelection,
    TextSearch,
    Inject,
} from '@syncfusion/ej2-react-pdfviewer';
import { PDFViewerContext } from 'contexts/PdfViewerContext';
import { accessToken$ } from 'services/subjects';
import pdfViewerClassNames from './PDFViewer.module.scss';
import Button from '@mui/material/Button';

export const PDFViewer = () => {
    const { dialogOpen, documents, setDialogOpen, removeDocument } = useContext(PDFViewerContext);
    const [value, setValue] = useState(0);
    const [accessToken, setAccessToken] = useState(0);

    const handleChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }, []);

    const handleClose = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const handleClickMyDocuments = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const handleClickClose = useCallback((documentPath: string) => {
        removeDocument(documentPath);
    }, []);

    useEffect(() => {
        accessToken$.subscribe({
            next: (token) => setAccessToken(token),
        });
    }, []);

    useEffect(() => {
        if (!documents) return;
        setValue(documents.length - 1);
    }, [documents]);

    return (
        <div className={pdfViewerClassNames.PDFViewer}>
            <div className={pdfViewerClassNames['floating-btn-container']}>
                {!!documents.length && (
                    <Button
                        variant="contained"
                        className={pdfViewerClassNames['floating-btn']}
                        onClick={handleClickMyDocuments}
                    >
                        My Documents
                        <DescriptionIcon />
                    </Button>
                )}
            </div>
            <Dialog onClose={handleClose} open={dialogOpen} className={pdfViewerClassNames.dialog}>
                <Box className={pdfViewerClassNames.box}>
                    <Box>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {documents.map((doc) => (
                                <Tab
                                    className={pdfViewerClassNames.tab}
                                    key={doc.documentPath}
                                    label={
                                        <span>
                                            {doc.displayName}
                                            <CloseIcon
                                                className={pdfViewerClassNames['tab-close-btn']}
                                                onClick={handleClickClose.bind(
                                                    {},
                                                    doc.documentPath
                                                )}
                                            />
                                        </span>
                                    }
                                />
                            ))}
                        </Tabs>
                    </Box>
                    {documents.map(
                        (doc, idx) =>
                            value === idx && (
                                <Box key={idx} className={pdfViewerClassNames['dialog-body']}>
                                    <PdfViewerComponent
                                        id="container"
                                        documentPath={doc.documentPath}
                                        serviceUrl={doc.serviceUrl}
                                        className={pdfViewerClassNames.pdfviewer}
                                        ajaxRequestSettings={{
                                            ajaxHeaders: [
                                                {
                                                    headerName: 'Authorization',
                                                    headerValue: `Bearer ${accessToken}`,
                                                },
                                            ],
                                        }}
                                    >
                                        <Inject
                                            services={[
                                                Toolbar,
                                                Magnification,
                                                Navigation,
                                                Print,
                                                TextSelection,
                                                TextSearch,
                                            ]}
                                        />
                                    </PdfViewerComponent>
                                </Box>
                            )
                    )}
                </Box>
            </Dialog>
        </div>
    );
};
