import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';

const labService = {
    get$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({
                method: 'GET',
                url: `api/labresults`,
                headers,
            })
        )
    ),
};

export default labService;
