import { SVGProps, FunctionComponent } from 'react';
import { ReactComponent as HomeIcon } from 'icons/v2/home.svg';
import { ReactComponent as CareIcon } from 'icons/v2/book-an-appointment.svg';
import { ReactComponent as MyBenefitsIcon } from 'icons/v2/my-benefits.svg';
import { ReactComponent as MyHealthIcon } from 'icons/v2/my-health.svg';
import { ReactComponent as MyPrescriptionsIcon } from 'icons/v2/my-prescriptions.svg';
import { ReactComponent as MyPaymentsIcon } from 'icons/v2/my-payments.svg';
import { ReactComponent as FormAndResourcesIcon } from 'icons/v2/forms-and-resources.svg';
export type NavItem = {
    subMenuTitle?: string;
    to: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    description?: string;
    bgIconColor: string;
    children?: {
        title: string;
        to: string;
    }[];
}

export const navItems: Record<
    string,
    NavItem
> = {
    HOME: {
        icon: HomeIcon,
        bgIconColor: '#EDF3FF',
        to: './',
    },
    'MY BENEFITS': {
        subMenuTitle: 'My Benefits Overview',
        icon: MyBenefitsIcon,
        bgIconColor: '#FFE8C7',
        to: '/benefits',
        description:
            'Explore your health plan which is designed to guide you on your journet to better health and well-being.',
        children: [
            {
                title: 'My ACCESS Card Benefits',
                to: '/benefits#',
            },
            {
                title: 'Healthy Rewards',
                to: '/healthy-rewards',
            },
            {
                title: 'OTC Mail Order',
                to: '/benefits#',
            },
            {
                title: 'My Plan Benefits',
                to: '/benefits#',
            },
            {
                title: 'My Plan Documents',
                to: '/benefits#',
            },
        ],
    },
    'GET CARE': {
        subMenuTitle: 'Get Care Overview',
        icon: CareIcon,
        to: '/virtual-care',
        bgIconColor: '#D8EEF1',
        description:
            'Quickly an efficently access the care you need - connect with a clinician, find a provider, and schedule transportation to visits',
        children: [
            {
                title: 'Book an Appointment',
                to: '/virtual-care#',
            },
            {
                title: 'Get 24/7 Urgent Care',
                to: '/virtual-care#',
            },
            {
                title: 'Find a Provider',
                to: '/virtual-care#',
            },
            {
                title: 'My Appointments',
                to: '/virtual-care#',
            },
            {
                title: 'Schedule Transportation',
                to: '/virtual-care#',
            },
        ],
    },
    'MY HEALTH': {
        subMenuTitle: 'My Health Overview',
        icon: MyHealthIcon,
        bgIconColor: '#EAF5DC',
        to: '/health-summary',
        description:
            'View you health history, See latest visits and lab results, check authorization status and review vitals',
        children: [
            {
                title: 'My Lab Results',
                to: '/health-summary#',
            },
            {
                title: 'My Authorizations',
                to: '/health-summary#',
            },
            {
                title: 'My Past Visits',
                to: '/health-summary#',
            },
            {
                title: 'Vitals',
                to: '/health-summary#',
            },
        ],
    },
    PRESCRIPTIONS: {
        subMenuTitle: 'Prescriptions Overview',
        icon: MyPrescriptionsIcon,
        to: '/',
        bgIconColor: '#FDE1D8',
        description:
            'View and manage your prescriptions, find a pharmacy for a convinient refill, review your medications and access key resources and forms',
        children: [
            {
                title: 'My Prescriptions',
                to: '/prescriptions',
            },
            {
                title: 'Find a Pharmacy',
                to: '/',
            },
            {
                title: 'Find Medication',
                to: '/',
            },
        ],
    },
    PAYMENTS: {
        subMenuTitle: 'Payments Overview',
        bgIconColor: '#FDF4CF',
        to: '/payments',
        icon: MyPaymentsIcon,
        description:
            'Check your out-of-pocket-status, view and pay your premium, and see your payent and claims history',
        children: [
            {
                title: 'Pay my Premium',
                to: '/',
            },
            {
                title: 'My Payment History',
                to: '/',
            },
            {
                title: 'View my Claims',
                to: '/',
            },
            {
                title: 'Maximum Out-Of-Pocket Expenses',
                to: '/',
            },
        ],
    },
    'FORMS & RESOURCES': {
        bgIconColor: '#CAF3FF',
        icon: FormAndResourcesIcon,
        subMenuTitle: 'All Documents',
        to: '/plan-documents',
        description:
            'Check your out-of-pocket-status, view and pay your premium, and see your payent and claims history',
        children: [
            {
                title: 'Plan and Benefit Resources',
                to: '/plan-documents#',
            },
            {
                title: 'Prescription Resources',
                to: '/plan-documents#',
            },
            {
                title: 'View my Claims',
                to: '/plan-documents#',
            },
            {
                title: 'Maximum Out-Of-Pocket Expenses',
                to: '/',
            },
        ],
    },
};
