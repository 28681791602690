import React from 'react';
import { merge, Subject, timer } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import BaseComponent from 'components/BaseComponent';
import { messageService } from 'services/messageService';
import { memberId$, unreadMessageCount$ } from 'services/subjects';
import { getHasUnread } from 'components/pages/MessageCenter';
import { featureFlagsNames } from 'common/constants';

const poll$ = timer(0, 30000);

const fetchInteractions$ = new Subject();

const listenForInteractions$ = merge(poll$, fetchInteractions$).pipe(
    take(5),
    mergeMap((args) => messageService.getInteractions$())
);

export default function withInteractionData(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.fetchStarted = false;

            this.state = {};
        }

        componentDidMount = () => {
            if (
                this.props.featureFlags &&
                this.props.featureFlags[featureFlagsNames.crmInteractionMessageCenter]
            ) {
                this.beginFetch();
            }
        };

        componentDidUpdate = () => {
            if (
                !this.fetchStarted &&
                this.props.featureFlags &&
                this.props.featureFlags[featureFlagsNames.crmInteractionMessageCenter]
            ) {
                this.beginFetch();
            }
        };

        componentWillUnmount = () => {
            super.componentWillUnmount();
        };

        beginFetch = () => {
            this.fetchStarted = true;
            memberId$.subscribe((memberId) => this.setState({ memberId }));
            listenForInteractions$.subscribe((response) => {
                this.handleInteractionsResponse(response.response);
            });
        };

        refreshInteractions = () => {
            messageService.getInteractions$().subscribe({
                next: (response) => {
                    this.handleInteractionsResponse(response.response);
                },
            });
        };

        handleInteractionsResponse = (interactions) => {
            this.setState({ interactions });
            if (interactions) {
                const hasUnreadInteractions = interactions.filter((interaction) =>
                    getHasUnread(interaction, this.state.memberId)
                );
                unreadMessageCount$.next(hasUnreadInteractions.length);
            }
        };

        render() {
            return (
                <WrappedComponent
                    interactions={this.state.interactions}
                    refreshInteractions={this.refreshInteractions}
                    {...this.props}
                />
            );
        }
    };
}
