import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { combineLatest } from 'rxjs';
import { memberId$ } from './subjects';

const memberCardService = {
    get$: combineLatest(jsonHeaders$, memberId$).pipe(
        switchMap(([headers, memberId]) =>
            ajax({
                method: 'GET',
                url: `api/enrollment/members/${memberId}/id_card`,
                headers,
            })
        )
    ),
    request$: combineLatest(jsonHeaders$, memberId$).pipe(
        switchMap(([headers, memberId]) =>
            ajax({
                method: 'POST',
                url: `api/enrollment/members/${memberId}/physical_id_card`,
                headers,
            })
        )
    ),
};

export default memberCardService;
