import React, { useState, useEffect } from 'react';
import Inbox from '../organisms/LegacyInbox';
import withMessageData from '../../hoc/withMessageData';
import Message from '../organisms/LegacyMessage';
import PageHeader from '../molecules/PageHeader';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import withMemberPortalConfigData from 'hoc/withMemberPortalConfigData';

const MessageCenter = (props) => {
    const { messages, memberPortalConfig } = props;

    const [subscriptions, setSubscriptions] = useState([]);
    const [currentMessage, setCurrentMessage] = useState();

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalMessageCenter })
            .subscribe();

        setSubscriptions([...subscriptions, trackPageVisitSub]);

        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    return (
        <div>
            <PageHeader>My Message Center</PageHeader>
            {currentMessage ? (
                <Message
                    message={currentMessage}
                    back={() => setCurrentMessage()}
                    memberPortalConfig={memberPortalConfig}
                    {...props}
                />
            ) : (
                <>
                    <p>
                        <span className="bold-text">
                            Thank you for reaching out to your ACCESS On-Demand Concierge team.
                        </span>
                    </p>
                    <p>
                        Please send us your message and we will respond to you within 72 hours. If
                        you need immediate assistance, however, please give us a call at
                        1-833-AHC-ACCESS (1-833-242-2223), TTY 711, 24 hours a day, seven days a
                        week.
                    </p>
                    <div className="mb-5">
                        <Inbox
                            messages={messages}
                            memberPortalConfig={memberPortalConfig}
                            open={setCurrentMessage}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default withMessageData(withMemberPortalConfigData(MessageCenter));
