import React, { useEffect } from 'react';
import { useState } from 'react';
import ssoService from '../services/ssoService';
import { map } from 'rxjs/operators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { accessToken$ } from '../services/subjects';

const SSOHandler = (props) => {
    const [__html, setHtml] = useState();
    console.log(__html);

    const urlParams = new URLSearchParams(window.location.search);

    let partnerName = urlParams.get('partnerName');

    if (partnerName?.includes('otc')) {
        partnerName = 'https://api.otcnetwork.com';
    }

    if (partnerName?.includes('NBSSO2021')) {
        partnerName = 'NBSSO2021OTC';
    }

    const relayUrl = urlParams.get('relayUrl');
    const token = urlParams.get('token');

    if (token) {
        accessToken$.next(token);
    }

    const subscription =
        __html ||
        ssoService
            .fetchSSOForm$(partnerName, relayUrl)
            .pipe(map((response) => setHtml(response)))
            .subscribe();

    useEffect(() => {
        document.forms.samlform && document.forms.samlform.submit();
        return function () {
            subscription.unsubscribe();
        };
    });

    return (
        <>
            <div className="text-center mt-5">
                <FontAwesomeIcon
                    icon={faCircleNotch}
                    aria-label="Currently Loading Content"
                    size="3x"
                />
            </div>
            <div dangerouslySetInnerHTML={{ __html }} />
        </>
    );
};

export default SSOHandler;
