import React, { Component } from 'react';
import { labs$ } from '../services/subjects';
import { map, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import labService from '../services/labService';

export default function withLabData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            const fetchLabssFromServer$ = labService.get$.pipe(
                map((response) => response.response),
                tap((labs) => labs$.next(labs))
            );

            labs$
                .pipe(switchMap((labs) => (labs === null ? fetchLabssFromServer$ : of(labs))))
                .subscribe(
                    (labs) => this.setState({ labs }),
                    (error) =>
                        this.setState({ error: { message: 'Error retrieving lab data' }, labs: [] })
                );
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    };
}
