import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';
import { get } from 'lodash';
import FormHOC from '../../Form/FormHOC';
import { TableInputForm } from 'components/Form/Patient/Input/TableForm';
import moment from 'moment';
import CheckboxWithController from 'components/Form/Patient/Input/Checkbox';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import { DateInputWithController } from 'components/Form/Patient/Input/DateInput';

const FormContent = ({ state, prevPageAction, onSubmit }) => {
    const { control, handleSubmit, errors, setValue, register } = useFormContext();
    return (
        <>
            <Row>
                <Col>
                    <div className="h2 py-3">
                        <strong>
                            <u>Designation of Personal Representative</u>
                        </strong>
                    </div>
                    As required by the Health Insurance Portability and Accountability Act of 1996
                    (“HIPAA”), you have a right to nominate one or more persons to act on your
                    behalf with respect to the protection of health information that pertains to
                    you. By completing this form you are informing us of your wish to designate the
                    named person(s) as your “personal representative.” You may revoke this
                    designation at any time by signing and dating the revocation section of your
                    copy of this form and returning it to this office.
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="h4 py-3">
                        <strong>
                            <u>DESIGNATION SECTION:</u>
                        </strong>
                    </div>
                    <p>
                        I,{' '}
                        <strong className="px-2 h5">
                            <u>
                                {state?.firstName} {state?.lastName}
                            </u>
                        </strong>
                        Date of Birth{' '}
                        <strong className="px-2 h5">
                            <u>{moment(state?.dateOfBirth).format('MM/DD/YYYY')}</u>
                        </strong>{' '}
                        hereby appoint the following person(s) to act as my personal
                        representative(s) with respect to decisions involving the use and/or
                        disclosure of health information that pertains to me.
                    </p>
                    <TableInputForm
                        control={control}
                        state={state}
                        setValue={setValue}
                        register={register}
                        name="designation"
                        title="Designation Section"
                        initialInputs={{ representative: '', relationship: '' }}
                        headers={['Name of Personal Representative(s)', 'Relationship to Patient']}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        The Authority of this person when serving as my “personal representative” is
                        restricted to the following functions:
                    </p>
                    <p>Description:</p>
                    <CheckboxWithController
                        control={control}
                        defaultValue={get(state, `testCheck1`, false)}
                        name={`testCheck1`}
                        question="This person is to be afforded all of the privileges that would be afforded to me with respect to my health information."
                    />
                    <CheckboxWithController
                        control={control}
                        defaultValue={get(state, `testCheck2`, false)}
                        name={`testCheck2`}
                        question="This person is restricted to the following information about my health care:"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div>
                        I understand that I may revoke this designation at any time by signing the
                        revocation section of my copy of this form and returning it to:{' '}
                        <div className="d-flex justify-content-center py-3">
                            Access Primary Care Physicians Inc
                            <br /> 9209 Colima Rd, Ste 400
                            <br /> Whittier, CA 90605
                            <br />
                            Attention: Clinic Manager
                        </div>
                        I further understand that any such revocation does not apply to the extent
                        that persons authorized to use or disclose my health information have
                        already acted in reliance on this designation.
                    </div>
                </Col>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.patientPrintName}
                        name="patientPrintName"
                        question="Patient Name"
                    />
                </Col>
                <Col md={6}>
                    <DateInputWithController
                        control={control}
                        defaultValue={state?.dateOfBirth}
                        name="dateOfBirth"
                        question="Date Of Birth"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="h4 py-3">
                        <strong>
                            <u>REVOCATION SECTION:</u>
                        </strong>
                    </div>
                    <p>
                        I hereby revoke the designation of ________________. as my personal
                        representative
                    </p>
                </Col>
                <Col md={6}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.patientPrintName}
                        name="patientPrintName"
                        question="Patient Name"
                    />
                </Col>
                <Col md={6}>
                    <DateInputWithController
                        control={control}
                        defaultValue={state?.dateOfBirth}
                        name="dateOfBirth"
                        question="Date Of Birth"
                    />
                </Col>
            </Row>
            <hr />

            <div className="d-flex justify-content-center mt-3">
                <div className="d-flex justify-content-around w-75">
                    <Button onClick={prevPageAction} size="lg">
                        Go Back
                    </Button>

                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Save and Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

const DesignationOfRepresentative = ({ state, onSubmit, props, ...rest }) => {
    return (
        <FormHOC>
            <FormContent onSubmit={onSubmit} state={state} {...rest} />
        </FormHOC>
    );
};

export default DesignationOfRepresentative;
