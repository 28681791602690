import './bootstrap-themed.scss';
import 'draft-js/dist/Draft.css';
import 'react-calendar/dist/Calendar.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { ajax } from 'rxjs/ajax';
import { first, map, tap } from 'rxjs/operators';
import { env$, lastUpdated$, config$ } from './services/subjects';
import { initAppInsights } from './ApplicationInsights';
import { ariaFixMsalIframe } from './utils/accessibility';
import * as msal from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';
import { registerLicense } from '@syncfusion/ej2-base';
// Msal imports
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { PDFViewerProvider } from 'contexts/PdfViewerContext';
import { PDFViewer } from 'components/organisms/PDFViewer';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import accountService from 'services/accountService';

const rootElement = document.getElementById('root');
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const urlParams = new URLSearchParams(window.location.search);

const token = urlParams.get('token');
if (token) {
    ReactDOM.render(
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <PDFViewerProvider>
                <BrowserRouter basename={baseUrl}>
                    <App />
                </BrowserRouter>
                <PDFViewer />
            </PDFViewerProvider>
        </LocalizationProvider>,
        rootElement
    );
} else {
    ajax('api/config/b2c')
        .pipe(
            map((response) => response.response),
            tap((config) =>
                env$.next(
                    config.clientId === '1fc70862-cfc0-4348-a13d-66f0cbbbc21b' ? 'prod' : 'dev'
                )
            ),
            tap((config) => {
                registerLicense(config.syncfusionLicenseKey);
            }),
            tap((config) => lastUpdated$.next(config.lastReleaseDate))
        )
        .subscribe((config) => {
            config$.next(config);

            const { clientId, tenantName, signInPolicy, cacheLocation, scopes } = config;
            const tenant = `${tenantName}.onmicrosoft.com`;
            const azureAdB2CHostname = `${tenantName}.b2clogin.com`;
            const authority = `https://${azureAdB2CHostname}/tfp/${tenant}/${signInPolicy}`;

            const msalConfig = {
                auth: {
                    clientId: clientId,
                    authority, // Choose sign-up/sign-in user-flow as your default.
                    knownAuthorities: [azureAdB2CHostname], // You must identify your tenant's domain as a known authority.
                    redirectUri: window.location.origin,
                },
                cache: {
                    cacheLocation, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                                case msal.LogLevel.Error:
                                    console.error(message);
                                    return;
                                case msal.LogLevel.Verbose:
                                    console.debug(message);
                                    return;
                                case msal.LogLevel.Warning:
                                    console.warn(message);
                                    return;
                                case msal.LogLevel.Info:
                                default:
                                    console.info(message);
                                    return;
                            }
                        },
                    },
                },
            };
            const msalInstance = new msal.PublicClientApplication(msalConfig);
            initAppInsights(config.instrumentationKey);
            msalInstance.addEventCallback((event) => {
                if (event.eventType === msal.EventType.LOGIN_SUCCESS) {
                    trackService
                        .customEvent$({
                            eventType: AUDITEVENTTYPE.MemberLoggedIn,
                        })
                        .pipe(first())
                        .subscribe();
                }

                accountService.registerLogin$().pipe(first()).subscribe();
            });
            const loginRequest = { scopes };

            ReactDOM.render(
                <MsalProvider instance={msalInstance}>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={loginRequest}
                    >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <PDFViewerProvider>
                                <BrowserRouter basename={baseUrl}>
                                    <App />
                                </BrowserRouter>
                                <PDFViewer />
                            </PDFViewerProvider>
                        </LocalizationProvider>
                    </MsalAuthenticationTemplate>
                </MsalProvider>,
                rootElement
            );

            ariaFixMsalIframe();
            registerServiceWorker();
        });
}
