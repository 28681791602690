import React, { useEffect, useState } from 'react';
import ConfigurationService from '../services/configurationService';
import { map } from 'rxjs/operators';

function useFeatureFlags() {
    const [featureFlags, setFeatureFlags] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    // set how many hours feature flags will live in session cache before refreshing from the server
    const cacheDuration = 1;

    useEffect(() => {
        var flagsFromSession = getFeatureFlagsFromSessionStorage();

        if (!flagsFromSession || areFlagsExpired(flagsFromSession?.lastUpdatedAt)) {
            var featureFlagsSubscription = getFeatureFlagsFromServer().subscribe((flags) => {
                setFeatureFlags(flags);
                setIsLoading(false);
                var sessionFlags = {
                    lastUpdatedAt: new Date().getTime(),
                    flags,
                };
                window.sessionStorage.setItem('feature-flags', JSON.stringify(sessionFlags));
            });

            return () => featureFlagsSubscription.unsubscribe();
        } else {
            setFeatureFlags(flagsFromSession.flags);
            setIsLoading(false);
        }
    }, []);

    const areFlagsExpired = (lastUpdatedAt) => {
        // bypass caching
        return true;

        /*
        var expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() - cacheDuration);

        return lastUpdatedAt < expirationDate.getTime();
        */
    };
    const getFeatureFlagsFromSessionStorage = () => {
        return JSON.parse(window.sessionStorage.getItem('feature-flags'));
    };

    const getFeatureFlagsFromServer = () => {
        return ConfigurationService.getFeatureFlags$().pipe(
            map((response) => response.response),
            map((flags) => {
                if (!flags) {
                    flags = [];
                }

                var result = {};

                for (var i = 0; i < flags.length; i++) {
                    result[flags[i].name] = flags[i].isActive;
                }

                return result;
            })
        );
    };

    return { featureFlags, isFeatureFlagLoading: isLoading };
}

export default useFeatureFlags;
