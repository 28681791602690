import { Observable } from 'rxjs';
import documentService from 'services/documentService';
import { DocumentHeader } from 'types/documents/DocumentHeader';
import { ExternalDocumentHeader } from 'types/documents/ExternalDocumentHeader';
import { useObservableQuery } from './useObservableQuery';

export const useAdditionalResourcesData = () => {
    const {
        data: additionalResources,
        loading: loadingAdditionalResources,
        errorMessage: additionalResourcesErrorMessage,
        reload: reloadAdditionalResources,
    } = useObservableQuery({
        dependencies: [],
        query: documentService.getAdditionalResources$ as unknown as () => Observable<Array<DocumentHeader | ExternalDocumentHeader>>,
        initialValue: [],
        enabled: true,
    });

    return {
        additionalResources,
        loadingAdditionalResources,
        additionalResourcesErrorMessage,
        reloadAdditionalResources,
    };
};
