import React, { useState, useCallback } from 'react';
import { ColorectalCancerScreening } from '../ColorectalCancerScreening/ColorectalCancerScreening';
import { Loader } from 'components/v2/atoms/Loader/Loader';
import { useHealthyRewardsData } from 'hooks/useHealthyRewardsData';
import { useEffect } from 'react';
import { withAppInsightsErrorBoundary } from 'hoc/withAppInsightsErrorBoundary';
import { Container } from 'components/v2/atoms/Container/Container';
import { HealthyReward } from 'types/healthyRewards/HealthyReward';
import { HealthyRewardEvent } from 'enums/HealthyRewardEvent';

const COLORECTAL_CAMPAIGN_ID = 'HEDIS-C02';

export type HealthyRewardsProps = {
    openFlow: HealthyRewardFlow;
};

export enum HealthyRewardFlow {
    None = 'None',
    Colorectal = 'Colorectal',
}

export const HealthyRewards = withAppInsightsErrorBoundary(
    ({ openFlow }: HealthyRewardsProps) => {
        const [healthyRewardByCampaignIdDict, setHealthyRewardByCampaignIdDict] = useState<
            Record<string, HealthyReward>
        >({});
        const {
            healthyRewards,
            loadingHealthyRewards,
            healthyRewardsErrorMessage,
            reloadHealthyRewards,
        } = useHealthyRewardsData();
        const [selectedFlow, setSelectedFlow] = useState<HealthyRewardFlow>(openFlow);

        const handleClickBack = useCallback(() => {
            setSelectedFlow(HealthyRewardFlow.None);
            reloadHealthyRewards();
        }, []);

        useEffect(() => {
            if (!healthyRewardsErrorMessage) {
                return;
            }
            // TODO implement toast
            console.error(healthyRewardsErrorMessage);
        }, [healthyRewardsErrorMessage]);

        useEffect(() => {
            if (!healthyRewards) {
                return;
            }
            setHealthyRewardByCampaignIdDict(
                healthyRewards.reduce<Record<string, HealthyReward>>(
                    (accumulatedDict, healthyReward) => {
                        if (!accumulatedDict[healthyReward.campaignId]) {
                            accumulatedDict[healthyReward.campaignId] = healthyReward;
                        }
                        return accumulatedDict;
                    },
                    {}
                )
            );
        }, [healthyRewards]);

        if (selectedFlow === HealthyRewardFlow.Colorectal) {
            return (
                <ColorectalCancerScreening
                    onClickBack={handleClickBack}
                    healthyReward={healthyRewards?.find(
                        ({ campaignId }) => campaignId === COLORECTAL_CAMPAIGN_ID
                    )}
                    fobtKitAlreadyRequested={
                        !!(
                            healthyRewardByCampaignIdDict[COLORECTAL_CAMPAIGN_ID] &&
                            healthyRewardByCampaignIdDict[
                                COLORECTAL_CAMPAIGN_ID
                            ].healthyRewardsData?.healthyRewardEvents?.find(
                                (healthyRewardEvent) =>
                                    healthyRewardEvent === HealthyRewardEvent.FOBTKitRequested
                            )
                        )
                    }
                    selfReportAlreadyRequested={
                        !!(
                            healthyRewardByCampaignIdDict[COLORECTAL_CAMPAIGN_ID] &&
                            healthyRewardByCampaignIdDict[
                                COLORECTAL_CAMPAIGN_ID
                            ].healthyRewardsData?.healthyRewardEvents?.find(
                                (healthyRewardEvent) =>
                                    healthyRewardEvent === HealthyRewardEvent.ColRewardSelfReported
                            )
                        )
                    }
                />
            );
        }

        if (loadingHealthyRewards) {
            return <Loader size="lg" container />;
        }
        return (
            <Container>
                <header>
                    <h1 className="page-title">EXCLUSIVE HEALTHY REWARDS</h1>
                </header>
                {healthyRewardByCampaignIdDict[COLORECTAL_CAMPAIGN_ID] && (
                    <div
                        className="body-link"
                        onClick={setSelectedFlow.bind({}, HealthyRewardFlow.Colorectal)}
                    >
                        Colorectal Cancer Screening
                    </div>
                )}
            </Container>
        );
    },
    () => (
        <Container>
            <header>
                <h1 className="page-title">EXCLUSIVE HEALTHY REWARDS</h1>
                <p>An error has ocurred</p>
            </header>
        </Container>
    )
);
