import { ajax } from 'rxjs/ajax';
import { map, publishBehavior, switchMap, take } from 'rxjs/operators';
import { jsonHeaders$ } from './subjects';

export const emergencyContactService = {
    getRelationships$: jsonHeaders$.pipe(
        switchMap((headers) =>
            ajax({ method: 'GET', url: 'api/emergency/contact/relationships', headers })
        ),
        map((response) => response.response),
        publishBehavior()
    ),
    processEmergencyContact$: (formData, memberId) =>
        jsonHeaders$.pipe(
            take(1),
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: 'api/emergency/contact',
                    headers,
                    body: { FormData: formData, MemberId: memberId },
                }).pipe(map((response) => response.description))
            )
        ),
};
