import React from 'react';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import { dateToStr, dateToEnglishStr, getTimeStr, getTimeZone } from '../../common/utility';
import { BiCalendarEvent, BiTimeFive } from 'react-icons/bi';
import { Row, Col } from 'react-bootstrap';
import './DateTime.scss';
import withForwardedRef from '../../hoc/withForwardedRef';

class DateTime extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDate: null,
            selectedTime: null,
            timeSlot: null,
            newDateSelected: false,
        };
    }

    componentDidUpdate = () => {
        if (this.state.newDateSelected) {
            return;
        }
        if (this.props.time) {
            if (this.state.selectedDate !== this.props.time.date) {
                this.setState({ selectedDate: this.props.time.date });
            }
            if (this.state.selectedTime !== this.props.time) {
                this.setState({ selectedTime: this.props.time });
            }
        }
    };

    setSelectedDate = (date) => {
        this.setState({
            selectedDate: date,
            selectedTime: null,
            newDateSelected: true,
        });
        this.props.onChange(null);
    };

    onActiveStartDateChange = (startDate) => {
        this.props.onActiveStartDateChange(startDate);
    };

    setSelectedTime = (time) => {
        const dateTime = { ...time, date: this.state.selectedDate };
        this.setState({ selectedTime: dateTime });
        this.props.onChange(dateTime);
    };

    render() {
        if (!this.props.availableDateTimes) {
            return null;
        }
        return (
            <div className="dt-container">
                <Row className="dt-datepicker-container">
                    <Col lg={6} className="dt-datepicker">
                        <DatePicker
                            ref={this.props.forwardedRef}
                            date={this.state.selectedDate}
                            availableDates={Object.keys(this.props.availableDateTimes)}
                            setSelectedDate={this.setSelectedDate}
                            onActiveStartDateChange={this.onActiveStartDateChange}
                        />
                    </Col>
                    <Col lg={6} className="dt-timepicker">
                        <TimePicker
                            availableTimes={
                                this.props.availableDateTimes
                                    ? this.props.availableDateTimes[
                                          dateToStr(this.state.selectedDate)
                                      ]
                                    : []
                            }
                            setSelectedTime={this.setSelectedTime}
                            doctorName={this.props.doctorName}
                            date={this.state.selectedDate}
                            selectedTime={this.state.selectedTime}
                        />
                    </Col>
                </Row>
                <Row className="dt-picked">
                    <Col className="dt-picked-item" md={6} style={{ fontSize: '1.2em' }}>
                        <BiCalendarEvent size="0.8em" />
                        {dateToStr(this.state.selectedDate) === dateToStr(new Date())
                            ? 'Today,'
                            : ''}{' '}
                        {dateToEnglishStr(this.state.selectedDate)}
                    </Col>
                    <Col className="dt-picked-item" md={6} style={{ fontSize: '1.2em' }}>
                        <BiTimeFive size="0.8em" />
                        {this.state.selectedTime
                            ? `${getTimeStr(this.state.selectedTime)} ${getTimeZone()}`
                            : 'Select available time'}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withForwardedRef(DateTime);
