import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from './CustomCarousel.module.scss';

type data = {
    image: string;
    title: string;
    description: string;
    redirectTo: string;
    buttonText?: string;
    color: string;
};

interface CarouselProps {
    data: data[];
    showControls?: boolean;
    showIndicators?: boolean;
    effect?: string;
    isStatic?: boolean;
    time: number;
}

const CustomCarousel = ({
    data,
    showControls = false,
    showIndicators = true,
    effect = 'slide',
    isStatic = false,
    time = 3000,
}: CarouselProps) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handlePrev = useCallback(() => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
    }, [data.length]);

    const handleNext = useCallback(() => {
        setActiveIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    }, [data.length]);

    useEffect(() => {
        if (isStatic) return;

        const timer = setInterval(
            () => {
                handleNext();
            },
            time ? time : 3000
        );

        return () => clearInterval(timer);
    }, [handleNext]);

    return (
        <div
            className={`${styles['wrapper']} ${effect === 'fade' ? styles[effect] : ''}`}
            tabIndex={0}
        >
            <div className={`${styles['items']}`}>
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`${styles['item']} ${
                            index === activeIndex ? `${styles['active']}` : ''
                        }`}
                        style={{
                            backgroundColor: item.color,
                            transform:
                                effect === 'slide' ? `translateX(-${activeIndex * 100}%)` : '',
                        }}
                    >
                        <div className={styles['text-container']}> 
                        <div className={styles['header']}>{item.title}</div>
                        <div className={styles['description']}>{item.description}</div>
                        {item.buttonText && (
                            <Link to={item.redirectTo}>
                                <button className={styles['custom-button']}>
                                    {item.buttonText}
                                </button>
                            </Link>
                        )}
                        </div>
                        <div> 
                        <img className={styles['image']} src={item.image} alt={item.description} />
                        </div>
                    </div>
                ))}
            </div>

            {showControls && (
                <>
                    <button
                        className={`${styles['control']} ${styles['prev']}`}
                        onClick={handlePrev}
                    >
                        {`<`}
                    </button>
                    <button
                        className={`${styles['control']} ${styles['next']}`}
                        onClick={handleNext}
                    >
                        {`>`}
                    </button>
                </>
            )}

            {showIndicators && (
                <div className={styles['pagination']}>
                    {data.map((_, index) => (
                        <button
                            key={index}
                            className={`${styles['indicator']} ${
                                index === activeIndex ? `${styles['active']}` : ''
                            }`}
                            onClick={() => setActiveIndex(index)}
                        ></button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomCarousel;
