import React, { Component, ComponentType, ReactNode } from 'react';
import { documents$ } from '../services/subjects';
import documentService from '../services/documentService';
import { map, tap, switchMap, filter, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { DocumentHeader } from 'types/documents/DocumentHeader';

export type WithDocumentDataState = {
    documents: DocumentHeader[],
    loadingDocuments: boolean;
};

export default function withDocumentData(WrappedComponent: ComponentType<WithDocumentDataState & any>) {
    return class extends Component<any, WithDocumentDataState> {
        constructor(props: any) {
            super(props);
            this.state = {
                documents: [],
                loadingDocuments: true,
            };
        }

        componentDidMount() {
            const fetchDocumentsFromServer$ = documentService.get$.pipe(
                filter((response) => response && response.response),
                map((response) => response.response.results),
                tap((documents) => documents$.next(documents)),
                first(),
            );

            documents$
                .pipe(
                    switchMap((document) =>
                        document === null ? fetchDocumentsFromServer$ : of(document)
                    )
                )
                .subscribe({
                    next: (documents) =>
                        this.setState({ documents: documents, loadingDocuments: false }),
                    error: (error) => {
                        this.setState({ loadingDocuments: false });
                    },
                });
        }

        render() {
            return (
                <WrappedComponent
                    documents={this.state.documents}
                    loadingDocuments={this.state.loadingDocuments}
                    {...this.props}
                />
            );
        }
    };
}
