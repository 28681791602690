import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withMemberData from '../../hoc/withMemberData';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import AddressForm from '../molecules/AddressForm';
import PageHeader from '../molecules/PageHeader';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import EmergencyContactForm from 'components/molecules/EmergencyContactForm';

const AccountInformation = (props) => {
    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewAccountInformation })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    return (
        <div className="pb-5">
            <PageHeader>My Information</PageHeader>
            <p className="mb-5">
                Your account information is provided for your convenience. If you need to make any
                changes, please call your ACCESS On-Demand Concierge at 1-833-242-2223 (TTY 711), 24
                hours a day, seven days a week.
            </p>
            <Row className="mb-4">
                <Col md="4">
                    <FormGroup>
                        <Label id="label-for-memberId" for="memberId">
                            My Member ID #
                        </Label>
                        <Input
                            aria-labelledby="label-for-memberId"
                            readOnly
                            name="memberId"
                            id="memberId"
                            defaultValue={props.member && props.member.member_number}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label id="label-for-firstName" for="firstName">
                            First Name
                        </Label>
                        <Input
                            aria-labelledby="label-for-firstName"
                            readOnly
                            name="firstName"
                            id="firstName"
                            defaultValue={props.member && props.member.first_name}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label id="label-for-lastName" for="lastName">
                            Last Name
                        </Label>
                        <Input
                            aria-labelledby="label-for-lastName"
                            readOnly
                            name="lastName"
                            id="lastName"
                            defaultValue={props.member && props.member.last_name}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {props.member &&
            (props.member.addresses || []).filter((address) => address.address_type === 'Home')
                .length ? (
                <AddressForm
                    {...props.member.addresses.filter(
                        (address) => address.address_type === 'Home'
                    )[0]}
                />
            ) : null}
            {props.member &&
            (props.member.addresses || []).filter((address) => address.address_type === 'Mailing')
                .length ? (
                <>
                    <AddressForm
                        {...props.member.addresses.filter(
                            (address) => address.address_type === 'Mailing'
                        )[0]}
                    />
                </>
            ) : null}
            <h3>Contact Information</h3>
            <Row className="mb-4">
                <Col md="4">
                    <FormGroup>
                        <Label id="label-for-home_phone" for="home_phone">
                            Home Phone Number
                        </Label>
                        <Input
                            aria-labelledby="label-for-home_phone"
                            readOnly
                            name="home_phone"
                            id="home_phone"
                            defaultValue={props.member && props.member.home_phone}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label id="label-for-cell_phone" for="cell_phone">
                            Mobile Phone Number
                        </Label>
                        <Input
                            aria-labelledby="label-for-cell_phone"
                            readOnly
                            name="cell_phone"
                            id="cell_phone"
                            defaultValue={props.member && props.member.cell_phone}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label id="label-for-email" for="email">
                            Email Address
                        </Label>
                        <Input
                            aria-labelledby="label-for-email"
                            readOnly
                            name="email"
                            id="email"
                            defaultValue={props.member && props.member.email}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <EmergencyContactForm memberId={props.member && props.member.member_number} />
        </div>
    );
};

export default withMemberData(AccountInformation);

export { AccountInformation };

AccountInformation.propTypes = {
    member: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        member_number: PropTypes.string,
        addresses: PropTypes.arrayOf(
            PropTypes.shape({
                address_type: PropTypes.string,
            })
        ),
        cell_phone: PropTypes.string,
        email: PropTypes.string,
        home_phone: PropTypes.string,
        office_phone: PropTypes.string,
    }),
};
