import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { Col } from 'reactstrap';
import styles from './StepBreadcrumb.module.scss';

export default function StepBreadcrumb(props) {
    return (
        <Row>
            <Col>
                <div className={styles['steps-container']}>
                    {props.steps.map((step, i) => (
                        <div
                            key={i}
                            className={`${
                                props.indexAt === i
                                    ? styles['step-selected']
                                    : props.maxIndexAt > i
                                    ? styles['step-completed']
                                    : ''
                            } ${styles.step}`}
                            onClick={() => {
                                props.indexAt >= i && props.handleStepChange(i);
                            }}
                        >
                            <div className={styles['step-number']}>{i + 1}</div>
                            <div className={styles['step-title']}>{step?.title}</div>
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    );
}

StepBreadcrumb.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            step: PropTypes.shape({
                title: PropTypes.string,
            }),
        })
    ),
    indexAt: PropTypes.number,
    maxIndexAt: PropTypes.number,
    handleStepChange: PropTypes.func,
};
StepBreadcrumb.defaultProps = {
    steps: [],
};
