import React, { Component } from 'react';
import { filter } from 'rxjs/operators';
import { emergencyContactService } from 'services/emergencyContactService';
import { emergencyContactInfo$ } from 'services/subjects';

export default function withRelationships(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
            this.subscriptions = [];
        }

        componentDidMount() {
            this.subscriptions.push(
                emergencyContactService.getRelationships$
                    .pipe(filter((relationships) => relationships))
                    .subscribe((relationships) => {
                        this.setState({
                            ...relationships,
                        });
                    })
            );

            emergencyContactService.getRelationships$.connect();

            this.subscriptions.push(
                emergencyContactInfo$
                    .pipe(filter((emergencyContact) => emergencyContact))
                    .subscribe((emergencyContact) => {
                        this.setState({
                            emergencyContact: emergencyContact,
                        });
                    })
            );
        }

        componentWillUnmount() {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    };
}
