import useFeatureFlags from 'hooks/useFeatureFlags';
import React from 'react';

const Feature = (props) => {
    const { featureFlags } = useFeatureFlags();

    const isActive = () => {
        return featureFlags[props.name] ?? false;
    };

    const getComponentToRender = () => {
        if (isActive() && props.children) {
            return props.children;
        }
        return null;
    };

    return <>{featureFlags !== null && getComponentToRender()}</>;
};

export default Feature;
