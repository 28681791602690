import { Stack } from '@mui/material';
import React, { HTMLProps, PropsWithChildren, memo } from 'react';
import withMemberCardData from 'hoc/withMemberCardData';
import { Tile } from 'components/v2/atoms/Tile/Tile';
import classNames from './CardTile.module.scss';
import { withRouter,  } from 'react-router-dom';
import { sendCustomEvent } from 'common/utility';
import { AUDITEVENTTYPE } from 'services/trackService';

export type TileProps = PropsWithChildren<{
    title: string;
    viewDetailsText: string;
    onViewDetailsClick: () => void;
}> &
    HTMLProps<HTMLDivElement>;

export type CardTileProps = {
    frontIdImgSrc: string;
    history: any;
    onViewDetailsClick: () => void;
};

export const CardTile = withRouter(withMemberCardData(({ frontIdImgSrc, history }: CardTileProps) => {
    const handleClickViewDetails = () => {
        history.push('/id-card');
    }

    const handleClickDownload = () => {
        sendCustomEvent({ eventType:  AUDITEVENTTYPE.MemberPortalDownloadCard});
    }
    return (
        <Tile title="MY ID CARD" viewDetailsText="View Details" onViewDetailsClick={handleClickViewDetails} className={classNames.CardTile}>
            <Stack direction="column" justifyContent="center" spacing={2} height="100%">
                <Stack alignItems="center">
                    {frontIdImgSrc && <img src={frontIdImgSrc} alt="Front ID" className={classNames['card-img']} />}
                </Stack>
                {frontIdImgSrc && <a  onClick={handleClickDownload} href={frontIdImgSrc} target='_blank' download="MemberCard.jpeg" className="body-link" rel="noreferrer">Download my ID card</a>}
            </Stack>
        </Tile>
    );
}));
