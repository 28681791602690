import React, { Component } from 'react';
import configurationService from 'services/configurationService';
import { memberPortalConfig$ } from 'services/subjects';

export default function withMemberPortalConfigData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                memberPortalConfig: null,
            };
            this.getMemberPortalConfigSubscription = null;
            this.memberPortalConfigSubject = null;
        }

        componentDidMount() {
            this.getMemberPortalConfigSubscription = configurationService
                .getMemberPortalConfig$()
                .subscribe({
                    next: (response) => {
                        memberPortalConfig$.next(response?.response ?? null);
                    },
                });
            this.memberPortalConfigSubject = memberPortalConfig$.subscribe({
                next: (value) => {
                    this.setState({ memberPortalConfig: value });
                },
            });
        }

        componentWillUnmount() {
            this.getMemberPortalConfigSubscription.unsubscribe();
            this.memberPortalConfigSubject.unsubscribe();
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    memberPortalConfig={this.state.memberPortalConfig}
                />
            );
        }
    };
}
