import React from 'react';
import { get, isEmpty } from 'lodash';
import FieldType from './enums/FieldType';
import { FormField } from './interfaces/Form';
import {
    TextFieldWithController,
    RadiosWithController,
    SelectWithController,
    MultiSelectCheckboxWithController,
} from './Form/Input';
import { useFormContext } from 'react-hook-form';
import { Alert, AlertTitle } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

export interface FormFieldInputProps {
    field: FormField | null;
    formResults: object | null;
    readOnly: boolean;
}

const FormFieldInput = ({ field, formResults, readOnly }: FormFieldInputProps) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    let formField;

    switch (field?.type) {
        case FieldType.DropDownList: {
            const props = {
                control: control,
                name: field.name,
                defaultValue: get(formResults, `${field.name}`, field.value),
                label: field.label,
                options: field.options,
                disabled: readOnly,
                required: field.required,
                errors: null,
            };
            formField = <SelectWithController {...props} />;
            break;
        }
        case FieldType.MultiSelectCheckbox: {
            const props = {
                control: control,
                name: field.name,
                defaultValue: get(formResults, `${field.name}`),
                question: field.label,
                options: field.selections,
                disabled: readOnly,
                required: field.required,
                errors: null,
            };
            formField = <MultiSelectCheckboxWithController {...props} />;
            break;
        }
        case FieldType.Radio: {
            const props = {
                control: control,
                errors: null,
                name: field.name,
                defaultValue: get(formResults, `${field.name}`, field.value),
                question: field.label,
                options: field.options,
                colSize: null,
                disabled: readOnly,
                required: field.required,
            };
            formField = <RadiosWithController {...props} />;
            break;
        }
        case FieldType.TextEditable: {
            const props = {
                control: control,
                name: field.name,
                defaultValue: get(formResults, `${field.name}`, field.value),
                label: field.label,
                type: null,
                error: null,
                onKeyUp: null,
                normalize: null,
                disabled: readOnly,
                required: field.required,
            };
            formField = <TextFieldWithController {...props} />;
            break;
        }
        default:
            break;
    }

    return (
        <>
            {formField ? (
                <div className="w-100">
                    {formField}
                    {get(errors, `${field?.name}`) && (
                        <Alert severity="error">{get(errors, `[${field?.name}].message`)}</Alert>
                    )}
                </div>
            ) : (
                <Alert severity="warning" icon={<HelpOutline />}>
                    <AlertTitle>Whoops!</AlertTitle>
                    There isn&apos;t a form field to display!
                </Alert>
            )}
        </>
    );
};

export default FormFieldInput;
