import React from 'react';
import { object, string } from 'yup';
import { FormTemplate } from '../interfaces/FormTemplate';
import { checkBoxValidation } from '../Form/Input/utils/validation';
import FieldType from '../enums/FieldType';
import { FormField } from '../interfaces/Form';
import { formatKey } from '../Form/Input/utils/format';
import { Segment } from '../interfaces/Segment';
import { Page } from '../interfaces/Page';
import GeneratedForm from '../GeneratedForm';
import { ObjectShape } from 'yup/lib/object';

const checkBoxErrorMsg = 'Please select at least one option';

const generateInitialState = (fields: FormField[] | null | undefined): object | undefined => {
    let initialState: { [key: string]: Object } = {};
    fields?.forEach((field) => {
        if (!field.name) return;
        let defaultValue = field.value;
        switch (field.type) {
            case FieldType.DropDownList:
            case FieldType.Radio:
            case FieldType.TextEditable: {
                initialState[field.name] = defaultValue || '';
                break;
            }
            case FieldType.MultiSelectCheckbox: {
                const values: { [key: string]: Object } = {};
                field.selections?.forEach((option) => {
                    values[formatKey(option)] = false;
                });
                initialState[field.name] = defaultValue || values;
                break;
            }
            default:
                break;
        }
    });
    return initialState;
};

const generateValidators = (formTemplate: FormTemplate | null) => {
    let shape: ObjectShape = {};
    formTemplate?.fields?.forEach((field) => {
        if (field.name && field.required) {
            switch (field.type) {
                case FieldType.MultiSelectCheckbox:
                    shape[field.name] = object().test(field.name, checkBoxErrorMsg, (checkBox) =>
                        checkBoxValidation(checkBox)
                    );
                    break;
                default:
                    shape[field.name] = string().required(`This field is required`);
            }
        }
    });
    return object().shape(shape);
};

const populateValues = (
    fields: FormField[] | null | undefined,
    values: { [key: string]: Object }
): FormField[] | null | undefined => {
    return fields?.map((field) => ({ ...field, value: field.name ? values[field.name] : null }));
};

const generatePages = (segments: Segment[], readOnly: boolean): Page[] | null => {
    return segments.map((segment) => ({
        label: segment.label,
        content: (
            <GeneratedForm
                key={segment.label}
                formTemplateFields={segment.fields}
                readOnly={readOnly}
            />
        ),
    }));
};

export { generateInitialState, generateValidators, populateValues, generatePages };
