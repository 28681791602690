import { ArrowBack, ErrorOutline } from '@mui/icons-material';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => {
    return (
        <div className="not-found">
            <Alert severity="warning" icon={false}>
                <AlertTitle className="not-found-title">
                    <ErrorOutline fontSize="large" htmlColor="#ff9800" />
                    <span>This Page Doesn't Exist!</span>
                </AlertTitle>
                <Link to="/" className="return-home">
                    <ArrowBack />
                    Return to Home Page
                </Link>
            </Alert>
        </div>
    );
};

export default NotFound;
