import { ajax } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { combineLatest } from 'rxjs';
import { memberId$ } from './subjects';

const benefitService = {
    get$: combineLatest(jsonHeaders$, memberId$).pipe(
        switchMap(([headers, memberId]) =>
            ajax({
                method: 'GET',
                url: `api/enrollment/members/${memberId}/benefits`,
                headers,
            })
        )
    ),
    getByPeriod$: (period) =>
        combineLatest(jsonHeaders$, memberId$).pipe(
            switchMap(([headers, memberId]) =>
                ajax({
                    method: 'GET',
                    url: `api/enrollment/members/${memberId}/benefits/${period}`,
                    headers,
                })
            )
        ),
};

export default benefitService;
