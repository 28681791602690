import { NavMenu } from 'components/v2/organisms/NavMenu/NavMenu';
import { Header } from 'components/organisms/Header';
import withMemberData from 'hoc/withMemberData';
import withInteractionData from 'hoc/withInteractionData';
import withFeatureFlags from 'hoc/withFeatureFlags';
import { MemberPortalConfigProvider } from 'contexts/MemberPortalConfigContext';
import React, { useState } from 'react';
import { NavMenuProvider } from 'contexts/v2/NavMenuContext';

interface LayoutProps {
    children: React.ReactNode;
    member?: any
}
const Layoutv2 = ({ children, member, ...props }: LayoutProps) => {
    const [navOpen, setNavOpen] = useState(false);
    return (
        <div className="main-content">
            <MemberPortalConfigProvider member={member}>
                <NavMenuProvider value={{ navOpen, setNavOpen }}>
                    <Header {...props} />
                    <NavMenu {...props} />
                </NavMenuProvider>
                {children}
            </MemberPortalConfigProvider>
        </div>
    );
};

export default withFeatureFlags(withInteractionData(withMemberData(Layoutv2)));
