import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default function Linked(WrappedComponent) {
    return class extends Component {
        render() {
            if (this.props.href) {
                return (
                    <a
                        href={this.props.href}
                        target={this.props.target}
                        rel="noopener noreferrer"
                        className="text-decoration-none"
                        onClick={this.props.onClick}
                    >
                        <WrappedComponent {...this.props} />
                    </a>
                )
            } else if (this.props.onClick && typeof this.props.onClick === 'function') {
                return (
                    <button
                        tabIndex={0}
                        className="button-link text-decoration-none ptr"
                        style={{ color: '#0376a8' }}
                        onClick={this.props.onClick}
                    >
                        <WrappedComponent {...this.props} />
                    </button>
                );
            } else if (this.props.to) {
                return (
                    <Link to={this.props.to} className="text-decoration-none">
                        <WrappedComponent {...this.props} />
                    </Link>
                );
            } else {
                return <WrappedComponent {...this.props} />;
            }
        }
    };
}
