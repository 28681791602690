import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import withAvailabilityData from '../../../hoc/withAvailabilityData';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Modal } from 'react-bootstrap';
import { pick } from 'lodash';
import FormHOC from '../../Form/FormHOC';
import RadioQuestion from '../../molecules/input/RadioQuestion';
import ComboCheckboxQuestion from '../../molecules/input/ComboCheckboxQuestion';
import Calendar from 'react-calendar';
import './DateAndTimeOption.scss';

const FormContent = ({ prevPageAction, onSubmit, state, props, ...rest }) => {
    const { handleSubmit, control, reset, getValues, } = useFormContext();
    const [allowUserToContinue, setAllowUserToContinue] = useState(false);
    const [selectedDate, setSelectedDate] = useState(state.preferredDate);
    const [option, setOption] = useState(state.dateTimeOption);
    const [timeSelected, setTimeSelected] = useState(state.preferredTimeOfDay.length > 0);

    useEffect(() => {
        if (option === 0 || (selectedDate !== '' && timeSelected == true)) {
            setAllowUserToContinue(true);
        } else {
            setAllowUserToContinue(false);
        }
    }, [option, selectedDate, timeSelected]);

    const handleChange = (value, field) => {
        if (field === 'option') {
            setOption(value);
        }
        if (field === 'date') {
            setSelectedDate(value);
        }
        if (field === 'time') {
            setTimeSelected(value);
        }
    };

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        <>
            <div className="mt-5 mb-5">
                <Controller
                    control={control}
                    name="dateTimeOption"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                        <RadioQuestion
                            isColumn
                            onChange={(e) => {
                                if (e == 0) {
                                    reset({
                                        ...state,
                                        dateTimeOption: 0,
                                        preferredDate: '',
                                        preferredTimeOfDay: [],
                                    });
                                    handleChange('', 'date');
                                    handleChange(false, 'time');
                                }
                                onChange(e);
                                handleChange(e, 'option');
                            }}
                            onBlur={onBlur}
                            value={value}
                            question="Choose an option"
                            options={[
                                {
                                    label: 'Get in line for the next available appointment',
                                    note: 'If you need an appointment today, choose this option',
                                    value: 0,
                                },
                                {
                                    label: 'Select Preferred Date and Time of Day',
                                    value: 1,
                                },
                            ]}
                        />
                    )}
                />
            </div>
            <div style={{ display: option == 0 ? 'none' : 'block' }} className="calender-container">
                <p style={{ fontSize: '2rem', fontWeight: 'bold' }}>Select Your Preferred Date</p>
                <p>Note: Same day virtual visits can&apos;t be scheduled online</p>
                <Controller
                    control={control}
                    name="preferredDate"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                        <Calendar
                            calendarType="US"
                            minDate={tomorrow}
                            onChange={(e) => {
                                onChange(e);
                                handleChange(e, 'date');
                            }}
                            value={value}
                        />
                    )}
                />
            </div>
            <div style={{ display: selectedDate == '' ? 'none' : 'block' }}>
                <p style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                    Select Your Preferred Time of Day
                </p>
                <p>(You may select multiple)</p>
                <Controller
                    control={control}
                    name="preferredTimeOfDay"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                        <ComboCheckboxQuestion
                            isColumn={true}
                            onChange={(e) => {
                                if (value.includes(e)) {
                                    const index = value.indexOf(e);
                                    const newValues = [...value];
                                    newValues.splice(index, 1);
                                    if (newValues.length == 0) handleChange(false, 'time');
                                    onChange(newValues);
                                } else {
                                    onChange([...value, e]);
                                    handleChange(true, 'time');
                                }
                            }}
                            onBlur={onBlur}
                            value={value}
                            question="Select your preferred time of day"
                            options={[
                                {
                                    label: 'Morning (8AM-12PM)',
                                    value: 1,
                                },
                                {
                                    label: 'Afternoon (12PM-4PM)',
                                    value: 2,
                                },
                                {
                                    label: 'Evening (4PM-8PM)',
                                    value: 3,
                                },
                                {
                                    label: 'Overnight (8PM-8AM)',
                                    value: 4,
                                },
                            ]}
                        />
                    )}
                />
                <p style={{ marginTop: '1rem', marginBottom: 0, fontWeight: 'bold' }}>
                    Need help finding an appointment?
                </p>
                <p>Call 833 402-5803 for help 24/7</p>
            </div>
            <div className="text-center mt-5">
                <Button
                    onClick={() => {
                        rest.history.push({
                            pathname: '/virtual-care',
                        });
                    }}
                    variant="secondary"
                    size="lg"
                    className="m-4"
                >
                    Cancel
                </Button>
                <Button onClick={() => prevPageAction(getValues())} variant="secondary" size="lg" className="m-4">
                    Back
                </Button>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="primary"
                    size="lg"
                    disabled={!allowUserToContinue}
                >
                    Next
                </Button>
            </div>
        </>
    );
};

const DateAndTimeOption = ({ state, onSubmit, props, ...rest }) => {
    const valuePaths = ['dateTimeOption', 'preferredDate', 'preferredTimeOfDay'];

    return (
        <FormHOC formDefaultValues={pick(state, valuePaths)}>
            <FormContent onSubmit={onSubmit} state={state} props={props} {...rest} />
        </FormHOC>
    );
};

export default withRouter(DateAndTimeOption);
export { DateAndTimeOption };
