import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withMemberCardData from '../../hoc/withMemberCardData';
import { Row, Col, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faDownload } from '@fortawesome/free-solid-svg-icons';
import html2pdf from 'html2pdf.js';
import withPolicyData from '../../hoc/withPolicyData';
import PageHeader from '../molecules/PageHeader';
import { handleEnterKeyToClick } from '../../common/accessibilityHelpers';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { sendCustomEvent } from 'common/utility';

const IdCard = (props) => {
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewIdCard })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    const html2pdfOptions = {
        margin: 1,
        filename: `Alighment Health Plan ${
            props.policies && props.policies.length && props.policies[0].policy_name
        } ID Card.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    return (
        <div>
            <Row className="align-items-baseline">
                <Col xs="9">
                    <PageHeader>
                        <strong>My ID Card</strong>
                    </PageHeader>
                </Col>
                <Col xs="3">
                    {props.frontIdImgSrc && (
                        <span
                            className="ptr mr-2"
                            onClick={() => {
                                var element = document.getElementById('id-card-display');
                                if (!downloading && element) {
                                    setDownloading(true);
                                    sendCustomEvent({eventType: AUDITEVENTTYPE.MemberPortalDownloadCard});
                                    html2pdf()
                                        .set(html2pdfOptions)
                                        .from(element)
                                        .save()
                                        .then(() => setDownloading(false));
                                }
                            }}
                            onKeyUp={handleEnterKeyToClick}
                            tabIndex="0"
                            aria-label="Click to download ID Card"
                        >
                            <div className="float-right">Download</div>
                            <FontAwesomeIcon
                                className="float-right mr-2"
                                icon={downloading ? faCircleNotch : faDownload}
                                aria-label={downloading ? 'Downloading' : 'Click to Download'}
                                color="#333"
                                size="2x"
                            />
                        </span>
                    )}
                </Col>
            </Row>
            <Alert isOpen={props.error !== undefined} color="danger" aria-atomic="true">
                {(props.error || {}).message}
            </Alert>
            {props.error === undefined ? (
                <Row id="id-card-display">
                    <Col md="6">
                        {props.frontIdImgSrc ? (
                            <img
                                id={props.docIds && props.docIds[0]}
                                className="card-shadow"
                                src={props.frontIdImgSrc}
                                width="100%"
                                alt="Access Card front side"
                            />
                        ) : (
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ height: '375px' }}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    aria-label="Currently Loading Content"
                                    size="3x"
                                />
                            </div>
                        )}
                    </Col>
                    <Col md="6">
                        {props.frontIdImgSrc ? (
                            <img
                                id={props.docIds && props.docIds[1]}
                                className="card-shadow"
                                src={props.backIdImgSrc}
                                width="100%"
                                alt="Access Card back side"
                            />
                        ) : (
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ height: '375px' }}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    aria-label="Currently Loading Content"
                                    size="3x"
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            ) : null}
            <div className="mt-5">
                <h5>
                    <strong>What if the information on my card is wrong?</strong>
                </h5>
                <p>
                    If any information on your member ID card is wrong, call your 24/7 ACCESS
                    On-Demand Concierge at your earliest convenience at{' '}
                    <strong>1-833-242-2223</strong> (TTY 711). We will be happy to fix any errors
                    and send you a new one.
                </p>
                <h5>
                    <strong>What if I lose my member ID card?</strong>
                </h5>
                <p>
                    If you lose your ID card and need to show it for care, you can show a printed
                    copy instead. To request a new ID card, or if you are using a screen reader and
                    need assistance, please call your 24/7 ACCESS On-Demand Concierge at{' '}
                    <strong>1-833-242-2223</strong> (TTY 711)
                </p>
                .
            </div>
        </div>
    );
};

export default withPolicyData(withMemberCardData(IdCard));

IdCard.propTypes = {
    idCards: PropTypes.array,
    downloadCard: PropTypes.func,
};
