import React, { Component } from 'react';
import { emergencyContactInfo$, memberAdas$, policies$ } from '../services/subjects';
import { filter } from 'rxjs/operators';
import { plansWithPremiums } from '../common/constants';
import { PlanTypes } from './Enums';

function isActive(start, end) {
    if (!start || !end) return false;

    let startParts = start.split('-');
    if (startParts.length !== 3) return false;

    let endParts = end.split('-');
    if (endParts.length !== 3) return false;
    return (
        new Date(startParts[0], parseInt(startParts[1]) - 1, startParts[2]) < Date.now() &&
        new Date(endParts[0], endParts[1], endParts[2]) > Date.now()
    );
}

function isFuture(start) {
    if (!start) return false;

    let startParts = start.split('-');
    if (startParts.length !== 3) return false;

    return new Date(startParts[0], parseInt(startParts[1]) - 1, startParts[2]) > Date.now();
}

function isPremium(coverage) {
    return coverage && plansWithPremiums.includes(coverage.plan_code);
}

export default function withPolicyData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
            this.subscriptions = [];
        }

        componentDidMount() {
            this.subscriptions.push(
                memberAdas$.pipe(filter((member) => member)).subscribe((member) => {
                    this.setState({
                        hasOptumBehavioralHealth: member.hasOptumBehavioralHealth,
                        instacartUrl: member.instacartUrl,
                        planType: member.virtualPlanType,
                    });

                    const emergencyContact = {
                        relationship: member.emergencyContactRelation,
                        email: member.emergencyContactEmail,
                        fullName: member.emergencyContactFullName,
                        phone: member.emergencyContactPhoneNumber,
                    };

                    emergencyContactInfo$.next(emergencyContact);
                })
            );
            this.subscriptions.push(
                policies$
                    .pipe(filter((policies) => policies !== undefined))
                    .subscribe((policies) => {
                        //active or soon to be active if new member
                        let activePolicy =
                            policies.find((policy) => policy.policy_status === 'active') ||
                            policies.find((policy) =>
                                (policy.product_coverages || []).find((coverage) =>
                                    isFuture(coverage.member_coverages[0].effective_date)
                                )
                            );
                        let activeMemberCoverage =
                            activePolicy &&
                            activePolicy.product_coverages[0].member_coverages.find(
                                (coverage) =>
                                    isActive(coverage.effective_date, coverage.termination_date) ||
                                    isFuture(coverage.effective_date)
                            );

                        this.setState({
                            policies,
                            activePolicy,
                            activeMemberCoverage,
                            isPremium: isPremium(activeMemberCoverage),
                        });
                    })
            );

            policies$.connect();
            memberAdas$.connect();
        }

        componentWillUnmount() {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    };
}
