import React from 'react';
import { catchError, map } from 'rxjs/operators';
import BaseComponent from '../components/BaseComponent';
import availabilityService from './../services/availabilityService';
import { of } from 'rxjs';

export default function withAppointmentData(WrappedComponent) {
    return class extends BaseComponent {
        constructor(props) {
            super(props);
            this.state = {
                availableDateTimes: null,
                loadingAvailability: false,
            };
        }

        fetchAvailabilityFromServer = (pcpId, reason, body) => {
            this.setState({ loadingAvailability: true });
            const fetchAvailability = availabilityService.get$(pcpId, reason, body).pipe(
                map((response) => response.response),
                map((availableDateTimes) => {
                    var filteredDatetimes = {};
                    for (var key in availableDateTimes) {
                        if (availableDateTimes[key] && availableDateTimes[key].length) {
                            filteredDatetimes = {
                                ...filteredDatetimes,
                                [key]: availableDateTimes[key],
                            };
                        }
                    }
                    return filteredDatetimes;
                }),
                catchError((error) => {
                    this.setState({ availableDateTimes: [], loadingAvailability: false });
                    return of({});
                })
            );

            this.subscriptions.push(
                fetchAvailability.subscribe((availableDateTimes) =>
                    this.setState({ availableDateTimes, loadingAvailability: false })
                )
            );
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    fetchAvailabilityFromServer={this.fetchAvailabilityFromServer}
                />
            );
        }
    };
}
