import React from 'react';
import { weekDays, months } from './constants';
import moment from 'moment-timezone';
import { isNil } from 'lodash';
import { first } from 'rxjs/operators';
import trackService from 'services/trackService';

const MEETING_DATE_FORMAT = 'dddd, MMM DD h:mm a';

export function search(arr, term) {
    if (!arr || !term) return arr;
    return arr.filter(function (obj) {
        return Object.keys(obj).some(function (key) {
            return (
                obj[key] &&
                typeof obj[key] === 'string' &&
                obj[key].toLowerCase().includes(term.toLowerCase())
            );
        });
    });
}
export function openPdfResponseInNewTab(response, contentType) {
    const blob = new Blob([response], { type: contentType ?? 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    document.body.removeChild(link);
}

export function navigateToBlobLink(blob) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export function downloadImage(response) {
    const blob = new Blob([response], { type: 'image/jpeg' });
    navigateToBlobLink(blob);
}

export function downloadFile(response, contentType) {
    const blob = new Blob([response], { type: contentType });
    navigateToBlobLink(blob);
}

export function downloadImages(response) {
    const blob = new Blob(response, { type: 'image/jpeg' });
    navigateToBlobLink(blob);
}

export function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => (binary += String.fromCharCode(b)));

    return window.btoa(binary);
}

export function getComparerFn(field, direction) {
    return (datum1, datum2) => {
        const value1 = byString(datum1, field);
        const value2 = byString(datum2, field);
        return (!value1 ? -1 : !value2 ? 1 : value1 < value2 ? -1 : 1) * direction;
    };
}

export function yyyyddmmTommddyyyy(date) {
    if (!date) {
        return 'N/A';
    }
    var parts = date.split('-');
    return parts.length === 3 ? `${parts[1]}/${parts[2]}/${parts[0]}` : 'N/A';
}

export function dateToStr(date, format) {
    if (!date || !date.getMonth) {
        return 'N/A';
    }
    let year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate();

    if (format === 'm/d/yyyy') {
        return `${month}/${day}/${year}`;
    }
    return `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
}

export const jsDateTimeToString = (dateTime, is24Hour = false) => {
    // default format is M/DD/YYYY h:mm
    const jsDateTime = new Date(dateTime);
    const month = jsDateTime.getMonth() + 1;
    const day = jsDateTime.getDate();
    const year = jsDateTime.getFullYear();
    const hour24 = jsDateTime.getHours();
    let minutes = jsDateTime.getMinutes();
    const meridiem = hour24 < 12 ? 'AM' : 'PM';

    let hour12 = hour24;
    if (hour24 === 0) {
        hour12 = 12;
    }
    if (hour24 > 12) {
        hour12 = hour24 - 12;
    }

    if (minutes.toString().length < 2) {
        minutes = `0${minutes}`;
    }

    let dateString = `${month}/${day}/${year} ${is24Hour ? hour24 : hour12}:${minutes}`;
    if (!is24Hour) {
        dateString += ` ${meridiem}`;
    }

    return dateString;
};

export function dateToEnglishStr(date) {
    if (!date || !date.getMonth) {
        return 'N/A';
    }
    return `${weekDays[date.getDay()]}, ${
        months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
}

export function getTimeStr(time) {
    if (time.hour < 12) {
        return `${time.hour}:${time.minute < 10 ? '0' + time.minute : time.minute} AM`;
    } else {
        return `${time.hour === 12 ? time.hour : time.hour - 12}:${
            time.minute < 10 ? '0' + time.minute : time.minute
        } PM`;
    }
}

export function fromDateTimeToMeetingTime(date, time, format = MEETING_DATE_FORMAT) {
    const startMoment = moment(date).set({
        hour: time?.hour || 0,
        minute: time?.minute || 0,
        second: 0,
    });
    const startTime = startMoment.format(format);
    return {
        startTime,
        endTime: startMoment.add(moment.duration(time?.duration || 0, 'm')).format(format),
    };
}

export function getMeetingTimeStringFromDateTime(date, time) {
    const startMoment = moment(date).set({
        hour: time?.hour || 0,
        minute: time?.minute || 0,
        second: 0,
    });
    return `${startMoment.format(MEETING_DATE_FORMAT)} to ${startMoment
        .add(moment.duration(time?.duration || 0, 'm'))
        .format('h:mm a')}`;
}

export function getMeetingTimeStringFromTimes(startTime, endTime) {
    return `${moment(startTime).format(MEETING_DATE_FORMAT)} to ${moment(endTime).format(
        'h:mm a'
    )}`;
}

export function getDateTimeFormat(datetime, format = MEETING_DATE_FORMAT) {
    return moment(datetime).format(format);
}

export function isNumericInput({ keyCode }) {
    return (
        (keyCode >= 48 && keyCode <= 57) || // allow number line
        (keyCode >= 96 && keyCode <= 105) // allow number pad
    );
}

export function isModifierKey(event) {
    const { keyCode, shiftKey, ctrlKey, metaKey } = event;
    return (
        shiftKey === true ||
        keyCode === 35 ||
        keyCode === 36 || // allow shift, home and end
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 46 || // allow backspace, tab, enter and delete
        (keyCode > 36 && keyCode < 41) || // Allow left, up, right, down
        // allow ctrl/command + a, c, v, x, and z
        ((ctrlKey === true || metaKey === true) &&
            (keyCode === 65 ||
                keyCode === 67 ||
                keyCode === 86 ||
                keyCode === 88 ||
                keyCode === 90))
    );
}

export function enforcePhoneFormat(event) {
    // input must be of a valid number format or a modifier key
    if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault();
    }
}

export function formatToPhone(event) {
    if (isModifierKey(event)) {
        return;
    }
    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // first ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6);

    if (input.length > 6) {
        event.target.value = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
        event.target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
        event.target.value = `(${zip}`;
    }
}
export function isMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export function hasSearchTerm(searchTerm, value, type) {
    let bool = false;
    if (isNil(value)) {
        return bool;
    }
    switch (type) {
        case 'string':
            if (value.toLowerCase().includes(searchTerm)) {
                bool = true;
            }
            break;
        case 'date':
            if (
                String(value).toLowerCase().includes(searchTerm) ||
                String(moment(value).format('MM/DD/YYYY')).toLowerCase().includes(searchTerm) ||
                String(moment(value).format('MM-DD-YYYY')).toLowerCase().includes(searchTerm)
            ) {
                bool = true;
            }
            break;
        default:
            bool = false;
    }
    return bool;
}

export function byString(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot

    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const currencyFormatterNearestDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export function getTimeZone() {
    return moment.tz(moment.tz.guess()).format('z');
}

export const getSearchParamsString = (obj) => new URLSearchParams(obj).toString();

export const getFirstOfMonthRange = (numMonthsInPast, numMonthsInFuture) => {
    const dates = [];
    const today = new Date();
    today.setDate(1);
    const currMonth = today.getMonth();

    for (let i = numMonthsInPast; i > -1 - numMonthsInFuture; i--) {
        const date = new Date(today);
        date.setMonth(currMonth - i);
        if (currMonth < i) {
            date.setFullYear(today.getFullYear() - 1);
        }
        dates.push(date);
    }

    return dates;
};

export const sendCustomEvent = async (eventType) => {
    trackService.customEvent$(eventType).pipe(first()).subscribe();
};

export const sanitizeString = (str) => {
    if (!str) {
        return str;
    }

    const map = {
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;',
    };
    const reg = /[<>"'/]/gi;
    // do ampersands separately so we don't ruin existing encoded entities
    // don't want to end up with &amp;amp;
    const ampReg = /&(?![A-Za-z]+;|#[0-9]+;|#x[0-9a-fA-F]+;)/g;
    return str.replace(reg, (match) => map[match]).replace(ampReg, '&amp;');
};

export const parseMarkdownHyperlink = (str, removeHyperlink = false) => {
    if (!str) {
        return '';
    }

    const reg = /\[([^[]+)\](\(.*\))/gm;

    const getInnerStr = (enclosedStr, type) => {
        const textReg = /\[([^[]+)\]/gm;
        const linkReg = /(\(.*\))/gm;

        const text = enclosedStr.match(type === 'text' ? textReg : linkReg);
        if (text && text.length > 0) {
            return text[0].substring(1, text[0].length - 1);
        }
        return '';
    };

    // doing it like this so i don't have to dangerously set html
    const elements = [];
    let match;
    let startFromIndex = 0;

    while ((match = reg.exec(str))) {
        const matchedStr = str.substring(match.index, reg.lastIndex);
        elements.push({ type: 'text', text: str.substring(startFromIndex, match.index) });
        elements.push({
            type: 'link',
            text: getInnerStr(matchedStr, 'text'),
            link: getInnerStr(matchedStr, 'link'),
        });
        startFromIndex = reg.lastIndex;
    }

    if (elements.length < 1) {
        return str;
    } else {
        if (startFromIndex < str.length) {
            elements.push({ type: 'text', text: str.substring(startFromIndex, str.length) });
        }
    }

    if (removeHyperlink) {
        return elements.map((element) => element.text).join('');
    }

    return elements.map((element, i) => {
        if (element.type === 'text') {
            return <span key={`parseMarkdownHyperlink-${i}`}>{element.text}</span>;
        }
        if (element.type === 'link') {
            return (
                <a
                    href={element.link}
                    key={`parseMarkdownHyperlink-${i}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {element.text}
                </a>
            );
        }
    });
};
