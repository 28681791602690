import React from 'react';
import { Alert } from 'reactstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertMessage = (props) => {
    return (
        <Alert isOpen={props.isOpen} color={props.color} closeClassName="close">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={props.toggle && props.toggle.bind({}, !props.isOpen)}>
                <span aria-hidden="true"><FontAwesomeIcon
                        icon={faTimes}
                        aria-label="Currently Loading Content"
                    /></span>
            </button>
            {props.message}

        </Alert>
    );
};

export default AlertMessage;
