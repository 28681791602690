import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import ContactInfo from '../molecules/ContactInfo';
import PageHeader from '../molecules/PageHeader';

const ContactUs = (props) => {
    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewContactUs })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    return (
        <Container>
            <Row>
                <Col md={{ size: 4, offset: 2 }}>
                    <PageHeader>Call Us</PageHeader>
                </Col>
            </Row>
            <ContactInfo />
        </Container>
    );
};

export default ContactUs;
