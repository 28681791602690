import { Stack } from '@mui/material';
import React, { PropsWithChildren, DetailedHTMLProps, HTMLAttributes } from 'react';
import classNames from './Tile.module.scss';

export type TileProps = PropsWithChildren<{
    title: string;
    className?: string;
    bodySize: 'fixed' | 'normal';
    viewDetailsText?: string;
    onViewDetailsClick?: () => void;
}> &
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export const Tile = ({
    title,
    viewDetailsText,
    onViewDetailsClick,
    className,
    children,
    bodySize,
}: TileProps) => (
    <div className={`${classNames.Tile} ${className || ''}`}>
        <header className={classNames.header}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3 className={`${classNames.title} card-section-title`}>{title}</h3>
                {onViewDetailsClick && (
                    <button
                        onClick={onViewDetailsClick}
                        className={classNames['details-button']}
                        type="button"
                    >
                        {viewDetailsText}
                    </button>
                )}
            </Stack>
        </header>
        <Stack
            direction="column"
            spacing={2}
            className={`${classNames.body} ${bodySize === 'fixed' ? classNames['body-fixed'] : ''}`}
        >
            {children}
        </Stack>
    </div>
);

Tile.defaultProps = {
    bodySize: 'normal',
};

Tile.displayName = 'Tile';
