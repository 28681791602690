import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table, Modal, Form, Dropdown } from 'react-bootstrap';
import { CustomToggle } from 'components/Form/Patient/Input/TableForm';
import { VACCINE_TYPES } from 'components/Form/Patient/Constants';
import { get, isEmpty } from 'lodash';
import { DateInput } from 'components/Form/Patient/Input/DateInput';
import moment from 'moment';
import { TableInputActionsType } from 'components/Form/Patient/Enums';

const VaccineInformation = ({ control, state, setValue, register }) => {
    const name = 'vaccineHistory';
    const headers = ['Vaccine Type', 'Date (year-month)'];
    const initialInputs = { type: VACCINE_TYPES[0].label, date: '' };
    const title = 'Vaccine History';

    const [records, setRecords] = useState({ data: [], rows: 0 });
    const [stagingInputs, setStagingInputs] = useState({ ...initialInputs });
    const [modalVisible, setModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState({ status: false, index: 0 });
    useEffect(() => {
        register(name);
        const stateData = get(state, name, []).map(({ date, ...rest }) => ({
            ...rest,
            date: moment(date).format('YYYY-MM'),
        }));
        if (!isEmpty(stateData)) {
            setRecords({ data: [...stateData], rows: stateData.length });
        }
        setValue(name, [...stateData]);
    }, []);
    const saveAdditions = () => {
        let newRecords = [...records.data, stagingInputs];
        if (isEditing.status) {
            newRecords = [
                ...records.data.slice(0, isEditing.index),
                stagingInputs,
                ...records.data.slice(isEditing.index + 1),
            ];
            setIsEditing({ status: false, index: 0 });
        }
        setValue(name, newRecords);
        setRecords({
            data: newRecords,
        });
        setStagingInputs({ ...initialInputs });
    };
    const updateRecords = (action, position) => {
        switch (action) {
            case TableInputActionsType.DELETE: {
                const newData = [
                    ...records.data.slice(0, position),
                    ...records.data.slice(position + 1),
                ];
                setValue(name, newData);
                setRecords({
                    data: newData,
                });
                return;
            }
            case TableInputActionsType.EDIT:
                setIsEditing({ status: true, index: position });
                setModalVisible(true);
                setStagingInputs(records.data[position]);
                return;
            default:
                return;
        }
    };
    const TableHeaders = () => (
        <thead className="">
            <tr>
                {headers.map((label, idx) => (
                    <th key={`header_${idx}`} className="align-middle text-wrap">
                        {label}
                    </th>
                ))}
                <th className="align-middle text-wrap text-right">
                    <Button
                        onClick={() => {
                            setModalVisible(true);
                        }}
                    >
                        + Add
                    </Button>
                </th>
            </tr>
        </thead>
    );
    const TableContent = useCallback(() => {
        return (
            <tbody className="">
                {records.data.map((record, idx) => (
                    <tr key={`row_${idx}`}>
                        {Object.keys(initialInputs).map((key, idx) => (
                            <td key={`data_${idx}`}>{get(record, key)}</td>
                        ))}

                        <td className="text-right pr-5 text-secondary">
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="">
                                    <Dropdown.Item
                                        onClick={() =>
                                            updateRecords(TableInputActionsType.EDIT, idx)
                                        }
                                    >
                                        Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className="text-danger"
                                        onClick={() =>
                                            updateRecords(TableInputActionsType.DELETE, idx)
                                        }
                                    >
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    }, [records]);
    return (
        <>
            <Table striped>
                <TableHeaders />
                <TableContent />
            </Table>
            <Modal
                show={modalVisible}
                backdrop="static"
                onHide={() => {
                    setModalVisible(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>{headers[0]}</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={({ target }) =>
                                    setStagingInputs((state) => ({ ...state, type: target.value }))
                                }
                                value={stagingInputs.type}
                            >
                                {VACCINE_TYPES.map(({ label, key }, idx) => (
                                    <option key={`test_${idx}`}>{label}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <DateInput
                            question={headers[1]}
                            onChange={(val) => {
                                setStagingInputs((state) => ({ ...state, date: val }));
                            }}
                            value={stagingInputs.date}
                            onlyMonth
                        />

                        <hr />
                        <div className="text-right">
                            <Button
                                variant="secondary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsEditing({ status: false, index: 0 });
                                    setModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveAdditions();
                                    setModalVisible(false);
                                }}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default VaccineInformation;
