import { Subject } from 'rxjs';

const showSubject = new Subject();
const hideSubject = new Subject();

const loadOverlayService = {
    show: (message) => showSubject.next(message),
    show$: showSubject.asObservable(),
    hide: () => hideSubject.next(),
    hide$: hideSubject.asObservable(),
};

export default loadOverlayService;
