import React from 'react';
import styles from './Banner.module.scss';
interface BannerProps {
    title: string;
    color: string;
    description: string;
    image: any;
    width?: string | null;
    //this prop is set for adapt the image style because of different image sizes the banner could be a little different from mockups
}

export const Banner = ({ title, color, description, image, width = null }: BannerProps) => {
    return (
        <div className={styles['banner-container']} style={{ backgroundColor: color }}>
            <div className={styles['banner-text-group']}>
                <h2 className={`${styles['banner-title']} banner-title`}>{title} </h2>
                <p className={styles['banner-description']}>{description} </p>
            </div>
            <img
                className={styles['image']}
                src={image}
                alt={description}
            />
        </div>
    );
};
