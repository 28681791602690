import React, { memo } from 'react';
import classNames from './Container.module.scss';

export type ContainerProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

export const Container = memo(({ children, className, ...restOfProps }: ContainerProps) => (
    <section className={`${classNames.Container} ${className}`} {...restOfProps}>
        {children}
    </section>
));
