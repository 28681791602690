import { MemberNotification } from "types/notifications";

export interface MemberPortalConfig {
    messageCenterCategories: string[];
    messageCenterPcpCategories: string[];
    homeBanners: Banner[];
    memberCardTemplates: any[];
}

export interface MemberPortalConfigContextType {
    memberPortalConfig: MemberPortalConfig;
    memberNotifications: MemberNotification[];
    refreshMemberNotifications: () => void;
}

export type Banner = {
    buttonText: string;
    description: string;
    color: string;
    image: string;
    redirectTo: string;
    title: string;
};

export const MemberPortalConfigInitialValue = {
    memberPortalConfig: {
        homeBanners: [],
        memberCardTemplates: [],
        messageCenterCategories: [],
        messageCenterPcpCategories: [],

    },
    memberNotifications: [],
    refreshMemberNotifications: () => {}
};
