import React, { memo } from 'react';
import classNames from './ColorectalCancerScreening.module.scss';
import { ReactComponent as BackArrow } from 'icons/v2/back-arrow.svg';
import { ColorectalCancerScreeningHaveNotCompleted } from 'components/v2/organisms/ColorectalCancerScreeningHaveNotCompleted/ColorectalCancerScreeningHaveNotCompleted';
import { HealthyReward } from 'types/healthyRewards/HealthyReward';
import { ColorectalCancerScreeningHaveCompleted } from 'components/v2/organisms/ColorectalCancerScreeningHaveCompleted/ColorectalCancerScreeningHaveCompleted';

export type ColorectalCancerScreeningProps = {
    onClickBack: () => void;
    selfReportAlreadyRequested: boolean;
    healthyReward?: HealthyReward;
    fobtKitAlreadyRequested: boolean;
};
export const ColorectalCancerScreening = memo(
    ({
        onClickBack,
        selfReportAlreadyRequested,
        fobtKitAlreadyRequested,
        healthyReward,
    }: ColorectalCancerScreeningProps) => {
        return (
            <main className={classNames.container}>
                <button className={classNames['back-btn']} type="button" onClick={onClickBack}>
                    <BackArrow className={classNames['back-icon']} /> Back
                </button>
                <header>
                    <h1 className="page-title">COLORECTAL CANCER SCREENING</h1>
                </header>
                <p>
                    A colorectal cancer screening is ordered to look for colorectal diseases when a
                    person does not display symptoms. If symptoms are present, diagnostic tests will
                    be performed. Members up to age 86 should be regularly screened for colon cancer
                    and other diseases through a number of different tests including colonoscopies,
                    sigmoidoscopies, FIT DNA tests, and FOBT tests. When caught early, Colon Cancer
                    is treatable in about 90% of people.
                </p>
                <p>
                    Have you had a colonoscopy done within the past 10 years or an FOBT during the
                    current year? Completing this screening can earn you $10 in Healthy Reward
                    dollars.
                </p>
                <p>
                    Once we receive information from your health care provider that you have
                    completed a qualifying activity, Healthy Reward dollars will load onto your
                    Concierge Card within 3 business days.
                </p>
                <ColorectalCancerScreeningHaveNotCompleted
                    fobtKitAlreadyRequested={fobtKitAlreadyRequested}
                />
                <ColorectalCancerScreeningHaveCompleted
                    selfReportAlreadyRequested={selfReportAlreadyRequested}
                    defaultValues={healthyReward?.healthyRewardsData}
                />
            </main>
        );
    }
);
