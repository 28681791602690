import React from 'react';
import BenefitsImag from '../../../assets/My-Benefits.png';
import { Banner } from 'components/v2/organisms/Banner/Banner';

interface BenefitsProps {}

export const Benefits = ({}: BenefitsProps) => {
    return (
                <div>
                <Banner image={BenefitsImag} title="MY BENEFITS" description="Welcome to your comprehensive health care plan overview, designed to guide you on your journey to better health and well-being. Our care plan is a roadmap that ensures your medical needs are met with precision and compassion." color="#FFD03B" />
                </div>
    );
};


