import React from 'react';
import PropTypes from 'prop-types';
import './inboxMessage.css';
import { handleEnterKey } from '../../common/accessibilityHelpers';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const doubleZeroFormat = (number) => ('0' + number).slice(-2);

const isToday = (someDate) => {
    const today = new Date();
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
};

const getTimeString = (date) => {
    return `${((date.getHours() - 1) % 12) + 1}:${doubleZeroFormat(date.getMinutes())} ${
        date.getHours() < 12 ? 'AM' : 'PM'
    }`;
};

const inboxDateString = (date) => {
    if (isToday(date)) {
        return `Today ${getTimeString(date)}`;
    }
    return `${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}`;
};

const InboxMessage = (props) => {
    return (
        <tr
            className={`${props.is_read ? 'gray' : ''} ptr inbox-message`}
            tabIndex="0"
            onKeyUp={handleEnterKey(() => props.clicked(props))}
        >
            <td onClick={(_) => props.clicked(props)}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{props.author_name}</span>
                    <span className="d-md-none">{inboxDateString(new Date(props.updated_at))}</span>
                </div>
                <div className="d-md-none">
                    <span>{props.subject}</span>
                </div>
                <div className="d-md-none">{props.content.substring(0, 20)}</div>
            </td>
            <td className="d-none d-md-table-cell" onClick={(_) => props.clicked(props)}>
                <span>{props.category}</span>
            </td>
            <td className="d-none d-md-table-cell" onClick={(_) => props.clicked(props)}>
                <span>{props.subject}</span> - {props.content.substring(0, 20)}
            </td>
            <td
                className="d-none d-md-table-cell"
                style={{ maxWidth: '200px' }}
                onClick={(_) => props.clicked(props)}
            >
                {inboxDateString(new Date(props.updated_at))}
            </td>
        </tr>
    );
};

InboxMessage.propTypes = {
    clicked: PropTypes.func,
    selected: PropTypes.func,
    deselected: PropTypes.func,
    author_name: PropTypes.string,
    author_user_id: PropTypes.string,
    content: PropTypes.string,
    category: PropTypes.string,
    created_at: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    updated_at: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    id: PropTypes.string,
    reference_id: PropTypes.string,
    is_read: PropTypes.bool,
    is_staff: PropTypes.bool,
    parent: PropTypes.string,
    tags: PropTypes.array,
    status: PropTypes.string,
    subject: PropTypes.string,
    replies: PropTypes.array,
};

export default InboxMessage;
