import { ajax } from 'rxjs/ajax';
import { switchMap, take } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { combineLatest } from 'rxjs';
import { memberId$ } from './subjects';

const preferenceService = {
    getUserPreference$: (preferenceType) =>
        combineLatest(jsonHeaders$, memberId$).pipe(
            take(1),
            switchMap(([headers, memberId]) =>
                ajax({
                    method: 'GET',
                    url: `api/member/memberCommunicationPreference/${memberId}/${preferenceType}`,
                    headers,
                })
            )
        ),
    updateUserPreference$: (preferenceType, preferences) =>
        combineLatest(jsonHeaders$).pipe(
            take(1),
            switchMap(([headers]) =>
                ajax({
                    method: 'POST',
                    url: `api/member/updateCommunicationPreference`,
                    headers,
                    body: {
                        preferences: [{ category: preferenceType, names: preferences }],
                    },
                })
            )
        ),
};

export default preferenceService;
