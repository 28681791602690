import { formatToPhone } from 'common/utility';
import { RELATIONSHIPS } from 'components/Form/Patient/Constants';
import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

const ResponsiblePartyFields = ({ control, state }) => {
    const RelationshipStatusField = useCallback(
        () => (
            <Row>
                <Col>
                    <RadiosWithController
                        defaultValue={state?.responsibleParty.relations}
                        question="Responsible Party"
                        name="responsibleParty.relations"
                        options={RELATIONSHIPS}
                        colSize={12}
                        isHeading
                    />
                </Col>
            </Row>
        ),
        [state]
    );
    return (
        <Row>
            <Col md={3}>
                <RelationshipStatusField />
            </Col>
            <Col>
                <Row>
                    <Col md={5}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={state?.responsibleParty.firstName}
                            name="responsibleParty.firstName"
                            question="First Name"
                            showError={false}
                        />
                    </Col>
                    <Col md={5}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={state?.responsibleParty.lastName}
                            name="responsibleParty.lastName"
                            question="Last Name"
                            showError={false}
                        />
                    </Col>
                    <Col md={2}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={state?.responsibleParty.middleInitial}
                            name="responsibleParty.middleInitial"
                            question="MI"
                            showError={false}
                        />
                    </Col>
                    <Col md={5} className="mt-2">
                        <TextFieldWithController
                            control={control}
                            defaultValue={state?.responsibleParty.phoneNumber}
                            name="responsibleParty.phoneNumber"
                            question="Phone #"
                            showError={false}
                            onKeyUp={formatToPhone}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={5}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={state?.responsibleParty.address}
                            name="responsibleParty.address"
                            question="Address"
                            showError={false}
                        />
                    </Col>
                    <Col md={3}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={state?.responsibleParty.city}
                            name="responsibleParty.city"
                            question="City"
                            showError={false}
                        />
                    </Col>
                    <Col md={2}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={state?.responsibleParty.state}
                            name="responsibleParty.state"
                            question="State"
                            showError={false}
                        />
                    </Col>
                    <Col md={2}>
                        <TextFieldWithController
                            control={control}
                            defaultValue={state?.responsibleParty.zipCode}
                            name="responsibleParty.zipCode"
                            question="Zip"
                            showError={false}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
export default ResponsiblePartyFields;
