import React, { useState } from 'react';
import { lastUpdated$, env$ } from '../../services/subjects';
import { filter, map } from 'rxjs/operators';
import { Container } from 'reactstrap';

const Footer = (props) => {
    const [lastUpdated, setLastUpdated] = useState(false);
    const [prod, setProd] = useState();

    if (lastUpdated === false) {
        lastUpdated$.pipe(filter((lastUpdated) => lastUpdated)).subscribe({
            next: setLastUpdated,
        });
    }

    if (prod === undefined) {
        env$.pipe(map((env) => env === 'prod')).subscribe({
            next: setProd,
        });
    }

    return (
        <div className="footer" role="contentinfo">
            <Container>
                <p>
                    &#169; {new Date().getFullYear()} Alignment Health Plan. All Rights Reserved.{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://${
                            prod ? 'www' : 'test'
                        }.alignmenthealthplan.com/about-us/terms-of-use`}
                    >
                        Privacy Policy &amp; Terms of Use
                    </a>
                </p>
                <p style={{ textAlign: 'center', padding: 0 }}>Last Updated: {lastUpdated}</p>
            </Container>
        </div>
    );
};

export default Footer;

export { Footer };

Footer.propTypes = {};
