import React, { useRef, useState, useEffect, useContext } from 'react';
import classNames from './Headerv2.module.scss';
import { FaBell } from 'react-icons/fa6';
import { DropdownNotification } from 'components/organisms/DropdownNotification/DropdownNotification';
import { Link } from 'react-router-dom';
import { MemberPortalConfigContext } from 'contexts/MemberPortalConfigContext';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {}

const HeaderNotificationsDropdown = ({ history }: Props): any => {
    const { memberNotifications, refreshMemberNotifications } =
        useContext(MemberPortalConfigContext);
    const [isMyInfoOpen, setIsMyInfoOpen] = useState(false);
    const dropdownElementRef = useRef<HTMLDivElement>(null);
    const handleClickOnMyInfo = () => {
        if (memberNotifications.length == 0) {
            history.push({
                pathname: '/notifications',
            });
        } else {
            if (isMyInfoOpen) {
                refreshMemberNotifications();
            }
            setIsMyInfoOpen((currentIsMyInfoOpen) => !currentIsMyInfoOpen);
        }
    };

    const handleClickAway = (event: MouseEvent) => {
        if (
            dropdownElementRef?.current &&
            !dropdownElementRef?.current.contains(event.target as Node)
        ) {
            refreshMemberNotifications();
            setIsMyInfoOpen(false);
        }
    };

    useEffect(() => {
        if (isMyInfoOpen) {
            document.addEventListener('click', handleClickAway);
        }
        return () => {
            document.removeEventListener('click', handleClickAway);
        };
    }, [isMyInfoOpen]);

    return (
        <div
            aria-label="button"
            className={`d-flex flex-column justify-content-center align-items-center ${
                classNames['header-item']
            } ${classNames.clickable} ${isMyInfoOpen ? classNames.open : ''}`}
            onClick={handleClickOnMyInfo}
        >
            <div>
                <FaBell color="white" size={40} />
                {memberNotifications.length > 0 && (
                    <span
                        className={classNames['notifications-count-container']}
                        style={{ fontSize: memberNotifications.length > 9 ? 20 : 25 }}
                    >
                        {memberNotifications.length}
                    </span>
                )}
            </div>
            <span>Notifications</span>

            {isMyInfoOpen && (
                <div className={classNames['dropdown']} ref={dropdownElementRef}>
                    <div className={classNames['notifications-dropdown-container']}>
                        <div className={classNames['notifications-header']}>
                            <span> NOTIFICATIONS </span>{' '}
                        </div>
                        {memberNotifications.slice(0, 3).map((notification, i) => (
                            <DropdownNotification
                                key={'notification' + i}
                                notification={notification}
                            />
                        ))}
                        {memberNotifications.length > 3 && (
                            <Link to="/notifications">
                                <div className={classNames['notifications-footer']}>
                                    <span> View All </span>{' '}
                                </div>{' '}
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </div> 
    );
};

export default withRouter(HeaderNotificationsDropdown);
