import { Stack } from '@mui/system';
import React, { PropsWithChildren, InputHTMLAttributes, useRef, useState } from 'react';
import classNames from './Select.module.scss';
import { DetailedHTMLProps } from 'react';
import { useEffect } from 'react';
import { ReactComponent as ArrowDownIcon } from 'icons/v2/arrow-down.svg';

export type SelectProps<TValue> = DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    required?: boolean;
    errorMessage?: string;
    label: string;
    value: TValue;
    onChange: (value: TValue) => void;
    options: Option<TValue>[];
};

export type Option<TValue> = { value: TValue; text: string; key: string | number };

export const Select = <TValue,>({
    label,
    id,
    required,
    errorMessage,
    children,
    placeholder,
    value,
    options,
    onChange,
    disabled,
    ...restOfProps
}: SelectProps<TValue>) => {
    useEffect(() => {}, [children]);
    const [open, setOpen] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const handleClickAway = (event: MouseEvent) => {
        if (containerRef?.current && !containerRef?.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (!open) {
            return;
        }
        document.addEventListener('click', handleClickAway);
        return () => {
            document.removeEventListener('click', handleClickAway);
        };
    }, [open]);

    const handleChange = (value: TValue) => {
        onChange &&  onChange(value);
        setOpen(false);
    }

    return (
        <Stack
            direction="column"
            className={`${classNames.Select} ${errorMessage ? classNames.error : ''} ${disabled ? classNames.disabled : ''}`}
        >
            <label htmlFor={id}>
                <strong>{label}</strong>
                {required && <span className={classNames.required}>*</span>}
            </label>
            <article ref={containerRef} className={classNames['list-box-container']}>
                <article onClick={setOpen.bind({}, (latestOpen) => !latestOpen)}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classNames['input-container']}
                        aria-haspopup="listbox"
                        aria-expanded={open}
                    >
                        <input
                            {...restOfProps}
                            value={value}
                            placeholder={placeholder}
                            className={classNames.input}
                        />
                        <ArrowDownIcon className={classNames['arrow-down']} />
                    </Stack>
                </article>
                {open && (
                    <ul className={classNames['list-box']} role="listbox">
                        {options.map((option) => (
                            <li
                                role="option"
                                className={classNames['list-box-item']}
                                key={option.key}
                                onClick={handleChange.bind({}, option.value)}
                            >
                                {option.text}
                            </li>
                        ))}
                    </ul>
                )}
            </article>
            <article className={classNames['error-message']}>{errorMessage}</article>
        </Stack>
    );
};

export type OptionProp<TModel> = PropsWithChildren<{
    value: TModel;
    onChange: (newValue: TModel) => void;
}>;

Select.Option = <TModel,>({ value, children, onChange }: OptionProp<TModel>) => {
    return <article onClick={onChange.bind({}, value)}>{children}</article>;
};
