import { ajax } from 'rxjs/ajax';
import { switchMap, take } from 'rxjs/operators';
import { jsonHeaders$ } from './headers';
import { combineLatest } from 'rxjs';
import { memberId$ } from './subjects';

const planService = {
    get$: (planYear) =>
        combineLatest(jsonHeaders$, memberId$).pipe(
            switchMap(([headers, memberId]) =>
                ajax({
                    method: 'GET',
                    url: `api/enrollment/members/${memberId}/plans${
                        planYear ? `?planYear=${planYear}` : ``
                    }`,
                    headers,
                })
            ),
            take(1)
        ),
};

export default planService;
