import { PDFViewerDocument } from 'components/organisms/PDFViewer.types';
import React, { useState, SetStateAction, PropsWithChildren, useEffect } from 'react';

const DEFAULT_PDF_VIEWER_CONTEXT: PDFViewerContextType = {
    addAndOpenDocument: (_document: PDFViewerDocument) => {},
    removeDocument: (_documentPath: string) => {},
    dialogOpen: false,
    setDialogOpen: (_stateAction: SetStateAction<boolean>) => {},
    documents: [],
};

export type PDFViewerContextType = {
    addAndOpenDocument: (document: PDFViewerDocument) => void;
    removeDocument: (documentPath: string) => void;
    dialogOpen: boolean;
    setDialogOpen: (stateAction: SetStateAction<boolean>) => void;
    documents: PDFViewerDocument[];
};

export const PDFViewerContext = React.createContext(DEFAULT_PDF_VIEWER_CONTEXT);

export const PDFViewerProvider = ({ children }: PropsWithChildren<{}>) => {
    const [documents, setDocuments] = useState<PDFViewerDocument[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const addAndOpenDocument = ({ serviceUrl, documentPath, displayName }: PDFViewerDocument) => {
        setDialogOpen(true);
        if(documents.find(doc => doc.documentPath === documentPath)) return;
        setDocuments((currentDocuments) => [
            ...currentDocuments,
            { serviceUrl, documentPath, displayName },
        ]);
    };

    const removeDocument = (documentPath: string) => {
        setDocuments((currentDocuments) => currentDocuments.filter(doc => doc.documentPath !== documentPath));
    };

    useEffect(() => {
        if(!documents.length && dialogOpen) {
            setDialogOpen(false);
        }
    }, [documents]);

    return (
        <PDFViewerContext.Provider
            value={{
                addAndOpenDocument,
                removeDocument,
                dialogOpen,
                setDialogOpen,
                documents,
            }}
        >
            {children}
        </PDFViewerContext.Provider>
    );
};
