import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const PreferenceOptionRadio = ({
    member,
    option,
    userPreferences,
    preference,
    setUserPreference,
    setInvalidEmail,
    setChanged,
    disabled,
}) => {
    const updateValue = async () => {
        const newUserPreferences = await userPreferences.map((up, i) => {
            if (up.name == option.campaignName) {
                const newUp = up.memberPreferences.map((p) => {
                    if (option.communicationType == p.communicationType) {
                        return { ...p, optedOut: !p.optedOut };
                    } else {
                        return { ...p, optedOut: true };
                    }
                });
                return { ...up, memberPreferences: newUp };
            } else {
                return up;
            }
        });
        setUserPreference(newUserPreferences);
        setChanged(true);
    };

    return (
        <FormGroup>
            <Input
                disabled={disabled}
                key={`${option.communicationType}_${preference.name}`}
                multiple={true}
                id={`${option.communicationType}_${preference.name}`}
                className="d-flex"
                type="radio"
                name={`preference_${preference.name}`}
                onClick={() => {
                    if (
                        option.communicationType?.toLowerCase() === 'email' &&
                        !validateEmail(member.email)
                    ) {
                        setInvalidEmail(true);
                    } else {
                        updateValue();
                    }
                }}
                checked={!option.optedOut}
                value={!option.optedOut}
                //Due onChange wasn't working properly when a error happened in email a replaced it for onClick, OnChange set for avoid warnings on console
                onChange={() => {}}
            />
            <Label htmlFor={option.communicationType} check>
                {option.communicationType}
            </Label>
        </FormGroup>
    );
};

export default PreferenceOptionRadio;
