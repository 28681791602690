import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type UseObservableQueryArgs<TValue> = {
    query: () => Observable<TValue>;
    enabled?: boolean;
    dependencies: any[];
    initialValue?: TValue;
    next?: () => void;
    error?: (error: Error) => void;
};

export const useObservableQuery = <TValue>({
    query,
    enabled = true,
    dependencies = [],
    initialValue,
    next,
    error,
}: UseObservableQueryArgs<TValue>) => {
    const [loading, setLoading] = useState(enabled);
    const [errorMessage, setErrorMessage] = useState('');
    const [data, setData] = useState<typeof initialValue>(initialValue);
    const subscriptionsRef = useRef<Subscription[]>([]);
    const unsubscribeRef$ = useRef(new Subject<boolean>());

    const load = () => {
        setLoading(true);
        const subscription = query()
            .pipe(takeUntil(unsubscribeRef$.current))
            .subscribe({
                next: (data: TValue) => {
                    setLoading(false);
                    setData(data);
                    next && next();
                },
                error: (err: Error) => {
                    setLoading(false);
                    setErrorMessage(err?.message);
                    error && error(err);
                },
            });
        subscriptionsRef.current.push(subscription);
    };

    useEffect(() => {
        if (enabled) {
            load();
        }
    }, dependencies);

    useEffect(() => () => {
        unsubscribeRef$.current.next(true);
        unsubscribeRef$.current.unsubscribe();
    }, [])

    return {
        loading,
        reload: load,
        errorMessage,
        data,
    };
};
