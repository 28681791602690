import React from 'react';
import BasicInformationForm from 'components/pages/PatientForm/BasicInformationForm';
import FinalReview from 'components/pages/PatientForm/FinalReview';
import MedicalHistoryForm from 'components/pages/PatientForm/MedicalHistoryForm';
import patientAccessService from 'services/patientAccessService';
import HealthInformationForm from 'components/pages/PatientForm/HealthInformationForm';
import Welcome from 'components/pages/PatientForm/Welcome';
import ReceiptNotice from 'components/pages/PatientForm/ReceiptNotice';
import DesignationOfRepresentative from 'components/pages/PatientForm/DesignationOfRepresentative';
import TreatmentConsent from 'components/pages/PatientForm/TreatmentConsent';
import AuthorizeReleasePHI from 'components/pages/PatientForm/AuthorizeReleasePHI';
import { PatientPages } from './Enums';

const patientFormDefaultValues = {
    pharmacy: {},
    employer: {},
    responsibleParty: {},
    emergencyContact: {},
    primary: {},
    secondary: {},
    obgynHistory: {},
    authorizePHI: [],
    allergies: [],
    deceasedFamily: [],
    prescriptions: [],
    surgicalHistory: [],
    preventativeHistory: [],
    vaccineHistory: [],
    physiciansInfo: [],
};

const patientSteps = [
    { title: 'Personal Information', page: PatientPages.BASIC_INFO },
    { title: 'Medical History', page: PatientPages.MEDICAL_HISTORY },
    { title: 'Health Information', page: PatientPages.HEALTH_DETAILS },
    { title: 'Review', page: PatientPages.REVIEW },
];

/*

injection data for all validations, paging necessary to drive the Wizard.

*/

// It's not redux, but similar. These are Actions used with useReducer in Wizard.
const ACTIONS = {
    RESET: 'RESET',
    START_FORM: 'START_FORM',
    FINISHED_BASIC_INFO: 'FINISHED_BASIC_INFO',
    FINISHED_MEDICAL_HISTORY: 'FINISHED_MEDICAL_HISTORY',
    FINISHED_HEALTH_DETAILS: 'FINISHED_HEALTH_DETAILS',
    FINISHED_RECEIPT: 'FINISHED_RECEIPT',
    FINISHED_DESIGNATE_REPRESENT: 'FINISHED_DESIGNATE_REPRESENT',
    FINISHED_TREATMENT_CONSENT: 'FINISHED_TREATMENT_CONSENT',
    FINISHED_AUTHORIZE_PHI: 'FINISHED_AUTHORIZE_PHI',
    FINISHED_REVIEW: 'FINISHED_REVIEW',
    GOTO: 'GOTO',
};

// This stores the entire form data. React Hook Forms will pass this as argument in handleSubmit((here)=>{}).
const initialFormState = {
    ...patientFormDefaultValues,
    submitted: false,
    page: PatientPages.WELCOME,
    isAppointmentForm: false,
};

// This gives the metadata of page component, and what actions to dispatch for going back / prev page.
const pageMetaData = {
    [PatientPages.WELCOME]: {
        showProgressBar: false,
        component: Welcome,
        nextAction: ACTIONS.START_FORM,
        prevPage: PatientPages.WELCOME,
    },
    [PatientPages.BASIC_INFO]: {
        showProgressBar: true,
        component: BasicInformationForm,
        nextAction: ACTIONS.FINISHED_BASIC_INFO,
        prevPage: PatientPages.WELCOME,
    },
    [PatientPages.MEDICAL_HISTORY]: {
        showProgressBar: true,
        component: MedicalHistoryForm,
        nextAction: ACTIONS.FINISHED_MEDICAL_HISTORY,
        prevPage: PatientPages.BASIC_INFO,
    },
    [PatientPages.HEALTH_DETAILS]: {
        showProgressBar: true,
        component: HealthInformationForm,
        nextAction: ACTIONS.FINISHED_HEALTH_DETAILS,
        prevPage: PatientPages.MEDICAL_HISTORY,
    },
    [PatientPages.REVIEW]: {
        showProgressBar: true,
        component: FinalReview,
        nextAction: ACTIONS.FINISHED_REVIEW,
        prevPage: PatientPages.HEALTH_DETAILS,
    },
    [PatientPages.RECEIPT]: {
        showProgressBar: false,
        component: ReceiptNotice,
        nextAction: ACTIONS.FINISHED_RECEIPT,
        prevPage: PatientPages.HEALTH_DETAILS,
    },
    [PatientPages.DESIGNATE_REPRESENT]: {
        showProgressBar: false,
        component: DesignationOfRepresentative,
        nextAction: ACTIONS.FINISHED_DESIGNATE_REPRESENT,
        prevPage: PatientPages.RECEIPT,
    },
    [PatientPages.TREATMENT_CONSENT]: {
        showProgressBar: false,
        component: TreatmentConsent,
        nextAction: ACTIONS.FINISHED_TREATMENT_CONSENT,
        prevPage: PatientPages.DESIGNATE_REPRESENT,
    },
    [PatientPages.AUTHORIZE_PHI]: {
        showProgressBar: false,
        component: AuthorizeReleasePHI,
        nextAction: ACTIONS.FINISHED_AUTHORIZE_PHI,
        prevPage: PatientPages.TREATMENT_CONSENT,
    },
};
const savePatientForm = (state) => {
    patientAccessService.save$(state).subscribe({
        next: () => {},
        error: (e) => {},
    });
};
// Reducer, exactly like how redux work. Actions will affect the state of the initialState data.
const reducer = (state, action) => {
    let currentState;
    switch (action.type) {
        case ACTIONS.RESET:
            return initialFormState;
        case ACTIONS.START_FORM:
            currentState = {
                ...state,
                page: PatientPages.BASIC_INFO,
            };
            return currentState;
        case ACTIONS.FINISHED_BASIC_INFO:
            currentState = {
                ...state,
                ...action.payload,
                page: PatientPages.MEDICAL_HISTORY,
            };
            savePatientForm(currentState);
            return currentState;

        case ACTIONS.FINISHED_MEDICAL_HISTORY:
            currentState = {
                ...state,
                ...action.payload,
                page: PatientPages.HEALTH_DETAILS,
            };
            savePatientForm(currentState);
            return currentState;
        case ACTIONS.FINISHED_HEALTH_DETAILS:
            currentState = {
                ...state,
                ...action.payload,
                page: PatientPages.REVIEW,
            };
            savePatientForm(currentState);
            return currentState;
        case ACTIONS.FINISHED_REVIEW:
            return {
                ...state,
                submitted: true,
                page: PatientPages.RECEIPT,
            };
        case ACTIONS.FINISHED_RECEIPT:
            currentState = {
                ...state,
                ...action.payload,
                page: PatientPages.DESIGNATE_REPRESENT,
            };
            savePatientForm(currentState);
            return currentState;
        case ACTIONS.FINISHED_DESIGNATE_REPRESENT:
            currentState = {
                ...state,
                ...action.payload,
                page: PatientPages.TREATMENT_CONSENT,
            };
            savePatientForm(currentState);
            return currentState;
        case ACTIONS.FINISHED_TREATMENT_CONSENT:
            currentState = {
                ...state,
                ...action.payload,
                page: PatientPages.AUTHORIZE_PHI,
            };
            savePatientForm(currentState);
            return currentState;
        case ACTIONS.FINISHED_AUTHORIZE_PHI:
            currentState = {
                ...state,
                ...action.payload,
                page: PatientPages.REVIEW,
            };
            savePatientForm(currentState);
            return currentState;

        case ACTIONS.GOTO:
            return {
                ...state,
                page: action.payload,
            };
        default:
            return state;
    }
};
export { initialFormState, ACTIONS, reducer, pageMetaData, patientSteps };
