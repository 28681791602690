/** libs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

/** styles */
import styles from './Biography.module.scss';

/**
 * Biography component to present pcp info before user
 * @component Biography
 * */
class Biography extends Component {
    static displayName = Biography.name;

    static propTypes = {
        /** Pcp for biography **/
        pcp: PropTypes.shape({
            nppes: PropTypes.shape({
                firstName: PropTypes.string,
                middleName: PropTypes.string,
                lastName: PropTypes.string,
            }),
            npi: PropTypes.string,
            specialties: PropTypes.arrayOf(
                PropTypes.shape({
                    code: PropTypes.string,
                    desc: PropTypes.string,
                    spanishDesc: PropTypes.string,
                })
            ),
        }),
    };

    /**
     * mapStateAndPropsToClassNames
     * @returns {string} - classNames derived from state and props
     */
    mapStateAndPropsToClassNames = () => {
        const { visible } = this.props;
        let classNames = styles.biography;
        classNames = `${classNames} ${visible ? styles.visible : styles.invisible}`;

        return classNames;
    };

    /**
     * @returns {React.ReactElement}
     */
    render() {
        const {
            pcp: {
                nppes: { firstName, lastName, middleName },
                npi,
                specialties,
            },
            onClose,
        } = this.props;
        const name = [firstName, middleName, lastName].join(' ');

        return (
            <article className={this.mapStateAndPropsToClassNames()} aria-label="PCP Information">
                <Container>
                    <Row>
                        <div
                            className={styles['close-container']}
                            onClick={onClose}
                            aria-label="Close PCP Information"
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className={styles['close-icon']}
                                aria-label="Close"
                            />
                            Close
                        </div>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <div className={styles['bio-container']}>
                                <Row>
                                    <Col sm="8">
                                        <h3 className={styles.title}>{name}</h3>
                                        <Row>
                                            <Col xs="4">
                                                <div>
                                                    <span className="bold-text">NPI:</span>
                                                </div>
                                                <div>
                                                    <span className="bold-text">Specialties:</span>
                                                </div>
                                            </Col>
                                            <Col xs="8">
                                                <div>{npi}</div>
                                                <div>
                                                    {specialties
                                                        ? specialties
                                                              .map((specialty) => specialty.desc)
                                                              .join(', ')
                                                        : null}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm="4"></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col sm="5"></Col>
                    </Row>
                </Container>
            </article>
        );
    }
}

export default Biography;
