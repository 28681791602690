import React, { useState, memo, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { Button } from 'components/v2/atoms/Button/Button';
import { healthyRewardService } from 'services/healthyRewardService';
import { take } from 'rxjs/operators';
import { SectionTitle } from 'components/v2/atoms/SectionTitle/SectionTitle';
import { Alert } from 'components/v2/molecules/Alert/Alert';
import { Loader } from 'components/v2/atoms/Loader/Loader';

import { Select } from 'components/v2/molecules/forms/Select/Select';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { useEffect } from 'react';

export type ColorectalCancerScreeningHaveCompletedProps = {
    selfReportAlreadyRequested: boolean;
    defaultValues?: {
        selfReportColorectalRewardType: string;
        selfReportColorectalRewardYear: string;
        selfReportColorectalRewardMonth: string;
    };
};

export type SelfRewardFormState = {
    month: string;
    year: string;
    type: string;
};

const COLONOSCOPY_OPTION = 'Colonoscopy';
const FIT_DNA_OPTION = 'FIT DNA';
const FOBT_OPTION = 'FOBT';

const MAX_ALLOWED_PAST_YEARS_IN_COLONOSCOPY = 9;
const MAX_ALLOWED_PAST_YEARS_IN_FOBT = 2;
const MAX_ALLOWED_PAST_YEARS_IN_FIT_DNA = 1;

export const ColorectalCancerScreeningHaveCompleted = memo(
    ({
        selfReportAlreadyRequested,
        defaultValues,
    }: ColorectalCancerScreeningHaveCompletedProps) => {
        const [selfReportRequestedAlertOpen, setSelfReportRequestedAlertOpen] = useState(true);
        const [selfReportLoading, setSelfReportLoading] = useState(false);
        const [selftReportRequested, setSelfReportRequested] = useState(true);
        const {
            control,
            handleSubmit,
            trigger,
            formState: { errors, isSubmitted },
            setValue,
            watch,
        } = useForm<SelfRewardFormState>({
            defaultValues: {
                year: defaultValues?.selfReportColorectalRewardYear,
                month: defaultValues?.selfReportColorectalRewardMonth,
                type: defaultValues?.selfReportColorectalRewardType,
            },
        });
        const watchedType = watch('type');
        const watchedYearDate = watch('year');

        const yearOptions = useMemo(() => {
            let maxAllowedYears = MAX_ALLOWED_PAST_YEARS_IN_FIT_DNA;
            if (watchedType === COLONOSCOPY_OPTION) {
                maxAllowedYears = MAX_ALLOWED_PAST_YEARS_IN_COLONOSCOPY;
            } else if (watchedType === FOBT_OPTION) {
                maxAllowedYears = MAX_ALLOWED_PAST_YEARS_IN_FOBT;
            }
            return Array.from(new Array(maxAllowedYears)).map((_, index) => {
                const year = new Date().getFullYear() - index;
                return { key: year.toString(), value: year.toString(), text: year.toString() };
            });
        }, [watchedType]);
        const monthOptions = useMemo(
            () => [
                { key: 'Jan', value: 'January', text: 'Jan' },
                { key: 'Feb', value: 'February', text: 'Feb' },
                { key: 'Mar', value: 'March', text: 'Mar' },
                { key: 'Apr', value: 'April', text: 'Apr' },
                { key: 'May', value: 'May', text: 'May' },
                { key: 'Jun', value: 'June', text: 'Jun' },
                { key: 'Jul', value: 'July', text: 'Jul' },
                { key: 'Aug', value: 'August', text: 'Aug' },
                { key: 'Sep', value: 'September', text: 'Sep' },
                { key: 'Oct', value: 'October', text: 'Oct' },
                { key: 'Nov', value: 'November', text: 'Nov' },
                { key: 'Dec', value: 'December', text: 'Dec' },
            ],
            []
        );
        const handleSubmitSelfReport = ({ month, type, year }: SelfRewardFormState) => {
            setSelfReportLoading(true);
            healthyRewardService
                .selftReportColorectalReward$({
                    type,
                    month,
                    year,
                })
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        setSelfReportRequested(true);
                        setSelfReportRequestedAlertOpen(true);
                        setSelfReportLoading(false);
                    },
                    error: (error: Error) => {
                        // TODO implement toast
                        console.error(error?.message);
                        setSelfReportLoading(false);
                    },
                });
        };

        useEffect(() => {
            if (isSubmitted) {
                trigger('month');
            }
            if (
                watchedYearDate &&
                !yearOptions.find((yearOption) => yearOption.value === watchedYearDate)
            ) {
                setValue('year', new Date().getFullYear());
            }
        }, [watchedType]);

        return (
            <section>
                <SectionTitle>HAVE COMPLETED</SectionTitle>
                {!!Object.values(errors).length && (
                    <Stack marginBottom={3}>
                        <Alert title="MISSING FIELDS" variant="error">
                            One or more of the required fields is empty or contains invalid data.
                            Please correct the errors and try again
                        </Alert>
                    </Stack>
                )}
                {selftReportRequested ? (
                    <>
                        {selfReportRequestedAlertOpen && (
                            <Stack paddingY={4} spacing={2}>
                                <Alert
                                    title="TEST SUBMITTED"
                                    variant="success"
                                    onClose={setSelfReportRequestedAlertOpen.bind({}, false)}
                                >
                                    Your test was submitted successfully and your reward points are
                                    now pending
                                </Alert>
                            </Stack>
                        )}
                        <p>
                            You&apos;ve already entered the details. Your reward is currently
                            pending. If you need to provide additional information or have any
                            questions, please send concierge a message.
                        </p>
                    </>
                ) : (
                    <>
                        {selfReportAlreadyRequested ? (
                            <p>
                                You&apos;ve already entered the details. Your reward is currently
                                pending. If you need to provide additional information or have any
                                questions, please send concierge a message.
                            </p>
                        ) : (
                            <>
                                <Stack paddingBottom={2}>
                                    <p>
                                        If you have already completed your Colorectal Cancer
                                        Screening, enter the details of your test below to receive
                                        your rewards points.
                                    </p>
                                </Stack>
                                <Stack paddingBottom={2}>
                                    <article>
                                        <strong>Colonoscopy</strong> - Done within the past 10 years
                                    </article>
                                    <article>
                                        <strong>FIT DNA (Cologuard)</strong> - Done within this
                                        calendar year, or two years prior
                                    </article>
                                    <article>
                                        <strong>FOBT</strong> - Done within this calendar year
                                    </article>
                                </Stack>
                            </>
                        )}
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            spacing={{
                                xs: 2,
                                md: 3,
                            }}
                            marginBottom={3}
                        >
                            <Stack flex={1}>
                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{
                                        required: 'Insert Test Type',
                                    }}
                                    render={(field) => (
                                        <Select
                                            required
                                            label="Test Type"
                                            placeholder="Select one"
                                            disabled={selfReportAlreadyRequested}
                                            value={field.value}
                                            errorMessage={errors?.type?.message}
                                            onChange={field.onChange}
                                            options={[
                                                {
                                                    value: COLONOSCOPY_OPTION,
                                                    text: COLONOSCOPY_OPTION,
                                                    key: COLONOSCOPY_OPTION,
                                                },
                                                {
                                                    value: FIT_DNA_OPTION,
                                                    text: FIT_DNA_OPTION,
                                                    key: FIT_DNA_OPTION,
                                                },
                                                {
                                                    value: FOBT_OPTION,
                                                    text: FOBT_OPTION,
                                                    key: FOBT_OPTION,
                                                },
                                            ]}
                                        ></Select>
                                    )}
                                />
                            </Stack>
                            <Stack flex={1}>
                                <Controller
                                    name="year"
                                    control={control}
                                    rules={{ required: 'Insert Year' }}
                                    render={(field) => (
                                        <Select
                                            required
                                            label="Year"
                                            placeholder="Select one"
                                            disabled={selfReportAlreadyRequested}
                                            value={field.value}
                                            errorMessage={errors?.year?.message}
                                            onChange={field.onChange}
                                            options={yearOptions}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack flex={1}>
                                <Controller
                                    name="month"
                                    rules={{
                                        required:
                                            watchedType !== COLONOSCOPY_OPTION
                                                ? 'Insert Month'
                                                : undefined,
                                    }}
                                    control={control}
                                    render={(field) => (
                                        <Select
                                            required={watchedType !== COLONOSCOPY_OPTION}
                                            label="Month"
                                            placeholder="Select one"
                                            disabled={selfReportAlreadyRequested}
                                            value={field.value}
                                            errorMessage={errors?.month?.message}
                                            onChange={field.onChange}
                                            options={monthOptions}
                                        />
                                    )}
                                />
                            </Stack>
                        </Stack>
                        {!selfReportAlreadyRequested && (
                            <Stack
                                spacing={2}
                                justifyContent="center"
                                alignItems="center"
                                padding={3}
                            >
                                {selfReportLoading ? (
                                    <Loader size="md" />
                                ) : (
                                    <Button
                                        variant="main"
                                        onClick={handleSubmit(handleSubmitSelfReport)}
                                    >
                                        Submit
                                    </Button>
                                )}
                            </Stack>
                        )}
                    </>
                )}
            </section>
        );
    }
);
