import React, { Component } from 'react';
import { identity$ } from '../services/subjects';

export default function withIdentityData(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            identity$.subscribe((identity) => this.setState({ identity }));
        }

        render() {
            return <WrappedComponent identity={this.state.identity} {...this.props} />;
        }
    };
}
