import React, { ChangeEvent, forwardRef, useCallback, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import classnames from 'classnames';

const CheckboxWithController = ({
    control,
    name,
    defaultValue,
    question,
    disabled,
    required,
    updateError, // for MultiSelectCheckbox, as the errors object is not updated properly
}) => {
    const handleChange = useCallback((e) => {
        const newValue = e.target.checked;

        setCheckboxVal(newValue);
    }, []);
    const [checkboxVal, setCheckboxVal] = useState(defaultValue || false);
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(
                { onChange, onBlur, value, name, ref },
                { invalid, isTouched, isDirty, error },
                formState
            ) => (
                <div className="d-flex align-items-center">
                    <input
                        type="checkbox"
                        checked={value}
                        name={name}
                        onChange={(e) => {
                            onChange(e.target.checked);
                            updateError(e.target.checked);
                        }}
                        id={name}
                        aria-labelledby={`label-question-checkbox-${name}`}
                        disabled={disabled}
                        data-testid={name}
                        required={required}
                    />
                    <label
                        id={`label-question-checkbox-${name}`}
                        htmlFor={name}
                        className={classnames('ml-1 mb-0 p-2', { disabled })}
                        style={{ cursor: 'pointer' }}
                    >
                        <span className="h5">{question}</span>
                    </label>
                </div>
            )}
        />
    );
};
export default CheckboxWithController;
