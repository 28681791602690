import { formatToPhone } from 'common/utility';
import { ETHNICITIES, LANGUAGES, RACES } from 'components/Form/Patient/Constants';
import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import SelectWithController from 'components/Form/Patient/Input/Select';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

const AdditionalInformationFields = ({ control, state }) => {
    return (
        <>
            <Row>
                <Col md={6}>
                    <SelectWithController
                        defaultValue={state?.race}
                        question="Race"
                        name="race"
                        options={RACES}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                    <RadiosWithController
                        defaultValue={state?.isHispanic}
                        question="Ethnicity"
                        name="isHispanic"
                        options={ETHNICITIES}
                        colSize={6}
                    />
                </Col>
                <Col md={{ span: 5, offset: 2 }}>
                    <RadiosWithController
                        defaultValue={state?.languagePreference}
                        question="Which Language do you prefer"
                        name="languagePreference"
                        options={LANGUAGES}
                        colSize={6}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.pharmacy.name}
                        name="pharmacy.name"
                        question="Name of your Pharmacy"
                        showError={false}
                    />
                </Col>
                <Col md={4}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.pharmacy.phoneNumber}
                        name="pharmacy.phoneNumber"
                        question="Phone #"
                        showError={false}
                        onKeyUp={formatToPhone}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={5}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.pharmacy.address}
                        name="pharmacy.address"
                        question="Address"
                        showError={false}
                    />
                </Col>
                <Col md={3}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.pharmacy.city}
                        name="pharmacy.city"
                        question="City"
                        showError={false}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.pharmacy.state}
                        name="pharmacy.state"
                        question="State"
                        showError={false}
                    />
                </Col>
                <Col md={2}>
                    <TextFieldWithController
                        control={control}
                        defaultValue={state?.pharmacy.zipCode}
                        name="pharmacy.zipCode"
                        question="Zip"
                        showError={false}
                    />
                </Col>
            </Row>
        </>
    );
};
export default AdditionalInformationFields;
