import React, { HTMLProps, PropsWithChildren, useMemo, memo, useCallback, useState } from 'react';
import { ReactComponent as SuccessAlert } from 'icons/v2/success-alert.svg';
import { ReactComponent as ErrorAlert } from 'icons/v2/error-alert.svg';
import { ReactComponent as WarningAlert } from 'icons/v2/warning-alert.svg';
import { ReactComponent as InfoAlert } from 'icons/v2/info-alert.svg';
import { ReactComponent as CancelX } from 'icons/v2/cancel-x.svg';
import classNames from './Alert.module.scss';

const ICON_COLOR = {
    INFO: '#2C53BB',
    ERROR: '#336442',
    WARNING: '#F89C1D',
    SUCCESS: '#336442',
};

export type AlertProps = PropsWithChildren<{
    title: string;
    onClose?: () => void;
    variant: 'success' | 'error' | 'warning' | 'info';
}> &
    HTMLProps<HTMLDivElement>;

const Alert = ({ title, children, variant, className, onClose, ...restOfProps }: AlertProps) => {
    const statusColor = useMemo(() => {
        switch (variant) {
            case 'success':
                return ICON_COLOR.SUCCESS;
            case 'error':
                return ICON_COLOR.ERROR;
            case 'warning':
                return ICON_COLOR.WARNING;
            default:
                return ICON_COLOR.INFO;
        }
    }, [variant]);

    const renderIcon = useCallback(() => {
        let Icon = InfoAlert;
        switch (variant) {
            case 'success':
                Icon = SuccessAlert;
                break;
            case 'error':
                Icon = ErrorAlert;
                break;
            case 'warning':
                Icon = WarningAlert;
                break;
            default:
                Icon = InfoAlert;
        }
        return (
            <Icon
                fill={statusColor}
                className={`${classNames.icon} ${classNames['floating-icon']}`}
            />
        );
    }, [variant]);

    return (
        <article
            aria-label="alert"
            className={`${classNames.Alert} ${classNames[variant]} ${className || ''}`}
            {...restOfProps}
        >
            <header className={classNames.header}>
                <h3 className={`card-section-title ${classNames.title}`}>
                    {renderIcon()}
                    {title}
                </h3>
                {onClose && (
                    <CancelX
                        className={`${classNames.icon} ${classNames.cancel}`}
                        fill={statusColor}
                        onClick={onClose}
                    />
                )}
            </header>
            {children}
        </article>
    );
};

Alert.defaultProps = {
    variant: 'info',
};

const MemoAlert = memo(Alert);
export { MemoAlert as Alert };
