import patientAccessService from 'services/patientAccessService';
import AppointmentFirstStep from '../../pages/AppointmentForm/First-Reason';
import AppointmentThirdStep from '../../pages/AppointmentForm/Third-ContactAndDetails';
import AppointmentFourthStep from '../../pages/AppointmentForm/Fourth-ConfirmAndBook';

const patientFormDefaultValues = {
    reason: '',
    type: '',
    patientEmail: '',
    patientPhone: '',
    notificationPreferences: 0,
    appointmentNotes: '',
    consent: 1,
    translatorPreferredLanguage: '',
    translatorRequired: 0,
};

const PAGES = {
    REASON: 0,
    CONTACT_AND_DETAILS: 1,
    CONFIRM_AND_BOOK: 2,
};

const steps = [
    { title: 'Reason', page: PAGES.REASON },
    { title: 'Contact and Details', page: PAGES.CONTACT_AND_DETAILS },
    { title: 'Confirm & Book', page: PAGES.CONFIRM_AND_BOOK },
];

/*

injection data for all validations, paging necessary to drive the Wizard.

*/

// It's not redux, but similar. These are Actions used with useReducer in Wizard.
const ACTIONS = {
    RESET: 'RESET',
    FINISHED_REASON: 'FINISHED_REASON',
    FINISHED_CONTACT_AND_DETAILS: 'FINISHED_CONTACT_AND_DETAILS',
    FINISHED_CONFIRM_AND_BOOK: 'FINISHED_CONFIRM_AND_BOOK',
    GOTO: 'GOTO',
};

// This stores the entire form data. React Hook Forms will pass this as argument in handleSubmit((here)=>{}).
const initialFormState = {
    ...patientFormDefaultValues,
    submitted: false,
    page: PAGES.REASON,
    isAppointmentForm: false,
};

// This gives the metadata of page component, and what actions to dispatch for going back / prev page.
const pageMetaData = {
    [PAGES.REASON]: {
        showProgressBar: true,
        component: AppointmentFirstStep,
        nextAction: ACTIONS.FINISHED_REASON,
        prevPage: PAGES.REASON,
    },
    [PAGES.CONTACT_AND_DETAILS]: {
        showProgressBar: true,
        component: AppointmentThirdStep,
        nextAction: ACTIONS.FINISHED_CONTACT_AND_DETAILS,
        prevPage: PAGES.REASON,
    },
    [PAGES.CONFIRM_AND_BOOK]: {
        showProgressBar: true,
        component: AppointmentFourthStep,
        nextAction: ACTIONS.FINISHED_CONFIRM_AND_BOOK,
        prevPage: PAGES.CONTACT_AND_DETAILS,
    },
};

// Reducer, exactly like how redux work. Actions will affect the state of the initialState data.
const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.RESET:
            return initialFormState;
        case ACTIONS.FINISHED_REASON:
            return {
                ...state,
                ...action.payload,
                page: PAGES.CONTACT_AND_DETAILS,
            };
        case ACTIONS.FINISHED_CONTACT_AND_DETAILS:
            return {
                ...state,
                ...action.payload,
                page: PAGES.CONFIRM_AND_BOOK,
            };
        case ACTIONS.FINISHED_CONFIRM_AND_BOOK:
            return {
                ...state,
                submitted: true,
            };
        case ACTIONS.GOTO:
            return {
                ...state,
                page: action.payload.page,
                ...action.payload,
            };
        default:
            return state;
    }
};
export { initialFormState, ACTIONS, reducer, pageMetaData, steps, PAGES };
