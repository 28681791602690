import * as yup from 'yup';
import { defaultTo, isString, keys, isEmpty } from 'lodash';
import { PatientPages } from './Enums';

const middleInitialYup = yup
    .string()
    .test('len', 'Middle initial must be exactly 1 character', (val) => {
        if (val === '') {
            return true;
        }
        if (isString(val) && val.length === 1) {
            return true;
        }
        return new yup.ValidationError('Middle initial must be 1', 'len', 'middleInitial');
    });
const emailYup = yup.string().email('Please enter correct email format').nullable();
const phoneYup = yup
    .string()
    .matches(
        /^$|^\(?([0-9]{3})\)?[-. ] ?([0-9]{3}) ?[-. ] ?([0-9]{4})$/,
        'Phone number is invalid. Please enter a correct phone number.'
    )
    .nullable();
const zipCodeYup = yup
    .string()
    .matches(/^$|^[0-9]{5}(?:-[0-9]{4})?$/, 'Zip code should be in the form: #####, or #####-####');
const dateStringYup = yup.date().nullable();
const validators = {
    [PatientPages.BASIC_INFO]: yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        middleInitial: middleInitialYup,
        dateOfBirth: dateStringYup,
        age: yup.number().nullable().positive().integer('Age must be a positive integer'),
        zipCode: zipCodeYup,
        email: emailYup,
        phoneHome: phoneYup,
        phoneMobile: phoneYup,
    }),
    [PatientPages.MEDICAL_HISTORY]: yup.object().shape({
        metalPlatesDescription: yup.string().nullable(),
    }),
};
export { validators };
