import { ajax } from 'rxjs/ajax';
import { map, tap, switchMap } from 'rxjs/operators';
import { jsonHeaders$, authHeaders$ } from './headers';

const patientAccessService = {
    get$: () =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'GET',
                    url: `api/patientaccess`,
                    headers,
                }).pipe(map(({ response }) => response))
            )
        ),
    save$: (patientForm) =>
        jsonHeaders$.pipe(
            switchMap((headers) =>
                ajax({
                    method: 'POST',
                    url: `api/patientaccess`,
                    headers,
                    body: patientForm,
                }).pipe(map(({ response }) => response))
            )
        ),
};

export default patientAccessService;
