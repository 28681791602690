import React, { useState, useEffect } from 'react';
import PageHeader from 'components/molecules/PageHeader';
import FormGenerator from 'components/organisms/FormGenerator/FormGenerator';
import { FormTemplate } from 'components/organisms/FormGenerator/interfaces/FormTemplate';
import formTemplateService from 'services/FormTemplateService';
import { Subscription } from 'rxjs';
import { healthRiskAssessmentService } from 'services/HealthRiskAssessmentService';
import { SUBMISSION_STATUS } from 'enums/status';
import HealthRiskAssessmentConfirmation from 'components/organisms/HealthRiskAssessment/HealthRiskAssessmentConfirmation';
import { memberAdas$ } from 'services/subjects';
import { filter } from 'rxjs/operators';
import Loading from 'components/atoms/Loading';
import { HealthRiskAssessment, SubmissionResponse } from 'interfaces/HealthRiskAssessment';
import ErrorMessage from 'components/molecules/ErrorMessage';

const HealthRiskAssessmentForm = () => {
    const [formTemplate, setFormTemplate] = useState<FormTemplate | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [submissionResponse, setSubmissionResponse] = useState<SubmissionResponse>({
        status: SUBMISSION_STATUS.PENDING,
        referenceNo: '',
        applicationId: '',
        storageBlobId: '',
        fileName: '',
    });
    const [memberInfo, setMemberInfo] = useState<{}>();
    const [error, setError] = useState<boolean>(false);

    const getFormTemplate = () => {
        setIsLoading(true);
        const getFormTemplatesSub = formTemplateService
            .getMostRecentFormTemplate$('Health Risk Assessment')
            .subscribe({
                next: (response) => {
                    if (response) {
                        setFormTemplate(response);
                    } else {
                        console.error('Error while retrieving form template');
                        setIsLoading(false);
                        setError(true);
                    }
                    setIsLoading(false);
                },
                error: () => {
                    console.error('Error while retrieving form template');
                    setIsLoading(false);
                    setError(true);
                },
            });
        setSubscriptions((prev) => [...prev, getFormTemplatesSub]);
    };

    const submitHealthRiskAssessment = (data: object) => {
        const hra: HealthRiskAssessment = { ...(data as HealthRiskAssessment), ...memberInfo };

        const submitHealthRiskAssessmentSub = healthRiskAssessmentService
            .submitHealthRiskAssessment$(hra)
            .subscribe({
                next: (resp) => {
                    const response = resp.response;
                    if (response && resp.status === 201) {
                        setSubmissionResponse((prevSubmissionResponse) => ({
                            ...prevSubmissionResponse,
                            ...response,
                            status: SUBMISSION_STATUS.SUCCESS,
                        }));
                    } else {
                        setSubmissionResponse({
                            status: SUBMISSION_STATUS.FAILURE,
                            referenceNo: '',
                            applicationId: '',
                            storageBlobId: '',
                            fileName: '',
                        });
                        console.error('Error while submitting Health Risk Assessment');
                    }
                },
                error: () => {
                    setSubmissionResponse({
                        status: SUBMISSION_STATUS.FAILURE,
                        referenceNo: '',
                        applicationId: '',
                        storageBlobId: '',
                        fileName: '',
                    });
                    console.error('Error while submitting Health Risk Assessment');
                },
            });

        setIsComplete(true);
        setSubscriptions((prev) => [...prev, submitHealthRiskAssessmentSub]);
    };

    const getMemberInfo = () => {
        const memberSub: Subscription = memberAdas$
            .pipe(filter((member) => member))
            .subscribe((member) => {
                setMemberInfo({
                    memberId: member.memberId,
                    firstName: member.firstName,
                    lastName: member.lastName,
                    phoneNum: member.phoneNumber,
                    dateOfBirth: member.dateOfBirth,
                });
            });
        setSubscriptions((prev) => [...prev, memberSub]);
    };

    useEffect(() => {
        getMemberInfo();
        getFormTemplate();
        return () => subscriptions.forEach((subscription) => subscription.unsubscribe());
    }, []);

    return (
        <div>
            <PageHeader>Health Risk Assessment</PageHeader>
            {!isLoading ? (
                !error ? (
                    !isComplete ? (
                        <FormGenerator
                            formTemplate={formTemplate}
                            onSubmit={submitHealthRiskAssessment}
                        />
                    ) : (
                        <HealthRiskAssessmentConfirmation submissionResponse={submissionResponse} />
                    )
                ) : (
                    <ErrorMessage message="Something went wrong when loading the form!" />
                )
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default HealthRiskAssessmentForm;
