import React from 'react';
import PropTypes from 'prop-types';
import { handleEnterKey } from '../../../common/accessibilityHelpers';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './RadioQuestion.module.scss';

const RadioQuestion = (props) => {
    return (
        <>
            <p className={styles['question-title']}>{props.question}</p>
            <Row role="radiogroup" aria-labelledby={`label-radiogroup`}>
                {props.options.map((option, i) => (
                    <Col key={`radio-option-${i}`} md={props.isColumn ? '12' : '6'}>
                        <input
                            className={styles.radio}
                            data-testid={option.label}
                            type="radio"
                            checked={props.value === option.value}
                            name={option.label}
                            onChange={(_) => props.onChange(option.value)}
                            id={option.label}
                            value={option.value}
                            aria-label={option.label}
                        />
                        <label
                            key={`radio-option-${i}`}
                            className={styles.label}
                            htmlFor={option.label}
                            tabIndex="0"
                            onKeyUp={handleEnterKey(() => props.onChange(option.value))}
                            ref={props.forwardedRef}
                            style={{ marginBottom: 0, marginTop: 0 }}
                        >
                            {option.label}

                            {option.note !== undefined ? (
                                <>
                                    {' '}
                                    <br />{' '}
                                    <span>
                                        <b>Note:</b> {option.note}
                                    </span>{' '}
                                </>
                            ) : (
                                ''
                            )}
                            {option.icon && (
                                <FontAwesomeIcon icon={option.icon} className={styles.icon} />
                            )}
                        </label>
                    </Col>
                ))}
            </Row>
        </>
    );
};

RadioQuestion.propTypes = {
    question: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            answer: PropTypes.shape({
                label: PropTypes.string,
                note: PropTypes.string,
                value: PropTypes.any,
                icon: PropTypes.object,
            }),
        })
    ),
};

export default RadioQuestion;
