import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { LinkedPost } from '../molecules/Post';
import ContactInfo from '../molecules/ContactInfo';

import { env$ } from '../../services/subjects';

import withPolicyData from '../../hoc/withPolicyData';
import withMemberCardData from '../../hoc/withMemberCardData';
import withMemberData from '../../hoc/withMemberData';

import {
    teledocLink,
    accessCardLink,
    getPrimeLink,
    PrimeLandingPages,
    payspanLink,
} from '../../services/ssoService';
import CustomCarousel from 'components/molecules/CustomCarousel/CustomCarousel';
import FlueVaccine from '../../assets/FlueVaccine.png';
import ColorectalCancer from '../../assets/ColorectalCancer.png';
import RateYoutPCP from '../../assets/RateYourPCP.png';
import { sendCustomEvent } from 'common/utility';
import { AUDITEVENTTYPE } from 'services/trackService';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        env$.subscribe({
            next: (env) => this.setState({ env }),
        });
    }

    render() {
        let providerSearchSite =
            this.state.env === 'prod'
                ? 'https://providersearch.alignmenthealthplan.com'
                : 'https://ava-providersearch-uat.azurewebsites.net';
        
        const posts = [
            {
                icon: 'doctor',
                header: `Get Virtual Care`,
                subHeader: `Talk to your doctor by phone or video`,
                to: '/virtual-care',
            },
            {
                icon: 'credit-card',
                header: 'Access Card Balance',
                subHeader: `Check your balance`,
                href: accessCardLink,
                target: '_blank',
                onClick: () => {
                    sendCustomEvent({ eventType: AUDITEVENTTYPE.MemberPortalViewMyAccessCardTile });
                    sendCustomEvent({ eventType: AUDITEVENTTYPE.MemberPortalSSOInComm });
                },
            },
            {
                icon: 'reception-bell',
                header: `Access Concierge`,
                subHeader: `Send your concierge a message`,
                to: '/message-center',
            },
            {
                icon: 'medication',
                header: 'Find a Drug',
                subHeader: 'View the prescription drugs covered by your plan',
                href: getPrimeLink(PrimeLandingPages.FINDADRUG),
                target: '_blank',
            },
            {
                icon: 'hospital',
                header: 'Find A Provider',
                subHeader: 'Search for a doctor or health care facility',
                href: providerSearchSite,
                target: '_blank',
            },
            {
                icon: 'credit-cards',
                header: 'Pay My Premium',
                subHeader: `Make a payment online`,
                href: payspanLink,
                target: '_blank',
            },
        ];

        return (
            <div>
                <Container fluid id="home-banner" className="jumbotron">
                    <Row>
                        <Col md="7">
                            Welcome,{' '}
                            <span className="bold-text">
                                {(this.props.member || {}).first_name}
                            </span>
                            <div className="accent" />
                        </Col>
                        <Col md="5" className="justify-content-center">
                            <img
                                className="access-card"
                                src="/img/access-card.png"
                                alt="Access Card"
                            />
                        </Col>
                    </Row>
                </Container>

                <h1 className="font-weight-normal text-center">My Member ID Card</h1>
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <Link to="id-card">
                                {this.props.frontIdImgSrc ? (
                                    <img
                                        className="card-shadow"
                                        src={this.props.frontIdImgSrc}
                                        width="100%"
                                        alt="Access Card front side"
                                    />
                                ) : (
                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{ height: '375px' }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            aria-label="Currently Loading Content"
                                            size="3x"
                                        />
                                    </div>
                                )}
                            </Link>
                        </Col>
                    </Row>
                </Container>
                <h1 className="font-weight-normal text-center">I would like to</h1>
                <Container>
                    <Row>
                        {posts.map((post, i) => (
                            <Col key={i} md="4">
                                <LinkedPost {...post} />
                            </Col>
                        ))}
                    </Row>
                </Container>
                <Container fluid className="mb-0 jumbotron">
                    <Container>
                        <h1 className="text-center">Call Us</h1>
                        <ContactInfo />
                    </Container>
                </Container>
            </div>
        );
    }
}

export default withPolicyData(withMemberCardData(withMemberData(Home)));

export { Home };
