import { MedicalGroup } from 'components/pages/PcpChange/PcpChange';
import {
    formatAsPhone,
    getAddressAsString,
    getIsAcceptingNewPatients,
    getIsPcp,
    getName,
    getSpecialtiesAsString,
} from 'components/pages/PcpChange/util';
import { Providers } from 'interfaces/Provider';
import React from 'react';

interface ProviderReviewProps {
    effectiveDate: string;
    medicalGroup: MedicalGroup;
    provider: Providers;
}

const ProviderReview = ({ effectiveDate, medicalGroup, provider }: ProviderReviewProps) => {
    return (
        <div>
            <div className="p-3">
                <p className="mb-2">
                    <span className="label">Effective Date: </span>
                    {effectiveDate ?? ''}
                </p>
                <p className="label mb-1">{getName(provider.nppes)}</p>
                <p>
                    <span className="label">NPI: </span> {provider.npi}
                </p>
                <p>
                    <span className="label">Specialty/PCP: </span>{' '}
                    {getIsPcp(medicalGroup.record)
                        ? 'Primary Care Practitioner'
                        : getSpecialtiesAsString(medicalGroup.record)}
                </p>
                <p>
                    <span className="label">Gender: </span> {provider.nppes?.gender}
                </p>
                <p>
                    <span className="label">Language: </span> {provider.primaryLanguage}
                </p>
                <p>
                    <span className="label">Address: </span>{' '}
                    {medicalGroup.address && getAddressAsString(medicalGroup.address)}
                </p>
                <p>
                    <span className="label">Phone: </span>{' '}
                    {formatAsPhone(medicalGroup.address.phone)}
                </p>
                <p>
                    <span className="label">Fax: </span> {formatAsPhone(medicalGroup.address.fax)}
                </p>
                <p>
                    <span className="label">Medical Group: </span> {medicalGroup.record.ipa?.desc}
                </p>
                <p>
                    <span className="label">Provider ID: </span> {medicalGroup.record.providerID}
                </p>
                <p>
                    <span className="label">
                        {medicalGroup.address && getIsAcceptingNewPatients(medicalGroup.address)
                            ? 'Accepting New (All) Patients'
                            : 'Accepting New Patients: '}
                    </span>{' '}
                    {medicalGroup.address &&
                        !getIsAcceptingNewPatients(medicalGroup.address) &&
                        'No'}
                </p>
            </div>
        </div>
    );
};

export default ProviderReview;
