import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';
import FormHOC from '../../Form/FormHOC';
import PersonalInformationFields from './PersonalForms/PersonalInformation';
import ResponsiblePartyFields from './PersonalForms/ResponsibleParty';
import EmergencyInformationFields from './PersonalForms/EmergencyInformation';
import InsuranceInformationFields from './PersonalForms/InsuranceInformation';
import AdditionalInformationFields from './PersonalForms/AdditionalInformation';
import { validators } from 'components/Form/Patient/Validations';
import { PatientPages } from 'components/Form/Patient/Enums';

const FormContent = ({ prevPageAction, onSubmit, state }) => {
    const { control, handleSubmit, errors } = useFormContext();
    return (
        <>
            <PersonalInformationFields control={control} state={state} errors={errors} />
            <hr />

            <h2 className="pb-4">Additional Information</h2>
            <AdditionalInformationFields control={control} state={state} />
            <hr />

            <ResponsiblePartyFields control={control} state={state} />
            <hr />

            <h2 className="pb-4">In Case of Emergency Notify</h2>
            <EmergencyInformationFields control={control} state={state} />
            <hr />

            <h2 className="pb-4">Insurance Information</h2>
            <InsuranceInformationFields control={control} state={state} />
            <hr />

            <div className="d-flex justify-content-center mt-3">
                <div className="d-flex justify-content-around w-75">
                    <Button onClick={prevPageAction} size="lg">
                        Go Back
                    </Button>

                    <Button onClick={handleSubmit(onSubmit)} variant="primary" size="lg">
                        Save and Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

const BasicInformationForm = ({ state, onSubmit, props, ...rest }) => {
    return (
        <FormHOC yupValidations={validators[PatientPages.BASIC_INFO]}>
            <FormContent onSubmit={onSubmit} {...rest} state={state} />
        </FormHOC>
    );
};

export default BasicInformationForm;
