import { formatKey } from './utils/format';
import get from 'lodash/get';
import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import CheckboxWithController from './Checkbox';
import { TextValueOption } from '../../interfaces/Form';

interface MultiSelectCheckboxWithControllerProps {
    control: Control<FieldValues>;
    name: any;
    defaultValue: object;
    question: string | null;
    options: string[] | null | undefined;
    disabled: boolean | null | undefined;
    required: boolean | null | undefined;
    errors: object | null;
}

const MultiSelectCheckboxWithController: React.FC<MultiSelectCheckboxWithControllerProps> = ({
    control,
    name,
    defaultValue,
    question,
    options,
    disabled,
    required,
    errors,
}: MultiSelectCheckboxWithControllerProps) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ onChange, onBlur, value, ref }) => (
                <div className="pt-3">
                    <p className="h5 font-weight-bold">
                        {question}
                        <span className="text-danger">{required ? ' *' : ' '}</span>
                    </p>
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                        {options?.map((option) => (
                            <li key={`${name}.${formatKey(option)}]`}>
                                <CheckboxWithController
                                    control={control}
                                    name={`${name}.${formatKey(option)}`}
                                    defaultValue={get(defaultValue, option, false)}
                                    question={option}
                                    disabled={disabled}
                                    required={required}
                                    updateError={(changedValue: boolean) =>
                                        onChange({
                                            ...value,
                                            [formatKey(option)]: changedValue,
                                        })
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                    <p className="h5 text-danger">{get(errors, `[${name}].message`)}</p>
                </div>
            )}
        />
    );
};

export default MultiSelectCheckboxWithController;
