import React, { useState, useEffect } from 'react';
import TableInfoPage from '../templates/TableInfoPage';
import withLabData from '../../hoc/withLabData';
import { search } from '../../common/utility';
import { isNil } from 'lodash';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';

const Labs = (props) => {
    const [searchTerm, updateSearchTerm] = useState(null);

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewLabs })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    const onSearchChange = (term) => {
        updateSearchTerm(term);
    };

    return (
        <TableInfoPage
            pageHeader={'Labs'}
            searchBarPlaceholder={'Search Labs by Name'}
            onSearchChange={onSearchChange}
            data={!isNil(props.labs) && search(props.labs || [], searchTerm)}
            fields={[
                {
                    name: 'component',
                    header: 'Name',
                    col: { lg: 3 },
                    format: 'header',
                },
                {
                    name: 'resultNum',
                    header: 'Result Value',
                    col: { lg: 3 },
                },
                {
                    name: 'rangeDisplay',
                    header: 'Range',
                    col: { lg: 3 },
                },
                {
                    name: 'dateofObservation',
                    header: 'Date',
                    col: { lg: 3 },
                    format: 'date',
                },
            ]}
        />
    );
};

export default withLabData(Labs);
