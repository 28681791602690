import React from 'react';
import useFeatureFlags from '../hooks/useFeatureFlags';

const withFeatureFlags = (WrappedComponent) => {
    return (props) => {
        const { featureFlags } = useFeatureFlags();

        return <WrappedComponent featureFlags={featureFlags} {...props} />;
    };
};

export default withFeatureFlags;
