import { useEffect, useMemo, useState } from 'react';
import { beforeRedirect$ } from 'services/subjects';
import { useSessionStorage } from './useSessionStorage';

export const usePersistedStateAfterARedirection = <TValue>(
    key: string,
    value: TValue,
    {
        serialize,
        deserialize,
    }: {
        serialize: (value: TValue) => string | Promise<string>;
        deserialize: (stringifiedValue: string) => TValue;
    } = {
        serialize: JSON.stringify,
        deserialize: JSON.parse as (stringifiedValue: string) => TValue,
    }
) => {
    const { getAndRemove, setItem } = useSessionStorage();
    const state = useState(
        useMemo(() => {
            try {
                return (deserialize(getAndRemove(key) || '') as TValue) || value;
            } catch {
                return value;
            }
        }, [])
    );

    useEffect(() => {
        const beforeRedirectSubscription = beforeRedirect$.subscribe(async () => {
            setItem(key, await serialize(state[0]));
        });
        return () => beforeRedirectSubscription.unsubscribe();
    }, [state[0]]);
    return state;
};
