import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Alert } from 'reactstrap';
import withPreferencData from '../../hoc/withPreferenceData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import withMemberData from '../../hoc/withMemberData';
import PageHeader from '../molecules/PageHeader';
import PreferenceOptionRadios from '../molecules/input/PreferenceOptionRadios';
import { getPrimeLink, PrimeLandingPages } from '../../services/ssoService';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';

const Preferences = (props) => {
    const [changed, setChanged] = useState(false);
    const [userPreferences, setUserPreference] = useState(props.userPreferences);
    const [invalidEmail, setInvalidEmail] = useState(false);

    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewCommunicationPreferences })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!userPreferences) {
            setUserPreference(props.userPreferences);
        }
    }, [userPreferences, props.userPreferences]);

    return userPreferences?.length > 0 ? (
        <div>
            <PageHeader>My Communication Preferences</PageHeader>
            <p dangerouslySetInnerHTML={{ __html: props.preferenceData?.description }}></p>
            <div>
                <Alert
                    isOpen={invalidEmail}
                    toggle={() => setInvalidEmail(false)}
                    color="danger"
                    aria-atomic="true"
                >
                    The email address we have on file is invalid. Please call On Demand Concierge
                    [1-833-242-2223 (TTY 711)] to get the email address added to the file.
                </Alert>
                <h2>Health Plan Materials</h2>
                <hr />
                {userPreferences?.map((preference, i) => {
                    return (
                        <Row key={`${preference?.name}_${i}`} className="align-items-center">
                            <Col md="6">
                                <h3>{preference?.label}</h3>
                                <p>{preference?.description}</p>
                            </Col>
                            <Col
                                xs={{ size: 8, offset: 2 }}
                                md={{ size: 4, offset: 1 }}
                                className="d-flex justify-content-between mt-3 mb-3"
                            >
                                {userPreferences === undefined ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            aria-label="Currently Loading Content"
                                            size="2x"
                                        />
                                    </div>
                                ) : preference?.name === 'PartD EOB' ? (
                                    <a
                                        href={getPrimeLink(
                                            PrimeLandingPages.COMMUNICATIONPREFERENCES
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <b>View Part D EOB Communication Preferences</b>
                                    </a>
                                ) : (
                                    <PreferenceOptionRadios
                                        member={props.member}
                                        options={preference?.memberPreferences}
                                        preference={preference}
                                        userPreferences={userPreferences}
                                        setChanged={setChanged}
                                        setInvalidEmail={setInvalidEmail}
                                        setUserPreference={setUserPreference}
                                        disabled={invalidEmail}
                                    />
                                )}
                            </Col>
                            <Col md="12">
                                <hr />
                            </Col>
                        </Row>
                    );
                })}
                <Row>
                    <Col className="text-center mt-4 mb-4">
                        <Button
                            color="primary"
                            onClick={async () => {
                                await props.updatePreference(userPreferences);
                                setChanged(false);
                            }}
                            disabled={!changed}
                        >
                            Save Changes
                        </Button>
                        {props.updating ? (
                            <FontAwesomeIcon
                                className="button-icon"
                                icon={faCircleNotch}
                                aria-label="Update in progress.."
                            />
                        ) : props.updateFailure ? (
                            <FontAwesomeIcon
                                className="button-icon"
                                icon={faTimesCircle}
                                aria-label="Update failed"
                            />
                        ) : (
                            <FontAwesomeIcon
                                className="button-icon fade-out"
                                icon={faCheckCircle}
                                aria-label="Update successful"
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    ) : null;
};

export default withPreferencData(withMemberData(Preferences));

Preferences.propTypes = {
    userPreferences: PropTypes.array,
    member: PropTypes.shape({
        email: PropTypes.string,
    }),
};
