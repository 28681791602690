import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Subscription } from 'rxjs';
import {
    parseMarkdownHyperlink,
    jsDateTimeToString,
    openPdfResponseInNewTab,
} from 'common/utility';
import { getHasUnread } from 'components/pages/MessageCenter';
import { PlanTypes } from 'hoc/Enums';
import withMemberData from 'hoc/withMemberData';
import withPcpData from 'hoc/withPcpData';
import withPolicyData from 'hoc/withPolicyData';
import { AttachedFile } from 'interfaces/AttachedFile';
import { Interaction } from 'interfaces/Interaction';
import { messageService } from 'services/messageService';
import FileUploader from './FileUploader';
import { messageTheme } from './MessageTheme';
import { MemberPortalConfig } from 'interfaces/MemberPortalConfig';
import { usePersistedStateAfterARedirection } from 'hooks/usePersistedStateAfterARedirection';

declare module '@mui/material/styles' {
    interface Palette {
        gray: Palette['primary'];
    }
    interface PaletteOptions {
        gray: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        gray: true;
    }
}

interface Errors {
    recipient: string | null;
    category: string | null;
    message: string | null;
}

interface MessageProps {
    hasVirtualCare: boolean;
    interaction: Interaction | null;
    memberId: string;
    memberPortalConfig: MemberPortalConfig | null;
    onBackClick: () => void;
    onMessageSuccess: () => void;
    planType: PlanTypes;
    refreshInteractions: () => void;
    onMessageError: () => void;
    onCaseClosedError: (interactionId: string) => void;
}

const CASE_HAS_BEEN_CLOSED_ERROR_STATUS = 412;
const Message = ({
    hasVirtualCare,
    interaction,
    memberId,
    memberPortalConfig,
    onBackClick,
    onMessageSuccess,
    planType,
    refreshInteractions,
    onMessageError,
    onCaseClosedError,
}: MessageProps) => {
    const isCaseClosed = Boolean(interaction?.isCaseClosed);
    const isVirtualHmo = hasVirtualCare && planType === PlanTypes.HMO;
    const serializeFiles = async (files: File[]) => {
        const allFiles = await Promise.all(
            files.map(
                (file) =>
                    new Promise<string>((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = (event: ProgressEvent<FileReader>) => {
                            const base64String = event.target?.result;
                            const metadata = JSON.stringify({ name: file.name, type: file.type });
                            const metadataEncoded = btoa(metadata); // Base64 encode the metadata
                            const combined = `${metadataEncoded}|${base64String || ''}`;
                            resolve(combined);
                        };
                        reader.onerror = (error) => reject(''); // Reject the promise on error
                        reader.readAsDataURL(file); // Reads the file as Data URL (Base64)
                    })
            )
        );
        return JSON.stringify(allFiles);
    };
    const deserializeFiles = (filesAsString: string) => {
        const stringifiedFiles = JSON.parse(filesAsString) as string[];
        return stringifiedFiles.map((stringifiedFile) => {
            const [metadataEncoded, base64Data] = stringifiedFile.split('|');
            const metadataJson = atob(metadataEncoded);
            const metadata = JSON.parse(metadataJson);

            const byteCharacters = atob(base64Data.split(',')[1]);
            const byteArrays = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteArrays);
            const blob = new Blob([byteArray], { type: metadata.type });
            const file = new File([blob], metadata.name, { type: metadata.type });
            return file;
        });
    };

    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    const [recipient, setRecipient] = usePersistedStateAfterARedirection<string | null>(
        'recipient',
        interaction?.recipient ?? null
    );
    const [category, setCategory] = usePersistedStateAfterARedirection<string | null>(
        'category',
        interaction?.category ?? null
    );
    const [subject, setSubject] = usePersistedStateAfterARedirection<string | null>(
        'subject',
        null
    );
    const [messageText, setMessageText] = usePersistedStateAfterARedirection<string | null>(
        'messageText',
        null
    );
    const [files, setFiles] = usePersistedStateAfterARedirection<File[]>('files', [], {
        serialize: serializeFiles,
        deserialize: deserializeFiles,
    });
    const [errors, setErrors] = useState<Errors>({
        recipient: null,
        category: null,
        message: null,
    });

    useEffect(() => {
        if (interaction && getHasUnread(interaction, memberId)) {
            const markReadSub = messageService.markMessagesRead$(interaction.id).subscribe({
                next: (response) => {
                    if (response.response.success) {
                        refreshInteractions();
                    }
                },
            });
            setSubscriptions([...subscriptions, markReadSub]);
        }

        return () => {
            subscriptions.forEach((sub) => sub.unsubscribe());
        };
    }, []);

    useEffect(() => {
        if (errors.recipient && recipient) {
            setErrors((prevErrors) => ({ ...prevErrors, recipient: null }));
        }
    }, [recipient]);

    useEffect(() => {
        if (errors.category && category) {
            setErrors((prevErrors) => ({ ...prevErrors, category: null }));
        }
    }, [category]);

    useEffect(() => {
        if (errors.message && messageText && messageText.length > 0) {
            setErrors((prevErrors) => ({ ...prevErrors, message: null }));
        }
    }, [messageText]);

    const onSendClick = () => {
        setAlertMessage(null);
        const tempErrors: Errors = { recipient: null, category: null, message: null };
        if (!interaction) {
            if (isVirtualHmo && !recipient) {
                tempErrors.recipient = 'This field is required';
            }
            if (!category) {
                tempErrors.category = 'This field is required';
            }
        }
        if (!messageText || messageText.length < 1) {
            tempErrors.message = 'Please enter a message';
        }
        setErrors(tempErrors);
        if (tempErrors.recipient || tempErrors.category || tempErrors.message) {
            return;
        }

        if (Array.isArray(files) && files.length > 0) {
            uploadFiles();
        } else {
            if (interaction) {
                addMessage();
            } else {
                createNewInteraction();
            }
        }
    };

    const uploadFiles = () => {
        setLoading(true);
        var formData = new FormData();
        for (let x = 0; x < files.length; x++) {
            formData.append('files', files[x]);
        }
        const uploadFilesSub = messageService.uploadFilesForInteraction$(formData).subscribe({
            next: (response) => {
                if (response.response.success) {
                    if (interaction) {
                        addMessage(response.response.attachedFiles);
                    } else {
                        createNewInteraction(response.response.attachedFiles);
                    }
                } else {
                    setAlertMessage(
                        'Sorry, we ran into an issue while sending your message. Please try again later.'
                    );
                    setLoading(false);
                }
            },
            error: () => {
                setLoading(false);
                setAlertMessage(
                    'Sorry, we ran into an issue while sending your message. Please try again later.'
                );
            },
        });
        setSubscriptions([...subscriptions, uploadFilesSub]);
    };

    const addMessage = (attachedFiles?: AttachedFile[]) => {
        if (!interaction || !messageText) {
            return;
        }

        setLoading(true);
        const addMessageSub = messageService
            .addMessage$(interaction.id, messageText, attachedFiles ?? [])
            .subscribe({
                next: ({ response }) => {
                    if (response.success) {
                        onMessageSuccess();
                    } else {
                        onMessageError();
                        setAlertMessage(
                            'Sorry, we ran into an issue while sending your message. Please try again later.'
                        );
                    }
                    setLoading(false);
                },
                error: (error) => {
                    setLoading(false);
                    setAlertMessage(
                        error.response?.Message ||
                            'Sorry, we ran into an issue while sending your message. Please try again later.'
                    );
                    if (error.status === CASE_HAS_BEEN_CLOSED_ERROR_STATUS && onCaseClosedError) {
                        onCaseClosedError(interaction.id);
                    }
                },
            });

        setSubscriptions([...subscriptions, addMessageSub]);
    };

    const createNewInteraction = (attachedFiles?: AttachedFile[]) => {
        if ((isVirtualHmo && !recipient) || !category || !messageText) {
            return;
        }

        setLoading(true);
        const createInteractionSub = messageService
            .createInteraction$(
                recipient ?? 'Concierge Team',
                category,
                subject,
                messageText,
                attachedFiles ?? []
            )
            .subscribe({
                next: (response) => {
                    if (response.response.success) {
                        onMessageSuccess();
                    } else {
                        setAlertMessage(
                            'Sorry, we ran into an issue while sending your message. Please try again later.'
                        );
                    }
                    setLoading(false);
                },
                error: () => {
                    setLoading(false);
                    setAlertMessage(
                        'Sorry, we ran into an issue while sending your message. Please try again later.'
                    );
                },
            });

        setSubscriptions([...subscriptions, createInteractionSub]);
    };

    const downloadAttachment = (attachment: AttachedFile) => {
        setLoading(true);
        const getAttachmentSub = messageService
            .getInteractionFileContent$(attachment.fileId)
            .subscribe({
                next: (response) => {
                    setLoading(false);
                    if (response.response && response.response.success) {
                        openPdfResponseInNewTab(
                            base64ToArrayBuffer(response.response.fileContent),
                            attachment.contentType
                        );
                    } else {
                        setAlertMessage('Sorry, we ran into an issue while retrieving the file.');
                    }
                },
                error: () => {
                    setLoading(false);
                    setAlertMessage('Sorry, we ran into an issue while retrieving the file.');
                },
            });

        setSubscriptions([...subscriptions, getAttachmentSub]);
    };

    const base64ToArrayBuffer = (base64: string) => {
        const binaryString = atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const getCategories = () => {
        let cats = memberPortalConfig?.messageCenterCategories ?? [];
        if (isVirtualHmo && recipient === 'PCP Office') {
            cats = memberPortalConfig?.messageCenterPcpCategories ?? [];
        }
        return cats;
    };

    return (
        <ThemeProvider theme={messageTheme}>
            <div style={{ position: 'relative' }}>
                {alertMessage && (
                    <Alert className="mb-1" severity="error">
                        {alertMessage}
                    </Alert>
                )}
                <div className="mb-2">
                    <Button onClick={onBackClick}>
                        <ArrowBackIcon />
                        <span className="ml-2">Back</span>
                    </Button>
                </div>
                <div className="mb-3">
                    {!interaction && (
                        <div>
                            <Grid container spacing={2}>
                                {isVirtualHmo && (
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <FormControl
                                            error={errors.recipient ? true : false}
                                            fullWidth
                                            size="small"
                                        >
                                            <Select
                                                displayEmpty
                                                onChange={(args) => {
                                                    setRecipient(
                                                        args.target.value ? args.target.value : null
                                                    );
                                                }}
                                                value={recipient ?? ''}
                                            >
                                                <MenuItem value=""> -- To -- </MenuItem>
                                                {hasVirtualCare ? (
                                                    <MenuItem value="Virtual Care">
                                                        Virtual Care/Concierge Team
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem value="Concierge Team">
                                                        Virtual Care/Concierge Team
                                                    </MenuItem>
                                                )}
                                                <MenuItem value="PCP Office">PCP Office</MenuItem>
                                            </Select>
                                            <FormHelperText>{errors.recipient}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={isVirtualHmo ? 6 : 12}
                                    lg={isVirtualHmo ? 6 : 12}
                                >
                                    <FormControl
                                        error={errors.category ? true : false}
                                        fullWidth
                                        size="small"
                                    >
                                        <Select
                                            displayEmpty
                                            onChange={(args) => {
                                                setCategory(
                                                    args.target.value ? args.target.value : null
                                                );
                                            }}
                                            value={category ?? ''}
                                        >
                                            <MenuItem value=""> -- Select Category -- </MenuItem>
                                            {getCategories().map((category, i) => (
                                                <MenuItem key={`category-${i}`} value={category}>
                                                    {category}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.category}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <TextField
                                    fullWidth
                                    onChange={(args) => {
                                        if (args.target.value) {
                                            if (args.target.value.length <= 60) {
                                                setSubject(args.target.value);
                                            }
                                        } else {
                                            setSubject(null);
                                        }
                                    }}
                                    placeholder="Subject"
                                    value={subject ?? ''}
                                />
                                <p
                                    className="mb-0"
                                    style={{
                                        alignSelf: 'flex-end',
                                        color: subject && subject.length >= 60 ? 'red' : 'black',
                                    }}
                                >
                                    Max 60 characters
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="mt-3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            error={errors.message ? true : false}
                            disabled={isCaseClosed}
                            fullWidth
                            helperText={errors.message}
                            multiline
                            onChange={(args) => {
                                if (args.target.value) {
                                    if (args.target.value.length <= 1200) {
                                        setMessageText(args.target.value);
                                    }
                                } else {
                                    setMessageText(null);
                                }
                            }}
                            placeholder={
                                isCaseClosed
                                    ? 'Replies are disabled.'
                                    : `Type message here${interaction ? ' to reply' : ''}`
                            }
                            rows={5}
                            value={messageText ?? ''}
                        />
                        <p
                            className="mb-0"
                            style={{
                                alignSelf: 'flex-end',
                                color: messageText && messageText.length >= 1200 ? 'red' : 'black',
                            }}
                        >
                            Max 1200 characters
                        </p>
                    </div>
                    {!isCaseClosed && (
                        <div>
                            <FileUploader
                                onChange={(files: File[]) => {
                                    setFiles(files);
                                }}
                                initialFiles={files}
                            />
                        </div>
                    )}
                    <div
                        className="mt-4"
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                    >
                        <Button color="gray" onClick={onBackClick} variant="contained">
                            Cancel
                        </Button>
                        <Button
                            className="ml-3"
                            disabled={isCaseClosed}
                            onClick={onSendClick}
                            variant="contained"
                        >
                            Send
                        </Button>
                    </div>
                </div>
                {(interaction?.memberPortalMessageHistory?.length ?? 0) > 0 && (
                    <div className="mb-5">
                        <p className="mb-1" style={{ fontWeight: 'bold' }}>
                            Message History
                        </p>
                        <div
                            style={{
                                maxHeight: '500px',
                                overflowY: 'auto',
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {interaction?.memberPortalMessageHistory?.map((message, i) => (
                                <div
                                    className="mb-1 p-3"
                                    key={`message-${i}`}
                                    style={{
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                >
                                    <div style={{ fontWeight: 'bold' }}>
                                        {message.authorName} {jsDateTimeToString(message.date)}
                                    </div>
                                    <p className="m-0">{parseMarkdownHyperlink(message.message)}</p>
                                    {message.attachments && message.attachments.length > 0 && (
                                        <div className="mt-2">
                                            <span style={{ fontWeight: 'bold' }}>Attachments:</span>
                                            {message.attachments.map((attachment, i) => (
                                                <div
                                                    key={`file-${i}`}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: 'blue',
                                                        textDecoration: 'underline',
                                                    }}
                                                    className="py-1"
                                                    onClick={() => {
                                                        downloadAttachment(attachment);
                                                    }}
                                                >
                                                    {attachment.fileName}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {loading && (
                    <CircularProgress
                        style={{
                            position: 'absolute',
                            top: 'calc(50% - 20px)',
                            left: 'calc(50% - 20px)',
                            height: '40px',
                            width: '40px',
                        }}
                    />
                )}
            </div>
        </ThemeProvider>
    );
};

export default withMemberData(withPcpData(withPolicyData(Message)));
