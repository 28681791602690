import React, { forwardRef, useCallback, useState, ChangeEvent } from 'react';
import { Controller, Control, ErrorOption } from 'react-hook-form';
import { Row, Col, Form } from 'react-bootstrap';
import { handleEnterKey } from 'common/accessibilityHelpers';
import { Option } from '../Models';

interface SelectWithControllerProps {
    control: Control<any>;
    question: string | undefined;
    options: Option[];
    name: string;
    defaultValue: string | undefined;
    error: ErrorOption;
    disabled?: boolean;
    colSize?: number;
    isHeading?: boolean;
}

const SelectWithController = forwardRef<HTMLInputElement, SelectWithControllerProps>(
    ({ control, name, defaultValue, question, options, disabled, isHeading }, ref) => {
        const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;

            setSelectVal(newValue);
        }, []);
        const [selectVal, setSelectVal] = useState<string | number>(defaultValue || '');
        return (
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({ onChange, onBlur, value }) => (
                    <Form.Group ref={ref} className="pt-3">
                        <Form.Label
                            className={isHeading ? 'h2' : 'h5 mt-2 font-weight-bold'}
                            id={`label-question-select-${name}`}
                        >
                            {question}
                        </Form.Label>
                        <Form.Control
                            as="select"
                            size="lg"
                            className={'border border-dark'}
                            value={selectVal}
                            id={name}
                            aria-labelledby={`label-question-select-${name}`}
                            disabled={disabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleChange(e);
                                onChange(e.target.value);
                            }}
                        >
                            <option value="" disabled>
                                Select...
                            </option>
                            {options.map((option: Option) => (
                                <option value={option.value}>{option.label}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                )}
            />
        );
    }
);

export default SelectWithController;
