import { YES_NO_NA } from 'components/Form/Patient/Constants';
import { YesNo } from 'components/Form/Patient/Enums';
import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import { TextAreaFieldWithController } from 'components/Form/Patient/Input/TextAreaInput';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import DeviceUseInformation from '../PersonalForms/DeviceUseInformation';

const SmokingHabits = ({ control, state }) => {
    const didQuitSmoking = useWatch({
        control,
        name: 'didQuitSmoking',
        defaultValue: state?.didQuitSmoking || YesNo.NA,
    });
    const hasEverSmoked = useWatch({
        control,
        name: 'hasEverSmoked',
        defaultValue: state?.hasEverSmoked || YesNo.NA,
    });
    return (
        <>
            <Row>
                <Col>
                    <RadiosWithController
                        defaultValue={state?.hasEverSmoked}
                        question="Do you now/have you ever smoked?"
                        name="hasEverSmoked"
                        options={YES_NO_NA}
                        colSize={12}
                    />
                </Col>
            </Row>
            {hasEverSmoked === YesNo.YES && (
                <Row>
                    <Col md={6}>
                        <TextFieldWithController
                            defaultValue={state?.smokingHistory}
                            question="If yes, how long have/did you smoke?"
                            name="smokingHistory"
                            showError={false}
                        />
                    </Col>
                    <Col md={6}>
                        <TextFieldWithController
                            defaultValue={state?.packsPerDay}
                            question="How many packs per day?"
                            name="packsPerDay"
                            showError={false}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <RadiosWithController
                        defaultValue={state?.didQuitSmoking}
                        question="Did you quit?"
                        name="didQuitSmoking"
                        options={YES_NO_NA}
                        colSize={12}
                    />
                </Col>
            </Row>
            {didQuitSmoking === YesNo.YES && (
                <Row>
                    <Col md={6}>
                        <TextFieldWithController
                            defaultValue={state?.yearQuitSmoking}
                            question="If yes, what year did you quit?"
                            name="yearQuitSmoking"
                            showError={false}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};
const PainHistory = ({ control, state }) => {
    const hasPainHistory = useWatch({
        control,
        name: 'hasPainHistory',
        defaultValue: state?.hasPainHistory || YesNo.NA,
    });
    return (
        <>
            <Row>
                <Col>
                    <RadiosWithController
                        defaultValue={state?.hasPainHistory}
                        question="In the past 6 months, have you had a regular problem with pain?"
                        name="hasPainHistory"
                        options={YES_NO_NA}
                        colSize={12}
                    />
                </Col>
            </Row>
            {hasPainHistory === YesNo.YES && (
                <Row>
                    <Col md={6}>
                        <TextFieldWithController
                            defaultValue={state?.painLocation}
                            question="Where?"
                            name="painLocation"
                            showError={false}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};
const DietInformation = ({ control, state }) => {
    const followsHealthyDiet = useWatch({
        control,
        name: 'followsHealthyDiet',
        defaultValue: state?.followsHealthyDiet || YesNo.NA,
    });
    return (
        <>
            <Row>
                <Col>
                    <RadiosWithController
                        defaultValue={state?.followsHealthyDiet}
                        question="Do you follow a healthy diet?"
                        name="followsHealthyDiet"
                        options={YES_NO_NA}
                        colSize={12}
                    />
                </Col>
            </Row>
            {followsHealthyDiet === YesNo.YES && (
                <Row>
                    <Col>
                        <TextAreaFieldWithController
                            defaultValue={state?.dietDescription}
                            question="Please describe what type of diet you follow - well-balanced, low carb, low fat, etc."
                            name="dietDescription"
                            showError={false}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};
const HealthHabitsInformation = ({ control, state }) => {
    return (
        <>
            <SmokingHabits control={control} state={state} />
            <Row>
                <Col md={6}>
                    <TextFieldWithController
                        defaultValue={state?.alcoholPerWeek}
                        question="How many alcoholic beverages do you drink per week?"
                        name="alcoholPerWeek"
                        showError={false}
                    />
                </Col>
                <Col md={6}>
                    <TextFieldWithController
                        defaultValue={state?.daysExercisePerWeek}
                        question="How many days per week do you exercise?"
                        name="daysExercisePerWeek"
                        showError={false}
                    />
                </Col>
            </Row>
            <PainHistory control={control} state={state} />
            <Row>
                <Col md={6}>
                    <RadiosWithController
                        defaultValue={state?.hasGlassesOrCorrectiveLenses}
                        question="Do you wear glasses/corrective lenses?"
                        name="hasGlassesOrCorrectiveLenses"
                        options={YES_NO_NA}
                        colSize={6}
                    />
                </Col>
                <Col md={6}>
                    <RadiosWithController
                        defaultValue={state?.hasHearingAid}
                        question="Do you wear a hearing aid?"
                        name="hasHearingAid"
                        options={YES_NO_NA}
                        colSize={6}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="h5 mt-3 font-weight-bold">
                        Do you use any of the following equipment?
                    </div>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center py-3">
                <Col lg={7}>
                    <DeviceUseInformation control={control} state={state} />
                </Col>
            </Row>

            <DietInformation control={control} state={state} />
        </>
    );
};
export default HealthHabitsInformation;
