import React from 'react';
import PropTypes from 'prop-types';
import { currencyFormatter, currencyFormatterNearestDollar } from '../../common/utility';

const UsageBar = (props) => {
    const usagePct = props.total === 0 ? 0 : (props.used * 100) / props.total;
    const remaining = props.total - props.used;
    return (
        <div className="usage">
            <p className="rem-1-35">Max - {currencyFormatterNearestDollar.format(props.total)}</p>
            <div className="bar">
                <div className="bar fill" style={{ width: `${usagePct}%` }} />
            </div>
            <div className="labels">
                <div>
                    <p className="rem-1-35">{currencyFormatter.format(props.used)}</p>
                    <label>Spent</label>
                </div>
                <div>
                    <p className="rem-1-35">{currencyFormatter.format(remaining)}</p>
                    <label>Left</label>
                </div>
            </div>
        </div>
    );
};

export default UsageBar;

UsageBar.propTypes = {
    used: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};
