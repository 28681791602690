import React from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import ProviderCard from './ProviderCard';
import { getIsProviderEffective } from 'components/pages/PcpChange/util';
import { Providers } from 'interfaces/Provider';

interface ProviderSearchResultsProps {
    effectiveDate: string;
    onProviderSelect: (provider: Providers) => void;
    onSearchValueChange: (property: string, value: any) => void;
    pageNumber: number;
    providers: Providers[];
    searchValues: any;
    setQueueSearch: (value: boolean) => void;
    totalRecordsCount: number;
}

const ProviderSearchResults = ({
    effectiveDate,
    onProviderSelect,
    onSearchValueChange,
    pageNumber,
    providers,
    searchValues,
    setQueueSearch,
    totalRecordsCount,
}: ProviderSearchResultsProps) => {
    return (
        <div>
            <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}
            >
                <div>
                    <p className="mb-0">{totalRecordsCount} Result(s)</p>
                </div>
                <div>
                    <TextField
                        select
                        id="sortBy"
                        label="Sort By"
                        onChange={(args) => {
                            onSearchValueChange('sortby', args.target.value);
                        }}
                        value={searchValues.sortby}
                    >
                        <MenuItem value="distance">Distance</MenuItem>
                    </TextField>
                </div>
            </div>
            <hr />
            <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                <Grid container spacing={2}>
                    {providers.map((provider, i) => (
                        <Grid
                            item
                            sm={12}
                            md={12}
                            lg={6}
                            key={`provider-card-${provider.npi}-${i}`}
                            sx={{ width: '100%' }}
                        >
                            <div
                                style={{
                                    cursor: getIsProviderEffective(effectiveDate, provider)
                                        ? 'pointer'
                                        : 'not-allowed',
                                }}
                                onClick={() => {
                                    onProviderSelect(provider);
                                }}
                            >
                                <ProviderCard provider={provider} />
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className="my-3" style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                    color="primary"
                    count={Math.ceil(totalRecordsCount / 10)}
                    page={pageNumber}
                    onChange={(args, newPageNumber) => {
                        onSearchValueChange('pageNumber', newPageNumber);
                        setQueueSearch(true);
                    }}
                />
            </div>
        </div>
    );
};

export default ProviderSearchResults;
