import { ArrowBack, Close, FileOpenOutlined, TaskAlt } from '@mui/icons-material';
import { Alert, AlertTitle, CircularProgress, IconButton } from '@mui/material';
import { handleEnterKeyToClick } from 'common/accessibilityHelpers';
import { openPdfResponseInNewTab } from 'common/utility';
import { SubmissionResponse } from 'interfaces/HealthRiskAssessment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { healthRiskAssessmentService } from 'services/HealthRiskAssessmentService';

export interface SuccessConfirmationProps {
    submissionResponse: SubmissionResponse;
}

const SuccessConfirmation = ({ submissionResponse }: SuccessConfirmationProps) => {
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

    const getHealthRiskAssessmentPdf = () => {
        setIsLoading(true);
        const getHealthRiskAssessmentSub = healthRiskAssessmentService
            .getHealthRiskAssessmentPdf$(
                submissionResponse.applicationId as string,
                submissionResponse.storageBlobId as string
            )
            .subscribe({
                next: (response) => {
                    if (response) {
                        openPdfResponseInNewTab(response);
                    } else {
                        setError(true);
                        console.error('Something went wrong when retrieving the file.');
                    }
                },
                error: () => {
                    setError(true);
                    console.error('Something went wrong when retrieving the file.');
                },
            });
        setSubscriptions([...subscriptions, getHealthRiskAssessmentSub]);
        setIsLoading(false);
    };

    useEffect(() => subscriptions.forEach((subscription) => subscription.unsubscribe()), []);

    return (
        <Alert
            severity="success"
            icon={false}
            className="hra-confirmation-alert"
            data-testid="successAlert"
        >
            <AlertTitle className="hra-confirmation-alert-title">
                <TaskAlt fontSize="large" htmlColor="#4caf50" />
                <p className="hra-confirmation-title" data-testid="successAlertTitle">
                    Thank you for completing your Annual Health Assessment.
                </p>
            </AlertTitle>
            <p className="hra-confirmation-number" data-testid="successAlertReferenceNo">
                Confirmation Number: {submissionResponse?.referenceNo}
            </p>
            <p className="hra-confirmation-details">
                A nurse will call you to learn more about your health care needs.
            </p>
            <button
                className="hra-confirmation-document-link"
                onClick={getHealthRiskAssessmentPdf}
                aria-label="Click to download document"
                onKeyUp={handleEnterKeyToClick}
                tabIndex={0}
                disabled={isLoading}
                data-testid="pdfDownloadLink"
            >
                {isLoading && (
                    <div className="hra-confirmation-loading">
                        <CircularProgress color="inherit" size="2em" />
                    </div>
                )}
                <FileOpenOutlined aria-label={'Document File'} fontSize="medium" />
                <span className="hra-confirmation-filename">
                    Open {submissionResponse.fileName || 'file'}
                </span>
            </button>
            {error && (
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setError(false);
                            }}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    }
                    data-testid="errorMessage"
                >
                    Something went wrong when retrieving the file.
                </Alert>
            )}
            <Link to="/" className="hra-confirmation-return">
                <ArrowBack />
                Return to Home Page
            </Link>
        </Alert>
    );
};

export default SuccessConfirmation;
