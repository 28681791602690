import { FieldCategory } from '../interfaces/FieldCategory';
import { FormField } from '../interfaces/Form';
import { Segment } from '../interfaces/Segment';
import { isEmpty } from 'lodash';

/**
 * @param {FormField[] | null | undefined} fields - a list of fields corresponding to a form question
 */

const SEGMENT_SIZE = 5;

const segmentFields = (
    fields: FormField[] | null | undefined,
    categories: FieldCategory[] | undefined
): Segment[] | null => {
    if (!fields) return null;
    return isEmpty(categories) ? segmentDefault(fields) : segmentCategorized(fields, categories);
};

const segmentDefault = (fields: FormField[] | null | undefined): Segment[] | null => {
    let result: Segment[] = [];
    let formFields: FormField[] = [];
    fields?.forEach((field, index) => {
        if (formFields.length >= SEGMENT_SIZE) {
            result.push({
                label: `Page ${result.length + 1}`,
                fields: formFields,
            });
            formFields = [];
        }
        formFields.push({ ...field, listNumber: index + 1 });
    });
    if (formFields.length > 0) {
        result.push({
            label: `Page ${result.length + 1}`,
            fields: formFields,
        });
    }
    return result;
};

const segmentCategorized = (
    fields: FormField[] | null | undefined,
    categories: FieldCategory[] | undefined
) => {
    let result: any = {};
    categories?.forEach((category) => (result[category.categoryName] = []));
    result['Miscellaneous'] = [];

    let matchingCategory;

    fields?.forEach((field) => {
        matchingCategory = categories?.find(
            (category) => field.name && category.fields.includes(field.name)
        );
        if (matchingCategory && matchingCategory.categoryName) {
            result[matchingCategory.categoryName].push(field);
        } else {
            result['Miscellaneous'].push(field);
        }
    });

    if (result['Miscellaneous'].length < 1) delete result['Miscellaneous'];

    result = Object.entries(result).map(([categoryName, fields]) => ({
        label: categoryName,
        fields: fields,
    }));

    let listNumber = 1;
    result.forEach(
        (category: Segment) =>
            (category.fields = category.fields.map((field: FormField) => ({
                ...field,
                listNumber: listNumber++,
            })))
    );

    return result;
};

export default segmentFields;
