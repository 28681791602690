import React from 'react';
import Radio from '@mui/material/Radio';
import { AHRecord, AHRecordAddress } from 'interfaces/Provider';
import {
    formatAsPhone,
    getAddressAsString,
    getIsAcceptingNewPatients,
} from 'components/pages/PcpChange/util';
import { MedicalGroup } from 'components/pages/PcpChange/PcpChange';

interface MedicalGroupCardProps {
    ahRecord: AHRecord;
    onSelectMedicalGroup: (medicalGroup: MedicalGroup) => void;
    selectedMedicalGroup: MedicalGroup | null;
}

const MedicalGroupCard: React.FC<MedicalGroupCardProps> = ({
    ahRecord,
    onSelectMedicalGroup,
    selectedMedicalGroup,
}: MedicalGroupCardProps) => {
    return (
        <div>
            <p style={{ fontWeight: 'bold', fontSize: '.85rem' }}>{ahRecord.ipa.desc}</p>
            <p className="mb-0">
                <span style={{ fontWeight: 'bold' }}>Provider ID: </span>
                {ahRecord.providerID}
            </p>
            <p className="mb-0">
                <span style={{ fontWeight: 'bold' }}>Effective Date: </span>
                {ahRecord.effectiveDate}
            </p>
            <p className="mb-0">
                <span style={{ fontWeight: 'bold' }}>Term Date: </span>
                <span style={{ color: 'red' }}>{ahRecord.termDate}</span>
            </p>
            <div className="mb-0">
                <p style={{ fontWeight: 'bold' }}>Address(es): </p>
                {ahRecord.addresses &&
                    [...ahRecord.addresses]
                        .sort((a) => a.distanceFromGivenAddress)
                        .map((address, i) => (
                            <div
                                key={`${ahRecord.providerID}-${i}`}
                                className="mt-2"
                                style={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <div className="mr-2" style={{ marginTop: '-5px' }}>
                                    <Radio
                                        onChange={(args) => {
                                            if (args.target.checked) {
                                                onSelectMedicalGroup({
                                                    record: ahRecord,
                                                    address: address,
                                                });
                                            }
                                        }}
                                        checked={
                                            selectedMedicalGroup &&
                                            selectedMedicalGroup.record == ahRecord &&
                                            selectedMedicalGroup.address == address
                                                ? true
                                                : false
                                        }
                                        name={`selectedAddress-${i}`}
                                    />
                                </div>
                                <div className="vertical-flex">
                                    <p className="mb-0">{getAddressAsString(address)}</p>
                                    <p className="mb-0">{formatAsPhone(address.phone)}</p>
                                    <p className="mb-0">
                                        <span style={{ fontWeight: 'bold' }}>
                                            {getIsAcceptingNewPatients(address)
                                                ? 'Accepting New (All) Patients'
                                                : 'Accepting New Patients: '}
                                        </span>
                                        {!getIsAcceptingNewPatients(address) && `No`}
                                    </p>
                                </div>
                            </div>
                        ))}
            </div>
        </div>
    );
};

export default MedicalGroupCard;
