import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';

const AddressForm = (props) => {
    const header = props.address_type === 'Home' ? 'Permanent' : props.address_type;
    return (
        <div className="mb-4">
            <h3>{header} Address</h3>
            <Row>
                <Col md="4">
                    <FormGroup>
                        <Label
                            id={`label-for-${props.address_type}_address1`}
                            for={`${props.address_type}_address1`}
                        >
                            Address 1
                        </Label>
                        <Input
                            id={`${props.address_type}_address1`}
                            aria-labelledby={`label-for-${props.address_type}_address1`}
                            readOnly
                            name={`${props.address_type}_address1`}
                            defaultValue={props.street_name_1}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label
                            id={`label-for-${props.address_type}_address2`}
                            for={`${props.address_type}_address2`}
                        >
                            Address 2
                        </Label>
                        <Input
                            id={`${props.address_type}_address2`}
                            aria-labelledby={`label-for-${props.address_type}_address2`}
                            readOnly
                            name={`${props.address_type}_address2`}
                            defaultValue={props.street_name_2}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label
                            id={`label-for-${props.address_type}_city`}
                            for={`${props.address_type}_city`}
                        >
                            City
                        </Label>
                        <Input
                            id={`${props.address_type}_city`}
                            aria-labelledby={`label-for-${props.address_type}_city`}
                            readOnly
                            name={`${props.address_type}_city`}
                            defaultValue={props.city_name}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <FormGroup>
                        <Label
                            id={`label-for-${props.address_type}_state`}
                            for={`${props.address_type}_state`}
                        >
                            State
                        </Label>
                        <Input
                            id={`${props.address_type}_state`}
                            aria-labelledby={`label-for-${props.address_type}_state`}
                            readOnly
                            name={`${props.address_type}_state`}
                            defaultValue={props.state}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label
                            id={`label-for-${props.address_type}_zip`}
                            for={`${props.address_type}_zip`}
                        >
                            Zip Code
                        </Label>
                        <Input
                            id={`${props.address_type}_zip`}
                            aria-labelledby={`label-for-${props.address_type}_zip`}
                            readOnly
                            name={`${props.address_type}_zip`}
                            defaultValue={props.zip_code}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
};

export default AddressForm;

AddressForm.propTypes = {
    address_type: PropTypes.string.isRequired,
    street_name_1: PropTypes.string,
    street_name_2: PropTypes.string,
    city_name: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
};
