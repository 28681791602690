import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import withDocumentData, { WithDocumentDataState } from 'hoc/withDocumentData';
import { DocumentLink } from 'components/molecules/DocumentLink';
import PageHeader from 'components/molecules/PageHeader';
import Button from 'components/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { getPrimeLink } from 'services/ssoService';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';
import { first } from 'rxjs/operators';
import { DocumentHeader } from 'types/documents/DocumentHeader';
import { PDFViewerContext } from 'contexts/PdfViewerContext';

const SERVICE_URL = 'api/members/documents';
export type ExplanationOfBenefitsProps = WithDocumentDataState;

const ExplanationOfBenefits  = ({documents, loadingDocuments}: ExplanationOfBenefitsProps) => {

    const { addAndOpenDocument } = useContext(PDFViewerContext);
    useEffect(() => {
        trackService
                .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewExplanationOfBenefits })
                .pipe(first())
                .subscribe();
    }, []);

    const handleClickEOBStatements = () => {
        window.open(getPrimeLink('ExplanationOfBenefits'), '_blank');
    };

    const handleDocumentClick = (document: DocumentHeader) => {
            addAndOpenDocument({
                serviceUrl: SERVICE_URL,
                documentPath: document.id,
                displayName: document.filename || '',
            });
        }

        let documentsToDisplay = documents
        ?.filter((document) => document.data.document_type === 'eob')
        ?.map((document) => ({ ...document, filename: `Part C ${document.filename}` }));
    return (
        <div>
            <PageHeader>
                <span className="bold-text">Explanation of Benefits (EOB)</span>
            </PageHeader>
            <div>
                <p>
                    An explanation of benefits (EOB) is a monthly statement that explains what
                    costs were paid for medical treatments you received and/or prescription
                    drugs you purchased. Your Medicare Part C EOB provides information about
                    medical claims, while your Medicare Part D EOB provides information about
                    any prescriptions that were covered by Alignment Health Plan. The EOB is not
                    a bill.
                </p>
                <p>
                    While not all communications are available for email delivery, we are
                    working to provide you with more paperless options. If you have opted for
                    email communications through your profile settings, this electronic EOB
                    replaces the EOB sent to you by mail and you will be notified by email when
                    a new electronic EOB is available.
                </p>
                <p>
                    If for any reason you want to change your preferences back to paper mail
                    communications, you can select that option. Depending on the day of the
                    month that you update your communication preference, there may be a slight
                    delay processing your request due to print schedules.
                </p>
                <p>
                    This information is available for free in other languages. For assistance,
                    please call your 24/7 ACCESS On-Demand Concierge at 1-833-242-2223 (TTY
                    711).
                </p>
            </div>
            <div style={{ padding: '10px 0px' }}>
                <h5>
                    <span className="bold-text">Medicare Part D Pharmacy EOB</span>
                </h5>
                <br />
                <Button
                    type="primary"
                    text="View Part D EOB Statements"
                    onClick={handleClickEOBStatements}
                />
                <br />
            </div>
            <hr className="eob-partc-separator" />
            <div style={{ paddingTop: '10px' }}>
                <h5>
                    <span className="bold-text">Medicare Part C Medical and Hospital EOB</span>
                </h5>
                {loadingDocuments ? (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ padding: '30px 0' }}
                    >
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            aria-label="Currently Loading Content"
                            size="3x"
                        />
                    </div>
                ) : documentsToDisplay && documentsToDisplay.length > 0 ? (
                    <ul className="file-list mb-5" style={{ padding: '10px 0px' }}>
                        {documentsToDisplay.map((document, i) => (
                            <DocumentLink key={i} document={document} onDocumentClick={handleDocumentClick} />
                        ))}
                    </ul>
                ) : (
                    <p style={{ padding: '10px 0px' }}>No documents available</p>
                )}
            </div>
        </div>
    );
}

export { ExplanationOfBenefits };

export default withDocumentData(ExplanationOfBenefits);