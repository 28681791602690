import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { currencyFormatter, byString } from '../../common/utility';
import { handleEnterKeyToClick } from '../../common/accessibilityHelpers';
import './tableContent.scss';

export default class TableContent extends React.Component {
    constructor(props) {
        super(props);

        this.clickableRow = typeof props.rowClicked === 'function';
        this.fields = props.fields.map((field) => ({
            ...field,
            col: typeof field.col === 'object' ? field.col : { md: field.col },
            sortable: field.sortable ?? true,
        }));
    }

    getCell = (datum, field) => {
        switch (field.format) {
            case 'currency':
                return currencyFormatter.format(byString(datum, field.name));
            case 'date':
                var date = datum[field.name];
                return date
                    ? moment(new Date(date)).format(field.skeleton || 'YYYY-MM-DD HH:mm')
                    : '';
            case 'list':
                var value = datum[field.name];
                if (!value) {
                    return 'N/A';
                }
                return (
                    <ul>
                        {value.split(/\|\s*\|/).map((singleValue, i) => (
                            <li key={i}>{singleValue}</li>
                        ))}
                    </ul>
                );

            case 'preserve': {
                const value = datum[field.name];
                if (!value) {
                    return 'N/A';
                }
                return <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>;
            }

            default:
                return byString(datum, field.name);
        }
    };

    getSortIconProperties = (field) => {
        const { sortBy, sortDirection } = this.props;
        let sortIconProperties = {};
        if (sortBy !== field.name || sortDirection === 0) {
            sortIconProperties = {
                icon: faSort,
                ariaLabel: `Order ascending or descending by ${field.header}`,
            };
        } else if (sortDirection === 1) {
            sortIconProperties = {
                icon: faSortUp,
                ariaLabel: `Order ascending by ${field.header}`,
            };
        } else {
            sortIconProperties = {
                icon: faSortDown,
                ariaLabel: `Order descending by ${field.header}`,
            };
        }
        return sortIconProperties;
    };

    render() {
        const { sortBy, sortDirection, toggleSort } = this.props;

        return (
            <React.Fragment>
                <Row className="header d-none d-md-flex">
                    {this.fields.map((field) => {
                        const sortIconProperties = this.getSortIconProperties(field);
                        return (
                            <Col
                                key={`${field.name}`}
                                {...field.col}
                                onClick={() => toggleSort(field.name)}
                                onKeyUp={handleEnterKeyToClick}
                                tabIndex="0"
                                style={{ cursor: 'pointer' }}
                                aria-label={`Order ascending or descending by ${field.header}`}
                            >
                                <span className="bold-text">{field.header}</span>
                                {field.sortable && (
                                    <FontAwesomeIcon
                                        className="ml-2"
                                        icon={sortIconProperties.icon}
                                        aria-label={sortIconProperties.ariaLabel}
                                    />
                                )}
                            </Col>
                        );
                    })}
                </Row>

                {this.props.data.length !== 0
                    ? this.props.data.map((datum, i) => (
                          <Row
                              key={i}
                              className={`${i % 2 === 0 ? '' : 'gray'} ${
                                  this.clickableRow ? 'ptr' : ''
                              }`}
                              onClick={() => this.clickableRow && this.props.rowClicked(datum)}
                              onKeyUp={handleEnterKeyToClick}
                              tabIndex="0"
                          >
                              {this.fields.map((field) => (
                                  <Col key={`${i}_${field.name}`} {...field.col}>
                                      {field.format === 'header' ? (
                                          <h5>{byString(datum, field.name)}</h5>
                                      ) : (
                                          <>
                                              <label className="d-md-none mt-4 mb-0">
                                                  <span className="bold-text">{field.header}</span>
                                              </label>
                                              <div
                                                  onClick={
                                                      field.onClick
                                                          ? () => field.onClick(field.name, datum)
                                                          : () => {}
                                                  }
                                              >
                                                  {this.getCell(datum, field)}
                                              </div>
                                          </>
                                      )}
                                  </Col>
                              ))}
                          </Row>
                      ))
                    : 'No data to display'}
            </React.Fragment>
        );
    }
}
