import { CONDITIONS, FAMILY, ILLNESSES } from 'components/Form/Patient/Constants';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

const MedicalHistoryReview = ({ state }) => {
    const healthHistory = get(state, `healthHistory`, {});
    const healthConditions = Object.keys(healthHistory).filter((val) => healthHistory[val]);
    const healthLabels = ILLNESSES.map(({ label, key }) => {
        return healthConditions.includes(key) ? label : null;
    }).filter((val) => !isEmpty(val));

    const { allergies, deceasedFamily, vaccineHistory, preventativeHistory } = state;
    return (
        <Row className="px-4">
            <Col md={6} className="border-left px-2">
                <p className="h4">Family History</p>
                {CONDITIONS.map(({ label, key }, idx) => {
                    const formValue = get(state, `${key}`, {});
                    const members = Object.keys(formValue).filter((val) => formValue[val]);
                    const memberLabels = FAMILY.map(({ label, key }) => {
                        return members.includes(key) ? label : null;
                    }).filter((val) => !isEmpty(val));
                    return (
                        <p key={`condition_${idx}`}>
                            <span className="h5">{label}</span>:<br />
                            <strong>{isEmpty(members) ? 'None' : memberLabels.join(' - ')}</strong>
                        </p>
                    );
                })}
                <hr />
                <p className="h4">Deceased Family</p>
                {deceasedFamily.map(({ age, relationship, cause }, idx) => (
                    <div key={`member_${idx}`}>
                        <p className="h5"></p>
                        <strong>{relationship}</strong>
                        <p>
                            Age: {age}
                            <br />
                            Cause: {cause}
                        </p>
                    </div>
                ))}
                <hr />
                <p className="h4">Vaccine History</p>
                {vaccineHistory.map(({ type, date }, idx) => (
                    <div key={`member_${idx}`}>
                        <p className="h5"></p>
                        <strong>{type}</strong>
                        <p>Date: {moment(date).format('MM/YYYY')}</p>
                    </div>
                ))}
            </Col>
            <Col md={6} className="border-left px-2">
                <p className="h4">Preventative History</p>
                {preventativeHistory.map(({ test, date, result, physician }, idx) => (
                    <div key={`member_${idx}`}>
                        <p className="h5"></p>
                        <strong>{test}</strong>
                        <p>
                            Date: {moment(date).format('MM/YYYY')}
                            <br />
                            Result: {result}
                            <br />
                            Physician: {physician}
                        </p>
                    </div>
                ))}
                <hr />
                <p className="h4">Health History</p>
                <ul>
                    {healthLabels.map((label, idx) => {
                        return (
                            <li key={`condition_${idx}`}>
                                <strong>{label}</strong>
                            </li>
                        );
                    })}
                </ul>
                <hr />
                <p className="h4">Allergies</p>
                <ul>
                    {allergies.map(({ cause, reaction }, idx) => {
                        return (
                            <li key={`allergy_${idx}`}>
                                <strong>
                                    {cause}
                                    <FontAwesomeIcon
                                        icon={faLongArrowAltRight}
                                        aria-label="Causes reaction"
                                        size="2x"
                                        className="align-bottom mx-3"
                                    />
                                    {reaction}
                                </strong>
                            </li>
                        );
                    })}
                </ul>
            </Col>
        </Row>
    );
};
export default MedicalHistoryReview;
