import React from 'react';
import { Grid } from '@mui/material';
import { CardTile } from 'components/v2/molecules/CardTile/CardTile';

export const HomeTilesList = () => {
    return (
        <section>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <CardTile />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
        </Grid>
        </section>
    );
};

HomeTilesList.displayName = 'HomeTilesList';
