import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavHoverLocator from '../molecules/NavHoverLocator';
import PageHeader from '../molecules/PageHeader';
import { accessCardLink } from '../../services/ssoService';
import trackService, { AUDITEVENTTYPE } from 'services/trackService';

const FAQs = (props) => {
    useEffect(() => {
        const trackPageVisitSub = trackService
            .customEvent$({ eventType: AUDITEVENTTYPE.MemberPortalViewFaq })
            .subscribe();

        return () => {
            trackPageVisitSub.unsubscribe();
        };
    }, []);

    return (
        <>
            <PageHeader>
                <span className="bold-text">Frequently Asked Questions</span>
            </PageHeader>
            <h2 className="rem-1-125">
                <span className="bold-text">
                    What can I do through my online Alignment Health Plan member account?
                </span>
            </h2>
            <p>
                On members.alignmenthealthplan.com, you can find information related to your plan
                benefits and coverage 24 hours a day, seven days a week. Once logged into our secure
                site, you can:
            </p>
            <ul>
                <li>
                    Access general member forms and information under the{' '}
                    <span className="bold-text">
                        <NavHoverLocator>Forms &amp; Resources</NavHoverLocator>
                    </span>{' '}
                    menu{' '}
                </li>
                <li>
                    {"Find your plan's specific benefit information under "}
                    <span className="bold-text">
                        <Link to="/benefits">Benefit Highlights</Link>
                    </span>
                </li>
                <li>
                    Access a doctor 24/7 by phone or video and find an in-network doctor or hospital
                    under the{' '}
                    <span className="bold-text">
                        <NavHoverLocator>Doctors &amp; Care</NavHoverLocator>
                    </span>{' '}
                    menu
                </li>
                <li>
                    Find an in-network pharmacy and view your prescription history under the{' '}
                    <span className="bold-text">
                        <NavHoverLocator>Prescriptions</NavHoverLocator>
                    </span>{' '}
                    menu
                </li>
                <li>
                    Search through recent{' '}
                    <span className="bold-text">
                        <Link to="/claims">medical claims</Link>
                    </span>{' '}
                    under the Payments menu
                </li>
                <li>
                    Review the{' '}
                    <span className="bold-text">
                        <Link to="/explanation-of-benefits">Explanation of Benefits</Link>
                    </span>{' '}
                    under the{' '}
                    <span className="bold-text">
                        <NavHoverLocator>Forms &amp; Resources</NavHoverLocator>
                    </span>{' '}
                    menu
                </li>
                <li>
                    View and download your member ID card under{' '}
                    <span className="bold-text">
                        <Link to="/id-card">My ID Card</Link>
                    </span>
                </li>
                <li>
                    View plan documents like your Evidence of Coverage booklet under{' '}
                    <span className="bold-text">
                        <Link to="/plan-documents">Plan Documents</Link>
                    </span>
                </li>
                <li>
                    Send your Concierge team a message on{' '}
                    <span className="bold-text">
                        <Link to="/message-center">My Message Center</Link>
                    </span>
                </li>
            </ul>
            <h2 className="rem-1-125">
                <span className="bold-text">
                    How do I send a message through My Message Center? How will I know that someone
                    has responded to me?
                </span>
            </h2>
            <p>
                As a member, you can send us a secure message 24 hours a day, seven days a week
                through{' '}
                <span className="bold-text">
                    <Link to="/message-center">My Message Center</Link>
                </span>
                . Just follow the steps below:
            </p>
            <ul>
                <li>
                    Click on{' '}
                    <span className="bold-text">
                        <Link to="/message-center">My Message Center</Link>
                    </span>
                </li>
                <li>
                    Send a new message by clicking{' '}
                    <span className="bold-text">Write a New Message</span>
                </li>
                <li>
                    Make sure to include a category, subject, and message before clicking{' '}
                    <span className="bold-text">Send</span>
                </li>
            </ul>
            {`My Message Center keeps messages you previously sent to us and any replies you've
                received from your ACCESS On-Demand Concierge team. Once you receive a message
                response, you will be notified via email. If you have any unread messages in your
                inbox, you will see a red notification on the envelope icon on the top of your
                screen.`}

            <h2 className="rem-1-125">
                <span className="bold-text">
                    How can I change my contact information on file such as my home address, phone
                    number, or email address?
                </span>
            </h2>
            <p>
                If you need to make any changes to your contact information,{' '}
                <Link to="/contact-us">call</Link> your 24/7 ACCESS On-Demand Concierge or send us a
                message through My Message Center.
            </p>
            <h2 className="rem-1-125">
                <span className="bold-text">What is the ACCESS On-Demand Concierge program?</span>
            </h2>
            <p>
                {`Your health care needs can happen any time - that's why we launched our ACCESS
                On-Demand Concierge program, exclusively for Alignment Health Plan members. ACCESS
                On-Demand Concierge gives you access 24 hours a day, seven days a week to:`}
            </p>
            <ul>
                <li>A licensed doctor by phone or video</li>
                <li>
                    A <span className="bold-text">concierge team</span> dedicated to helping you
                    navigate the services and benefits available to you with speed, ease and
                    efficiency - from making appointments, scheduling transportation, accessing
                    benefits, or discussing health-related topics with a clinician
                </li>
                <li>
                    Information about your benefits via <span className="bold-text">phone</span>,{' '}
                    <span className="bold-text">web</span> and{' '}
                    <span className="bold-text">mobile app</span>
                </li>
                <li>
                    {`Your ACCESS On-Demand Concierge "black card" to purchase eligible items at
                    participating retailers (Benefits vary by plan. Copays and certain restrictions
                    may apply.)`}
                </li>
            </ul>
            <h2 className="rem-1-125">
                <span className="bold-text">
                    How can I earn rewards through ACCESS On-Demand Concierge?
                </span>
            </h2>
            <p>
                Members of eligible plans can receive rewards for completing select healthy
                activities and preventive screenings. Upon completion of the activity, reward
                dollars will be loaded onto your ACCESS card and can be used to purchase eligible
                items at participating retailers. For more information,{' '}
                <Link to="/contact-us">call</Link> your 24/7 ACCESS On-Demand Concierge or send us a
                message through{' '}
                <span className="bold-text">
                    <Link to="/message-center">My Message Center</Link>
                </span>
                .
            </p>
            <h2 className="rem-1-125">
                <span className="bold-text">Where can I check my ACCESS card balance?</span>
            </h2>
            <p>
                You can check your{' '}
                <span className="bold-text">
                    <a
                        tabIndex="0"
                        className="ptr"
                        href={accessCardLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ACCESS Card Balance
                    </a>
                </span>{' '}
                online under the{' '}
                <span className="bold-text">
                    <NavHoverLocator>Plan &amp; Benefits</NavHoverLocator>
                </span>{' '}
                menu. You can also{' '}
                <span className="bold-text">
                    <Link to="/contact-us">call</Link>
                </span>{' '}
                your ACCESS On-Demand Concierge or send us a message through{' '}
                <span className="bold-text">
                    <Link to="/message-center">My Message Center</Link>
                </span>
                .
            </p>
            <h2 className="rem-1-125">
                <span className="bold-text">
                    What is the difference between the Alignment Health Plan member ID card and the
                    black ACCESS On-Demand Concierge card?
                </span>
            </h2>
            <p>
                Your Alignment Health Plan member ID card is regulated by the Centers for Medicare
                &amp; Medicaid Services (CMS) and includes your ID number, plan type, prescription
                (Rx) benefits, and member services phone line.{' '}
                <span className="bold-text">
                    <em>
                        You will need to show this card to access covered medical and prescription
                        drug benefits
                    </em>
                </span>{' '}
                {`at the doctor's office or at the pharmacy for prescription refills.`}
            </p>
            <p>
                Your ACCESS On-Demand Concierge card connects members to ACCESS On-Demand Concierge
                features. It is black with the Alignment Healthcare logo, your name and unique
                ACCESS account number.
            </p>
            <h2 className="rem-1-125">
                <span className="bold-text">
                    How can I order a replacement member ID and/or ACCESS card?
                </span>
            </h2>
            <p>
                If you have lost your member ID card or need a replacement,{' '}
                <span className="bold-text">
                    <Link to="/contact-us">call</Link>
                </span>{' '}
                your 24/7 ACCESS On-Demand Concierge, or send us a message through{' '}
                <span className="bold-text">
                    <Link to="/message-center">My Message Center</Link>
                </span>
                .
            </p>
            <h2 className="rem-1-125">
                <span className="bold-text">
                    What is the ACCESS On-Demand Concierge phone number?
                </span>
            </h2>
            <p>
                <span className="bold-text">1-833-AHC-ACCESS (1-833-242-2223), TTY 711</span>
            </p>
            <p>Available 24 hours a day, seven days a week, 365 days a year</p>
            <h2 className="rem-1-125">
                <span className="bold-text">
                    What is the Alignment Health Plan Member Services phone number?
                </span>
            </h2>
            <p>
                <span className="bold-text">1-866-634-2247, TTY 711</span>
            </p>
            <p>
                Member Services representatives are available 8 a.m. to 8 p.m. seven days a week
                Oct. 1 - March 31 (except Thanksgiving and Christmas), and 8 a.m. to 8 p.m.
                Monday-Friday April 1 - Sept. 30 (excluding holidays).
            </p>
        </>
    );
};

export default FAQs;
