export const useSessionStorage = () => {
    const setItem = (key: string, value: string) => {
        return sessionStorage.setItem(key, value);
    };

    const getItem = (key: string) => {
        return sessionStorage.getItem(key);
    };

    const getAndRemove = (key: string) => {
        const item = sessionStorage.getItem(key);
        sessionStorage.removeItem(key);
        return item;
    };

    const removeItem = (key: string) => {
        return sessionStorage.removeItem(key);
    };

    return {
        getItem,
        setItem,
        removeItem,
        getAndRemove
    };
};
