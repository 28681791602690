import React, { PureComponent } from 'react';
import promptService from '../../services/promptService';

import styles from './Prompt.module.scss';

class Prompt extends PureComponent {
    state = {
        ...this.initialState,
    };

    subscriptions = [];

    doClose = () => {
        this.setState(() => ({ ...this.initialState }));
    };

    get initialState() {
        return {
            isPromptVisible: false,
        };
    }

    doOpen = (prompt) => {
        this.setState({
            isPromptVisible: true,
            ...prompt,
        });
    };

    componentDidMount() {
        this.subscriptions.push(promptService.closePrompt$.subscribe(() => this.doClose()));
        this.subscriptions.push(
            promptService.openPrompt$.subscribe((prompt) => this.doOpen(prompt))
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const { onActionDeclined, onActionConfirmed, text, title, isPromptVisible } = this.state;
        return (
            (isPromptVisible || null) && (
                <section className={styles.overlay}>
                    <article className={styles.prompt}>
                        <article className={styles['content']}>
                            <div className={styles.title}>{title}</div>
                            <div className={styles.text}>{text}</div>
                        </article>
                        <article className={styles.actions}>
                            <button
                                type="button"
                                className={styles.action}
                                onClick={onActionConfirmed}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className={styles.action}
                                onClick={onActionDeclined}
                            >
                                No
                            </button>
                        </article>
                    </article>
                </section>
            )
        );
    }
}

export default Prompt;
