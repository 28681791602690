import RadiosWithController from 'components/Form/Patient/Input/RadioInput';
import { TextFieldWithController } from 'components/Form/Patient/Input/TextInput';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

const OBGYNInformation = ({ control, state }) => {
    return (
        <>
            <TextFieldWithController
                control={control}
                defaultValue={state?.obgynHistory.pregnancyCount}
                name="obgynHistory.pregnancyCount"
                question="Number of Pregnancies"
                showError={false}
            />

            <TextFieldWithController
                control={control}
                defaultValue={state?.obgynHistory.fullTermBabyCount}
                name="obgynHistory.fullTermBabyCount"
                question="Number of Full Term Babies"
                showError={false}
            />

            <TextFieldWithController
                control={control}
                defaultValue={state?.obgynHistory.prematureBabyCount}
                name="obgynHistory.prematureBabyCount"
                question="Number of Premature Babies"
                showError={false}
            />

            <TextFieldWithController
                control={control}
                defaultValue={state?.obgynHistory.abortionMiscarriagesCount}
                name="obgynHistory.abortionMiscarriagesCount"
                question="Number of Abortions/Miscarriages"
                showError={false}
            />

            <TextFieldWithController
                control={control}
                defaultValue={state?.obgynHistory.livingChildrenCount}
                name="obgynHistory.livingChildrenCount"
                question="Number of Living Children"
                showError={false}
            />
        </>
    );
};
export default OBGYNInformation;
