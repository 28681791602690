import React, { ChangeEvent } from 'react';
import { Control, Controller, ErrorOption } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { isEmpty, get } from 'lodash';
import styles from 'components/molecules/input/InputQuestion.module.scss';
import moment from 'moment';

interface DateInputProps {
    question: string | undefined;
    name?: string;
    onChange: (e: string) => void;
    value: string | undefined;
    disabled?: boolean;
    onlyMonth?: boolean;
}
interface DateInputWithControllerProps {
    control: Control<any>;
    question: string | undefined;
    name: string;
    defaultValue: string | undefined;
    error: ErrorOption;
    disabled?: boolean;
    onlyMonth?: boolean;
}
const DateInput = ({ question, name, onChange, value, disabled, onlyMonth }: DateInputProps) => {
    return (
        <Row>
            <Col>
                <div>
                    <label
                        id={`label-inputquestion-${name}`}
                        className={styles['label-bold']}
                        htmlFor={name}
                    >
                        {question}
                    </label>
                </div>
                <div>
                    <input
                        data-testid={name}
                        className={styles.input}
                        type={onlyMonth ? 'month' : 'date'}
                        name={name}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        id={name}
                        value={moment(value).format(onlyMonth ? 'YYYY-MM' : 'YYYY-MM-DD')}
                        aria-labelledby={`label-inputquestion-${name}`}
                        disabled={disabled}
                    />
                </div>
            </Col>
        </Row>
    );
};

const DateInputWithController = ({
    control,
    name,
    defaultValue = '',
    question,
    error,
    onlyMonth,
    disabled,
}: DateInputWithControllerProps) => (
    <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value }) => (
            <div className="pt-3">
                <DateInput
                    value={value}
                    onChange={(e) => {
                        onChange(e);
                    }}
                    name={name}
                    question={question}
                    onlyMonth={onlyMonth}
                    disabled={disabled}
                />
                {!isEmpty(error) && (
                    <p className={styles['error-message']}>
                        {get(error, 'message', 'This field is incorrect')}
                    </p>
                )}
            </div>
        )}
    />
);
export { DateInputWithController, DateInput };
